import "./index.less";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import { CookiesProvider } from "react-cookie";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import WebSockets from "./components/WebSockets";

ReactDOM.render(
  <div className="app-container">
    <Provider store={store}>
      <CookiesProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        >
          <>
            <WebSockets />
            <App />
          </>
        </GoogleReCaptchaProvider>
      </CookiesProvider>
    </Provider>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
