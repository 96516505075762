import "./style.less";
import moment from "moment";
import { menu, socials } from "./data";
import logo from "../../../assets/images/footer-logo.png";
import { useSelector } from "react-redux";
import { getConfigSelector } from "../selectors";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../_helpers/config";

const FooterLayout = () => {
  const config = useSelector(getConfigSelector);
  return (
    <footer>
      <div className="wrapper">
        <div className="companyDetails">
          <div className="logo">
            <img src={logo} alt="" />
          </div>

          <div className="description">
            <p>Not your conventional wallet.</p>
            <small>
              &copy; {moment().format("YYYY")}{" "}
              <a href={"https://seedon.io"} target={"_blank"}>
                SeedOn Venture
              </a>
              . All copyrights reserved. <br />
              {config && config.appInfo ? (
                <Link to={ROUTES.releaseNotes}>
                  Version {config.appInfo.version}
                </Link>
              ) : (
                ""
              )}
            </small>

            <div className="socials">
              {socials.map((social, socialKey) => (
                <a
                  href={social.href}
                  title={social.title}
                  key={socialKey}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="columns">
          {menu.map((item, key) => (
            <div className="column" key={key}>
              <h3>{item.title}</h3>

              <ul>
                {item.links.map((link, linkId) => (
                  <li key={linkId}>
                    <a
                      href={link.href}
                      target={link.isExternal ? "_blank" : ""}
                      rel={
                        link.isExternal ? "noopener noreferrer nofollow" : ""
                      }
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default FooterLayout;
