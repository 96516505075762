import React, { useEffect } from "react";
import "./style.less";
import { CodeVerification } from "../index";
import { Modal } from "antd";
import { Scrollbars } from "react-custom-scrollbars-2";
import AnimatedScrollDown from "../AnimatedScrollDown";
import { IMFAtOtpsGuideModal } from "./interface";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserRequest,
  getMFAQrRequest,
  resetGetMFAQr,
} from "./userSlice";
import { getQrResponseSelector } from "./selectors";
import { IoClose } from "react-icons/io5";
import { useWindowSize } from "../../hooks/windowSize";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const MFAtOtpsGuideModal: React.FC<IMFAtOtpsGuideModal> = (
  props: IMFAtOtpsGuideModal
) => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const qrResponse = useSelector(getQrResponseSelector);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const isMobile = windowSize.width < 640;

  useEffect(() => {
    return () => {
      dispatch(resetGetMFAQr());
    };
  }, []);
  useEffect(() => {
    if (executeRecaptcha) {
      const sendForm = async () => {
        const token = await executeRecaptcha("mfa");
        dispatch(
          getMFAQrRequest({
            captchaToken: token,
          })
        );
      };
      sendForm();
    }
  }, [executeRecaptcha]);

  const codeVerified = () => {
    dispatch(getCurrentUserRequest());
    props.onSuccess();
  };
  return (
    <Modal
      closeIcon={<IoClose />}
      open={props.isVisible}
      className="authModals walletModals tutorialModal"
      maskClosable={false}
      footer={null}
      closable={props.closable}
      onCancel={props.closeModal}
      forceRender
    >
      <Scrollbars
        universal
        style={{ height: isMobile ? "85vh" : "75vh" }}
        renderView={(props) => <div {...props} className="scroll-view" />}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
      >
        <div className="heading">
          <h2>Almost there</h2>
        </div>

        <div className="auth-steps">
          <p>
            1. Install Google Authenticator app from{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ro&gl=US"
              target="_blank"
            >
              Google Play
            </a>{" "}
            or{" "}
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
            >
              App Store
            </a>
            .
          </p>

          {qrResponse ? (
            <>
              <p>2. Scan the QR Code.</p>

              <img src={qrResponse} alt="" />
            </>
          ) : (
            ""
          )}

          <p>3. Input below the code from your MFA app:</p>
        </div>

        <CodeVerification
          type={props.type ? props.type : "sms"}
          endpoint={props.endpoint}
          onSuccess={codeVerified}
          submitLabel={"Confirm"}
          additionalPayload={props.additionalPayload}
          recipient={props.recipient}
          hideTitle
        />
      </Scrollbars>

      <AnimatedScrollDown />
    </Modal>
  );
};

export default MFAtOtpsGuideModal;
