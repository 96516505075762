import { IResetPasswordParams, ResetPasswordState } from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: ResetPasswordState = {
  loading: false,
  error: null,
  success: false,
};

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    postResetPasswordRequest(
      state,
      action: PayloadAction<IResetPasswordParams>
    ) {
      return {
        ...state,
        loading: true,
        params: action.payload,
        error: null,
        success: false,
      };
    },
    postResetPasswordSuccess(state) {
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      };
    },
    postResetPasswordFailure(state, action) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error.response.data,
      };
    },
    resetState(state) {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  postResetPasswordRequest,
  postResetPasswordSuccess,
  postResetPasswordFailure,
  resetState,
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
