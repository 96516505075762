import { AppState } from "../../../store/rootReducer";
import { createSelector } from "reselect";

const getLoading = (state: AppState) => state.withdraw.loading;
const getError = (state: AppState) => state.withdraw.error;
const getWithdrawEstimateLoading = (state: AppState) =>
  state.withdraw.withdrawEstimateLoading;
const getWithdrawEstimate = (state: AppState) =>
  state.withdraw.withdrawEstimate;
const getWithdrawEstimateError = (state: AppState) =>
  state.withdraw.withdrawEstimateError;
const getShowWithdrawModal = (state: AppState) =>
  state.withdraw.showWithdrawModal;
const postWithdrawLoading = (state: AppState) => state.withdraw.withdrawLoading;
const postWithdrawSuccess = (state: AppState) => state.withdraw.withdrawSuccess;
const postWithdrawError = (state: AppState) => state.withdraw.withdrawError;
const validateWalletLoading = (state: AppState) =>
  state.withdraw.walletValidationLoading;
const validateWalletSuccess = (state: AppState) =>
  state.withdraw.walletValidationSuccess;
const validateWalletError = (state: AppState) =>
  state.withdraw.walletValidationError;

export const getWithdrawErrorSelector = createSelector(
  postWithdrawError,
  (res) => res
);
export const getWithdrawSuccessSelector = createSelector(
  postWithdrawSuccess,
  (res) => res
);
export const getWithdrawLoadingSelector = createSelector(
  postWithdrawLoading,
  (res) => res
);
export const getWalletValidationErrorSelector = createSelector(
  validateWalletError,
  (res) => res
);
export const getWalletValidationSuccessSelector = createSelector(
  validateWalletSuccess,
  (res) => res
);
export const getWalletValidationLoadingSelector = createSelector(
  validateWalletLoading,
  (res) => res
);
export const getWithdrawEstimateErrorSelector = createSelector(
  getWithdrawEstimateError,
  (res) => res
);
export const getWithdrawEstimateSelector = createSelector(
  getWithdrawEstimate,
  (res) => res
);
export const getWithdrawEstimateLoadingSelector = createSelector(
  getWithdrawEstimateLoading,
  (res) => res
);
export const getLoadingSelector = createSelector(getLoading, (res) => res);
export const getErrorSelector = createSelector(getError, (res) => res);
export const getShowWithdrawModalSelector = createSelector(
  getShowWithdrawModal,
  (res) => res
);
