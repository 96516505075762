import { Form, notification } from "antd";
import {
  API_VALIDATION_ERROR_MESSAGES,
  ROUTES,
  VALIDATION_ERROR_MESSAGES,
} from "../../_helpers/config";
import { Button, FormInput } from "../../components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthConfigSelector } from "../../components/Layout/selectors";
import { getErrorSelector, getSuccess, postLoadingSelector } from "./selectors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { postResetPasswordRequest, resetState } from "./resetPasswordSlice";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ResetPassword = () => {
  const [formRef] = Form.useForm();
  const config = useSelector(getAuthConfigSelector);
  const loading = useSelector(postLoadingSelector);
  const error = useSelector(getErrorSelector);
  const success = useSelector(getSuccess);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const submitForm = async () => {
    const token = await executeRecaptcha("resetPassword");
    formRef.validateFields().then((formValues) => {
      dispatch(
        postResetPasswordRequest({
          ...formValues,
          token: searchParams.get("token"),
          captchaToken: token,
        })
      );
    });
  };

  useEffect(() => {
    if (success) {
      notification.success({
        message: "Password updated successfully.",
      });
      formRef.resetFields();
      dispatch(resetState());
      navigate(ROUTES.homePage);
    }
  }, [success]);

  useEffect(() => {
    if (!searchParams.get("token")) {
      navigate(ROUTES.homePage);
    }
  }, []);

  return (
    <div className="walletHeading">
      <div className="container">
        <h1 data-aos="fade-up">Recover password</h1>
      </div>

      <div
        className="userProfileDetailsBox"
        data-aos="fade-up"
        data-aos-delay="600"
      >
        <Form form={formRef}>
          <Form.Item
            name={"newPassword"}
            rules={[
              { required: true, message: VALIDATION_ERROR_MESSAGES.required },
              () => ({
                validator(_, value) {
                  const regExpr = new RegExp(
                    config.passwordConfiguration.regex,
                    "g"
                  );
                  return value && !regExpr.test(value)
                    ? Promise.reject(VALIDATION_ERROR_MESSAGES.passwordFormat)
                    : Promise.resolve();
                },
              }),
            ]}
            hasFeedback
          >
            <FormInput label={"Password"} type={"password"} />
          </Form.Item>

          <Form.Item
            name={"confirm"}
            rules={[
              { required: true, message: VALIDATION_ERROR_MESSAGES.required },
              () => ({
                validator(_, value) {
                  if (
                    !value ||
                    formRef.getFieldValue("newPassword") === value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(VALIDATION_ERROR_MESSAGES.passwordConfirmation)
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <FormInput label="Password Confirmation" type="password" />
          </Form.Item>

          <Button
            loading={loading}
            label="Recover password"
            type="primary"
            onClick={() => submitForm()}
          />
          {error ? (
            <span className={"generalError"}>
              {API_VALIDATION_ERROR_MESSAGES[error.message]}
            </span>
          ) : (
            ""
          )}
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
