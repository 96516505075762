import React from "react";
import "./style.less";
import { ICoinPanelProps } from "./interface";
import { useSelector } from "react-redux";
import { getWalletSelector } from "../../selectors";
import PriceChart from "./PriceChart";
import { Spin, Tooltip } from "antd";
import { formatNumber } from "../../../../_helpers/numberFormatter";
import { CURRENCIES } from "../../../../_helpers/config";
import { getCurrentUserSelector } from "../../../User/selectors";

const CoinPanelHead = (props: ICoinPanelProps) => {
  const wallet = useSelector(getWalletSelector);
  const user = useSelector(getCurrentUserSelector);

  if (wallet[`${props.coin.contractAddress}FundsLoading`] !== false) {
    return (
      <div className="loading inline">
        <Spin />
      </div>
    );
  }

  return (
    <div className="panelHead">
      <img src={props.coin.iconUrl} alt="" />

      <div className="details">
        <div className="status">
          <Tooltip title={props.coin.tokenName} placement="right">
            <h3>{props.coin.tokenSymbol}</h3>
          </Tooltip>

          <p>
            {user ? CURRENCIES[user.state.properties.selectedCurrency] : ""}
            {formatNumber(
              (parseFloat(props.availableFunds.availableFunds) +
                parseFloat(props.availableFunds.lockedFunds)) *
                wallet[`${props.coin.contractAddress}Funds`].tokenData
                  .currentPrice
            )}
          </p>
        </div>

        <div className="stats">
          <h3
              className={
                wallet[`${props.coin.contractAddress}Funds`].tokenData
                    .priceChangePercentage24h > 0
                    ? "green"
                    : ""
              }
          >
            {user ? CURRENCIES[user.state.properties.selectedCurrency] : ""}
            {
              wallet[`${props.coin.contractAddress}Funds`].tokenData
                .currentPrice
            }
          </h3>

          <p
            className={
              wallet[`${props.coin.contractAddress}Funds`].tokenData
                .priceChangePercentage24h > 0
                ? "green"
                : ""
            }
          >
            {
              wallet[`${props.coin.contractAddress}Funds`].tokenData
                .priceChangePercentage24h
            }
            %
          </p>
        </div>

        <div className="chart">
          <PriceChart
            data={wallet[`${props.coin.contractAddress}Funds`].tokenData}
          />
        </div>
      </div>
    </div>
  );
};

export default CoinPanelHead;
