import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getCountriesRequest,
  getCountriesSuccess,
  putRequestSmsOtpFailure,
  putRequestSmsOtpRequest,
  putRequestSmsOtpSuccess,
  putResendSmsOtpRequest,
} from "./phoneValidationSlice";

import { axios } from "../../services/axios.service";

/*
  Worker Saga: Fired on getCountriesRequest action
*/
function* getCountriesSaga(action: ReturnType<typeof getCountriesRequest>) {
  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_AUTH_API_URL}/countries`,
      action.payload
    );
    yield put(
      getCountriesSuccess({
        countries: response.data,
      })
    );
  } catch (e: any) {}
}

function* putRequestSmsOtpSaga(
  action: ReturnType<typeof putRequestSmsOtpRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/phone-number`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(putRequestSmsOtpSuccess());
  } catch (e: any) {
    yield put(putRequestSmsOtpFailure({ error: e }));
  }
}

function* putResendSmsOtpSaga(action) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/phone-number/resend-otp`,
      {},
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
  } catch (e: any) {}
}
//putResendSmsOtpRequest
/*
  Starts worker saga on latest dispatched `getCountriesRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* loginSaga() {
  yield all([
    takeLatest(getCountriesRequest, getCountriesSaga),
    takeLatest(putRequestSmsOtpRequest, putRequestSmsOtpSaga),
    takeLatest(putResendSmsOtpRequest, putResendSmsOtpSaga),
  ]);
}

export default loginSaga;
