import "./style.less";
import { useEffect, useState } from "react";
import { localStorageGet, localStorageSet } from "../../_helpers/storage";
import { ROUTES } from "../../_helpers/config";
import Button from "../Button";
import { ButtonTypes } from "../Button/types";

const CookieDisclaimer = () => {
  const [visible, setVisible] = useState(true);

  const setCookies = () => {
    localStorageSet("cookieConsent", true);
    setVisible(false);
  };

  useEffect(() => {
    const hasCookies = localStorageGet("cookieConsent");

    if (hasCookies) {
      setVisible(false);
    }
  }, []);

  return (
    visible && (
      <div className="cookie-wrapper">
        <p>
          <strong>We use cookies in the delivery of our services.</strong> To
          learn about the cookies we use and about your preferences and opt-out
          choices, <a href={ROUTES.cookiePolicy}>please click here</a>. By using
          our platform you agree to our use of cookies.
        </p>

        <div className="buttons">
          <Button
            onClick={setCookies}
            type={ButtonTypes.PRIMARY}
            label="Accept"
          />
          <Button
            onClick={() => setVisible(false)}
            type={ButtonTypes.SECONDARY}
            label="Dismiss"
          />
        </div>
      </div>
    )
  );
};

export default CookieDisclaimer;
