import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setInterceptor } from "../../services/axios.service";
import { ROUTES, ROUTES_WITHOUT_WALLET } from "../../_helpers/config";

// Components
import { BackTop, Layout, Spin } from "antd";

// Base components
import { News } from "../index";
import Maintenance from "../Maintenance";
import HeaderLayout from "./Header/HeaderLayout";
import FooterLayout from "./Footer/FooterLayout";
import ReferralDisclaimer from "../ReferralDisclaimer";
import SecretRecoveryModal from "../SecretRecoveryModal";
import { AnnouncementsModal } from "../AnnouncementsModal";
import CookieDisclaimer from "../CookieDisclaimer/CookieDisclaimer";

// Selectors
import {
  getAnnouncementsModalVisibleSelector,
  getAnnouncementsSelector,
} from "../AnnouncementsModal/selectors";
import { getCurrentUserSelector } from "../User/selectors";

// Slices
import {
  getAnnouncementsRequest,
  setAnnouncementsModalVisible,
} from "../AnnouncementsModal/announcementSlice";
import { getCurrentUserRequest } from "../User/userSlice";
import { getWhitelistedCoinsRequest } from "../Wallet/walletSlice";
import { getAuthConfigRequest, getConfigRequest } from "./layoutSlice";
import { getUnreadNotificationsCountRequest } from "../Notifications/notificationSlice";
import { DottedBackground } from "../DottedBackground";
import classNames from "classnames";

const { Content } = Layout;

const AppLayout = (props: any) => {
  const { children, extraClasses = "" } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [token, _, removeCookie] = useCookies(["sOnFinanceToken"]);
  const [walletSetupModalVisible, setWalletSetupModalVisible] = useState(false);

  const currentUser = useSelector(getCurrentUserSelector);
  const announcements = useSelector(getAnnouncementsSelector);

  const announcementsModalVisible = useSelector(
    getAnnouncementsModalVisibleSelector
  );

  useEffect(() => {
    if (currentUser && currentUser.state) {
      setWalletSetupModalVisible(
        !currentUser.state.properties?.recoveryPhraseVerified
      );
    }
  }, [currentUser]);

  const closeWalletSetup = (isSuccess) => {
    setWalletSetupModalVisible(false);

    if (!isSuccess) {
      navigate(ROUTES.homePage);
    }
  };

  useEffect(() => {
    const intended = localStorage.getItem("intended");

    if (intended) {
      localStorage.removeItem("intended");
      navigate(intended);
    }

    setInterceptor(dispatch, removeCookie, navigate);

    dispatch(getAuthConfigRequest());

    if (token.sOnFinanceToken) {
      dispatch(getConfigRequest());
      dispatch(getCurrentUserRequest());
      dispatch(getWhitelistedCoinsRequest());
      dispatch(getAnnouncementsRequest({}));
      dispatch(getUnreadNotificationsCountRequest());
    }

    return () => {
      const dotsContainer = document.getElementById("dots-container");
      if (dotsContainer) {
        dotsContainer.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (announcements.length) {
      dispatch(setAnnouncementsModalVisible(true));
    }
  }, [announcements]);

  useEffect(() => {
    if (announcementsModalVisible) {
      document.body.classList.add("hide-scroll");
    } else {
      document.body.classList.remove("hide-scroll");
    }

    document.body.style.overflow = announcementsModalVisible ? "hidden" : "";
  }, [announcementsModalVisible]);

  return (
    <Layout className={`layout homePage ${extraClasses}`}>
      <Maintenance />
      <DottedBackground />
      <ReferralDisclaimer />

      <HeaderLayout />

      {currentUser ? (
        !currentUser.state.properties?.recoveryPhraseVerified &&
        !ROUTES_WITHOUT_WALLET.includes(location.pathname) &&
        !props.isNotFound &&
        location.pathname.indexOf("/article/") === -1 &&
        location.pathname.indexOf("/events/") === -1 ? (
          <SecretRecoveryModal
            isVisible={walletSetupModalVisible}
            closeModal={(isSuccess) => closeWalletSetup(isSuccess)}
          />
        ) : (
          <Content>{children}</Content>
        )
      ) : (
        <div className="loading inline">
          <Spin />
        </div>
      )}

      <News />
      <CookieDisclaimer />
      <BackTop />
      <FooterLayout />

      {announcementsModalVisible ? <AnnouncementsModal /> : ""}
    </Layout>
  );
};

export default AppLayout;
