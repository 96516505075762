import { CardFormInterface } from "./cardForm.interface";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import { getCardTypeIcon, validateCardNumber } from "./utils";
import React, { useEffect, useState } from "react";
import { months, years } from "./config";
import FormInput from "../../../components/FormInput";
import FormDropdownSelect from "../../../components/DropdownSelect";
import Button from "../../../components/Button";
import "../styles.less";
import FormInputNumber from "../../../components/FormInputNumber";
import { ButtonTypes } from "../../../components/Button/types";
const { Title, Paragraph } = Typography;

const CardForm: React.FC<CardFormInterface> = (props: CardFormInterface) => {
  const { withNewInput, onSuccess } = props;
  const [formCard] = Form.useForm();

  const cardNumber = Form.useWatch("cardNumber", formCard);
  const [cardIcon, setCardIcon] = useState(null);

  useEffect(() => {
    setCardIcon(getCardTypeIcon(cardNumber));
  }, [cardNumber]);

  useEffect(() => {
    return () => {
      withNewInput.setValue(null);
      formCard.resetFields();
    };
  }, []);

  const onFinish = () => {
    formCard.validateFields().then((values) => {
      const dataToSend = {
        ...values,
        expiryMonth: values["expiryMonth"],
        expiryYear: values["expiryYear"],
      };
      onSuccess(true);
    });
  };

  return (
    <Row className="paymentDetailsForm">
      <Form
        form={formCard}
        name="nest-messages"
        onFinish={onFinish}
        autoComplete={"off"}
        initialValues={withNewInput.initialValue}
      >
        {withNewInput && (
          <Form.Item
            hasFeedback
            name="investAmount"
            rules={[
              {
                required: true,
                message: "Invest amount is required.",
              },
            ]}
          >
            <FormInputNumber
              min={withNewInput.min}
              max={withNewInput.max}
              step={"100"}
              type={"number"}
              onChange={withNewInput.setValue}
              label="Invest amount"
            />
          </Form.Item>
        )}
        <Form.Item
          hasFeedback
          name="cardNumber"
          className="cardNumber"
          rules={[
            {
              required: true,
              message: "Card number is required.",
            },
            {
              max: 19,
              message: "Max 19 characters",
            },
            {
              validator: (_, value) => {
                if (value) {
                  return validateCardNumber(value)
                    ? Promise.resolve()
                    : Promise.reject("Card number is invalid.");
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <FormInput type={"number"} label="Card number" icon={cardIcon} />
        </Form.Item>

        <Form.Item
          hasFeedback
          name="cardholderName"
          rules={[
            {
              required: true,
              message: "Cardholder name is required.",
            },
            {
              max: 40,
              message: "Max 40 characters",
            },
          ]}
        >
          <FormInput type={"input"} label="Cardholder name" />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="expiryMonth"
              rules={[
                {
                  required: true,
                  message: "Expiry month is required.",
                },
              ]}
            >
              <FormDropdownSelect items={months} label="Expiry month" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="expiryYear"
              rules={[
                {
                  required: true,
                  message: "Expiry year is required.",
                },
              ]}
            >
              <FormDropdownSelect items={years} label="Expiry year" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              hasFeedback
              name="cvv"
              rules={[
                {
                  required: true,
                  message: "CVV number is required.",
                },
                {
                  max: 4,
                  message: "Max 4 characters",
                },
              ]}
            >
              <FormInput type={"number"} label="CVV number" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              hasFeedback
              name="zip"
              rules={[
                {
                  required: true,
                  message: "ZIP code is required.",
                },
                {
                  max: 10,
                  message: "Max 10 characters",
                },
              ]}
            >
              <FormInput type={"number"} label="ZIP code" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="separatorLine" />

        <Row className="formDisclaimer">
          <Title level={3} className="formDisclaimerTitle">
            Terms and conditions
          </Title>

          <Paragraph className="formDisclaimerDescription">
            SeedOn Venture is not intended as, and does not provide, any
            investment or financial advice whatsoever. As with any financial or
            investment decisions, you should conduct your own research and due
            diligence investigation, to properly evaluate the benefits and risks
            of any investment or financial transaction performed on or in
            connection to SeedOn Venture. You should also seek the advice and
            guidance of qualified accountants, financial advisors, tax advisors,
            legal counsels and investment advisors, in connection with any
            investment or financial transaction performed on or in connection to
            SeedOn Venture. Read more here{" "}
            <a href="https://seedon.io/terms-and-conditions/" target={"_blank"}>
              Terms and conditions.
            </a>
          </Paragraph>
        </Row>

        <Form.Item
          name="remember"
          valuePropName="checked"
          className="disclaimerCheckbox"
          rules={[
            {
              required: true,
              message: "Please check terms and conditions.",
            },
          ]}
        >
          <Checkbox>
            I acknowledge and agree with the{" "}
            <a href="https://seedon.io/terms-and-conditions/" target={"_blank"}>
              Terms and Conditions
            </a>{" "}
            provided by SeedOn Venture.
          </Checkbox>
        </Form.Item>

        <Button
          disabled={true}
          type={ButtonTypes.PRIMARY}
          extraClasses="submitButton"
          label="Invest"
          onClick={() => onFinish()}
        />
      </Form>
    </Row>
  );
};

export default CardForm;
