import React, { useEffect } from "react";
import { ISwapPreviewCoinSelectProps } from "./interfaces";
import FormDropdownSelect from "../DropdownSelect";
import FormInputNumber from "../FormInputNumber";
import debounce from "lodash/debounce";
import { Form, InputNumber } from "antd";
import { formatStringNumber } from "../../_helpers/numberFormatter";

export const SwapPreviewCoinSelect = (props: ISwapPreviewCoinSelectProps) => {
  const {
    onTokenChange,
    onValueChange,
    availableTokens,
    currentSelectedToken,
    currentValue,
  } = props;
  const [formRef] = Form.useForm();

  useEffect(() => {
    formRef.setFieldsValue({ selectedToken: currentSelectedToken });
  }, []);

  return (
    <Form form={formRef}>
      <div className="swapPreviewCoinSelectComponent">
        <div className="tokenDetailsContainer">
          <div className="tokenIcon">
            <img
              src={currentSelectedToken.iconUrl}
              alt={currentSelectedToken.tokenSymbol}
            />
          </div>
          <div className="tokenDetails">
            <div className="tokenSelect">
              <Form.Item name="selectedToken">
                <FormDropdownSelect
                  onChange={onTokenChange}
                  items={availableTokens}
                  keyToShow={"tokenSymbol"}
                  disabled={availableTokens?.length === 1}
                />
              </Form.Item>
            </div>
            <div className="tokenName">{currentSelectedToken.tokenName}</div>
          </div>
        </div>
        <div className="inputContainer">
          <InputNumber
            min={0}
            step={"0.000000000000000001"}
            formatter={(value) => {
              return formatStringNumber(value as any);
            }}
            onChange={debounce(onValueChange, 500)}
            disabled={!currentSelectedToken}
            defaultValue={0}
            value={currentValue}
          />
        </div>
      </div>
    </Form>
  );
};
