import React from "react";
import "./style.less";
import { IModalProps } from "./interface";
import { Modal } from "antd";
import { CodeVerification } from "../index";
import { IoClose } from "react-icons/io5";

const ConfirmOldPhoneNumber: React.FC<IModalProps> = (props: IModalProps) => {
  const phoneNumberValidationSuccess = () => {
    props.onSuccess();
  };

  return (
    <Modal
      closeIcon={<IoClose />}
      footer={null}
      className="authModals"
      open={props.isVisible}
      onCancel={() => props.closeModal()}
      forceRender
    >
      <CodeVerification
        type="sms"
        submitLabel="Continue"
        recipient={props.phoneNumber}
        endpoint={`${process.env.REACT_APP_AUTH_API_URL}/phone-number/validate`}
        onSuccess={() => phoneNumberValidationSuccess()}
      />
    </Modal>
  );
};

export default ConfirmOldPhoneNumber;
