import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICampaignDetails,
  ICampaignDetailsState,
  IGetCampaignDetailsPayload,
} from "./types";

const initialState: ICampaignDetailsState = {
  campaignDetails: null,
  campaignDetailsError: null,
  campaignDetailsLoading: false,

  campaignPoolData: null,
  campaignPoolDataLoading: false,
  campaignPoolDataError: null,
};

const campaignDetails = createSlice({
  name: "campaignDetails",
  initialState,
  reducers: {
    resetState(state) {
      return {
        ...initialState,
      };
    },
    getCampaignDetails(
      state,
      action: PayloadAction<IGetCampaignDetailsPayload>
    ) {
      return {
        ...state,
        campaignDetails: null,
        campaignDetailsLoading: true,
        params: action.payload,
      };
    },
    getCampaignDetailsSuccess(state, action: PayloadAction<ICampaignDetails>) {
      return {
        ...state,
        campaignDetails: action.payload,
        campaignDetailsLoading: false,
      };
    },
    getCampaignDetailsFailure(state) {
      return {
        ...state,
        tokenData: null,
        tokenDataLoading: false,
      };
    },
    getCampaignPoolData(
      state,
      action: PayloadAction<IGetCampaignDetailsPayload>
    ) {
      return {
        ...state,
        campaignPoolData: null,
        campaignPoolDataLoading: true,
        params: action.payload,
      };
    },
    getCampaignPoolDataSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        campaignPoolData: action.payload,
        campaignPoolDataLoading: false,
      };
    },
    getCampaignPoolDataFailure(state) {
      return {
        ...state,
        campaignPoolData: null,
        campaignPoolDataLoading: false,
      };
    },
  },
});

export const {
  resetState,
  getCampaignDetails,
  getCampaignDetailsFailure,
  getCampaignDetailsSuccess,
  getCampaignPoolData,
  getCampaignPoolDataSuccess,
  getCampaignPoolDataFailure,
} = campaignDetails.actions;

export default campaignDetails.reducer;
