import React from "react";
import "./style.less";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";
import { IOtpModal } from "./interface";
import { CodeVerification } from "../index";

const OtpModal = (props: IOtpModal) => {
  return (
    <>
      <Modal
        closeIcon={<IoClose />}
        open={props.isVisible}
        className="authModals walletModals"
        maskClosable={false}
        footer={null}
        closable={false}
        forceRender
      >
        <CodeVerification
          type={props.type ? props.type : "sms"}
          endpoint={props.endpoint}
          onSuccess={props.onSuccess}
          submitLabel={"Confirm"}
          additionalPayload={props.additionalPayload}
          recipient={props.recipient}
        />
      </Modal>
    </>
  );
};

export default OtpModal;
