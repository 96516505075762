import React from "react";
import "./style.less";
import { IAnnouncementProps } from "./interface";

export const Announcement: React.FC<IAnnouncementProps> = (
  props: IAnnouncementProps
) => {
  return (
    <div className="content" id={`content-${props.index}`}>
      <h3 className="title">{props.announcement.title}</h3>

      <h4 className="date">
        {props.announcement.appVersion}{" "}
        <span>{props.announcement.releaseDate}</span>
      </h4>

      {props.announcement.description ? (
        <p className="description">{props.announcement.description}</p>
      ) : (
        ""
      )}

      {props.announcement.imageAsset ? (
        <div className="imageContainer">
          <img src={props.announcement.imageAsset.url} alt="" />
        </div>
      ) : (
        ""
      )}

      <ul className="mainList">
        <li>
          <h3>{props.announcement.contentTitle}</h3>

          <ul>
            {props.announcement.content.map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};
