import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getReferralRequest,
  referFailure,
  referRequest,
  referSuccess,
} from "./referralSlice";

import { axios } from "../../services/axios.service";
import { getReferralSuccess } from "./referralSlice";

/*
  Worker Saga: Fired on getCountriesRequest action
*/
function* getReferralSaga(action: ReturnType<typeof getReferralRequest>) {
  try {
    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_AUTH_API_URL}/referrals`,
      {},
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(
      getReferralSuccess({
        referral: response.data,
      })
    );
  } catch (e: any) {}
}

function* referSaga(action: ReturnType<typeof referRequest>) {
  try {
    const response = yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/referrals/send`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(referSuccess());
  } catch (e: any) {
    yield put(referFailure());
  }
}

function* loginSaga() {
  yield all([takeLatest(getReferralRequest, getReferralSaga)]);
  yield all([takeLatest(referRequest, referSaga)]);
}

export default loginSaga;
