import { Link } from "react-router-dom";

// Components
import { Badge, Menu } from "antd";
import { ROUTES } from "../../../_helpers/config";
import { GtmEvents } from "../../../_helpers/types";
import { pushGtmEvent } from "../../../_helpers/gtm";
import { getAnnouncementsRequest } from "../../AnnouncementsModal/announcementSlice";

// Assets
import {
  MdPersonAddAlt,
  MdOutlineManageAccounts,
  MdFavoriteBorder,
} from "react-icons/md";
import { AiOutlineCalendar, AiOutlineUnorderedList } from "react-icons/ai";
import { RiWalletLine } from "react-icons/ri";
import { BsNewspaper } from "react-icons/bs";
import { TbArrowsLeftRight } from "react-icons/tb";
import { CgDollar } from "react-icons/cg";
import {
  RiMoneyEuroCircleLine,
  RiMoneyDollarCircleLine,
  RiMoneyPoundCircleLine,
} from "react-icons/ri";
import {
  FiBell,
  FiMessageCircle,
  FiThumbsUp,
  FiUser,
  FiCreditCard,
  FiFolder,
  FiPlus,
} from "react-icons/fi";

import { TiThLargeOutline } from "react-icons/ti";

const getCurrencyIcon = (currency) => {
  switch (currency) {
    case "USD":
      return <RiMoneyDollarCircleLine />;
    case "EUR":
      return <RiMoneyEuroCircleLine />;
    case "GBP":
      return <RiMoneyPoundCircleLine />;
    default:
      return <RiMoneyDollarCircleLine />;
  }
};

export const menuProtected = (
  dispatch,
  navigate,
  unreadNotificationsCount,
  badgeCount,
  currencies = [],
  selectedCurrency = "USD"
) => [
  {
    key: "spotWallet",
    label: (
      <div>
        <FiCreditCard /> Spot wallet
      </div>
    ),
    popupClassName: "headerSubMenu",
    children: [
      {
        label: (
          <Link
            to={ROUTES.buy}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_BUY)}
          >
            <FiPlus />
            Buy
          </Link>
        ),
        key: ROUTES.buy,
      },
      {
        label: (
          <Link
            to={ROUTES.vesting}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_VESTING)}
          >
            <AiOutlineCalendar />
            Vesting
          </Link>
        ),
        key: ROUTES.vesting,
      },
      {
        label: (
          <Link
            to={ROUTES.wallet}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_WALLET)}
          >
            <RiWalletLine />
            Wallet
          </Link>
        ),
        key: ROUTES.wallet,
      },
      {
        label: (
          <Link
            to={ROUTES.swap}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_SWAP)}
          >
            <TbArrowsLeftRight />
            Swap
          </Link>
        ),
        key: ROUTES.swap,
      },
      {
        label: (
          <Link
            to={ROUTES.transactions}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_TRANSACTIONS)}
          >
            <AiOutlineUnorderedList />
            Crypto transactions
          </Link>
        ),
        key: ROUTES.transactions,
      },
      {
        label: (
          <Link
            to={ROUTES.stake}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_STAKING)}
          >
            <CgDollar />
            Staking
          </Link>
        ),
        key: ROUTES.stake,
      },
    ],
  },
  {
    key: ROUTES.referral,
    label: (
      <Link
        to={ROUTES.referral}
        onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_REFERRAL)}
      >
        <MdPersonAddAlt />
        Referral
      </Link>
    ),
  },
  // {
  //   key: ROUTES.launchpad,
  //   label: (
  //     <Link
  //       to={ROUTES.launchpad}
  //       onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_LAUNCHPAD)}
  //     >
  //       <TiThLargeOutline />
  //       Launchpad
  //     </Link>
  //   ),
  // },
  {
    key: ROUTES.news,
    label: (
      <Link
        to={ROUTES.news}
        onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_NEWS)}
      >
        <BsNewspaper />
        News
      </Link>
    ),
  },
  {
    key: ROUTES.events,
    label: (
      <Link
        to={ROUTES.events}
        onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_EVENTS)}
      >
        <AiOutlineCalendar />
        Events
      </Link>
    ),
  },
  {
    key: "profile",
    label: (
      <div>
        <Badge
          count={unreadNotificationsCount + badgeCount}
          size="small"
          overflowCount={9}
        >
          <MdOutlineManageAccounts />
        </Badge>
        Account
      </div>
    ),
    popupClassName: "headerSubMenu",
    children: [
      {
        key: "/announcements",
        label: (
          <div
            className="submenuItem"
            onClick={() =>
              dispatch(
                getAnnouncementsRequest({
                  all: true,
                })
              )
            }
          >
            <FiMessageCircle />
            Announcements
          </div>
        ),
      },
      {
        key: ROUTES.favoriteNews,
        label: (
          <div
            className="submenuItem"
            onClick={() => navigate(ROUTES.favoriteNews)}
          >
            <FiThumbsUp />
            Favorite news
          </div>
        ),
      },
      {
        key: ROUTES.notifications,
        label: (
          <div
            className="submenuItem"
            onClick={() => navigate(ROUTES.notifications)}
          >
            <Badge
              count={unreadNotificationsCount}
              size="small"
              overflowCount={9}
            >
              <FiBell />
            </Badge>
            Notifications
          </div>
        ),
      },
      // {
      //   key: ROUTES.portfolio,
      //   label: (
      //     <div
      //       className="submenuItem"
      //       onClick={() => navigate(ROUTES.portfolio)}
      //     >
      //       <FiFolder />
      //       Portfolio
      //     </div>
      //   ),
      // },
      // {
      //   key: ROUTES.watchlist,
      //   label: (
      //     <div
      //       className="submenuItem"
      //       onClick={() => navigate(ROUTES.watchlist)}
      //     >
      //       <MdFavoriteBorder />
      //       Watchlist
      //     </div>
      //   ),
      // },
      {
        key: ROUTES.userProfile,
        label: (
          <div
            className="submenuItem"
            onClick={() => (window.location.href = ROUTES.userProfile)}
          >
            <Badge count={badgeCount} size="small" overflowCount={9}>
              <FiUser />
            </Badge>
            Profile settings
          </div>
        ),
      },
    ],
  },
  {
    key: "currency",
    label: (
      <div>
        {getCurrencyIcon(selectedCurrency)}
        {selectedCurrency}
      </div>
    ),
    popupClassName: "headerSubMenu currencySubMenu",
    children: currencies,
  },
];

export const menu = [
  {
    id: "referral",
    name: "Referral",
    href: ROUTES.referral,
    GtmKey: GtmEvents.MENU_NAVIGATION_REFERRAL,
  },
  // {
  //   id: "launchpad",
  //   name: "Launchpad",
  //   href: ROUTES.launchpad,
  //   GtmKey: GtmEvents.MENU_NAVIGATION_LAUNCHPAD,
  // },
  {
    id: "news",
    name: "News",
    href: ROUTES.news,
    GtmKey: GtmEvents.MENU_NAVIGATION_NEWS,
  },
  {
    id: "events",
    name: "Events",
    href: ROUTES.events,
    GtmKey: GtmEvents.MENU_NAVIGATION_VESTING,
  },
];

export const spotWalletDropdown = (
  <Menu
    items={[
      {
        key: "buy",
        label: (
          <Link
            to={ROUTES.buy}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_BUY)}
          >
            Buy
            <FiPlus />
          </Link>
        ),
      },
      {
        key: "vesting",
        label: (
          <Link
            to={ROUTES.vesting}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_VESTING)}
          >
            Vesting
            <AiOutlineCalendar />
          </Link>
        ),
      },
      {
        key: "wallet",
        label: (
          <Link
            to={ROUTES.wallet}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_WALLET)}
          >
            Wallet
            <RiWalletLine />
          </Link>
        ),
      },
      {
        key: "swap",
        label: (
          <Link
            to={ROUTES.swap}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_SWAP)}
          >
            <TbArrowsLeftRight />
            Swap
          </Link>
        ),
      },
      {
        key: "transactions",
        label: (
          <Link
            to={ROUTES.transactions}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_TRANSACTIONS)}
          >
            <AiOutlineUnorderedList />
            Crypto transactions
          </Link>
        ),
      },
      {
        key: "stake",
        label: (
          <Link
            to={ROUTES.stake}
            onClick={() => pushGtmEvent(GtmEvents.MENU_NAVIGATION_STAKING)}
          >
            <CgDollar />
            Staking
          </Link>
        ),
      },
    ]}
  />
);
