import { FiArrowRight, FiCopy, FiExternalLink } from "react-icons/fi";
import primaryAccountIcon from "../../../../assets/images/primary-account.png";
import secondaryAccountIcon from "../../../../assets/images/secondary-account.png";
import React, { useEffect, useState } from "react";
import { ITransactionPanelProps } from "./interface";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletDataSelector,
  getWhitelistedCoinsSelector,
} from "../../selectors";
import copy from "copy-to-clipboard";
import { notification } from "antd";
import {
  formatNumber,
  formatStringNumber,
} from "../../../../_helpers/numberFormatter";
import moment from "moment";
import { pushGtmEvent } from "../../../../_helpers/gtm";
import { GtmEvents } from "../../../../_helpers/types";
import { displayWalletAddress } from "../../../../_helpers/walletAddressTruncate";
import { StakeType } from "../../../Staking/StakeModal/types";
import { getWalletDataRequest } from "../../walletSlice";

const TransactionPanelBody = (props: ITransactionPanelProps) => {
  const dispatch = useDispatch();

  const [formattedTransaction, setFormattedTransaction] = useState(null);
  const whitelistedCoins = useSelector(getWhitelistedCoinsSelector);
  const walletData = useSelector(getWalletDataSelector);

  const formatTransactionForDisplay = (data) => {
    const transaction = { ...data };
    whitelistedCoins.map((coin) => {
      if (
        transaction?.metadata?.type === "fiatDepositTransactionMetadata" &&
        transaction?.metadata?.conversionCurrency
      ) {
        if (coin.contractAddress === transaction.metadata.conversionCurrency) {
          transaction.tokenSymbol = coin.tokenSymbol;
          return coin;
        }
      } else {
        if (coin.contractAddress === transaction.contractAddress) {
          transaction.tokenSymbol = coin.tokenSymbol;
          return coin;
        }
      }
      return coin;
    });
    return transaction;
  };

  const copyTransactionId = (transactionId) => {
    copy(transactionId);
    copy(transactionId);
    pushGtmEvent(GtmEvents.COPY_WALLET_TO_CLIPBOARD);
    notification.success({
      message: "Transaction Id successfully copied to clipboard.",
    });
  };

  const getImageFromContractAddress = (contractAddress) => {
    let imageUrl = "";
    whitelistedCoins.map((item) => {
      if (item.contractAddress === contractAddress) {
        imageUrl = item.iconUrl;
        return imageUrl;
      }
    });
    return imageUrl;
  };

  const getTokenSymbolFromContractAddress = (contractAddress) => {
    let token = "";
    whitelistedCoins.map((item) => {
      if (item.contractAddress === contractAddress) {
        token = item.tokenSymbol;
        return token;
      }
    });
    return token;
  };

  useEffect(() => {
    setFormattedTransaction(formatTransactionForDisplay(props.transaction));
    dispatch(getWalletDataRequest());
  }, []);

  return (
    <div className="transactionWrapper">
      {formattedTransaction ? (
        <>
          <div className="transactionStatus">
            <div className="row">
              <h3>Status</h3>

              <div
                className={`confirmed ${formattedTransaction.status.toLowerCase()}`}
              >
                <span>
                  {formattedTransaction.status === "LOCKED"
                    ? "Successful"
                    : formattedTransaction.status}
                </span>
              </div>
            </div>
            {formattedTransaction.metadata.type ===
              "tokenSwapTransactionMetadata" &&
              formattedTransaction.status === "FAILED" && (
                <div className={"row"}>
                  <p className="slippage-warning">
                    Insufficient output amount. Usually this occurs when the
                    slippage is too low, and due to price volatility, your token
                    swap could not be processed.
                  </p>
                </div>
              )}

            <div className="row">
              {(formattedTransaction.metadata.type ===
                "depositTransactionMetadata" ||
                formattedTransaction.metadata.type ===
                  "withdrawalTransactionMetadata" ||
                formattedTransaction.metadata.type ===
                  "tokenSwapTransactionMetadata") &&
              formattedTransaction.metadata.bscTxHash ? (
                <a
                  target="_blank"
                  className="blockExplorer"
                  href={`${process.env.REACT_APP_BSC_URL}/tx/${formattedTransaction.metadata.bscTxHash}`}
                >
                  View on block explorer
                  <span className="icon">
                    <FiExternalLink />
                  </span>
                </a>
              ) : (
                ""
              )}

              {(formattedTransaction.metadata.type ===
                "depositTransactionMetadata" ||
                formattedTransaction.metadata.type ===
                  "withdrawalTransactionMetadata" ||
                formattedTransaction.metadata.type ===
                  "tokenSwapTransactionMetadata") &&
              formattedTransaction.metadata.bscTxHash ? (
                <a
                  onClick={() =>
                    copyTransactionId(formattedTransaction.metadata.bscTxHash)
                  }
                >
                  Copy Transaction ID
                  <span className="icon">
                    <FiCopy />
                  </span>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>

          <hr />

          {formattedTransaction.metadata.type ===
            "depositTransactionMetadata" ||
          formattedTransaction.metadata.type ===
            "withdrawalTransactionMetadata" ? (
            <>
              <div className="transactionTokens">
                <div className="from">
                  <h3>From</h3>
                  {walletData ? (
                    <div className="wallet">
                      <img src={primaryAccountIcon} alt="" />
                      <span>
                        {parseFloat(formattedTransaction.value) > 0
                          ? displayWalletAddress(walletData.blockchainAddress)
                          : "SeedOn Hot Wallet"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="transactionDirection">
                  <FiArrowRight />
                </div>

                <div className="to">
                  <h3>To</h3>
                  {walletData ? (
                    <div className="wallet">
                      <img src={secondaryAccountIcon} alt="" />
                      <span>
                        {parseFloat(formattedTransaction.value) < 0
                          ? displayWalletAddress(
                              formattedTransaction.metadata.to
                            )
                          : "SeedOn Hot Wallet"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <hr />
            </>
          ) : (
            ""
          )}

          {formattedTransaction.metadata.type ===
          "tokenSwapTransactionMetadata" ? (
            <>
              <div className="transactionTokens">
                <div className="from">
                  <h3>From</h3>
                  <div className="wallet">
                    <img
                      src={getImageFromContractAddress(
                        formattedTransaction.metadata.from.contractAddress
                      )}
                      alt=""
                    />
                    <span>
                      {getTokenSymbolFromContractAddress(
                        formattedTransaction.metadata.from.contractAddress
                      )}
                    </span>
                  </div>
                </div>

                <div className="transactionDirection">
                  <FiArrowRight />
                </div>

                <div className="to">
                  <h3>To</h3>
                  <div className="wallet">
                    <img
                      src={getImageFromContractAddress(
                        formattedTransaction.metadata.to.contractAddress
                      )}
                      alt=""
                    />
                    <span>
                      {getTokenSymbolFromContractAddress(
                        formattedTransaction.metadata.to.contractAddress
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <hr />
            </>
          ) : (
            ""
          )}
          {formattedTransaction.metadata.type ===
            "depositTransactionMetadata" ||
          formattedTransaction.metadata.type ===
            "withdrawalTransactionMetadata" ? (
            <div className="transactionDetails">
              <h3>Transaction details</h3>

              <p>
                <span>Nonce</span>
                <span>{formattedTransaction.metadata.nonce}</span>
              </p>
              <p>
                <span>Gas Limit (units)</span>
                <span>
                  {formattedTransaction.metadata.gasLimit
                    ? formatNumber(formattedTransaction.metadata.gasLimit)
                    : "N/A"}
                </span>
              </p>
              <p>
                <span>Gas price</span>
                <span>
                  {formattedTransaction.metadata.gasPrice
                    ? formatNumber(formattedTransaction.metadata.gasPrice)
                    : "N/A"}
                </span>
              </p>
              {formattedTransaction.metadata.commission ? (
                <p>
                  <span>
                    Commission{" "}
                    {formattedTransaction.tokenSymbol
                      ? `(${formattedTransaction.tokenSymbol})`
                      : ""}
                  </span>
                  <span>
                    {formattedTransaction.metadata.commission
                      ? `${formatNumber(
                          formattedTransaction.metadata.commission
                        )}`
                      : "N/A"}
                  </span>
                </p>
              ) : (
                ""
              )}
              <p>
                <span>
                  Amount{" "}
                  {formattedTransaction.tokenSymbol
                    ? `(${formattedTransaction.tokenSymbol})`
                    : ""}
                </span>
                <span>
                  <b>{formatStringNumber(formattedTransaction.value)}</b>
                </span>
              </p>
            </div>
          ) : (
            <div className="transactionDetails">
              <h3>Transaction details</h3>
              {props.withReleaseDate && formattedTransaction.timestamp && (
                <p>
                  <span>Release Date</span>
                  <span>
                    {moment(formattedTransaction.timestamp).format(
                      "Do MMMM yyyy"
                    )}
                  </span>
                </p>
              )}
              {formattedTransaction.metadata.stakingReward ? (
                <p>
                  <span>
                    Staking Reward ({formattedTransaction.tokenSymbol})
                  </span>
                  <span>
                    {formatNumber(formattedTransaction.metadata.stakingReward)}
                  </span>
                </p>
              ) : (
                ""
              )}
              {formattedTransaction.metadata?.stakingType ==
              StakeType.FLEXIBLE ? (
                <p>
                  <span>Daily Reward ({formattedTransaction.tokenSymbol})</span>
                  <span>
                    {formatNumber(
                      formattedTransaction.metadata?.releaseSchedule[0]?.reward
                    )}
                  </span>
                </p>
              ) : (
                ""
              )}
              {formattedTransaction.metadata.percentage ? (
                <p>
                  <span>Percentage</span>
                  <span>{formattedTransaction.metadata.percentage * 100}%</span>
                </p>
              ) : (
                ""
              )}
              {formattedTransaction.metadata.releaseDate && (
                <p>
                  <span>Release Date</span>
                  <span>
                    {moment(formattedTransaction.metadata.releaseDate).format(
                      "Do MMMM yyyy"
                    )}
                  </span>
                </p>
              )}
              {formattedTransaction.metadata.type ===
                "fiatDepositTransactionMetadata" && (
                <>
                  <p>
                    <span>
                      Amount spent ({formattedTransaction.metadata.fiatCurrency}
                      )
                    </span>
                    <span>{formattedTransaction.metadata.fiatAmount}</span>
                  </p>
                </>
              )}
              <p>
                <span>
                  Amount{" "}
                  {formattedTransaction.metadata.type ===
                    "fiatDepositTransactionMetadata" && "purchased "}
                  (
                  {formattedTransaction.metadata.type ===
                  "tokenSwapTransactionMetadata"
                    ? getTokenSymbolFromContractAddress(
                        formattedTransaction.metadata.from.contractAddress
                      )
                    : formattedTransaction.tokenSymbol}
                  )
                </span>
                <span>
                  <b>
                    {formattedTransaction.metadata.type ===
                    "tokenSwapTransactionMetadata"
                      ? `-${formatStringNumber(
                          formattedTransaction.metadata.from.amount
                        )}`
                      : formattedTransaction.metadata.type ===
                        "fiatDepositTransactionMetadata"
                      ? formattedTransaction?.metadata?.conversionAmount
                        ? formatStringNumber(
                            formattedTransaction?.metadata?.conversionAmount
                          )
                        : formatStringNumber(formattedTransaction?.value)
                      : formatStringNumber(formattedTransaction?.value)}{" "}
                  </b>
                </span>
              </p>
              {formattedTransaction.metadata.type ===
                "fiatDepositTransactionMetadata" &&
                formattedTransaction?.metadata?.cashback && (
                  <>
                    <p>
                      <span>
                        Cashback (
                        {formattedTransaction?.metadata?.purchasedCurrency})
                      </span>
                      <span>
                        {formatStringNumber(
                          formattedTransaction?.metadata?.cashback
                            ?.cashbackAmount
                        )}
                      </span>
                    </p>
                  </>
                )}
              {formattedTransaction.metadata.type ===
                "fiatDepositTransactionMetadata" &&
                formattedTransaction?.metadata?.cashback && (
                  <>
                    <p>
                      <span>
                        Total Amount (
                        {formattedTransaction?.metadata?.purchasedCurrency})
                      </span>
                      <span>
                        {formatStringNumber(formattedTransaction?.value)}
                      </span>
                    </p>
                  </>
                )}
              {formattedTransaction.metadata.type ===
                "tokenSwapTransactionMetadata" && (
                <>
                  <p>
                    <span>
                      Amount to receive (
                      {getTokenSymbolFromContractAddress(
                        formattedTransaction.metadata.to.contractAddress
                      )}
                      )
                    </span>
                    <span>
                      <b>
                        {formatStringNumber(
                          formattedTransaction.metadata.to.amount
                        )}{" "}
                      </b>
                    </span>
                  </p>
                  <p>
                    <span>
                      Commission (
                      {getTokenSymbolFromContractAddress(
                        formattedTransaction.metadata.from.contractAddress
                      )}
                      )
                    </span>
                    <span>
                      <b>
                        -
                        {formatStringNumber(
                          formattedTransaction.metadata.commission
                        )}{" "}
                      </b>
                    </span>
                  </p>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default TransactionPanelBody;
