import React, { useEffect, useState } from "react";
import "./style.less";
import { IEventProps } from "./interface";
import { useDispatch } from "react-redux";
import { eventInteractionRequest, setEvent } from "./eventsSlice";

import moment from "moment";
import copy from "copy-to-clipboard";
import { useParams } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Badge, Dropdown, Menu, Row, Tooltip, Typography } from "antd";

// Icons
import { RiCoinsLine } from "react-icons/ri";
import {
  FiShare2,
  FiCheckCircle,
  FiBell,
  FiShare,
  FiCopy,
} from "react-icons/fi";
import {
  AiOutlineLike,
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillFacebook,
} from "react-icons/ai";
import {
  shareOnFacebook,
  shareOnLinkedIn,
  shareOnTwitter,
} from "../../_helpers/urlShare";
import { useWindowSize } from "../../hooks/windowSize";
import { useCookies } from "react-cookie";

const { Paragraph } = Typography;

const Event: React.FC<IEventProps> = (props: IEventProps) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [shareMenu, setShareMenu] = useState([]);
  const windowSize = useWindowSize();
  const [token] = useCookies(["sOnFinanceToken"]);

  const interactWithEvent = (event, interactionType) => {
    if (token?.sOnFinanceToken) {
      const getUpdatedEvent = (eventItem) => {
        const newInteraction = {
          ...eventItem.interaction,
        };

        const newData = {
          ...eventItem.data,
        };
        newInteraction.hasLiked =
          interactionType.indexOf("LIKE") !== -1
            ? interactionType === "LIKE"
            : eventItem.interaction.hasLiked;
        newInteraction.hasSubscribed =
          interactionType.indexOf("SUBSCRIBE") !== -1
            ? interactionType === "SUBSCRIBE"
            : eventItem.interaction.hasSubscribed;

        newInteraction.hasShared =
          interactionType === "SHARE" ? true : eventItem.interaction.hasShared;

        newData.statistics = {
          ...newData.statistics,
          likes:
            newData.statistics.likes +
            (interactionType.indexOf("LIKE") !== -1
              ? interactionType === "LIKE"
                ? 1
                : -1
              : 0),
          subscribes:
            newData.statistics.subscribes +
            (interactionType.indexOf("SUBSCRIBE") !== -1
              ? interactionType === "SUBSCRIBE"
                ? 1
                : -1
              : 0),
          shares:
            newData.statistics.shares +
            (interactionType === "SHARE" && !eventItem.interaction.hasShared
              ? 1
              : 0),
        };
        return {
          data: newData,
          interaction: newInteraction,
        };
      };

      props.setGroupedEvents(
        props.groupedEvents.map((group) => {
          return {
            ...group,
            data: group.data.map((item) => {
              if (item.data._id === event.data._id) {
                return getUpdatedEvent(item);
              }
              return item;
            }),
          };
        })
      );

      if (event.data.slag === params.id) {
        dispatch(
          setEvent({
            event: getUpdatedEvent(event),
          })
        );
      }

      const sendForm = async () => {
        const token = await executeRecaptcha("getEvents");
        dispatch(
          eventInteractionRequest({
            eventId: event.data._id,
            type: interactionType,
            captchaToken: token,
          })
        );
      };

      sendForm().then(() => {});
    }
  };
  const likeInteractionTriggered = () => {
    if (token?.sOnFinanceToken) {
      interactWithEvent(
        props.event,
        props.event.interaction.hasLiked ? "UNDO_LIKE" : "LIKE"
      );
    } else {
      localStorage.setItem("intended", window.location.pathname);
      window.location.href = process.env.REACT_APP_AUTH_URL;
    }
  };
  const subscribeInteractionTriggered = () => {
    if (token?.sOnFinanceToken) {
      interactWithEvent(
        props.event,
        props.event.interaction.hasSubscribed ? "UNSUBSCRIBE" : "SUBSCRIBE"
      );
    } else {
      localStorage.setItem("intended", window.location.pathname);
      window.location.href = process.env.REACT_APP_AUTH_URL;
    }
  };
  const facebookShare = (event) => {
    interactWithEvent(event, "SHARE");
    setTimeout(() => {
      shareOnFacebook(`${window.location.origin}/events/${event.data.slag}`);
    }, 500);
  };

  const twitterShare = (event) => {
    interactWithEvent(event, "SHARE");
    setTimeout(() => {
      shareOnTwitter(
        `${window.location.origin}/events/${event.data.slag}`,
        "Check out this upcoming crypto event on"
      );
    }, 500);
  };

  const linkedInShare = (event) => {
    interactWithEvent(event, "SHARE");
    setTimeout(() => {
      shareOnLinkedIn(`${window.location.origin}/events/${event.data.slag}`);
    }, 500);
  };

  const copyToClipboard = (event) => {
    interactWithEvent(event, "SHARE");
    copy(`${window.location.origin}/events/${event.data.slag}`);
    copy(`${window.location.origin}/events/${event.data.slag}`);
  };

  const getHostnameFromSource = (url) => {
    // run against regex
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    // extract hostname (will be null if no match is found)
    return matches && matches[1];
  };

  const EventDate = () => (
    <div className="eventDate">
      <div className="card">
        <div className="large">
          {moment(props.event.data.date_event).format("DD")}
        </div>

        <div className="side">
          {moment(props.event.data.date_event).format("MMM")}{" "}
          {moment(props.event.data.date_event).format("YYYY")}
        </div>
      </div>

      <small>{props.event.data.can_occur_before ? "OR EARLIER" : ""}</small>
    </div>
  );

  const Specs = () => (
    <div className="specs">
      <a href={props.event.data.proof} className="proof" target="_blank">
        <Tooltip title="View proof">
          <FiCheckCircle />
        </Tooltip>
        Proof
      </a>

      <a href={props.event.data.source} className="proof" target="_blank">
        <Tooltip title="Source">
          <FiShare />
        </Tooltip>
        {getHostnameFromSource(props.event.data.source)}
      </a>
    </div>
  );

  const Actions = () => (
    <div className="actions">
      <Badge count={props.event.data.statistics.subscribes} overflowCount={99}>
        <button
          className={`addToWatchlist ${
            props.event.interaction.hasSubscribed ? "active" : ""
          }`}
          onClick={subscribeInteractionTriggered}
        >
          <FiBell />
        </button>
      </Badge>

      <Badge count={props.event.data.statistics.likes} overflowCount={99}>
        <button
          className={`addToWatchlist ${
            props.event.interaction.hasLiked ? "active" : ""
          }`}
          onClick={likeInteractionTriggered}
        >
          <AiOutlineLike />
        </button>
      </Badge>

      <Dropdown placement="bottomRight" overlay={<Menu items={shareMenu} />}>
        <Badge count={props.event.data.statistics.shares} overflowCount={99}>
          <button className="addToWatchlist">
            <FiShare2 />
          </button>
        </Badge>
      </Dropdown>
    </div>
  );

  const Tokens = () => {
    const getTooltipString = () => {
      let tooltipString = "";
      props.event.data.coins.map((item, index) => {
        if (index > 4) {
          tooltipString += item.name;
          if (index !== props.event.data.coins.length - 1) {
            tooltipString += ", ";
          }
        }
      });
      return tooltipString;
    };
    return (
      <p className="tokens">
        {props.event.data.coins && props.event.data.coins.length > 0
          ? props.event.data.coins
              .slice(0, 5)
              .map((token, tKey) => <span key={tKey}>{token.name}</span>)
          : ""}
        {props.event.data.coins.length > 5 ? (
          <>
            <Tooltip title={getTooltipString()}>
              +{" "}
              {props.event.data.coins.length - 5 === 1
                ? "one other"
                : `${props.event.data.coins.length - 5} others`}
            </Tooltip>
          </>
        ) : (
          ""
        )}
      </p>
    );
  };

  useEffect(() => {
    const shareItemsArr = [
      {
        key: "1",
        label: (
          <a onClick={() => twitterShare(props.event)}>
            Share on Twitter <AiOutlineTwitter />
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a onClick={() => facebookShare(props.event)}>
            Share on Facebook <AiFillFacebook />
          </a>
        ),
      },
      {
        key: "3",
        label: (
          <a onClick={() => linkedInShare(props.event)}>
            Share on Linkedin <AiFillLinkedin />
          </a>
        ),
      },
      {
        key: "4",
        label: (
          <Paragraph onClick={() => copyToClipboard(props.event)}>
            Copy to clipboard <FiCopy />
          </Paragraph>
        ),
      },
    ];
    if (windowSize.width && windowSize.width < 960) {
      setShareMenu(shareItemsArr.filter((item) => item.key !== "3"));
    } else {
      setShareMenu(shareItemsArr);
    }
  }, [windowSize]);
  return (
    <>
      {props.isSelected ? (
        <Row className="pageHeading eventDetails">
          <EventDate />

          {props.event.data.categories &&
          props.event.data.categories.length > 0 ? (
            <div className="listCategories">
              {props.event.data.categories.map((category, cKey) => (
                <span key={cKey}>{category.name}</span>
              ))}
            </div>
          ) : (
            ""
          )}

          <h1>{props.event.data.title.en}</h1>

          {props.event.data.coins && props.event.data.coins.length > 0 ? (
            <div className="listCoins">
              <Tooltip title="Tokens" placement="left">
                <RiCoinsLine />
              </Tooltip>

              <Tokens />
            </div>
          ) : (
            ""
          )}

          <Specs />
          <Actions />
        </Row>
      ) : (
        <Row className={`${props.isOnNewsPage ? "eventItem" : ""}`}>
          <Row>
            <EventDate />
            <Specs />
          </Row>
          {props.isOnNewsPage ? "" : <Actions />}

          <div className="content">
            <h3>{props.event.data.title.en}</h3>
            <Tokens />
            {props.event.data.categories &&
            props.event.data.categories.length > 0 ? (
              <div className="listCategories">
                {props.event.data.categories.map((category, cKey) => (
                  <span key={cKey}>{category.name}</span>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </Row>
      )}
    </>
  );
};

export default Event;
