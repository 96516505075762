const SwapIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.979"
    height="15.595"
    viewBox="0 0 16.979 15.595"
  >
    <g
      id="Icon_feather-plus"
      data-name="Icon feather-plus"
      transform="translate(1 -1.37)"
    >
      <path
        id="Path_10927"
        data-name="Path 10927"
        d="M7.5,18H22.479l-3.766-3.766"
        transform="translate(-7.5 -11.449)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_11197"
        data-name="Path 11197"
        d="M22.479,14.234H7.5L11.266,18"
        transform="translate(-7.5 -2.449)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default SwapIcon;
