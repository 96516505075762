import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getCampaignDetails = (state: AppState) =>
  state.campaignDetails.campaignDetails;
const getCampaignDetailsLoading = (state: AppState) =>
  state.campaignDetails.campaignDetailsLoading;
const getCampaignDetailsError = (state: AppState) =>
  state.campaignDetails.campaignDetailsError;

const getCampaignPoolData = (state: AppState) =>
  state.campaignDetails.campaignPoolData;
const getCampaignPoolDataLoading = (state: AppState) =>
  state.campaignDetails.campaignPoolDataLoading;
const getCampaignPoolDataError = (state: AppState) =>
  state.campaignDetails.campaignPoolDataError;

export const getCampaignDetailsSelector = createSelector(
  getCampaignDetails,
  (res) => res
);
export const getCampaignDetailsLoadingSelector = createSelector(
  getCampaignDetailsLoading,
  (res) => res
);
export const getCampaignDetailsErrorSelector = createSelector(
  getCampaignDetailsError,
  (res) => res
);

export const getCampaignPoolDataSelector = createSelector(
  getCampaignPoolData,
  (res) => res
);
export const getCampaignPoolDataLoadingSelector = createSelector(
  getCampaignPoolDataLoading,
  (res) => res
);
export const getCampaignPoolDataErrorSelector = createSelector(
  getCampaignPoolDataError,
  (res) => res
);
