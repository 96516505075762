import { IConfigParams, ConfigState, IAuthConfigParams } from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: ConfigState = {
  loading: false,
  config: {},
  authConfig: {},
  shouldShowAuthModal: false,
  currencies: [],
  currenciesLoading: false,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    getConfigRequest(state, action: PayloadAction<IConfigParams>) {
      return {
        ...state,
        loading: true,
        config: {},
      };
    },
    getConfigSuccess(state, action) {
      return {
        ...state,
        loading: false,
        config: action.payload.config,
      };
    },
    getConfigFailure(state) {
      return {
        ...state,
        loading: false,
      };
    },
    getAuthConfigRequest(state, action: PayloadAction<IAuthConfigParams>) {
      return {
        ...state,
        loading: true,
        authConfig: {},
      };
    },
    getAuthConfigSuccess(state, action) {
      return {
        ...state,
        loading: false,
        authConfig: action.payload.config,
      };
    },
    getAuthConfigFailure(state) {
      return {
        ...state,
        loading: false,
      };
    },
    getCurrenciesRequest(state) {
      return {
        ...state,
        currencies: [],
        currenciesLoading: true,
      };
    },
    getCurrenciesSuccess(state, action) {
      return {
        ...state,
        currencies: action.payload.currencies,
        currenciesLoading: false,
      };
    },
    getCurrenciesFailure(state) {
      return {
        ...state,
        currencies: [],
        currenciesLoading: false,
      };
    },
    setAuthModal(state, action) {
      return {
        ...state,
        shouldShowAuthModal: action.payload,
      };
    },
  },
});

export const {
  getConfigRequest,
  getConfigSuccess,
  getConfigFailure,
  setAuthModal,
  getCurrenciesRequest,
  getCurrenciesSuccess,
  getCurrenciesFailure,
  getAuthConfigRequest,
  getAuthConfigSuccess,
  getAuthConfigFailure,
} = layoutSlice.actions;

export default layoutSlice.reducer;
