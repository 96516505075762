import { scroller } from "react-scroll";

export const scrollTo = (id, offset?, duration?) => {
  scroller.scrollTo(id, {
    duration: duration ? duration : 1600,
    offset: offset ? offset : 0,
    delay: 0,
    smooth: "easeInOutQuart",
  });
};

export const scrollToTop = () => {
  scroller.scrollTo("scrollTopId", {
    duration: 1600,
    offset: 0,
    delay: 0,
    smooth: "easeInOutQuart",
  });
};
