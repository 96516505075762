export interface ISuccessModal {
  isVisible?: boolean;
  title: string;
  subtitle?: string;
  description: string;
  ctaLabel: string;
  ctaRedirect: string;
  modalType: SuccessModalTypes;
  fromTokenName?: string;
  toTokenName?: string;
  onCancel?: () => void;
  isExternalRedirect?: boolean;
}

export enum SuccessModalTypes {
  STAKE = "STAKE",
  SWAP = "SWAP",
}
