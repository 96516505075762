import React from "react";
import { Button } from "../../components";
import { ROUTES } from "../../_helpers/config";
import { useNavigate } from "react-router-dom";
import notFoundImg from "../../assets/images/abstract-background.png";
import { ButtonTypes } from "../../components/Button/types";

const InternalServerError = () => {
  const navigate = useNavigate();

  const goToPage = (route) => {
    navigate(route);
  };

  return (
    <div className="homepage">
      <div className="section introSection errorLayout">
        <div className="container">
          <div className="content" data-aos="fade-right">
            <h1>Ooops</h1>
            <p>
              Looks like something it's not working. But no problem here’s a
              button to get back on track on our platform!
            </p>

            <Button
              type={ButtonTypes.PRIMARY}
              label="Back to homepage"
              onClick={() => goToPage(ROUTES.homePage)}
            />
          </div>

          <div className="visual" data-aos="zoom-out-left">
            <img src={notFoundImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalServerError;
