export interface DepositState {
  loading: boolean;
  error: any;
  showDepositModal: boolean;
}

export interface PostDepositSuccessPayload {
  news: any;
}

export interface PostDepositFailurePayload {
  error: string;
}

export interface IDepositResponse {}
export interface IDepositParams {}

export enum StakeType {
  LOCKED = "LOCKED",
  FLEXIBLE = "FLEXIBLE",
}
