import React, { useEffect, useRef, useState } from "react";
import "./style.less";
import QRCodeStyling from "qr-code-styling";
import { IQr } from "./interface";

const QrCode = (props: IQr) => {
  const ref = useRef(null);
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    setQrCode(
      new QRCodeStyling({
        width: 250,
        height: 250,
        type: "canvas",
        image: props.imageUrl,
        backgroundOptions: {
          color: "white",
        },
        dotsOptions: {
          color: "black",
          type: "square",
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 20,
          hideBackgroundDots: true,
        },
        cornersSquareOptions: {
          type: "square",
        },
      })
    );
    return () => {
      setQrCode(null);
    };
  }, []);

  useEffect(() => {
    if (qrCode) {
      qrCode.update({
        data: props.url,
      });
      qrCode.append(ref.current);
    }
  }, [qrCode]);
  return qrCode ? <div className={"qr"} ref={ref} /> : <></>;
};

export default QrCode;
