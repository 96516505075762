import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  downloadReportFailure,
  downloadReportRequest,
  downloadReportSuccess,
  getReportFailure,
  getReportRequest,
  getReportSuccess,
} from "./reportSlice";
import { GetReportPayload } from "./types";
import { axios } from "services/axios.service";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getReportSaga(action: ReturnType<typeof getReportRequest>) {
  try {
    const response: AxiosResponse<GetReportPayload> = yield call(
      axios.put,
      `${process.env.REACT_APP_STATISTICS_URL}/reports/transactions`,
      {},
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
        params: action.payload,
      }
    );
    yield put(
      getReportSuccess({
        report: response.data,
      })
    );
  } catch (e: any) {
    yield put(getReportFailure());
  }
}

function* downloadReportSaga(action: ReturnType<typeof downloadReportRequest>) {
  try {
    const response: AxiosResponse<GetReportPayload> = yield call(
      axios.get,
      `${process.env.REACT_APP_STATISTICS_URL}/reports/transactions`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
        params: action.payload,
      }
    );
    yield put(
      downloadReportSuccess({
        report: response.data,
      })
    );
  } catch (e: any) {
    yield put(downloadReportFailure());
  }
}

function* walletSaga() {
  yield all([
    takeLatest(getReportRequest, getReportSaga),
    takeLatest(downloadReportRequest, downloadReportSaga),
  ]);
}

export default walletSaga;
