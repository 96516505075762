import React from "react";
import { Modal, Spin } from "antd";
import { ITransactionDetailsModalProps } from "components/Wallet/types";
import TransactionPanelBody from "../TransactionPanelBody";
import "./styles.less";
import { IoClose } from "react-icons/io5";
import Button from "../../../Button";
import { ButtonTypes } from "components/Button/types";

export const TransactionDetailsModal = (
  props: ITransactionDetailsModalProps
) => {
  const { open, setOpen, transactionDetails } = props;

  return (
    <Modal
      className="authModals walletModals transactionDetailsModal"
      open={open}
      onCancel={() => setOpen(false)}
      maskClosable={false}
      footer={null}
      closeIcon={<IoClose />}
    >
      <div className="heading">
        <h2>Transaction details</h2>
        {transactionDetails ? (
          <TransactionPanelBody
            withReleaseDate
            transaction={transactionDetails}
          />
        ) : (
          <Spin />
        )}

        <Button
          label={"Continue"}
          type={ButtonTypes.PRIMARY}
          onClick={() => setOpen(false)}
        />
      </div>
    </Modal>
  );
};
