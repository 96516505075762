import React, { useEffect, useState } from "react";
import "./style.less";

// Helpers
import { GtmEvents } from "../../_helpers/types";
import { pushGtmEvent } from "../../_helpers/gtm";

import axios from "axios";
import { FiInfo } from "react-icons/fi";
import { scrollTo } from "../../_helpers/scroll";
import { VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";

// Components
import { Form, notification } from "antd";
import { Button, FormInput } from "../../components";

// Assets
import wallet from "../../assets/images/wallet.png";
import coinFaded from "../../assets/images/coin-faded.png";
import coinLarge from "../../assets/images/coin-large.png";

import aboutUs01 from "../../assets/images/about-us-visual.png";
import benefitIcon01 from "../../assets/images/staking-icon.png";
import benefitIcon02 from "../../assets/images/vesting-icon.png";
import benefitVisual from "../../assets/images/benefit-visual.png";
import comingSoon01 from "../../assets/images/coming-soon-01.png";
import comingSoon02 from "../../assets/images/coming-soon-02.png";
import comingSoon03 from "../../assets/images/coming-soon-03.png";
import comingSoon04 from "../../assets/images/coming-soon-04.png";
import createAccountImage from "../../assets/images/create-account-visual.png";

const HomePage = () => {
  const [formRef] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    pushGtmEvent(GtmEvents.PREVIEW_HOMEPAGE_CLIENT);
  }, []);

  const submitForm = () => {
    setLoading(true);

    formRef
      .validateFields()
      .then((formValues) => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/launch/subscription`,
            formValues
          )
          .then(() => {
            notification.success({
              message:
                "Welcome to our community. We are happy to have you on board.",
            });
            formRef.resetFields();
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="homepage">
      <div className="section introSection">
        <div className="container">
          <div className="content" data-aos="fade-up">
            <h1>
              Growing your investments,
              <br /> one seed at a time.{" "}
            </h1>
            <p>
              Our core principles are trust, transparency, and cybersecurity.
              With the use of the BSC blockchain, we’re changing the way we
              perceive wallet security.
            </p>

            <div className="howItWorks">
              <div className="icon">
                <FiInfo />
              </div>

              <div className="details">
                <h3>How it works</h3>
                <p>
                  Investments are risky, illiquid and may result in total loss
                  of capital.{" "}
                  <a onClick={() => scrollTo("benefits")}>Learn more</a>
                </p>
              </div>
            </div>
          </div>

          <div className="visual" data-aos="fade-left">
            <img src={coinFaded} alt="SeedOn-Coin" className="coin faded" />
            <img src={wallet} alt="SeedOn-Wallet" className="wallet" />
            <img src={coinLarge} alt="SeedOn-Coins" className="coin large" />
          </div>
        </div>
      </div>

      <div id="about" className="section aboutUs">
        <div className="container">
          <div className="content" data-aos="fade-left">
            <h2>About us</h2>
            <p>
              Our core principles are trust, transparency, and cybersecurity.
              With the use of the BSC blockchain, we’re changing the way we
              perceive wallet security
            </p>
          </div>

          <div className="visual" data-aos="flip-left">
            <img src={aboutUs01} alt="SeedOn-Blockchain" />
          </div>
        </div>
      </div>

      <div id="benefits" className="section benefits">
        <div className="container">
          <div className="content">
            <h2 data-aos="fade-left">
              SeedOn <br />
              Finance Benefits
            </h2>

            <div className="listBenefits" data-aos="fade-up">
              <div className="benefit">
                <div className="thumbnail">
                  <img src={benefitIcon01} alt="SeedOn-Benefits" />
                </div>

                <div className="details">
                  <h3>Staking</h3>
                  <p>
                    Earn rewards by “locking up” a portion of your crypto and
                    contributing to the blockchain network.
                  </p>
                </div>
              </div>

              <div className="benefit">
                <div className="thumbnail">
                  <img src={benefitIcon02} alt="SeedOn-Token" />
                </div>

                <div className="details">
                  <h3>Vesting</h3>
                  <p>
                    Check up on the next vesting dates for your SEON investments
                    and claim your tokens.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="visual" data-aos="flip-left">
            <img src={benefitVisual} alt="SeedOn-Vesting" />
          </div>
        </div>
      </div>
      <div id="create-account" className="section createAccount">
        <div className="container">
          <div className="content">
            <h2 data-aos="fade-left">
              How can you <br />
              create an account?
            </h2>

            <div className="listSteps">
              <div className="step" data-aos="fade-left" data-aos-delay="200">
                <div className="number">
                  <span>01</span>
                </div>

                <p>Add your e-mail address and your password</p>
              </div>
              <div className="step" data-aos="fade-left" data-aos-delay="400">
                <div className="number">
                  <span>02</span>
                </div>

                <p>Validate your phone number</p>
              </div>
              <div className="step" data-aos="fade-left" data-aos-delay="600">
                <div className="number">
                  <span>03</span>
                </div>

                <p>Confirm your phone</p>
              </div>
              <div className="step" data-aos="fade-left" data-aos-delay="800">
                <div className="number">
                  <span>04</span>
                </div>

                <p>Write down your recovery phrase</p>
              </div>
              <div className="step" data-aos="fade-left" data-aos-delay="1000">
                <div className="number">
                  <span>05</span>
                </div>

                <p>
                  Re-enter the recovery phrase (in order) to confirm your
                  account.
                </p>
              </div>
            </div>
          </div>

          <div className="visual" data-aos="zoom-out-right">
            <img src={createAccountImage} alt="SeedOn-Register" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
