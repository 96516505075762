import React, { useEffect, useState } from "react";
import "./style.less";
import { getEncodedString } from "../../_helpers/stringEncoder";
import { VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";

// Selectors
import { getCurrentUserSelector } from "./selectors";
import { getCanSendSmsCode } from "../CodeVerification/selectors";

// Helpers
import { GtmEvents } from "../../_helpers/types";
import { pushGtmEvent } from "../../_helpers/gtm";
import { scrollToTop } from "../../_helpers/scroll";

// Components
import { Button } from "../index";
import ConfirmOldPhoneNumber from "./CofirmOldPhoneNumber";
import PhoneValidationModal from "../PhoneValidationModal";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { putRequestSmsOtpRequest } from "../PhoneValidationModal/phoneValidationSlice";
import {
  getCanSendCodeRequest,
  resetCanSendCodeState,
} from "../CodeVerification/codeVerificationSlice";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ChangePhoneNumber = () => {
  const dispatch = useDispatch();

  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const [
    confirmOldPhoneNumberModalVisible,
    setConfirmOldPhoneNumberModalVisible,
  ] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const user = useSelector(getCurrentUserSelector);
  const canSendPhoneCode = useSelector(getCanSendSmsCode);

  const validatePhone = () => {
    pushGtmEvent(GtmEvents.VALIDATE_PHONE);
    setConfirmOldPhoneNumberModalVisible(false);
    setPhoneVerifyModalVisible(true);
  };

  const phoneValidationModalClosed = () => {
    setPhoneVerifyModalVisible(false);
    scrollToTop();
  };

  const confirmOldPhoneNumber = () => {
    dispatch(
      getCanSendCodeRequest({ type: "SMS", userIsNotAuthenticated: false })
    );
  };

  useEffect(() => {
    if (canSendPhoneCode && phoneVerifyModalVisible === false) {
      dispatch(resetCanSendCodeState());
      const sendForm = async () => {
        const token = await executeRecaptcha("phoneNumber");
        dispatch(
          putRequestSmsOtpRequest({
            phoneNumber: user.details.phoneNumber,
            captchaToken: token,
            countryIsoCode: user.details.countryIsoCode,
          })
        );
      };
      sendForm().then(() => {});
      setConfirmOldPhoneNumberModalVisible(true);
    }
  }, [canSendPhoneCode]);

  return (
    <div className="phoneConfirmation">
      {user ? (
        <p>
          Current phone number: {getEncodedString(user.details.phoneNumber)}
        </p>
      ) : (
        ""
      )}

      <Button
        label="Change phone number"
        onClick={() => confirmOldPhoneNumber()}
      />

      {canSendPhoneCode === false ? (
        <span className="generalError">
          {VALIDATION_ERROR_MESSAGES.tooManySmsOtp}
        </span>
      ) : null}

      {phoneVerifyModalVisible ? (
        <PhoneValidationModal
          isVisible={phoneVerifyModalVisible}
          closeModal={() => phoneValidationModalClosed()}
        />
      ) : (
        ""
      )}

      {confirmOldPhoneNumberModalVisible ? (
        <ConfirmOldPhoneNumber
          onSuccess={() => validatePhone()}
          phoneNumber={user.details.phoneNumber}
          isVisible={confirmOldPhoneNumberModalVisible}
          closeModal={() => setConfirmOldPhoneNumberModalVisible(false)}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ChangePhoneNumber;
