import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const getLoading = (state: AppState) => state.news.loading;

const getError = (state: AppState) => state.news.error;

const getNews = (state: AppState) => state.news.news;

const trendingNewsLoading = (state: AppState) => state.news.trendingNewsLoading;
const trendingNews = (state: AppState) => state.news.trendingNews;

const relatedNewsLoading = (state: AppState) => state.news.relatedNewsLoading;
const relatedNews = (state: AppState) => state.news.relatedNews;

const newsCategoriesLoading = (state: AppState) =>
  state.news.newsCategoriesLoading;
const newsCategories = (state: AppState) => state.news.newsCategories;

const articleLoading = (state: AppState) => state.news.articleLoading;
const article = (state: AppState) => state.news.article;

const favoriteNews = (state: AppState) => state.news.favoriteNews;
const favoriteNewsLoading = (state: AppState) => state.news.favoriteNewsLoading;

const articleInteractionSuccess = (state: AppState) =>
  state.news.articleInteractionSuccess;

export const articleInteractionSuccessSelector = createSelector(
  articleInteractionSuccess,
  (res) => res
);

export const favoriteNewsLoadingSelector = createSelector(
  favoriteNewsLoading,
  (res) => res
);
export const favoriteNewsSelector = createSelector(favoriteNews, (res) => res);

export const trendingNewsLoadingSelector = createSelector(
  trendingNewsLoading,
  (res) => res
);
export const trendingNewsSelector = createSelector(trendingNews, (res) => res);

export const relatedNewsLoadingSelector = createSelector(
  relatedNewsLoading,
  (res) => res
);
export const relatedNewsSelector = createSelector(relatedNews, (res) => res);

export const getLoadingSelector = createSelector(getLoading, (res) => res);
export const getErrorSelector = createSelector(getError, (res) => res);
export const getNewsSelector = createSelector(getNews, (res) => res);

export const getNewsCategoriesLoadingSelector = createSelector(
  newsCategoriesLoading,
  (res) => res
);
export const getNewsCategoriesSelector = createSelector(
  newsCategories,
  (res) => res
);

export const getArticleLoadingSelector = createSelector(
  articleLoading,
  (res) => res
);
export const getArticleSelector = createSelector(article, (res) => res);
