import { Tag } from "antd";
import moment from "moment";

import { BsCalendar2Check, BsCalendarX } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { TbSitemap } from "react-icons/tb";

const dataFormat = "YYYY/MM/DD";

const FiltersValues = (props) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setSearch,
    type,
    setType,
    status,
    setStatus,
  } = props;

  return (
    <div className="search-filters-values">
      {startDate && (
        <Tag
          closable
          onClose={() => {
            setStartDate(undefined);
            setSearch(true);
          }}
        >
          <BsCalendar2Check size={16} />
          {startDate ? moment(startDate).format(dataFormat) : undefined}
        </Tag>
      )}
      {endDate && (
        <Tag
          closable
          onClose={() => {
            setEndDate(undefined);
            setSearch(true);
          }}
        >
          <BsCalendarX size={16} />
          {endDate ? moment(endDate).format(dataFormat) : undefined}
        </Tag>
      )}
      {type && (
        <Tag
          closable
          onClose={() => {
            setType(null);
            setSearch(true);
          }}
        >
          <BiCategory size={20} /> {type.value}
        </Tag>
      )}
      {status && (
        <Tag
          closable
          onClose={() => {
            setStatus(null);
            setSearch(true);
          }}
        >
          <TbSitemap size={20} />
          {status}
        </Tag>
      )}
      {(type || status || startDate || endDate) && (
        <div
          className="clear-filter"
          onClick={() => {
            setType(null);
            setStatus(null);
            setStartDate(undefined);
            setEndDate(undefined);
            setSearch(true);
          }}
        >
          <Tag>Clear filter</Tag>
        </div>
      )}
    </div>
  );
};

export default FiltersValues;
