import React, { useEffect, useState } from "react";
import { Image, Select, Tabs } from "antd";
import "./styles.less";
import { tokenDataMock } from "./mock";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import AnimatedScrollDown from "../../components/AnimatedScrollDown";
import PriceChart from "../../components/Wallet/AssetsTab/CoinPanelHead/PriceChart";
import { ExternalLink } from "../ExternalLink";
import { SOCIAL_MEDIA_PLATFORM } from "../TeamMemberCard/interface";
import {
  FaDiscord,
  FaFacebookF,
  FaLink,
  FaLinkedinIn,
  FaMedium,
  FaReddit,
  FaTelegramPlane,
  FaTwitter,
} from "react-icons/fa";
import { TokenInformationCard } from "../TokenInformationCard";
import { SwapPreview } from "../SwapPreview";
import { AboutTab } from "../AboutTokenDetailsTab";
import { StakingTokenDetailsTab } from "../StakingTokenDetailsTab";
import { VestingTokenDetailsTab } from "../VestingTokenDetailsTab";
import { ReferralTokenDetailsTab } from "../ReferralTokenDetailsTab";
import { useDispatch, useSelector } from "react-redux";
import { getTokenDataRequest, resetState } from "./tokenDetails";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useParams } from "react-router-dom";
import {
  getTokenDataLoadingSelector,
  getTokenDataSelector,
  getTokenDetailsLoadingSelector,
  getTokenDetailsSelector,
} from "./selectors";
import Loading from "../Loading";
import { getAvailableFundsRequest } from "../Wallet/walletSlice";
import { getWalletSelector } from "../Wallet/selectors";
import binanceChainImage from "../../assets/images/bnb.png";

const TokenDetails = () => {
  const [currentSelectedTab, setCurrentSelectedTab] = useState("1");
  const dispatch = useDispatch();
  const params = useParams();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const tokenData = useSelector(getTokenDataSelector);
  const tokenDataLoading = useSelector(getTokenDataLoadingSelector);
  const wallet = useSelector(getWalletSelector);

  const getTokenData = async () => {
    const token = await executeRecaptcha("tokenData");
    // /chainId/Symbol;
    dispatch(
      getTokenDataRequest({
        captchaToken: token,
        chainId: params.chainId,
        tokenSymbol: params.tokenSymbol,
      })
    );
  };

  const getIconBasedOnSocialMedia = (social) => {
    switch (social) {
      case SOCIAL_MEDIA_PLATFORM.TWITTER:
        return <FaTwitter className="socialLinkItemIcon" />;
      case SOCIAL_MEDIA_PLATFORM.FACEBOOK:
        return <FaFacebookF className="socialLinkItemIcon" />;
      case SOCIAL_MEDIA_PLATFORM.LINKEDIN:
        return <FaLinkedinIn className="socialLinkItemIcon" />;
      case SOCIAL_MEDIA_PLATFORM.TELEGRAM:
        return <FaTelegramPlane className="socialLinkItemIcon" />;
      case SOCIAL_MEDIA_PLATFORM.MEDIUM:
        return <FaMedium className="socialLinkItemIcon" />;
      case SOCIAL_MEDIA_PLATFORM.REDDIT:
        return <FaReddit className="socialLinkItemIcon" />;
      case SOCIAL_MEDIA_PLATFORM.DISCORD:
        return <FaDiscord className="socialLinkItemIcon" />;
      default:
        return <FaLink className="socialLinkItemIcon" />;
    }
  };
  useEffect(() => {
    if (executeRecaptcha) {
      getTokenData();
    }

    return () => {
      dispatch(resetState());
    };
  }, [executeRecaptcha]);

  useEffect(() => {
    if (tokenData) {
      dispatch(
        getAvailableFundsRequest({
          contractAddress: tokenData.token.contractAddress,
        })
      );
    }
  }, [tokenData]);

  return (
    <div className="campaignInvestPageContainer">
      <Loading loading={tokenDataLoading}>
        <div className="introSection">
          <AnimatedScrollDown />{" "}
          {tokenData && (
            <>
              <div className="content">
                <div className="contentHeaderContainer">
                  <div className="contentHeader">
                    <div className="campaign">
                      <div className="logo">
                        <Image src={tokenData.token.iconUrl} preview={false} />
                      </div>
                      <div className="sectionName">
                        <span className="campaignName">
                          {tokenData.token.tokenName}
                        </span>
                        <span className="legalName">
                          {tokenData.token.tokenSymbol}
                        </span>
                      </div>
                    </div>
                    <div className="tokenPriceChart">
                      {wallet &&
                        wallet[`${tokenData.token.contractAddress}Funds`] &&
                        wallet[`${tokenData.token.contractAddress}Funds`]
                          .tokenData && (
                          <>
                            <PriceChart
                              data={
                                wallet[
                                  `${tokenData.token.contractAddress}Funds`
                                ].tokenData
                              }
                            />
                            <div
                              className={`tokenPrice ${
                                wallet[
                                  `${tokenData.token.contractAddress}Funds`
                                ]?.tokenData?.priceChange24h < 0
                                  ? "priceDecrease"
                                  : "priceIncrease"
                              }`}
                            >
                              $
                              {
                                wallet[
                                  `${tokenData.token.contractAddress}Funds`
                                ]?.tokenData?.currentPrice
                              }
                            </div>
                          </>
                        )}
                    </div>
                  </div>

                  <div className="socialLinksSection">
                    {tokenData &&
                      tokenData.details.socialAccounts.map(
                        (socialLink, index) => {
                          if (socialLink.url) {
                            return (
                              <ExternalLink
                                href={
                                  "https://" +
                                  socialLink.url.replace("https://", "")
                                }
                                key={index}
                                extraClassNames="socialLinkItemContainer"
                              >
                                <div className="social-link-item">
                                  {getIconBasedOnSocialMedia(socialLink.type)}
                                </div>
                              </ExternalLink>
                            );
                          } else return null;
                        }
                      )}
                  </div>
                </div>

                <div className="contentDetails">
                  {tokenData && (
                    <>
                      <div className="valueProposition">
                        {tokenData.details.brief.title}
                      </div>
                      <div className="publicDescription">
                        {tokenData.details.brief.content}
                      </div>
                      <div className="containerTag">
                        {tokenData.details.tags.map((tag, key) => (
                          <div className="tag" key={key}>
                            {tag.value}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                {/*{tokenData && (*/}
                {/*  <div className="interactionField">*/}
                {/*    <div className="interactionContent">*/}
                {/*      <div className="contentTitle">{`How do you feel about ${tokenData.tokenName} today?`}</div>*/}
                {/*      <div className="contentDescription">*/}
                {/*        Vote to see what our Community thinks*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div className="buttonsContainer">*/}
                {/*      <div*/}
                {/*        className="likeButton"*/}
                {/*        onClick={() => console.log("campaign liked action")}*/}
                {/*      >*/}
                {/*        <div className="iconContainer">*/}
                {/*          <FiThumbsUp />*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*      <div*/}
                {/*        className="dislikeButton"*/}
                {/*        onClick={() => console.log("campaign disliked action")}*/}
                {/*      >*/}
                {/*        <div className="iconContainer">*/}
                {/*          <FiThumbsDown />*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
              <div className="tokenInformationContainer">
                {tokenData && (
                  <>
                    <TokenInformationCard
                      tokenSymbol={tokenData.token.tokenSymbol}
                      tokenName={tokenData.token.tokenName}
                      contractAddress={tokenData.token.contractAddress}
                      numberOfDecimals={tokenData.token.decimals}
                      totalSupply={tokenData.token.totalSupply}
                      network={tokenData.token.chainId}
                      networkImageUrl={binanceChainImage}
                    />
                    <SwapPreview
                      toSwapTokenContractAddress={
                        tokenData.token.contractAddress
                      }
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {tokenData && (
          <>
            <div id="dropdownParent">
              <Select
                defaultValue={"1"}
                className="tokenDetailsTabSelector"
                popupClassName="tokenDetailsSelectorPopup"
                onChange={(value) => setCurrentSelectedTab(value)}
                getPopupContainer={() =>
                  document.getElementById("dropdownParent")
                }
                options={[
                  { value: "1", label: "About" },
                  { value: "2", label: "Staking" },
                  { value: "3", label: "Vesting" },
                  { value: "4", label: "Referral" },
                ]}
              />
            </div>

            <Tabs
              centered
              defaultActiveKey={"1"}
              activeKey={currentSelectedTab}
              className="tokenDetailsStepper"
              onTabClick={(value) => setCurrentSelectedTab(value)}
              items={[
                {
                  label: "About",
                  key: "1",
                  children: <AboutTab data={tokenData.details.about} />,
                },
                {
                  label: "Staking",
                  key: "2",
                  children: (
                    <StakingTokenDetailsTab
                      contractAddress={tokenData.details.contractAddress}
                      tokenName={tokenData.token.tokenName}
                      tokenSymbol={tokenData.token.tokenSymbol}
                      tokenIconUrl={tokenData.token.iconUrl}
                      title={tokenData.details.brief.content}
                    />
                  ),
                },
                {
                  label: "Vesting",
                  key: "3",
                  children: (
                    <VestingTokenDetailsTab
                      tokenSymbol={tokenData.token.tokenSymbol}
                      contractAddress={tokenData.token.contractAddress}
                      title={tokenData.details.brief.content}
                    />
                  ),
                },
                {
                  label: "Referral",
                  key: "4",
                  children: (
                    <ReferralTokenDetailsTab
                      tokenSymbol={tokenDataMock.details.tokenSymbol}
                      contractAddress={tokenData.token.contractAddress}
                    />
                  ),
                },
              ]}
            />
          </>
        )}
      </Loading>
    </div>
  );
};

export default TokenDetails;
