import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const postLoading = (state: AppState) => state.register.loading;

const postError = (state: AppState) => state.register.error;
const postRegisterSuccess = (state: AppState) => state.register.registerSuccess;

export const postLoadingSelector = createSelector(
  postLoading,
  (pending) => pending
);

export const postErrorSelector = createSelector(postError, (error) => error);
export const postRegisterSuccessSelector = createSelector(
  postRegisterSuccess,
  (registerSuccess) => registerSuccess
);
