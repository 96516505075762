import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  getStakingStatisticsFailure,
  getStakingStatisticsRequest,
  getStakingStatisticsSuccess,
} from "./stakingStatisticsSlice";
import { GetStakingStatisticsPayload } from "./types";
import { axios } from "services/axios.service";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getStakingStatisticsSaga(
  action: ReturnType<typeof getStakingStatisticsRequest>
) {
  try {
    const response: AxiosResponse<GetStakingStatisticsPayload> = yield call(
      axios.get,
      `${process.env.REACT_APP_STATISTICS_URL}/statistics/staking`,
      {
        params: action.payload,
      }
    );
    yield put(
      getStakingStatisticsSuccess({
        statistics: response.data,
      })
    );
  } catch (e: any) {
    yield put(getStakingStatisticsFailure());
  }
}

function* walletSaga() {
  yield all([
    takeLatest(getStakingStatisticsRequest, getStakingStatisticsSaga),
  ]);
}

export default walletSaga;
