import React, { useEffect } from "react";
import "./styles.less";
import { mockData } from "./data";
import { formatNumber } from "_helpers/numberFormatter";
import { IVestingTokenDetailsTab } from "./interface";
import moment from "moment";
import { FiClock } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  getVestingCoinsLoadingSelector,
  getVestingCoinsSelector,
} from "../Vesting/selectors";
import { getVestingCoinsRequest } from "../Vesting/vestingSlice";
import { Empty } from "antd";

export const VestingTokenDetailsTab = (props: IVestingTokenDetailsTab) => {
  const { tokenSymbol, contractAddress, title } = props;
  const dispatch = useDispatch();

  const vestingCoins = useSelector(getVestingCoinsSelector);
  const vestingCoinsLoading = useSelector(getVestingCoinsLoadingSelector);

  useEffect(() => {
    if (contractAddress === process.env.REACT_APP_SEON_CONTRACT_ADDRESS) {
      dispatch(getVestingCoinsRequest());
    }
  }, []);

  return (
    <div className="vestingTokenDetailsTab">
      <div className="tabHeader">
        <div className="headerTitle">Vesting details</div>
        <div className="headerSubtitle">{title}</div>
      </div>

      <div className="tabContent">
        {vestingCoins.length ? (
          vestingCoins.map((item, key) => {
            return (
              <div
                key={key}
                className={
                  item.status === "RELEASED"
                    ? "vestingItem"
                    : "vestingItem unlocked"
                }
              >
                <div className="itemLeftSection">
                  <div className="unlockedPercentage">
                    {item.status === "RELEASED"
                      ? "Unlocked"
                      : "Next vesting is "}{" "}
                    {formatNumber(item.percentage * 100)}%:
                  </div>
                  <div className="unlockedAmount">
                    {formatNumber(item.amount)} {tokenSymbol}
                  </div>
                </div>
                <div className="itemRightSection">
                  <div className="statusBadge">
                    {item.status === "RELEASED"
                      ? "Received"
                      : `${moment(item.releaseDate).diff(
                          moment(),
                          "days"
                        )} days`}
                    {item.status !== "RELEASED" && (
                      <FiClock className="clockIcon" />
                    )}
                  </div>
                  <div className="unlockDate">
                    {moment(item.releaseDate).format("Do MMMM YYYY")}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Empty description="Currently you don't have any vesting schedule available" />
        )}
      </div>
    </div>
  );
};
