import React from "react";
import "./style.less";
import { INewsProps } from "./interface";
import ProtectedNews from "./ProtectedNews";

const News: React.FC<INewsProps> = () => {
  return <ProtectedNews />;
};

export default News;
