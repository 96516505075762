import React, { useEffect, useState } from "react";
import "./style.less";
import { IPriceChartProps } from "./interface";
import { Chart } from "react-chartjs-2";

const PriceChart = (props: IPriceChartProps) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const lineOptions: any = {
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      xAxes: {
        display: false,
      },
      yAxes: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  useEffect(() => {
    setChartData({
      labels: props.data.last24hPriceMovement.map((item) => item.timestamp),
      datasets: [
        {
          label: [1, 2, 3, 4, 5, 6, 7, 4, 3, 5, 30, 12, 13, 14, 15],
          fill: false,
          lineTension: 0.7,
          backgroundColor:
            props.data.priceChange24h > 0 ? "#06CF82" : "#ED5440",
          borderColor: props.data.priceChange24h > 0 ? "#06CF82" : "#ED5440",
          borderCapStyle: "butt",
          borderDash: [],
          borderWidth: 2,
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor:
            props.data.priceChange24h > 0 ? "#06CF82" : "#ED5440",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 0,
          pointHoverRadius: 0,
          pointHoverBackgroundColor:
            props.data.priceChange24h > 0 ? "#06CF82" : "#ED5440",
          pointHoverBorderColor:
            props.data.priceChange24h > 0 ? "#06CF82" : "#ED5440",
          pointHoverBorderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 0,
          data: props.data.last24hPriceMovement.map((item) => item.value),
        },
      ],
    });
  }, []);

  return (
    <>
      <Chart
        type="line"
        options={lineOptions}
        data={chartData}
        id={`price-chart-${props.data.details.tokenSymbol}`}
      />
    </>
  );
};

export default PriceChart;
