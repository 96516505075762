export const groupBy = (data, key) => {
  return data.reduce((group, itemData) => {
    let groupByKeyIndex;
    group.map((item, index) => {
      if (item.groupByKey === itemData.data[key]) {
        groupByKeyIndex = index;
      }
    });
    if (groupByKeyIndex !== undefined) {
      group[groupByKeyIndex].data.push(itemData);
    } else {
      group.push({
        groupByKey: itemData.data[key],
        data: [itemData],
      });
    }
    return group;
  }, []);
};

export const merge = (firstArray, secondArray) => {
  const firstArrayGroupByKeys = firstArray.map((item) => item.groupByKey);
  const secondArrayGroupByKeys = secondArray.map((item) => item.groupByKey);
  return firstArrayGroupByKeys.concat(secondArrayGroupByKeys).map((key) => {
    return {
      groupByKey: key,
      data: [...firstArray.map((item) => item)[key], ...secondArray[key]],
    };
  });
};
