export const isGreater = (x: string, y: string) => {
  const xIntLength =
    x.search(/\.|$/); /* Finds position of . or end of string */
  const yIntLength =
    y.search(/\.|$/); /* Finds position of . or end of string */
  /* Compare lengths of int part */
  if (xIntLength !== yIntLength) {
    return xIntLength > yIntLength;
  } else {
    /* Add decimal point to Y if not present and add  trailing zeros
           because otherwise eg. '2.00' > '2.0' would return true */
    if (x.length === y.length) {
      return x > y;
    }
    if (x.length > y.length) {
      const yWithDecimalPoint = y.includes(".") ? y : y + ".";
      const paddedY = yWithDecimalPoint.padEnd(x.length, "0");
      return x > paddedY;
    } else {
      const xWithDecimalPoint = x.includes(".") ? x : x + ".";
      const paddedX = xWithDecimalPoint.padEnd(y.length, "0");
      return paddedX > y;
    }
  }
};
export const isEqual = (x: string, y: string) => {
  const xIntLength =
    x.search(/\.|$/); /* Finds position of . or end of string */
  const yIntLength =
    y.search(/\.|$/); /* Finds position of . or end of string */
  /* Compare lengths of int part */
  if (xIntLength !== yIntLength) {
    return false;
  } else {
    /* Add decimal point to Y if not present and add  trailing zeros
           because otherwise eg. '2.00' > '2.0' would return true */
    if (x.length === y.length) {
      return x === y;
    }
    if (x.length > y.length) {
      const yWithDecimalPoint = y.includes(".") ? y : y + ".";
      const paddedY = yWithDecimalPoint.padEnd(x.length, "0");
      return x === paddedY;
    } else {
      const xWithDecimalPoint = x.includes(".") ? x : x + ".";
      const paddedX = xWithDecimalPoint.padEnd(y.length, "0");
      return paddedX === y;
    }
  }
};
export const isLower = (x: string, y: string) => {
  const xIntLength =
    x.search(/\.|$/); /* Finds position of . or end of string */
  const yIntLength =
    y.search(/\.|$/); /* Finds position of . or end of string */
  /* Compare lengths of int part */
  if (xIntLength !== yIntLength) {
    return xIntLength < yIntLength;
  } else {
    /* Add decimal point to Y if not present and add  trailing zeros
           because otherwise eg. '2.00' > '2.0' would return true */
    if (x.length === y.length) {
      return x < y;
    }
    if (x.length > y.length) {
      const yWithDecimalPoint = y.includes(".") ? y : y + ".";
      const paddedY = yWithDecimalPoint.padEnd(x.length, "0");
      return x < paddedY;
    } else {
      const xWithDecimalPoint = x.includes(".") ? x : x + ".";
      const paddedX = xWithDecimalPoint.padEnd(y.length, "0");
      return paddedX < y;
    }
  }
};

export const longDivision = (number, divisor) => {
  let ans = "";
  let idx = 0;
  // @ts-ignore
  let temp = number[idx] - "0";
  while (temp < divisor) {
    temp = temp * 10 + number[idx + 1].charCodeAt(0) - "0".charCodeAt(0);
    idx += 1;
  }
  idx += 1;

  while (number.length > idx) {
    // Store result in answer i.e. temp / divisor
    ans += String.fromCharCode(Math.floor(temp / divisor) + "0".charCodeAt(0));

    // Take next digit of number
    temp =
      (temp % divisor) * 10 + number[idx].charCodeAt(0) - "0".charCodeAt(0);
    idx += 1;
  }

  ans += String.fromCharCode(Math.floor(temp / divisor) + "0".charCodeAt(0));
  return ans.length === 0 ? "0" : ans;
};
