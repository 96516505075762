import {
  IFavoriteNewsParams,
  IGetArticleRequestParams,
  IGetNewsCategoriesParams,
  INewsParams,
  IRelatedNewsParams,
  ITrendingNewsParams,
  NewsState,
} from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IArticleInteractionParams } from "./interface";

const initialState: NewsState = {
  loading: false,
  error: null,
  news: null,
  newsCategoriesLoading: false,
  newsCategories: null,
  article: null,
  articleLoading: false,
  trendingNews: [],
  trendingNewsLoading: false,
  relatedNews: [],
  relatedNewsLoading: false,
  articleInteractionLoading: false,
  articleInteractionSuccess: false,
  favoriteNews: null,
  favoriteNewsLoading: false,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    getFavoriteNewsRequest(state, action: PayloadAction<IFavoriteNewsParams>) {
      return {
        ...state,
        params: action.payload,
        favoriteNews: null,
        favoriteNewsLoading: true,
      };
    },
    getFavoriteNewsSuccess(state, action) {
      return {
        ...state,
        favoriteNews: action.payload,
        favoriteNewsLoading: false,
      };
    },
    getFavoriteNewsFailure(state) {
      return {
        ...state,
        favoriteNews: null,
        favoriteNewsLoading: false,
      };
    },
    getRelatedNewsRequest(state, action: PayloadAction<IRelatedNewsParams>) {
      return {
        ...state,
        params: action.payload,
        relatedNews: [],
        relatedNewsLoading: true,
      };
    },
    getRelatedNewsSuccess(state, action) {
      return {
        ...state,
        relatedNews: action.payload,
        relatedNewsLoading: false,
      };
    },
    getRelatedNewsFailure(state) {
      return {
        ...state,
        relatedNews: [],
        relatedNewsLoading: false,
      };
    },
    getTrendingNewsRequest(state, action: PayloadAction<ITrendingNewsParams>) {
      return {
        ...state,
        params: action.payload,
        trendingNews: [],
        trendingNewsLoading: true,
      };
    },
    getTrendingNewsSuccess(state, action) {
      return {
        ...state,
        trendingNews: action.payload,
        trendingNewsLoading: false,
      };
    },
    getTrendingNewsFailure(state) {
      return {
        ...state,
        trendingNews: [],
        trendingNewsLoading: false,
      };
    },
    getNewsRequest(state, action: PayloadAction<INewsParams>) {
      return {
        ...state,
        loading: true,
        params: action.payload,
        error: null,
        success: false,
      };
    },
    getNewsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        error: null,
        news: action.payload,
      };
    },
    getNewsFailure(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data,
      };
    },
    getNewsCategoriesRequest(
      state,
      action: PayloadAction<IGetNewsCategoriesParams>
    ) {
      return {
        ...state,
        params: action.payload,
        newsCategoriesLoading: true,
        newsCategories: null,
      };
    },
    getNewsCategoriesSuccess(state, action) {
      return {
        ...state,
        newsCategoriesLoading: false,
        newsCategories: action.payload,
      };
    },
    getNewsCategoriesFailure(state) {
      return {
        ...state,
        newsCategoriesLoading: false,
        newsCategories: null,
      };
    },
    getArticleRequest(state, action: PayloadAction<IGetArticleRequestParams>) {
      return {
        ...state,
        article: null,
        articleLoading: true,
        params: action.payload,
      };
    },
    getArticleSuccess(state, action) {
      return {
        ...state,
        article: action.payload.article,
        articleLoading: false,
      };
    },
    getArticleFailure(state) {
      return {
        ...state,
        article: null,
        articleLoading: false,
      };
    },
    articleInteractionRequest(
      state,
      action: PayloadAction<IArticleInteractionParams>
    ) {
      return {
        ...state,
        params: action.payload,
        articleInteractionLoading: true,
        articleInteractionSuccess: false,
      };
    },
    articleInteractionSuccess(state) {
      return {
        ...state,
        articleInteractionLoading: false,
        articleInteractionSuccess: true,
      };
    },
    articleInteractionFailure(state) {
      return {
        ...state,
        articleInteractionLoading: false,
        articleInteractionSuccess: false,
      };
    },
    setArticle(state, action) {
      return {
        ...state,
        article: action.payload.article,
      };
    },
    resetArticleInteractionState(state) {
      return {
        ...state,
        articleInteractionSuccess: false,
        articleInteractionLoading: false,
      };
    },
    resetState(state) {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  getNewsRequest,
  getNewsSuccess,
  getNewsFailure,
  resetState,
  getNewsCategoriesRequest,
  getNewsCategoriesSuccess,
  getNewsCategoriesFailure,
  getArticleRequest,
  getArticleSuccess,
  getArticleFailure,
  getTrendingNewsRequest,
  getTrendingNewsSuccess,
  getTrendingNewsFailure,
  getRelatedNewsRequest,
  getRelatedNewsSuccess,
  getRelatedNewsFailure,
  articleInteractionRequest,
  articleInteractionSuccess,
  articleInteractionFailure,
  getFavoriteNewsRequest,
  getFavoriteNewsSuccess,
  getFavoriteNewsFailure,
  setArticle,
  resetArticleInteractionState,
} = newsSlice.actions;

export default newsSlice.reducer;
