import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  getCoinDataFailure,
  getCoinDataRequest,
  getCoinDataSuccess,
  getWhitelistedCoinsFailure,
  getWhitelistedCoinsRequest,
  getWhitelistedCoinsSuccess,
  getAvailableFundsRequest,
  getAvailableFundsSuccess,
  getAvailableFundsFailure,
  getFundsHistoryRequest,
  getFundsHistorySuccess,
  getFundsHistoryFailure,
  getTransactionTypesRequest,
  getTransactionsLogRequest,
  getTransactionsLogSuccess,
  getTransactionsLogFailure,
  getFundsTotalRequest,
  getFundsTotalSuccess,
  getFundsTotalFailure,
  getWalletDataRequest,
  getWalletDataSuccess,
  getWalletDataFailure,
  putCancelStakingRequest,
  putCancelStakingSuccess,
  putCancelStakingFailure,
  getTransactionTypesSuccess,
  getTransactionTypesFailure,
  purchaseOrderSuccess,
  purchaseOrderFailure,
  purchaseOrderRequest,
  estimateRatesRequest,
  estimateRatesSuccess,
  estimateRatesFailure,
  getTransactionDetailsRequest,
  getTransactionDetailsSuccess,
  getTransactionDetailsFailure,
} from "./walletSlice";
import {
  FundsHistoryResponse,
  ITransactionDetails,
  ITransactionsLogResponse,
  IWalletResponse,
  PutCancelStakingSuccessPayload,
} from "./types";
import { axios } from "services/axios.service";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getWhitelistedCoinsSaga(
  action: ReturnType<typeof getWhitelistedCoinsRequest>
) {
  try {
    const response: AxiosResponse<IWalletResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/whitelist`
    );
    yield put(
      getWhitelistedCoinsSuccess({
        coins: response.data,
      })
    );
  } catch (e: any) {
    yield put(
      getWhitelistedCoinsFailure({
        error: e.response.data,
      })
    );
  }
}

function* getTransactionDetailsSaga(
  action: ReturnType<typeof getTransactionDetailsRequest>
) {
  try {
    const response: AxiosResponse<ITransactionDetails> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/funds/history/${action.payload.id}`
    );
    yield put(
      getTransactionDetailsSuccess({
        details: response.data,
      })
    );
  } catch (e: any) {
    yield put(getTransactionDetailsFailure());
  }
}

function* getTransactionTypesSaga(
  action: ReturnType<typeof getTransactionTypesRequest>
) {
  try {
    const response: AxiosResponse<any> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/funds/transactions/types`
    );
    yield put(getTransactionTypesSuccess(response.data));
  } catch (e: any) {
    yield put(
      getTransactionTypesFailure({
        error: e.response.data,
      })
    );
  }
}

function* getCoinDataSaga(action: ReturnType<typeof getCoinDataRequest>) {
  try {
    const response: AxiosResponse<IWalletResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/${
        action.payload.public ? "public/" : ""
      }tokens/${action.payload.contractAddress}/data`
    );
    yield put(
      getCoinDataSuccess({
        coin: response.data,
        contractAddress: action.payload.contractAddress,
      })
    );
  } catch (e: any) {
    yield put(
      getCoinDataFailure({
        error: e.response.data,
        contractAddress: action.payload.contractAddress,
      })
    );
  }
}

function* getTransactionsLogSaga(
  action: ReturnType<typeof getTransactionsLogRequest>
) {
  try {
    const response: AxiosResponse<ITransactionsLogResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/funds/${action.payload.contractAddress}/transactions-log`,
      {
        params: action.payload,
      }
    );
    yield put(
      getTransactionsLogSuccess({
        transactions: response.data,
        contractAddress: action.payload.contractAddress,
        page: action.payload.page,
      })
    );
  } catch (e: any) {
    yield put(
      getTransactionsLogFailure({
        error: e.response.data,
        contractAddress: action.payload.contractAddress,
      })
    );
  }
}

function* getAvailableFundsSaga(
  action: ReturnType<typeof getAvailableFundsRequest>
) {
  try {
    const response: AxiosResponse<IWalletResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/funds/${action.payload.contractAddress}/available`
    );
    yield put(
      getAvailableFundsSuccess({
        coin: response.data,
        contractAddress: action.payload.contractAddress,
      })
    );
  } catch (e: any) {
    yield put(
      getAvailableFundsFailure({
        contractAddress: action.payload.contractAddress,
      })
    );
  }
}

function* getFundsHistorySaga(
  action: ReturnType<typeof getFundsHistoryRequest>
) {
  try {
    const response: AxiosResponse<FundsHistoryResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/funds/history`,
      {
        params: action.payload,
      }
    );
    yield put(
      getFundsHistorySuccess({
        history: response.data,
        page: action.payload.page,
      })
    );
  } catch (e: any) {
    yield put(getFundsHistoryFailure());
  }
}

function* getFundsTotalSaga(action: ReturnType<typeof getFundsTotalRequest>) {
  try {
    const response: AxiosResponse<FundsHistoryResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/funds/total`
    );
    yield put(
      getFundsTotalSuccess({
        fundsTotal: response.data,
      })
    );
  } catch (e: any) {
    yield put(getFundsTotalFailure());
  }
}

function* getWalletDataSaga(action) {
  try {
    const response: AxiosResponse<FundsHistoryResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/wallet`
    );
    yield put(
      getWalletDataSuccess({
        walletData: response.data,
      })
    );
  } catch (e: any) {
    yield put(getWalletDataFailure());
  }
}

function* putCancelStaking(action) {
  try {
    const response: AxiosResponse<PutCancelStakingSuccessPayload> = yield call(
      axios.put,
      `${process.env.REACT_APP_API_URL}/staking/seedon-pool/cancel/${action.payload}`
    );
    yield put(
      putCancelStakingSuccess({
        cancelStaking: response.data,
      })
    );
  } catch (e: any) {
    yield put(putCancelStakingFailure());
  }
}

function* purchaseOrderSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_API_URL}/purchase-orders`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(purchaseOrderSuccess(response.data));
  } catch (e: any) {
    yield put(purchaseOrderFailure());
  }
}

function* estimateRatesSaga(action) {
  try {
    const response = yield call(
      axios.put,
      `${process.env.REACT_APP_API_URL}/purchase-orders/estimate`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(estimateRatesSuccess(response.data));
  } catch (e: any) {
    yield put(estimateRatesFailure());
  }
}

function* walletSaga() {
  yield all([
    takeLatest(getWhitelistedCoinsRequest, getWhitelistedCoinsSaga),
    takeEvery(getCoinDataRequest, getCoinDataSaga),
    takeEvery(getAvailableFundsRequest, getAvailableFundsSaga),
    takeEvery(getFundsHistoryRequest, getFundsHistorySaga),
    takeEvery(getTransactionsLogRequest, getTransactionsLogSaga),
    takeEvery(getTransactionTypesRequest, getTransactionTypesSaga),
    takeEvery(getFundsTotalRequest, getFundsTotalSaga),
    takeEvery(getWalletDataRequest, getWalletDataSaga),
    takeEvery(putCancelStakingRequest, putCancelStaking),
    takeEvery(purchaseOrderRequest, purchaseOrderSaga),
    takeEvery(estimateRatesRequest, estimateRatesSaga),
    takeEvery(getTransactionDetailsRequest, getTransactionDetailsSaga),
  ]);
}

export default walletSaga;
