import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { FiHelpCircle } from "react-icons/fi";
import TelegramOtpsGuideModal from "../PhoneValidationModal/TelegramOtpsGuideModal";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserSelector } from "./selectors";
import { getToggleOtpLoading } from "../CodeVerification/selectors";
import { toggleOtpRecipientRequest } from "../CodeVerification/codeVerificationSlice";
import { getCurrentUserRequest } from "./userSlice";

const OtpSettings = () => {
  const dispatch = useDispatch();

  const [showTelegramTutorial, setShowTelegramTutorial] = useState(false);
  const [shouldReceiveThroughTelegram, setShouldReceiveThroughTelegram] =
    useState(null);
  const [previousOtpPreference, setPreviousOtpPreference] = useState(null);

  const toggleLoading = useSelector(getToggleOtpLoading);
  const user = useSelector(getCurrentUserSelector);
  useEffect(() => {
    dispatch(getCurrentUserRequest());
  }, []);
  useEffect(() => {
    if (user) {
      setShouldReceiveThroughTelegram(
        user.state.properties?.shouldReceiveThroughTelegram
      );
      setPreviousOtpPreference(
        user.state.properties?.shouldReceiveThroughTelegram
      );
    }
  }, [user]);

  useEffect(() => {
    if (
      shouldReceiveThroughTelegram !== null &&
      shouldReceiveThroughTelegram !== previousOtpPreference
    ) {
      setPreviousOtpPreference(shouldReceiveThroughTelegram);
      dispatch(
        toggleOtpRecipientRequest({
          telegramEnabled: shouldReceiveThroughTelegram,
        })
      );
    }
  }, [shouldReceiveThroughTelegram]);

  return (
    <>
      <div className="otpSettings">
        <p>
          If you want to change the way in which you'll receive your OTP, you
          can easily switch between receiving the code via SMS or via our
          Telegram Bot.
        </p>

        <a onClick={() => setShowTelegramTutorial(true)}>
          <FiHelpCircle />
          How do I setup the Telegram Bot?
        </a>

        {shouldReceiveThroughTelegram !== null ? (
          <div className="radioValues">
            <div className="row">
              <span className={shouldReceiveThroughTelegram ? "" : "active"}>
                SMS
              </span>
              <Switch
                disabled={toggleLoading}
                checked={!shouldReceiveThroughTelegram}
                onChange={(val) => setShouldReceiveThroughTelegram(!val)}
              />
            </div>

            <div className="row">
              <span className={shouldReceiveThroughTelegram ? "active" : ""}>
                Telegram
              </span>
              <Switch
                disabled={toggleLoading}
                checked={shouldReceiveThroughTelegram}
                onChange={(val) => setShouldReceiveThroughTelegram(val)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <TelegramOtpsGuideModal
        isVisible={showTelegramTutorial}
        closeModal={() => setShowTelegramTutorial(false)}
      />
    </>
  );
};

export default OtpSettings;
