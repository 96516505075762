import { useEffect, useState } from "react";
import "./style.less";
import { Slider, Spin } from "antd";
import { IButtonProps } from "./interface";

const SwipeButton: React.FC<IButtonProps> = (props: IButtonProps) => {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(11);

  const onChange = (e) => {
    if (e <= 90 && e >= 11) {
      setValue(e);
    }

    if (e >= 85) {
      setLoading(true);
      setDisabled(true);
      if (!loading) {
        props.onSwipe();
      }
    }
  };

  const onAfterChange = (e) => {
    if (e >= 85) {
      setLoading(true);
      setDisabled(true);
    } else {
      setValue(11);
    }
  };

  return (
    <div className="swipeButton">
      <Spin spinning={props.loading || loading}>
        <Slider
          disabled={disabled || props.disabled}
          tooltip={{ open: false }}
          min={1}
          max={100}
          step={1}
          value={value}
          onChange={onChange}
          onAfterChange={onAfterChange}
          className={`${props.extraClasses ? props.extraClasses : ""}`}
        />
        <span
          className={props.disabled || disabled ? "label disabled" : "label"}
        >
          {props.disabled || disabled ? (
            "Disabled"
          ) : (
            <span>
              {props.label} {props.timer ? `( ${props.timer}s )` : ""}
            </span>
          )}
        </span>
      </Spin>
    </div>
  );
};

export default SwipeButton;
