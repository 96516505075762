import { all, call, put, takeLatest } from "redux-saga/effects";
import { axios } from "services/axios.service";
import {
  getVestingCoinsFailure,
  getVestingCoinsRequest,
  getVestingCoinsSuccess,
} from "./vestingSlice";
import { AxiosResponse } from "axios";
import { IVestingResponse } from "./types";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getVestingCoinsSaga(
  action: ReturnType<typeof getVestingCoinsRequest>
) {
  try {
    const response: AxiosResponse<IVestingResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/vesting/entries`
    );
    yield put(
      getVestingCoinsSuccess({
        vestingEntries: response.data,
      })
    );
  } catch (e: any) {
    yield put(
      getVestingCoinsFailure({
        error: e.response.data,
      })
    );
  }
}

function* walletSaga() {
  yield all([takeLatest(getVestingCoinsRequest, getVestingCoinsSaga)]);
}

export default walletSaga;
