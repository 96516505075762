export const mockData = {
  data: {
    id: "636a66afeebdef131b35af2c",
    slag: "new-new-new-1614519098",
    revisionId: "b678e44b-4a3f-45bc-9245-dcbf31594b0a",
    ownerId: "2c94808682da7ea30182e83988a90040",
    collaboratorIds: [],
    product: {
      publicDescription:
        "The G4AL Platform is the easiest and fastest approach for developers who want to experiment with Web3, enabling the addition of blockchain features to their games in a few minutes without any Web3 knowledge.",
      fullDescription:
        '<p class="rich-text-align-default">Greetings fellow entrepreneur and welcome to SeedOn&#39;s form for applying for funding. In this 6-step form, you are required to submit all relevant details on yourGreetings fellow entrepreneur and welcome to SeedOn&#39;s form for applying for funding. In tsdsds41222</p>',
      problem: {
        name: "Greetings fellow entrepreneur and welcome to SeedOn's form for applying for funding. In tsdsds",
        content:
          '<p class="rich-text-align-default">Greetings fellow entrepreneur and welcome to SeedOn&#39;s form for applying for funding. In this 6-step form, you are required to submit all relevant details on yourGreetings fellow entrepreneur and welcome to SeedOn&#39;s form for applying for funding. In tsdsds</p>',
      },
      solution: {
        name: "Greetings fellow entrepreneur and welcome to SeedOn's form for applying for funding. In tsdsds",
        content:
          '<p class="rich-text-align-default">Greetings fellow entrepreneur and welcome to SeedOn&#39;s form for applying for funding. In this 6-step form, you are required to submit all relevant details on yourGreetings fellow entrepreneur and welcome to SeedOn&#39;s form for applying for funding. In tsdsds</p>',
      },
      product: {
        name: "Greetings fellow entrepreneur and welcome to SeedOn's form for applying for funding. In tsdsds",
        content:
          '<p class="rich-text-align-default">Greetings fellow entrepreneur and welcome to SeedOn&#39;s form for applying for funding. In this 6-step form, you are required to submit all relevant details on yourGreetings fellow entrepreneur and welcome to SeedOn&#39;s for412m for applying for funding. In tsdsds</p>',
      },
      motivation: {
        name: "Greetings fellow entrepreneur and welcome to SeedOn's form for applying for funding. In tsdsds",
        content:
          '<p class="rich-text-align-default">Greetings fellow entrepreneur and welcome to SeedOn&#39;s form for applying for funding. In this 6-step form, you are required to submit all relevant details on yourGreetings fellow entrepreneur and welcome to SeedOn&#39;s form for applying for funding. In tsdsds</p>',
      },
      presentationVideo: { url: "https://www.ww.ww", file: null },
      valueProposition:
        "equired to submit all relevant details on your1241412 2",
      coverImageFile: {
        id: "2c93809183b701820183cbd98e15009e",
        fileName: "1665572376020-Screenshot 2022-10-10 at 14.37.59.png",
        filePath:
          "public/images/seedon/1665572376020-Screenshot 2022-10-10 at 14.37.59.png",
        contentType: "image/png",
        size: 72746,
        checksum: "f9a5774532744d4927193a8c1d8e2d1c",
        owner: "seedon",
        url: "https://images.unsplash.com/photo-1484591974057-265bb767ef71?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80",
        imageResolution: { height: 784, width: 610 },
        private: false,
      },
    },
    company: {
      mission: {
        name: "rwetw",
        content: '<p class="rich-text-align-default">fdgfdgfd</p>',
      },
      statement: {
        name: "gfdg",
        content: '<p class="rich-text-align-default">gfdgd</p>',
      },
      vision: {
        name: "fgd",
        content: '<p class="rich-text-align-default">fdgf</p>',
      },
      traction: {
        name: "gfdg",
        content: '<p class="rich-text-align-default">fdgd</p>',
      },
      businessModel: {
        name: "fdgfd",
        content: '<p class="rich-text-align-default">dfg</p>',
      },
      customers: {
        name: "fgd",
        content: '<p class="rich-text-align-default">fdd</p>',
      },
      marketCompetition: {
        name: "fd",
        content: '<p class="rich-text-align-default">gfd</p>',
      },
      risks: [
        {
          name: "fdg ",
          content: '<p class="rich-text-align-default">gfd </p>',
          correlationId: "bf5775f8-3cef-4e88-ad7c-66cc9296afec",
        },
      ],
    },
    campaign: {
      category: "Blockchain1",
      softCap: 30100,
      hardCap: 165000,
      type: "EQUITY",
      equityPrice: 0,
      minimumAmount: 442,
      maximumAmount: 1424,
      duration: 4,
      investmentPackages: [
        {
          name: "fds",
          minimumAmount: 44.0,
          description: "dfgf",
          benefits: ["re"],
        },
      ],
    },
    implementationStages: [
      {
        capitalAllocationPercentage: 16.68,
        startDate: "2023-02-16T08:31:54Z",
        endDate: "2023-02-22T08:31:54Z",
        description: '<p class="rich-text-align-default">fbgfbfg</p>',
        objectives: [
          {
            name: "bfgbfg",
            content: "bgfbfg",
            correlationId: "94400182-b8f9-40d5-a9ab-7eb070d89bf0",
            status: "NOT_STARTED",
          },
        ],
        correlationId: "7089376b-7434-4e78-ba81-1d32caf02a7c",
        status: "NOT_STARTED",
      },
      {
        capitalAllocationPercentage: 49.99,
        startDate: "2023-02-23T08:31:54Z",
        endDate: "2023-02-28T08:31:54Z",
        description: '<p class="rich-text-align-default">bfgfbf41</p>',
        objectives: [
          {
            name: "Investment package",
            content: "bgffggf",
            correlationId: "c3fc1d90-5715-4098-9a63-5520251fb420",
            status: "NOT_STARTED",
          },
        ],
        correlationId: "2750d2ba-01b3-44fc-87b0-90068688877a",
        status: "NOT_STARTED",
      },
      {
        capitalAllocationPercentage: 33.33,
        startDate: "2023-03-14T08:31:54Z",
        endDate: "2023-03-22T08:31:54Z",
        description: '<p class="rich-text-align-default">bgff</p>',
        objectives: [
          {
            name: "Maecenas volutpat aliquet sapien",
            content: "bffff",
            correlationId: "e9c76f42-ce80-4952-bf06-275d6c7aa332",
            status: "NOT_STARTED",
          },
        ],
        correlationId: "b857e67e-a860-4119-a8ba-8c3c645bab80",
        status: "NOT_STARTED",
      },
    ],
    about: {
      companyLogo: {
        id: "2c948091842dd3d7018451ac226300c7",
        fileName: "1667817546216-image.webp",
        filePath: "public/images/seedon/1667817546216-image.webp",
        contentType: "image/webp",
        size: 38404,
        checksum: "6afe17b99ebbf7d9e18fbb4739420632",
        owner: "seedon",
        url: "https://dev-minio.hungrybytes.co/hungrybytes-bucket/public/images/seedon/1667817546216-image.webp",
        imageResolution: { height: null, width: null },
        private: false,
      },
      legalName: "new new",
      campaignName: "new new new",
      foundedAt: "2022-11-02T10:38:32Z",
      entityForm: "LIMITED_PARTNERSHIP",
      employeesNumber: 8,
      location: "Siberia, Russia",
      email: "Asd.asd@asd.asda",
      websiteUrl: "sda.asd",
      socialLinks: [
        {
          url: "https://facebook.com/142",
          type: "FACEBOOK",
          correlationId: "87e05790-9f40-49eb-bab9-77def75ede9a",
        },
        {
          url: "https://twitter.com/142",
          type: "TWITTER",
          correlationId: "87e05790-9f40-49eb-bab9-77def75ede9a",
        },
        {
          url: "https://linkedin.com/142",
          type: "LINKEDIN",
          correlationId: "87e05790-9f40-49eb-bab9-77def75ede9a",
        },
        {
          url: "https://telegram.com/142",
          type: "TELEGRAM",
          correlationId: "87e05790-9f40-49eb-bab9-77def75ede9a",
        },
      ],
      teamPresentation:
        '<p class="rich-text-align-default">The required details below include some of your business&#39;s details such as legal name, number of employees if any, date of founding for your business and your team members.</p><p class="rich-text-align-default"></p>',
      teamMembers: [
        {
          image: {
            id: "2c948091842dd3d7018451ac60da00c9",
            fileName:
              "1667817562267-inspired-2014-09-29-Vanessa-Larco-main.webp",
            filePath:
              "public/images/seedon/1667817562267-inspired-2014-09-29-Vanessa-Larco-main.webp",
            contentType: "image/webp",
            size: 155128,
            checksum: "3df90985aa63405c82bf6e9841c27e21",
            owner: "seedon",
            url: "https://dev-minio.hungrybytes.co/hungrybytes-bucket/public/images/seedon/1667817562267-inspired-2014-09-29-Vanessa-Larco-main.webp",
            imageResolution: { height: null, width: null },
            private: false,
          },
          name: "Ana ana",
          bio: "The required details below include some of your business's details such as legal name, number of employees if any, date of founding for your business and your team members.\n",
          position: "CTO",
          socialLinks: [
            {
              url: "https://facebook.com",
              type: "FACEBOOK",
              correlationId: "7443f28f-606c-412c-a332-9a5817731a4c",
            },
          ],
          correlationId: "d096699b-ffed-42ed-a6c7-80606ce34a5c",
        },
      ],
      ceoStatement:
        "The required details below include some of your business's details such as legal name, number of employees if any, date of founding for your business and your team members.\nThe required details below include some of your business's details such as legal name, number of employees if any, date of founding for your business and your team members.\n",
      ceoAvatar: {
        id: "2c948091842dd3d7018451ac3f1c00c8",
        fileName:
          "1667817553050-portrait-handsome-young-man-with-crossed-arms_176420-15569.jpeg",
        filePath:
          "public/images/seedon/1667817553050-portrait-handsome-young-man-with-crossed-arms_176420-15569.jpeg",
        contentType: "image/jpeg",
        size: 261474,
        checksum: "c6582dd7837b999e0f0e8e9a50a88da1",
        owner: "seedon",
        url: "https://dev-minio.hungrybytes.co/hungrybytes-bucket/public/images/seedon/1667817553050-portrait-handsome-young-man-with-crossed-arms_176420-15569.jpeg",
        imageResolution: { height: 1333, width: 2000 },
        private: false,
      },
    },
    other: {
      faqSection: [
        {
          name: "The required details below include some of your business's details such as legal name, number of employees if any, date of founding for your business and your team members.",
          content:
            '<p class="rich-text-align-default">The required details below include some of your business&#39;s details such as legal name, number of employees if any, date of founding for your business and your team members.</p><p class="rich-text-align-default"></p>',
          correlationId: "3fa29272-1925-427a-8fd0-65f2df29e5c7",
        },
      ],
      highlights: [
        {
          value: "Additional info",
          correlationId: "63f7e8a1-731f-49e0-bdc8-fa47e76ea3d1",
        },
        {
          value: "Additional infoAdditional info",
          correlationId: "6d32c704-a706-4ea3-9c84-290e9d574a33",
        },
        {
          value: "Additional infoAdditional infoAdditional info",
          correlationId: "d7413d21-f7d5-4dda-90e3-2fb69bba9921",
        },
      ],
      pressArticles: [],
    },
    listingRequestId: "63652eed0f1ce2389dff583d",
    startDate: "2022-11-08T14:24:47.669Z",
    endDate: "2022-11-12T14:24:47.669Z",
    tags: [{ value: "#B2B" }],
    statistics: { raised: 0, backers: 0 },
    hardCapNotReached: true,
    daysLeft: 2,
    status: "ACTIVE",
    addedAt: "2022-11-08T14:24:47.674Z",
    platform: {
      url: "https://www.kasandbox.org/programming-images/avatars/primosaur-ultimate.png",
    },
  },
  userInteractions: [],
};

export const tokenDataMock = {
  iconUrl:
    "https://dev-minio.hungrybytes.co/hungrybytes-bucket/public/assets/icons/busd.png",
  details: {
    contractAddress: "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee",
    tokenSymbol: "BUSD",
    tokenName: "Binance USD",
    tokenDecimals: 18,
    totalSupply: 350000000,
  },
  currentPrice: 1.001,
  priceChange24h: -0.00083809794109,
  priceChangePercentage24h: -0.08365,
  last24hPriceMovement: [
    {
      timestamp: 1668574800000,
      value: 1.0017567066549546,
    },
    {
      timestamp: 1668578400000,
      value: 1.0001684192489915,
    },
    {
      timestamp: 1668582000000,
      value: 1.0010815290055939,
    },
    {
      timestamp: 1668585600000,
      value: 1.0011983253375984,
    },
    {
      timestamp: 1668589200000,
      value: 1.0010699922874944,
    },
    {
      timestamp: 1668592800000,
      value: 1.0011698072016342,
    },
    {
      timestamp: 1668596400000,
      value: 1.0015993664432739,
    },
    {
      timestamp: 1668600000000,
      value: 1.0026739070281676,
    },
    {
      timestamp: 1668603600000,
      value: 1.0041305936017453,
    },
    {
      timestamp: 1668607200000,
      value: 1.0006685369193131,
    },
    {
      timestamp: 1668610800000,
      value: 1.0021784507990978,
    },
    {
      timestamp: 1668614400000,
      value: 1.0019996272219633,
    },
    {
      timestamp: 1668618000000,
      value: 1.0020408150532316,
    },
    {
      timestamp: 1668621600000,
      value: 1.0016617460517214,
    },
    {
      timestamp: 1668625200000,
      value: 1.00093662302149,
    },
    {
      timestamp: 1668628800000,
      value: 1.0019961554749492,
    },
    {
      timestamp: 1668632400000,
      value: 1.0017024638686973,
    },
    {
      timestamp: 1668636000000,
      value: 1.0017168336788642,
    },
    {
      timestamp: 1668639600000,
      value: 1.0014111445747358,
    },
    {
      timestamp: 1668643200000,
      value: 1.0019777149137563,
    },
    {
      timestamp: 1668646800000,
      value: 1.0013086077250584,
    },
    {
      timestamp: 1668650400000,
      value: 1.0007869592561587,
    },
    {
      timestamp: 1668654000000,
      value: 1.0021289629643741,
    },
    {
      timestamp: 1668657600000,
      value: 1.0004786620275226,
    },
    {
      timestamp: 1668661200000,
      value: 1.0011898528317813,
    },
  ],
};
