import { Spin } from "antd";
import React from "react";
import "./style.less";

const Loading = (props) => (
  <div className="loadingSpinner inline">
    <Spin spinning={props.loading}>{props.children}</Spin>
  </div>
);

export default Loading;
