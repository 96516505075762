import "./style.less";
import React, { useEffect, useState } from "react";
import { INewsArticleProps } from "./interface";

import {
  Badge,
  Image,
  Menu,
  Button as ButtonAntD,
  Spin,
  Typography,
  Dropdown,
} from "antd";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  articleInteractionRequest,
  getArticleRequest,
  getRelatedNewsRequest,
  setArticle,
} from "../News/newsSlice";
import {
  getArticleLoadingSelector,
  getArticleSelector,
  relatedNewsLoadingSelector,
  relatedNewsSelector,
} from "../News/selectors";
import moment from "moment";
import ArticleCategories from "../News/ArticleCategories";
import { capitalizeFirstLetter } from "../../_helpers/stringEncoder";

// Icons
import { FiShare2, FiCalendar, FiEye, FiCopy } from "react-icons/fi";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineArrowLeft,
  AiOutlineLike,
  AiOutlineTwitter,
} from "react-icons/ai";

import { IoClipboardOutline } from "react-icons/io5";

import copy from "copy-to-clipboard";

// Ads
import wideAd from "../../assets/images/seedon-wide-ad.png";
import squareAd from "../../assets/images/seedon-square-ad.png";
import verticalAd from "../../assets/images/seedon-vertical-ad.png";
import {
  shareOnFacebook,
  shareOnLinkedIn,
  shareOnTwitter,
} from "../../_helpers/urlShare";
import { useWindowSize } from "../../hooks/windowSize";
import { useCookies } from "react-cookie";
import { ROUTES } from "../../_helpers/config";
import { ButtonTypes } from "../Button/types";
import { Button } from "components";

const { Paragraph } = Typography;

const NewsArticle: React.FC<INewsArticleProps> = (props: INewsArticleProps) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token] = useCookies(["sOnFinanceToken"]);

  const article = useSelector(getArticleSelector);
  const articleLoading = useSelector(getArticleLoadingSelector);

  const relatedNews = useSelector(relatedNewsSelector);
  const relatedNewsLoading = useSelector(relatedNewsLoadingSelector);

  const [articleSlag, setArticleSlag] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [shareMenu, setShareMenu] = useState([]);
  const windowSize = useWindowSize();

  const getArticle = async () => {
    const token = await executeRecaptcha("article");
    dispatch(
      getArticleRequest({
        slag: params.id,
        captchaToken: token,
      })
    );
  };
  const likeActionTriggered = () => {
    if (token?.sOnFinanceToken) {
      interactWithArticle(
        article,
        article.interaction.hasLiked ? "UNDO_LIKE" : "LIKE"
      );
    } else {
      localStorage.setItem("intended", window.location.pathname);
      window.location.href = process.env.REACT_APP_AUTH_URL;
    }
  };
  const getRelatedNews = async () => {
    const token = await executeRecaptcha("relatedNews");
    dispatch(
      getRelatedNewsRequest({
        categories: article.data.mainCategory.value,
        captchaToken: token,
        limit: 3,
      })
    );
  };

  const interactWithArticle = (article, interactionType) => {
    if (token?.sOnFinanceToken) {
      const getUpdatedArticle = (articleItem) => {
        const newInteraction = {
          ...articleItem.interaction,
        };

        const newData = {
          ...articleItem.data,
        };

        newInteraction.hasLiked =
          interactionType.indexOf("LIKE") !== -1
            ? interactionType === "LIKE"
            : articleItem.interaction.hasLiked;
        newInteraction.hasSubscribed =
          interactionType.indexOf("SUBSCRIBE") !== -1
            ? interactionType === "SUBSCRIBE"
            : articleItem.interaction.hasSubscribed;

        newInteraction.hasShared =
          interactionType === "SHARE"
            ? true
            : articleItem.interaction.hasShared;

        newData.statistics = {
          ...newData.statistics,
          likes:
            newData.statistics.likes +
            (interactionType.indexOf("LIKE") !== -1
              ? interactionType === "LIKE"
                ? 1
                : -1
              : 0),
          subscribes:
            newData.statistics.subscribes +
            (interactionType.indexOf("SUBSCRIBE") !== -1
              ? interactionType === "SUBSCRIBE"
                ? 1
                : -1
              : 0),
          shares:
            newData.statistics.shares +
            (interactionType === "SHARE" && !articleItem.interaction.hasShared
              ? 1
              : 0),
        };
        return {
          data: newData,
          interaction: newInteraction,
        };
      };

      dispatch(
        setArticle({
          article: getUpdatedArticle(article),
        })
      );

      const sendForm = async () => {
        const token = await executeRecaptcha("articleInteraction");
        dispatch(
          articleInteractionRequest({
            articleId: article.data._id,
            type: interactionType,
            captchaToken: token,
          })
        );
      };

      sendForm().then(() => {});
    }
  };

  const facebookShare = (article) => {
    interactWithArticle(article, "SHARE");
    setTimeout(() => {
      shareOnFacebook(`${window.location.origin}/article/${article.data.slag}`);
    }, 500);
  };

  const twitterShare = (article) => {
    interactWithArticle(article, "SHARE");
    setTimeout(() => {
      shareOnTwitter(
        `${window.location.origin}/article/${article.data.slag}`,
        "Check out this crypto news article on"
      );
    }, 500);
  };

  const linkedInShare = (article) => {
    interactWithArticle(article, "SHARE");
    setTimeout(() => {
      shareOnLinkedIn(`${window.location.origin}/article/${article.data.slag}`);
    }, 500);
  };

  const copyToClipboard = (article) => {
    interactWithArticle(article, "SHARE");
    copy(`${window.location.origin}/article/${article.data.slag}`);
    copy(`${window.location.origin}/article/${article.data.slag}`);
  };

  useEffect(() => {
    if (article && isInitialLoad) {
      setIsInitialLoad(false);
      getRelatedNews().then(() => {});
    }
  }, [article]);

  useEffect(() => {
    if (executeRecaptcha) {
      getArticle().then(() => {});
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    if (params && params.id !== articleSlag) {
      if (articleSlag) {
        getArticle().then(() => {});
      }
      setArticleSlag(params.id);
    }
  }, [params]);
  useEffect(() => {
    const shareItemsArr = [
      {
        key: "1",
        label: (
          <a onClick={() => twitterShare(article)}>
            Share on Twitter <AiOutlineTwitter />
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a onClick={() => facebookShare(article)}>
            Share on Facebook <AiFillFacebook />
          </a>
        ),
      },
      {
        key: "3",
        label: (
          <a onClick={() => linkedInShare(article)}>
            Share on Linkedin <AiFillLinkedin />
          </a>
        ),
      },
      {
        key: "4",
        label: (
          <Paragraph onClick={() => copyToClipboard(article)}>
            Copy to clipboard <FiCopy />
          </Paragraph>
        ),
      },
    ];
    if (windowSize.width && windowSize.width < 960) {
      setShareMenu(shareItemsArr.filter((item) => item.key !== "3"));
    } else {
      setShareMenu(shareItemsArr);
    }
  }, [windowSize, article]);
  return (
    <div className="newsArticlePage">
      <div className="container">
        <div className="article">
          {articleLoading || !article ? (
            <div className="loading inline">
              <Spin />
            </div>
          ) : (
            <>
              <Button
                type={ButtonTypes.SECONDARY}
                label="Back to articles"
                extraClasses="articlesButton articlesButtonGoBack"
                onClick={() =>
                  window.history.state && window.history.state.idx > 0
                    ? navigate(-1)
                    : navigate(ROUTES.news)
                }
                icon={<AiOutlineArrowLeft />}
              />

              <div className="heading">
                <ArticleCategories article={article.data} />

                <h1>{article.data.title}</h1>

                <p className="author">
                  <span>
                    <FiCalendar />
                    {capitalizeFirstLetter(
                      moment(article.data.dateTime).fromNow()
                    )}
                  </span>

                  <span>
                    <FiEye />
                    {article.data.readingTime === 1
                      ? `${article.data.readingTime} min read`
                      : `${article.data.readingTime} mins read`}
                  </span>
                </p>
              </div>

              <div className="articleCover">
                <Image src={article.data.image} />
              </div>

              <div className="actions">
                <Badge count={article.data.statistics.likes} overflowCount={99}>
                  <Button
                    type={ButtonTypes.SECONDARY}
                    label={`${
                      article.interaction.hasLiked ? "Unlike" : "Like"
                    }`}
                    extraClasses={`${
                      article.interaction.hasLiked
                        ? "active articlesButton likeBtn"
                        : "articlesButton likeBtn"
                    }`}
                    onClick={likeActionTriggered}
                    icon={<AiOutlineLike />}
                  />
                </Badge>

                <Dropdown
                  placement="bottomRight"
                  overlay={<Menu items={shareMenu} />}
                >
                  <ButtonAntD className="btn-secondary shareBtn">
                    <FiShare2 /> Share
                  </ButtonAntD>
                </Dropdown>
              </div>

              <div className="content">
                {article.data.body.split("\n\n").map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
                <a href={article.data.url} target="_blank">
                  View source
                </a>
              </div>
            </>
          )}
        </div>

        <div className="sidebar">
          <div className="advertisement">
            <a href="https://seedon.io/contact/" target={"_blank"}>
              <img src={verticalAd} className="desktop" alt="" />
              <img src={wideAd} className="tablet" alt="" />
              <img src={squareAd} className="mobile" alt="" />
            </a>
          </div>

          <div className="related">
            <h3>Related articles</h3>

            <div className="listRelated">
              {!relatedNews || relatedNewsLoading ? (
                <div className="loading">
                  <Spin />
                </div>
              ) : (
                <>
                  {relatedNews.map((item, index) => (
                    <div key={index} className="item">
                      <a
                        href={`/article/${item.slag}`}
                        target={"_blank"}
                        className="thumbnail"
                      >
                        <img src={item.image} alt={item.title} />

                        <span>
                          {item.categories.length
                            ? item.categories[0].value
                            : ""}
                        </span>
                      </a>

                      <div
                        className="details"
                        onClick={() => navigate(`/article/${item.slag}`)}
                      >
                        <h4>{item.title}</h4>

                        <div className="date">
                          {moment(item.dateTime).format("Do MMMM YYYY, H:mma")}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsArticle;
