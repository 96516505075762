import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";
import { create } from "domain";

const getCurrentUser = (state: AppState) => state.user.currentUser;
const getCurrentUserLoading = (state: AppState) => state.user.loading;
const getValidateUserPasswordSuccess = (state: AppState) =>
  state.user.validateUserPasswordSuccess;
const getValidateUserPasswordLoading = (state: AppState) =>
  state.user.validateUserPasswordLoading;
const getValidateUserPasswordError = (state: AppState) =>
  state.user.validateUserPasswordError;
const getChangeCurrencySuccess = (state: AppState) =>
  state.user.changeCurrencySuccess;
const getQrLoading = (state: AppState) => state.user.qrLoading;
const getQrResponse = (state: AppState) => state.user.qrResponse;

const qrToggleLoading = (state: AppState) => state.user.qrToggleLoading;
const qrToggleSuccess = (state: AppState) => state.user.qrToggleSuccess;

export const qrToggleLoadingSelector = createSelector(
  qrToggleLoading,
  (res) => res
);
export const qrToggleSuccessSelector = createSelector(
  qrToggleSuccess,
  (res) => res
);
export const getQrLoadingSelector = createSelector(getQrLoading, (res) => res);
export const getQrResponseSelector = createSelector(
  getQrResponse,
  (res) => res
);
export const getCurrentUserSelector = createSelector(
  getCurrentUser,
  (res) => res
);
export const getCurrentUserLoadingSelector = createSelector(
  getCurrentUserLoading,
  (res) => res
);
export const getValidateUserPasswordSuccessSelector = createSelector(
  getValidateUserPasswordSuccess,
  (res) => res
);
export const getValidateUserPasswordLoadingSelector = createSelector(
  getValidateUserPasswordLoading,
  (res) => res
);
export const getValidateUserPasswordErrorSelector = createSelector(
  getValidateUserPasswordError,
  (res) => res
);
export const getChangeCurrencySuccessSelector = createSelector(
  getChangeCurrencySuccess,
  (res) => res
);
