import React, { useEffect, useState } from "react";
import { INewsProps } from "./interface";
import "./protectedNews.less";

import { Button } from "../index";
import { scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "../../hooks/windowSize";
import { Carousel, Image, Pagination, Spin, Select, Tooltip } from "antd";

// Ads
import verticalAd from "../../assets/images/seedon-vertical-ad.png";
import squareAd from "../../assets/images/seedon-square-ad.png";
import wideAd from "../../assets/images/seedon-wide-ad.png";

// Configs and dummy data
import { protectedSliderSettings } from "./data";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  getLoadingSelector,
  getNewsCategoriesLoadingSelector,
  getNewsCategoriesSelector,
  getNewsSelector,
  trendingNewsLoadingSelector,
  trendingNewsSelector,
} from "./selectors";
import moment from "moment";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getEventsSelector } from "../Events/selectors";
import { getEventsRequest } from "../Events/eventsSlice";
import {
  getNewsCategoriesRequest,
  getNewsRequest,
  getTrendingNewsRequest,
  resetState,
} from "./newsSlice";
import { capitalizeFirstLetter } from "../../_helpers/stringEncoder";

// Components
import Event from "../Events/Event";
import ArticleCategories from "./ArticleCategories";
import { ROUTES } from "../../_helpers/config";
import { FiCalendar, FiEye } from "react-icons/fi";
import Loading from "../Loading";
import { ButtonTypes } from "../Button/types";

const { Option } = Select;

const ProtectedNews: React.FC<INewsProps> = (props: INewsProps) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 720;

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [searchParams, setSearchParams] = useSearchParams();

  const categories = useSelector(getNewsCategoriesSelector);
  const categoriesLoading = useSelector(getNewsCategoriesLoadingSelector);

  const trendingNews = useSelector(trendingNewsSelector);
  const trendingNewsLoading = useSelector(trendingNewsLoadingSelector);

  const news = useSelector(getNewsSelector);
  const newsLoading = useSelector(getLoadingSelector);
  const events = useSelector(getEventsSelector);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategoriesFilterSelect, setShowCategoriesFilterSelect] =
    useState(false);
  const [selectedDropdownCategory, setSelectedDropdownCategory] =
    useState(null);

  const [pager, setPager] = useState({
    page: 1,
    size: 10,
  });

  const getCategories = () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("getNewsCategories");

      dispatch(
        getNewsCategoriesRequest({
          captchaToken: token,
          page: 0,
          size: 1500,
        })
      );
    };

    sendForm().then(() => {});
  };

  const getSentiment = (item) => {
    return item < -0.15 ? "Negative" : item > 0.15 ? "Positive" : "Neutral";
  };

  const getNews = () => {
    const urlPage = searchParams.get("page");
    const categoryName = searchParams.get("category");

    if (urlPage) {
      setPager({
        ...pager,
        page: Number.parseInt(urlPage),
      });
    }

    if (categoryName) {
      categories.content.map((category) => {
        if (category.name === categoryName) {
          setSelectedCategory(category);
          setSelectedDropdownCategory(JSON.stringify(category));
        }
      });
    }

    const sendForm = async () => {
      const token = await executeRecaptcha("news");

      dispatch(
        getNewsRequest({
          ...pager,
          page: urlPage ? Number.parseInt(urlPage) - 1 : pager.page - 1,
          criteria: categoryName ?? undefined,
          captchaToken: token,
        })
      );
    };

    sendForm().then(() => {});
  };

  const loadMoreNews = (page) => {
    setPager({
      ...pager,
      page: page,
    });

    setSearchParams(
      selectedCategory
        ? {
            page,
            category: selectedCategory.name,
          }
        : {
            page,
          }
    );

    const sendForm = async () => {
      const token = await executeRecaptcha("news");

      dispatch(
        getNewsRequest({
          ...pager,
          page: page - 1,
          captchaToken: token,
          criteria: selectedCategory ? selectedCategory.name : undefined,
        })
      );
    };

    sendForm().then(() => {});

    scroller.scrollTo("articles", {
      duration: 1500,
      offset: 0,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const categoryChanged = (newCategory) => {
    setSelectedDropdownCategory(
      newCategory ? JSON.stringify(newCategory) : null
    );
    const sendForm = async () => {
      const token = await executeRecaptcha("getNews");

      dispatch(
        getNewsRequest({
          page: 0,
          size: 10,
          criteria:
            newCategory?.name !== selectedCategory?.name && newCategory !== null
              ? newCategory.name
              : undefined,
          captchaToken: token,
        })
      );
    };

    sendForm().then(() => {});

    if (newCategory?.name !== selectedCategory?.name) {
      setSearchParams(
        // @ts-ignore
        newCategory !== null
          ? {
              page: pager.page,
              category: newCategory.name,
            }
          : {
              page: pager.page,
            }
      );
      setSelectedCategory(newCategory);
    } else {
      setSelectedCategory(null);
      // @ts-ignore
      setSearchParams({
        page: pager.page,
      });
    }

    setPager({
      page: 1,
      size: 10,
    });

    scroller.scrollTo("articles", {
      duration: 1000,
      offset: 0,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const getTrendingNews = () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("news");

      dispatch(
        getTrendingNewsRequest({
          limit: 12,
          criteria: selectedCategory ? selectedCategory.name : undefined,
          captchaToken: token,
        })
      );
    };

    sendForm().then(() => {});
  };

  const getEvents = () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("events");

      dispatch(
        getEventsRequest({
          page: 0,
          size: 10,
          captchaToken: token,
        })
      );
    };

    sendForm().then(() => {});
  };

  useEffect(() => {
    if (categories && categories.content.length) {
      getNews();
    }
  }, [categories]);

  useEffect(() => {
    if (executeRecaptcha) {
      getCategories();
      getTrendingNews();
      getEvents();
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    scroller.scrollTo("protectedNews", {
      duration: 1000,
      offset: 0,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    return () => {
      dispatch(resetState());
    };
  }, []);

  return (
    <div className="protectedNews" id="protectedNews">
      <div className="container" data-aos="fade-up">
        <div className="pageTitle">
          <h1>Trending today</h1>
          <p>
            Browse through the most read and popular news articles available in
            SeedOn Finance. In this section, you can find the top picks that we
            have selected for you.
          </p>
        </div>
      </div>

      {trendingNewsLoading ? (
        <div className="loading inline">
          <Spin />
        </div>
      ) : (
        <>
          <div className="trendingNews">
            <div className="container">
              {trendingNews.map((item, index) =>
                index < 2 ? (
                  <div key={index} className="item">
                    <div className="thumbnail">
                      <Image preview={false} src={item.image} />
                      <ArticleCategories
                        article={item}
                        onCategorySelected={(cat) =>
                          categoryChanged({ ...cat, name: cat.value })
                        }
                      />

                      <div className="details">
                        <h3 onClick={() => navigate(`/article/${item.slag}`)}>
                          {item.title}
                        </h3>

                        <p className="date">
                          {capitalizeFirstLetter(
                            moment(item.dateTime).fromNow()
                          )}
                        </p>

                        <p
                          className="excerpt"
                          onClick={() => navigate(`/article/${item.slag}`)}
                        >
                          {item.body}
                        </p>

                        <div className="actions">
                          <Button
                            label="Read more"
                            type={ButtonTypes.SECONDARY}
                            extraClasses="readMore"
                            onClick={() => navigate(`/article/${item.slag}`)}
                          />

                          <div className="moodLevel">
                            <Tooltip
                              title={`${getSentiment(item.sentiment)} feeling`}
                              placement="right"
                            >
                              {getSentiment(item.sentiment) === "Positive" ? (
                                <div className="bars good" />
                              ) : getSentiment(item.sentiment) ===
                                "Negative" ? (
                                <div className="bars bad" />
                              ) : (
                                <div className="bars" />
                              )}
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
          </div>

          <div className="moreTrendingNews">
            <div className="container">
              <Carousel {...protectedSliderSettings}>
                {trendingNews.map((article, key) =>
                  key >= 2 ? (
                    <div className="item" key={key}>
                      <a
                        href={article.href}
                        className="thumbnail"
                        title={article.title}
                        onClick={() => navigate(`/article/${article.slag}`)}
                      >
                        <img src={article.image} alt={article.title} />
                      </a>

                      <h3>
                        <a onClick={() => navigate(`/article/${article.slag}`)}>
                          {article.title}
                        </a>
                      </h3>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </Carousel>
            </div>
          </div>
        </>
      )}

      {!categories ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        <div className="categoriesContainer">
          <div className="container">
            <div className="listCategories">
              <a
                onClick={() => categoryChanged(null)}
                className={selectedCategory === null ? "active" : ""}
              >
                <span>All</span>
              </a>

              {categories &&
                categories.content
                  .slice(0, isMobile ? 5 : 25)
                  .map((category, index) => (
                    <a
                      key={index}
                      onClick={() => categoryChanged(category)}
                      className={
                        selectedCategory &&
                        selectedCategory.name === category.name
                          ? "active"
                          : ""
                      }
                    >
                      <span>{category.name}</span>
                    </a>
                  ))}
            </div>

            <p>
              Didn't found what you're looking for?{" "}
              <button onClick={() => setShowCategoriesFilterSelect(true)}>
                Click here
              </button>
            </p>

            {showCategoriesFilterSelect ? (
              <div className="formItem">
                <p>Search categories</p>

                <Select
                  showSearch
                  placeholder="Search for a category"
                  onChange={(category) => categoryChanged(JSON.parse(category))}
                  value={selectedDropdownCategory}
                >
                  {categories.content.map((category, index) => (
                    <Option key={index} value={JSON.stringify(category)}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      <div className="recommendations" id="articles">
        <div className="sectionTitle">
          <h1>Crypto news articles</h1>
          <p>
            Stay up-to-date with our latest crypto articles from trusted global
            news outlets. Read, share with your friends or save for later your
            favorite articles, so you won't miss out on important updates.
          </p>
        </div>

        <div className="container">
          <div className="listRecommendations">
            <Loading loading={newsLoading}>
              {news &&
                news.content.map((article, index) => (
                  <div className="item" key={index}>
                    <a
                      className="thumbnail"
                      onClick={() => navigate(`/article/${article.data.slag}`)}
                    >
                      <img src={article.data.image} alt={article.data.title} />
                    </a>

                    <div className="details">
                      <ArticleCategories
                        article={article.data}
                        onCategorySelected={(cat) =>
                          categoryChanged({ ...cat, name: cat.value })
                        }
                      />

                      <h3>
                        <a
                          onClick={() =>
                            navigate(`/article/${article.data.slag}`)
                          }
                        >
                          {article.data.title}
                        </a>
                      </h3>

                      <p className="date">
                        <span>
                          <FiCalendar />
                          {capitalizeFirstLetter(
                            moment(article.data.dateTime).fromNow()
                          )}
                        </span>

                        <span>
                          <FiEye />
                          {article.data.readingTime === 1
                            ? `${article.data.readingTime} min read`
                            : `${article.data.readingTime} mins read`}
                        </span>
                      </p>

                      <p className="excerpt">{article.data.body}</p>

                      <Button
                        label="Read more"
                        extraClasses="readMore"
                        type={ButtonTypes.SECONDARY}
                        onClick={() =>
                          navigate(`/article/${article.data.slag}`)
                        }
                      />

                      <div className="moodLevel">
                        <Tooltip
                          placement="right"
                          title={`${getSentiment(
                            article.data.sentiment
                          )} feeling`}
                        >
                          {getSentiment(article.data.sentiment) ===
                          "Positive" ? (
                            <div className="bars good" />
                          ) : getSentiment(article.data.sentiment) ===
                            "Negative" ? (
                            <div className="bars bad" />
                          ) : (
                            <div className="bars" />
                          )}
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))}

              {news ? (
                <Pagination
                  simple={isMobile}
                  current={pager.page}
                  onChange={loadMoreNews}
                  showSizeChanger={false}
                  total={news.totalElements}
                  pageSize={pager.size}
                  hideOnSinglePage
                />
              ) : null}
            </Loading>
          </div>

          <div className="sidebar">
            <div className="advertisement">
              <a href="https://seedon.io/contact/" target={"_blank"}>
                <img src={verticalAd} className="desktop" alt="" />
                <img src={wideAd} className="tablet" alt="" />
                <img src={squareAd} className="mobile" alt="" />
              </a>
            </div>

            <div className="related">
              <h3>Events</h3>

              <div className="listRelated">
                {events.content &&
                  events.content.map((event, index) => (
                    <Event key={index} event={event} isOnNewsPage />
                  ))}
              </div>

              <a>
                <Button
                  label="View all events"
                  extraClasses="readMore"
                  type={ButtonTypes.SECONDARY}
                  onClick={() => navigate(ROUTES.events)}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectedNews;
