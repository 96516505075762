export const localStorageSet = (key: string, value: string) => {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const localStorageGet = (key: string) => {
  const value = localStorage.getItem(key);

  if (value) return JSON.parse(value);

  return undefined;
};

export const localStorageRemove = (key: string) => localStorage.removeItem(key);

export const localStorageClear = () => localStorage.clear();
