import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getStakingStatistics = (state: AppState) =>
  state.stakingStatistics.stakingStatistics;
const getStakingStatisticsLoading = (state: AppState) =>
  state.stakingStatistics.stakingStatisticsLoading;

export const getStakingStatisticsSelector = createSelector(
  getStakingStatistics,
  (res) => res
);
export const getStakingStatisticsLoadingSelector = createSelector(
  getStakingStatisticsLoading,
  (res) => res
);
