import React, { useEffect, useState } from "react";
import { Menu, Dropdown, notification } from "antd";

import "./style.less";
import {
  ICoinPanelBodyProps,
  IStakeItemProps,
  IVestingItemProps,
} from "./interface";
import {
  formatNumber,
  formatStringNumber,
} from "../../../../_helpers/numberFormatter";
import { Button } from "../../../index";
import { ROUTES } from "../../../../_helpers/config";
import { FiClock, FiHelpCircle, FiMoreHorizontal } from "react-icons/fi";
import { RiCloseCircleLine, RiCloseFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelStakingStateSelector,
  getTransactionsPagerSelector,
  getWalletSelector,
  putCancelStakingLoadingSelector,
} from "../../selectors";
import { Spin, Tooltip, Modal } from "antd";
import {
  getTransactionsLogRequest,
  putCancelStakingRequest,
  resetCancelStakingState,
  resetTransactionLogs,
  updateTransactionsPager,
} from "../../walletSlice";
import { pushGtmEvent } from "../../../../_helpers/gtm";
import { GtmEvents } from "../../../../_helpers/types";
import { IoClose } from "react-icons/io5";
import { StakeType } from "../../../Staking/StakeModal/types";
import lottie, { AnimationItem } from "lottie-web";
import CancelStakingLoading from "../../../../assets/animations/cancelStakingLoading.json";
import CancelStakingError from "../../../../assets/animations/cancelStakingError.json";
import CancelStakingSuccess from "../../../../assets/animations/cancelStakingSuccess.json";
import { CancelStakingStateEnum } from "../../types";
import seonCoin from "../../../../assets/images/seon_coin.png";
import PriceChart from "../CoinPanelHead/PriceChart";
import DepositModal from "../../../WalletControls/DepositModal";
import WithdrawModal from "../../../WalletControls/WithdrawModal";
import { scrollToTop } from "../../../../_helpers/scroll";
import OtpModalMultiple from "../../../OtpModalMultiple";
import { getMaintenanceSelector } from "../../../Maintenance/selectors";
import { getCurrentUserSelector } from "../../../User/selectors";
import { ButtonTypes } from "../../../Button/types";

import { FiDownload, FiUpload } from "react-icons/fi";
import { TiInfoLargeOutline } from "react-icons/ti";

const CloseStakingModal = (props) => {
  const dispatch = useDispatch();

  const putCancelStakingLoading = useSelector(putCancelStakingLoadingSelector);
  const cancelStakingState = useSelector(cancelStakingStateSelector);

  const [loadingAnimation, setLoadingAnimation] = useState<AnimationItem>();
  const [successAnimation, setSuccessAnimation] = useState<AnimationItem>();
  const [errorAnimation, setErrorAnimation] = useState<AnimationItem>();

  const [loadingAnimationVisible, setLoadingAnimationVisible] = useState(true);
  const [successAnimationVisible, setSuccessAnimationVisible] = useState(true);
  const [errorAnimationVisible, setErrorAnimationVisible] = useState(true);
  const [loadingAnimationFinished, setLoadingAnimationFinished] =
    useState(false);
  const [actionDispatched, setActionDispatched] = useState(false);

  const setAnimation = (
    containerId: string,
    animationObject: any,
    setAnimation: (value: AnimationItem) => void
  ) => {
    const newAnimation = lottie.loadAnimation({
      container: document.getElementById(containerId),
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationObject,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
      },
    });
    setAnimation(newAnimation);
  };

  const startAnimation = () => {
    dispatch(putCancelStakingRequest(props.correlationId));
    setActionDispatched(true);
  };

  useEffect(() => {
    if (actionDispatched) {
      setAnimation(
        "cancel-staking-loading",
        CancelStakingLoading,
        setLoadingAnimation
      );
    }
  }, [actionDispatched]);

  useEffect(() => {
    if (loadingAnimation) {
      loadingAnimation.play();
      loadingAnimation.addEventListener("complete", () => {
        setLoadingAnimationFinished(true);
        setLoadingAnimationVisible(false);
      });
    }
  }, [loadingAnimation]);

  useEffect(() => {
    if (
      loadingAnimationFinished &&
      cancelStakingState === CancelStakingStateEnum.SUCCESS
    ) {
      setAnimation(
        "cancel-staking-success",
        CancelStakingSuccess,
        setSuccessAnimation
      );
      setLoadingAnimationVisible(false);
      setSuccessAnimationVisible(true);
    }
    if (
      loadingAnimationFinished &&
      cancelStakingState === CancelStakingStateEnum.ERROR
    ) {
      setAnimation(
        "cancel-staking-error",
        CancelStakingError,
        setErrorAnimation
      );
      setLoadingAnimationVisible(false);
      setErrorAnimationVisible(true);
    }
  }, [loadingAnimationFinished, cancelStakingState]);

  useEffect(() => {
    if (successAnimation) {
      successAnimation.play();
    }
  }, [successAnimation]);

  useEffect(() => {
    if (errorAnimation) {
      errorAnimation.play();
    }
  }, [errorAnimation]);

  useEffect(() => {
    return () => {
      dispatch(resetCancelStakingState());
    };
  }, []);
  return (
    <Modal
      closeIcon={<IoClose />}
      open={props.isVisible}
      onCancel={() => props.onCancel()}
      className="authModals walletModals withdrawModal"
      maskClosable={false}
      destroyOnClose
      forceRender
      footer={null}
    >
      <>
        <div className="heading cancelStakingModal">
          <>
            {actionDispatched ? (
              <div className="swap-animation-container">
                <div
                  id="cancel-staking-loading"
                  className={`${
                    loadingAnimationVisible
                      ? "animation-container cancel-staking-animation-visible"
                      : "animation-container"
                  } ${
                    loadingAnimationFinished ? "loading-animation-finished" : ""
                  }`}
                />
                <div
                  id="cancel-staking-success"
                  className={
                    successAnimationVisible
                      ? "animation-container cancel-staking-animation-visible"
                      : "animation-container"
                  }
                />
                <div
                  id="cancel-staking-error"
                  className={
                    errorAnimationVisible
                      ? "animation-container cancel-staking-animation-visible"
                      : "animation-container"
                  }
                />
              </div>
            ) : (
              <img src={seonCoin} alt="deposit-token" />
            )}
            <h2>
              {loadingAnimation
                ? "Staking Cancellation Pending"
                : "Confirm staking cancellation"}
            </h2>
            <br />
          </>
          <p>
            {loadingAnimation
              ? "Your staking cancellation request has been sent and is now processing. Please wait a few seconds for confirmation, or close this pop-up and you will get an in-app notification once the processing has fully completed."
              : "Are you sure you want to cancel your staking deposit? Once submitted, you will have your funds unlocked and made available to your wallet. However, if you'll cancel a locked stakind deposit, then you will not receive any rewards."}
          </p>
        </div>

        {cancelStakingState === null && (
          <Button
            label="Cancel staking"
            extraClasses="submitBtn"
            onClick={() => startAnimation()}
            disabled={actionDispatched}
          />
        )}
        {(cancelStakingState === CancelStakingStateEnum.SUCCESS ||
          cancelStakingState === CancelStakingStateEnum.ERROR) && (
          <Button
            label="Dismiss"
            type={ButtonTypes.PRIMARY}
            extraClasses="submitBtn"
            onClick={() => props.onCancel()}
          />
        )}
      </>
    </Modal>
  );
};

const StakeItem = (props: IStakeItemProps) => {
  const [daysUntilRelease, setDaysUntilRelease] = useState(null);
  const [daysUntilReleaseLabel, setDaysUntilReleaseLabel] = useState("days");
  const [openCloseStakingModal, setOpenCloseStakingModal] = useState(false);

  useEffect(() => {
    const diff = moment(props.availableFunds.metadata.lockEndDate)
      .startOf("day")
      .diff(moment().startOf("day"), "days");
    setDaysUntilRelease(diff);
    if (diff === 1) {
      setDaysUntilReleaseLabel("day");
    }
    if (diff <= 0) {
      setDaysUntilReleaseLabel("Today");
    }
  }, []);

  return (
    <div className="stakeItem">
      {!props.availableFunds.metadata.cancelled &&
        props.availableFunds.status !== "UNLOCKED" &&
        props.availableFunds.metadata.cancellable &&
        moment(props.availableFunds.timestamp) >
          moment("2022-10-14T11:29:20.709202Z") && (
          <div
            className="closeIcon"
            onClick={() => setOpenCloseStakingModal(true)}
          >
            <RiCloseFill />
          </div>
        )}
      <div className="infos">
        {props.availableFunds.status === "PENDING" ? (
          <p>
            <span>Status</span>
            <b style={{ color: "yellow" }}>Pending</b>
          </p>
        ) : (
          ""
        )}
        <p>
          <span>Staked:</span>
          <b>
            {formatStringNumber(props.availableFunds.value)}{" "}
            {props.coin.tokenSymbol}
          </b>
        </p>
        <p className="reward">
          <span>Reward:</span>
          <b>
            {formatNumber(props.availableFunds.metadata.stakingReward)}{" "}
            {props.coin.tokenSymbol}
          </b>
        </p>
        {props.availableFunds.metadata.stakingType === StakeType.FLEXIBLE && (
          <p className="reward">
            <span>Daily:</span>
            <b>
              {formatNumber(
                props.availableFunds.metadata?.releaseSchedule[0]?.reward
              )}{" "}
              {props.coin.tokenSymbol}
            </b>
          </p>
        )}
      </div>

      <div className="timeline">
        <div
          className={
            props.availableFunds.metadata.cancelled
              ? "counter cancelled"
              : "counter"
          }
        >
          {props.availableFunds.status === "UNLOCKED" ? (
            props.availableFunds.metadata.cancelled ? (
              <>
                Cancelled <RiCloseCircleLine size={15} color={"#ED5440"} />
              </>
            ) : (
              "Unlocked"
            )
          ) : (
            <>
              {daysUntilRelease > 0 ? daysUntilRelease : ""}{" "}
              {daysUntilReleaseLabel}
              <FiClock />
            </>
          )}
        </div>
        <p>
          {moment(props.availableFunds.metadata.lockEndDate).format(
            "Do MMMM yyyy"
          )}
        </p>
      </div>
      {openCloseStakingModal && (
        <CloseStakingModal
          isVisible={openCloseStakingModal}
          onCancel={setOpenCloseStakingModal}
          onOk={setOpenCloseStakingModal}
          correlationId={props.availableFunds.correlationId}
        />
      )}
    </div>
  );
};

const VestingItem = (props: IVestingItemProps) => {
  const [daysUntilRelease, setDaysUntilRelease] = useState(null);
  const [daysUntilReleaseLabel, setDaysUntilReleaseLabel] = useState("days");

  useEffect(() => {
    const diff = moment(props.availableFunds.metadata.releaseDate)
      .startOf("day")
      .diff(moment().startOf("day"), "days");

    setDaysUntilRelease(diff);

    if (diff === 1) {
      setDaysUntilReleaseLabel("day");
    }
    if (diff <= 0) {
      setDaysUntilReleaseLabel("Today");
    }
  }, []);

  return (
    <div className="stakeItem vestingItem">
      <div className="infos">
        <p>
          <span>Vesting:</span>
          <b>
            {formatStringNumber(props.availableFunds.value)}{" "}
            {props.coin.tokenSymbol}
          </b>
        </p>
      </div>

      <div className="timeline">
        <div className="counter">
          {props.availableFunds.status === "UNLOCKED" ? (
            "Unlocked"
          ) : (
            <>
              {daysUntilRelease > 0 ? daysUntilRelease : ""}{" "}
              {daysUntilReleaseLabel} <FiClock />
            </>
          )}
        </div>

        <p>
          {moment(props.availableFunds.metadata.releaseDate).format(
            "Do MMMM yyyy"
          )}
        </p>
      </div>
    </div>
  );
};

const CoinPanelBody = (props: ICoinPanelBodyProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const wallet = useSelector(getWalletSelector);
  const pager = useSelector(getTransactionsPagerSelector);
  const maintenance = useSelector(getMaintenanceSelector);
  const user = useSelector(getCurrentUserSelector);

  const [depositModalVisible, setDepositModalVisible] = useState(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [otpModalVisible, setOtpModalVisible] = useState(false);
  const [additionalPayload, setAdditionalPayload] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(
        updateTransactionsPager({
          size: 25,
          page: 0,
        })
      );
      dispatch(
        resetTransactionLogs({
          contractAddress: props.coin.contractAddress,
        })
      );
    };
  }, []);

  const mainButtonClicked = () => {
    if (
      props.coin.contractAddress ===
        process.env.REACT_APP_SEON_CONTRACT_ADDRESS ||
      props.coin.contractAddress ===
        "0x55d398326f99059ff775485246999027b3197955"
    ) {
      pushGtmEvent(GtmEvents.STAKE_BUTTON_CLICKED);
      navigate(ROUTES.stake);
    } else {
      navigate(ROUTES.swap);
    }
  };

  const withdrawSubmitted = (formData) => {
    setAdditionalPayload({
      ...formData,
      contractAddress: formData.token.contractAddress,
    });
    setWithdrawModalVisible(false);
    setOtpModalVisible(true);

    scrollToTop();
  };

  const otpModalSuccess = () => {
    notification.success({
      message: "Withdraw request created successfully.",
    });
    setOtpModalVisible(false);
    navigate(ROUTES.wallet);
  };

  const depositButtonClicked = () => {
    pushGtmEvent(GtmEvents.DEPOSIT_BUTTON_CLICKED);
    setDepositModalVisible(true);
  };

  const withdrawButtonClicked = () => {
    pushGtmEvent(GtmEvents.WITHDRAW_BUTTON_CLICKED);
    setWithdrawModalVisible(true);
  };

  useEffect(() => {
    dispatch(
      getTransactionsLogRequest({
        contractAddress: props.coin.contractAddress,
        ...pager,
      })
    );
  }, [pager]);

  const loadMoreData = () => {
    dispatch(
      updateTransactionsPager({
        ...pager,
        page: pager.page + 1,
      })
    );
  };

  if (wallet[`${props.coin.contractAddress}Transactions`] === undefined) {
    return (
      <div className="loading inline">
        <Spin />
      </div>
    );
  }

  const menu = (tokenDetails) => (
    <Menu
      items={[
        {
          key: "deposit",
          label: (
            <div
              onClick={() => {
                depositButtonClicked();
                navigate(ROUTES.deposit);
              }}
            >
              <FiDownload size={14} /> Deposit
            </div>
          ),
        },
        {
          key: "withdraw",
          label: (
            <div
              onClick={() => {
                if (
                  user &&
                  user.state?.properties?.phoneNumberVerified &&
                  !(maintenance && maintenance.status === "ACTIVE")
                ) {
                  withdrawButtonClicked();
                  navigate(ROUTES.withdraw);
                }
              }}
              key="2"
            >
              <FiUpload size={14} /> Withdraw
            </div>
          ),
          disabled:
            !user ||
            !user.state?.properties?.phoneNumberVerified ||
            (maintenance && maintenance.status === "ACTIVE"),
        },
        // {
        //   key: "details",
        //   label: (
        //     <div
        //       onClick={() => {
        //         navigate(
        //           `/token-details/${props?.coin?.chainId}/${props?.coin?.tokenSymbol}`
        //         );
        //       }}
        //     >
        //       <TiInfoLargeOutline size={14} />
        //       Details
        //     </div>
        //   ),
        // },
      ]}
    />
  );

  return (
    <>
      <div className="availableTokens">
        <div className="row exchangeInformationRow">
          <div className="exchangeInformationContainer">
            <div className="exchangeTitle">Exchange information:</div>
            <div className="exchangeInformation">
              <div
                className={
                  wallet[`${props.coin.contractAddress}Funds`].tokenData
                    .priceChangePercentage24h > 0
                    ? "exchangeRate green"
                    : "exchangeRate"
                }
              >
                {
                  wallet[`${props.coin.contractAddress}Funds`].tokenData
                    .priceChangePercentage24h
                }
                %
              </div>
              <div className="chart">
                <PriceChart
                  data={wallet[`${props.coin.contractAddress}Funds`].tokenData}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <p>
            Tokens available:
            <Tooltip
              title={`${formatStringNumber(
                props.availableFunds.availableFunds
              )} ${props.coin.tokenSymbol}`}
            >
              <b>
                {formatStringNumber(props.availableFunds.availableFunds)}{" "}
                {props.coin.tokenSymbol}
              </b>
            </Tooltip>
          </p>
          {/*<Button*/}
          {/*  label="Stake"*/}
          {/*  extraClasses="stakeButton"*/}
          {/*  onClick={() => mainButtonClicked()}*/}
          {/*/>*/}
          <div className="splitButtonWalletContainer">
            <Dropdown.Button
              onClick={() => mainButtonClicked()}
              className="splitButtonWallet"
              overlay={() => menu("#")}
              icon={<FiMoreHorizontal size={18} color="#ffffff" />}
            >
              {props.coin.contractAddress ===
                process.env.REACT_APP_SEON_CONTRACT_ADDRESS ||
              props.coin.contractAddress ===
                "0x55d398326f99059ff775485246999027b3197955"
                ? "Stake"
                : "Swap"}
            </Dropdown.Button>
          </div>
        </div>

        <p className="lockedTokens">
          Tokens locked:
          <Tooltip
            title={`${formatStringNumber(props.availableFunds.lockedFunds)} ${
              props.coin.tokenSymbol
            }`}
          >
            <b>
              {formatStringNumber(props.availableFunds.lockedFunds)}{" "}
              {props.coin.tokenSymbol}
            </b>
          </Tooltip>
          <Tooltip title="Locked tokens represent the sum of staked funds, rewards and vested tokens to be received.">
            <FiHelpCircle className="infoIcon" />
          </Tooltip>
        </p>
      </div>

      {wallet[`${props.coin.contractAddress}Transactions`] &&
        wallet[`${props.coin.contractAddress}Transactions`].content.map(
          (item, key) =>
            item.status === "FAILED" ? (
              ""
            ) : item.type === "STAKED" ? (
              <StakeItem key={key} coin={props.coin} availableFunds={item} />
            ) : (
              <VestingItem key={key} coin={props.coin} availableFunds={item} />
            )
        )}

      {wallet[`${props.coin.contractAddress}TransactionsLoading`] !== false ||
      (wallet[`${props.coin.contractAddress}Transactions`] &&
        wallet[`${props.coin.contractAddress}Transactions`].last) ? (
        ""
      ) : (
        <Button
          label={"Load More"}
          type={ButtonTypes.SECONDARY}
          extraClasses="loadMoreButton"
          onClick={() => loadMoreData()}
          loading={wallet[`${props.coin.contractAddress}TransactionsLoading`]}
        />
      )}

      <DepositModal
        onCancel={() => setDepositModalVisible(false)}
        isVisible={depositModalVisible}
      />

      {withdrawModalVisible && (
        <WithdrawModal
          isVisible={withdrawModalVisible}
          onCancel={() => setWithdrawModalVisible(false)}
          onSubmit={withdrawSubmitted}
        />
      )}

      {otpModalVisible && (
        <OtpModalMultiple
          endpoint={"/withdraw"}
          isVisible={otpModalVisible}
          additionalPayload={additionalPayload}
          onSuccess={() => otpModalSuccess()}
          onCancel={() => setOtpModalVisible(false)}
        />
      )}
    </>
  );
};

export default CoinPanelBody;
