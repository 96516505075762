import React, { useEffect } from "react";
import "chart.js/auto";
import "./style.less";
import moment from "moment";

// Core
import { useDispatch, useSelector } from "react-redux";
import { getVestingCoinsRequest } from "../vestingSlice";
import {
  getVestingCoinsLoadingSelector,
  getVestingCoinsSelector,
} from "../selectors";

// Assets
import { Empty, List } from "antd";
import { FiClock } from "react-icons/fi";
import { formatNumber } from "../../../_helpers/numberFormatter";

const VestingList = () => {
  const dispatch = useDispatch();

  const vestingCoins = useSelector(getVestingCoinsSelector);
  const vestingCoinsLoading = useSelector(getVestingCoinsLoadingSelector);

  useEffect(() => {
    dispatch(getVestingCoinsRequest());
  }, []);

  return (
    <div className="vestingWrapper" data-aos="fade-up" data-aos-delay="800">
      <List
        itemLayout="horizontal"
        dataSource={vestingCoins}
        loading={vestingCoinsLoading}
        className="listVestingItems"
        locale={{
          emptyText: (
            <Empty description="Currently you don't have any vesting schedule available" />
          ),
        }}
        renderItem={(item) => (
          <List.Item
            className={`vestingItem ${item.status === "LOCKED" ? "next" : ""}`}
          >
            <>
              <div className="infos">
                <p>
                  <span>{item.status}:</span>
                  {/*  TODO ADD TOKEN SYMBOL HERE IN THE FUTURE*/}
                  <b>{formatNumber(item.amount)} SEON</b>
                </p>
              </div>

              <div className="timeline">
                <div className="counter">
                  <span>{moment(item.releaseDate).format("Do MMMM yyyy")}</span>{" "}
                  <FiClock />
                </div>
                <p>{item.date}</p>
              </div>
            </>
          </List.Item>
        )}
      />
    </div>
  );
};

export default VestingList;
