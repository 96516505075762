import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { axios } from "../../../services/axios.service";
import {
  getWithdrawEstimateFailure,
  getWithdrawEstimateRequest,
  getWithdrawEstimateSuccess,
  postWithdrawRequest,
  postWithdrawSuccess,
  postWithdrawFailure,
  validateWalletRequest,
  validateWalletSuccess,
  validateWalletFailure,
  generateOTPCodeRequest,
  resendOTPCodeRequest,
} from "./withdrawSlice";
import { IGetWithdrawEstimateResponse } from "./types";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/

function* getWithdrawEstimateSaga(
  action: ReturnType<typeof getWithdrawEstimateRequest>
) {
  try {
    const response: AxiosResponse<IGetWithdrawEstimateResponse> = yield call(
      axios.put,
      `${process.env.REACT_APP_API_URL}/withdraw/estimate`,
      action.payload
    );
    yield put(
      getWithdrawEstimateSuccess({
        estimate: response.data,
      })
    );
  } catch (e: any) {
    yield put(
      getWithdrawEstimateFailure({
        error: e.response.data,
      })
    );
  }
}

function* postWithdrawSaga(action: ReturnType<typeof postWithdrawRequest>) {
  try {
    yield call(
      axios.post,
      `${process.env.REACT_APP_API_URL}/withdraw`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(postWithdrawSuccess());
  } catch (e: any) {
    yield put(
      postWithdrawFailure({
        error: e.response.data.message,
      })
    );
  }
}

function* validateWalletSaga(action: ReturnType<typeof validateWalletRequest>) {
  try {
    yield call(
      axios.head,
      `${process.env.REACT_APP_API_URL}/withdraw/address/validate?to=${action.payload.walletAddress}`
    );
    yield put(validateWalletSuccess());
  } catch (e: any) {
    yield put(validateWalletFailure());
  }
}

function* generateOtpCodeSaga(action) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_API_URL}/withdraw/otp/generate`,
      {},
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
  } catch (e: any) {}
}

function* resendOtpCodeSaga(action: ReturnType<typeof resendOTPCodeRequest>) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_API_URL}/withdraw/otp/resend?type=${action.payload.type}`,
      {},
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
  } catch (e: any) {}
}

function* withdrawSaga() {
  yield all([
    takeLatest(getWithdrawEstimateRequest, getWithdrawEstimateSaga),
    takeLatest(postWithdrawRequest, postWithdrawSaga),
    takeLatest(validateWalletRequest, validateWalletSaga),
    takeLatest(generateOTPCodeRequest, generateOtpCodeSaga),
    takeLatest(resendOTPCodeRequest, resendOtpCodeSaga),
  ]);
}

export default withdrawSaga;
