export const data = {
  id: "6369fdsfdsfsdfdsfsdfddfs1ew5140f1ce2389dff5891",
  slag: "campaign-name-651868245",
  coverImageFile: {
    url: "https://picsum.photos/200",
  },
  valueProposition:
    "Patented, FDA-cleared, novel wound-care technology FDA-clear",
  tags: ["#Crowdsafe", "#Women Founders"],
  daysLeft: 12,
  category: "HEALTH & WELLNESS",
  type: "DONATION",
  minAllocation: "545455412300232352656565656565665",
  maxAllocation: "165000",
  minimumAmount: 330,
  maximumAmount: 500,
  backers: 3333,
  raised: 15000,
  softCap: 30000,
  campaignName: "North Carolina Beach Rental Portfolio",
  legalName: "XTGEN",
  trending: true,
  logo: { url: "https://picsum.photos/id/237/200" },
  platform: {
    url: "https://www.kasandbox.org/programming-images/avatars/primosaur-ultimate.png",
  },
};
