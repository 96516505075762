import React, { useCallback, useEffect, useState } from "react";
import SockJs from "sockjs-client";
import { useCookies } from "react-cookie";
import { Stomp } from "@stomp/stompjs";
import { useDispatch, useSelector } from "react-redux";
import { setRecoveryPhraseGenerationLoading } from "../SecretRecoveryModal/secretRecoverySlice";
import {
  getAvailableFundsRequest,
  getFundsTotalRequest,
  getWhitelistedCoinsRequest,
  updateCancelStakingState,
  updateHistoryPager,
  updateTransactionsPager,
} from "../Wallet/walletSlice";
import { notification } from "antd";
import moment from "moment";
import { formatNumber } from "../../_helpers/numberFormatter";
import { getUnreadNotificationsCountRequest } from "../Notifications/notificationSlice";
import { getCurrentUserSelector } from "../User/selectors";
import { CancelStakingStateEnum } from "../Wallet/types";

const WebSockets = (props) => {
  const [token] = useCookies(["sOnFinanceToken"]);
  const [stompClient, setStompClient] = useState(null);
  const [userId, setUserId] = useState(null);
  const user = useSelector(getCurrentUserSelector);
  const [connectionStarted, setConnectionStarted] = useState(false);

  const dispatch = useDispatch();

  const onSockMessage = (message) => {
    const decodedMessage = JSON.parse(message.body);
    switch (decodedMessage.type) {
      case "WALLET_GENERATION_NOTIFICATION":
        dispatch(setRecoveryPhraseGenerationLoading(false));
        break;
      case "STAKING_DEPOSIT_STATUS_NOTIFICATION":
        if (decodedMessage.payload.status === "CANCELLED") {
          dispatch(updateCancelStakingState(CancelStakingStateEnum.SUCCESS));
        }
        dispatch(getUnreadNotificationsCountRequest());
        dispatch(
          updateTransactionsPager({
            page: 0,
            size: 25,
          })
        );
        dispatch(
          updateHistoryPager({
            page: 0,
            size: 25,
          })
        );
        if (decodedMessage.payload.status === "REJECTED") {
          notification.error({
            message: "Staking deposit failed.",
          });
        } else {
          notification.success({
            message: "Staking deposit has been successful.",
          });
        }
        break;
      case "FUNDS_DEPOSIT_STATUS_NOTIFICATION":
        dispatch(getUnreadNotificationsCountRequest());
        if (decodedMessage.payload === "FAILED") {
          notification.error({
            message: "Your deposit request has failed.",
          });
        } else {
          notification.success({
            message: "Your deposit request has been processed and updated.",
          });
        }
        dispatch(getWhitelistedCoinsRequest());
        dispatch(getFundsTotalRequest());
        break;
      case "FUNDS_WITHDRAW_STATUS_NOTIFICATION":
        dispatch(getUnreadNotificationsCountRequest());
        if (decodedMessage.payload === "FAILED") {
          notification.error({
            message: "Your withdraw request has failed.",
          });
        } else {
          notification.success({
            message: "Your withdraw request has been processed and updated.",
          });
        }
        dispatch(getWhitelistedCoinsRequest());
        dispatch(getFundsTotalRequest());
        break;
      case "STAKING_REWARD_UNLOCKED_NOTIFICATION":
        dispatch(getUnreadNotificationsCountRequest());
        notification.success({
          message: `Your staking reward due on ${moment(
            decodedMessage.payload.releaseDate
          ).format("Do MMMM yyyy")} of ${formatNumber(
            decodedMessage.payload.value
          )} ${decodedMessage.payload.tokenSymbol} has been released.`,
        });
        dispatch(getWhitelistedCoinsRequest());
        dispatch(getFundsTotalRequest());
        break;
      case "TOKEN_PRICE_UPDATE":
        if (user && user.state.properties?.recoveryPhraseVerified) {
          dispatch(
            getAvailableFundsRequest({
              contractAddress: decodedMessage.payload.contractAddress,
              isFromWs: true,
            })
          );
        }
        break;
      case "TOKEN_SWAP_COMPLETED":
        dispatch(getUnreadNotificationsCountRequest());
        if (decodedMessage.payload === "FAILED") {
          notification.error({
            message: "Your swap request has failed.",
          });
        } else {
          notification.success({
            message: "Your swap request has been successful.",
          });
        }
        dispatch(getWhitelistedCoinsRequest());
        dispatch(getFundsTotalRequest());
        break;
      default:
        break;
    }
  };
  const initConnection = () => {
    const sock = new SockJs(`${process.env.REACT_APP_WS_URL}`);
    const stomp = Stomp.over(sock);
    stomp.debug = () => {};
    setStompClient(stomp);
  };
  useEffect(() => {
    if (user && !connectionStarted) {
      setConnectionStarted(true);
      setUserId(user.id);
      initConnection();
    }
  }, [user]);

  useEffect(() => {
    if (stompClient) {
      stompClient.connect(
        { "X-Authorization": `Bearer ${token.sOnFinanceToken}` },
        () => {
          stompClient.subscribe(
            `/events/${userId}/queue/messages`,
            onSockMessage
          );
        }
      );
      const checkStompConnectionInterval = setInterval(() => {
        if (!stompClient.connected) {
          initConnection();
        }
      }, 10000);
      return () => {
        clearInterval(checkStompConnectionInterval);
      };
    }
  }, [stompClient]);

  return <>{props.children}</>;
};

export default WebSockets;
