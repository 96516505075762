import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const selectReferral = (state: AppState) => state.referral.referral;

const selectReferLoading = (state: AppState) => state.referral.referLoading;
const selectReferSuccess = (state: AppState) => state.referral.referSuccess;

export const getReferral = createSelector(selectReferral, (res) => res);
export const getReferLoading = createSelector(selectReferLoading, (res) => res);
export const getReferSuccess = createSelector(selectReferSuccess, (res) => res);
