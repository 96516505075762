import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getMaintenanceLoading = (state: AppState) => state.maintenance.loading;
const getMaintenance = (state: AppState) => state.maintenance.maintenance;

export const getMaintenanceLoadingSelector = createSelector(
  getMaintenanceLoading,
  (res) => res
);
export const getMaintenanceSelector = createSelector(
  getMaintenance,
  (res) => res
);
