import React, { useEffect } from "react";
import "./style.less";

// Assets
import { FiArrowRight, FiInfo } from "react-icons/fi";

// Helpers
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/windowSize";

import { ROUTES } from "../../_helpers/config";
import { GtmEvents } from "../../_helpers/types";
import { pushGtmEvent } from "../../_helpers/gtm";

// Components
import { Button } from "antd";

const HomePageProtected = () => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 870;

  useEffect(() => {
    pushGtmEvent(GtmEvents.PREVIEW_HOMEPAGE_PROSPECT);
  }, []);

  const goToPage = (route) => {
    navigate(route);
  };

  const DesktopActions = () => {
    return (
      <div className="userActions">
        <div className="column">
          <div
            className="action --wallet"
            onClick={() => goToPage(ROUTES.wallet)}
          >
            <div className="container">
              <h3>Wallet</h3>
              <p>
                See your coins, deposit, withdraw or stake, all those actions at
                the tip of your finger!{" "}
              </p>
              <Button>
                Manage
                <FiArrowRight />
              </Button>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="action --staking"
            onClick={() => goToPage(ROUTES.stake)}
          >
            <div className="container">
              <h3>Staking</h3>
              <p>Earn rewards by “locking up” a portion of your crypto.</p>
              <Button>
                Start earning
                <FiArrowRight />
              </Button>
            </div>
          </div>
        </div>

        <div className="column">
          <div
            data-aos="fade-left"
            className="action --vesting"
            onClick={() => goToPage(ROUTES.vesting)}
          >
            <div className="container">
              <h3>Vesting</h3>
              <p>
                Check up on the next vesting dates for your SEON investments &
                claim your coins.
              </p>
              <Button>
                Check schedule
                <FiArrowRight />
              </Button>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="action --swap"
            onClick={() => goToPage(ROUTES.swap)}
          >
            <div className="container">
              <h3>Swap</h3>
              <p>
                Make use of atomic transactions to easily swap your tokens of
                preference.
              </p>
              <Button type="text">
                SWAP Tokens
                <FiArrowRight />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MobileActions = () => {
    return (
      <div className="userActions">
        <div className="column">
          <div
            className="action --wallet"
            onClick={() => goToPage(ROUTES.wallet)}
          >
            <div className="container">
              <h3>Wallet</h3>
              <p>
                See your coins, deposit, withdraw or stake, all those actions at
                the tip of your finger!{" "}
              </p>
              <Button>
                Manage
                <FiArrowRight />
              </Button>
            </div>
          </div>

          <div
            className="action --staking"
            onClick={() => goToPage(ROUTES.stake)}
          >
            <div className="container">
              <h3>Staking</h3>
              <p>Earn rewards by “locking up” a portion of your crypto.</p>
              <Button>
                Start earning
                <FiArrowRight />
              </Button>
            </div>
          </div>
        </div>

        <div className="column">
          <div
            className="action --vesting"
            onClick={() => goToPage(ROUTES.vesting)}
          >
            <div className="container">
              <h3>Vesting</h3>
              <p>
                Check up on the next vesting dates for your SEON investments &
                claim your coins.
              </p>
              <Button>
                Check schedule
                <FiArrowRight />
              </Button>
            </div>
          </div>

          <div className="action --swap" onClick={() => goToPage(ROUTES.swap)}>
            <div className="container">
              <h3>Swap</h3>
              <p>
                Make use of atomic transactions to easily swap your tokens of
                preference.
              </p>
              <Button type="text">
                SWAP Tokens
                <FiArrowRight />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="homepage">
      <div className="section introSection logged">
        <div className="container">
          <div className="content" data-aos="fade-right">
            <h1>Welcome</h1>
            <p>
              Staking, vesting, checking wallet balance, or editing your
              profile, what will your next step be?
            </p>

            <div className="howItWorks">
              <div className="icon">
                <FiInfo />
              </div>

              <div className="details">
                <h3>How it works</h3>
                <p>
                  Investments are risky, illiquid and may result in total loss
                  of capital.{" "}
                </p>
              </div>
            </div>
          </div>

          {isMobile ? <MobileActions /> : <DesktopActions />}
        </div>
      </div>
    </div>
  );
};

export default HomePageProtected;
