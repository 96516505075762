import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getEventsFailure,
  getEventsRequest,
  getEventsSuccess,
  getEventCategoriesRequest,
  getEventCategoriesSuccess,
  getEventCategoriesFailure,
  getEventRequest,
  getEventSuccess,
  getEventFailure,
  eventInteractionRequest,
  eventInteractionSuccess,
  eventInteractionFailure,
  getEventsStartDateRequest,
  getEventsStartDateSuccess,
  getEventsStartDateFailure,
} from "./eventsSlice";

import { AxiosResponse } from "axios";
import { IEventsResponse } from "./types";
import { axios } from "../../services/axios.service";

/*
  Worker Saga: Fired on getEventsRequest action
*/
function* getEventsSaga(action: ReturnType<typeof getEventsRequest>) {
  try {
    const response: AxiosResponse<IEventsResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_NEWS_API_URL}/public/events`,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getEventsSuccess(response.data));
  } catch (e: any) {
    yield put(
      getEventsFailure({
        error: e,
      })
    );
  }
}

function* getEventCategoriesSaga(
  action: ReturnType<typeof getEventCategoriesRequest>
) {
  try {
    const response: AxiosResponse<IEventsResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_NEWS_API_URL}/public/events/categories`,
      {
        params: action.payload,
        headers: {
          // @ts-ignore
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getEventCategoriesSuccess(response.data));
  } catch (e: any) {
    yield put(
      getEventCategoriesFailure({
        error: e,
      })
    );
  }
}

function* getEventSaga(action: ReturnType<typeof getEventRequest>) {
  try {
    const response: AxiosResponse<IEventsResponse> = yield call(
      axios.put,
      `${process.env.REACT_APP_NEWS_API_URL}/public/events/${action.payload.eventId}`,
      {},
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getEventSuccess(response.data));
  } catch (e: any) {
    yield put(getEventFailure());
  }
}

function* eventInteractionSaga(
  action: ReturnType<typeof eventInteractionRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_NEWS_API_URL}/events/${action.payload.eventId}/interaction`,
      action.payload,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(eventInteractionSuccess());
  } catch (e: any) {
    yield put(eventInteractionFailure());
  }
}

function* getEventsStartDateSaga(
  action: ReturnType<typeof getEventsStartDateRequest>
) {
  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_NEWS_API_URL}/public/events/start-date`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getEventsStartDateSuccess(response.data.date));
  } catch (e: any) {
    yield put(getEventsStartDateFailure());
  }
}

/*
  Starts worker saga on latest dispatched `getEventsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* loginSaga() {
  yield all([
    takeLatest(getEventsRequest, getEventsSaga),
    takeLatest(getEventCategoriesRequest, getEventCategoriesSaga),
    takeLatest(getEventRequest, getEventSaga),
    takeLatest(eventInteractionRequest, eventInteractionSaga),
    takeLatest(getEventsStartDateRequest, getEventsStartDateSaga),
  ]);
}

export default loginSaga;
