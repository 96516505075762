import { Cookies } from "react-cookie";
import axiosLibrary from "axios";
import { ROUTES } from "../_helpers/config";
import { setAuthModal } from "../components/Layout/layoutSlice";

/** Axios service
 * USE THIS INSTEAD OF NORMAL AXIOS
 * config each API Client call with headers and baseURL
 */
const http = axiosLibrary.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  async (config) => {
    // add token here to each request if token available
    const token = new Cookies().get("sOnFinanceToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);

export const axios = http;
export const setInterceptor = (dispatch, removeCookie, navigate) => {
  http.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      const logout = () => {
        removeCookie("sOnFinanceToken");
        document.cookie = `sOnFinanceToken=; Max-Age=0;secure'; path=/; domain=${process.env.REACT_APP_APP_DOMAIN};`;
        document.cookie = `sOnFinanceToken=; Max-Age=0;secure'; path=/; domain=finance.${process.env.REACT_APP_APP_DOMAIN};`;
        removeCookie("sOnFinanceToken", {
          path: "/",
          domain: `finance.${process.env.REACT_APP_APP_DOMAIN}`,
        });
        window.location.href = process.env.REACT_APP_AUTH_URL;
      };

      const logoutWithoutRedirect = () => {
        removeCookie("sOnFinanceToken");
        document.cookie = `sOnFinanceToken=; Max-Age=0;secure'; path=/; domain=${process.env.REACT_APP_APP_DOMAIN};`;
        document.cookie = `sOnFinanceToken=; Max-Age=0;secure'; path=/; domain=finance.${process.env.REACT_APP_APP_DOMAIN};`;
        removeCookie("sOnFinanceToken", {
          path: "/",
          domain: `finance.${process.env.REACT_APP_APP_DOMAIN}`,
        });
        window.location.href = process.env.REACT_APP_PUBLIC_URL;
      };

      if (!error.response) {
        navigate(ROUTES["500"]);
      }

      switch (error.response.status) {
        case 500:
          navigate(ROUTES["500"]);
          break;
        case 401:
          if (
            ![ROUTES.homePage, ROUTES["404"], ROUTES["500"]].includes(
              window.location.pathname
            )
          ) {
            localStorage.setItem("intended", window.location.pathname);
          }
          logoutWithoutRedirect();
          break;
        case 406:
          logout();
          break;
        case 404:
          navigate(ROUTES["404"]);
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  );
};
