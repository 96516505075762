import React, { useEffect, useState } from "react";
import { IStakingChartProps } from "./interface";
import "./style.less";
import { Chart } from "react-chartjs-2";
import { formatNumber } from "../../_helpers/numberFormatter";

const StakingChart = (props: IStakingChartProps) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [lineOptions, setLineOptions] = useState(null);

  useEffect(() => {
    setChartData({
      labels: props.data.timelineStakingData.map((item) => item.yearMonth),
      datasets: [
        {
          fill: false,
          lineTension: 0.7,
          borderColor: "#07e892",
          borderCapStyle: "butt",
          borderDash: [],
          borderWidth: 2,
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#07e892",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 5,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#07e892",
          pointHoverBorderColor: "#07e892",
          pointHoverBorderWidth: 0,
          pointRadius: 1,
          pointHitRadius: 1,
          data: props.data.timelineStakingData.map(
            (item) => item.totalStakedTokens
          ),
        },
      ],
    });
    let minValue = 10000;
    let maxValue = -1;
    const dataValues = props.data.timelineStakingData.map((item) => {
      if (item.totalStakedTokens > maxValue) {
        maxValue = item.totalStakedTokens;
      }
      if (item.totalStakedTokens < minValue) {
        minValue = item.totalStakedTokens;
      }
    });
    setLineOptions({
      plugins: {
        legend: {
          display: false,
          position: "top" as const,
        },
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: {
          display: true,
        },
        yAxes: {
          min: minValue,
          max: maxValue + 0.1 * maxValue,
          stepSize: (maxValue + 0.1 * maxValue - minValue) / 10,
          display: true,
        },
      },
      maintainAspectRatio: true,
    });
  }, []);

  return (
    <div className="chartContainer">
      <h3>{props.selectedToken.tokenSymbol} Staking analysis</h3>
      {lineOptions ? (
        <Chart
          type="line"
          redraw
          options={lineOptions}
          data={chartData}
          id={"test"}
        />
      ) : (
        ""
      )}

      <div className="specs">
        <p>
          <span>Number of stakers</span>
          <span>{formatNumber(props.data.poolNumberOfStakers)}</span>
        </p>

        <p>
          <span>Total supply</span>

          <span>{formatNumber(350000000)}</span>
        </p>
      </div>
    </div>
  );
};

export default StakingChart;
