import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button, FormInput } from "../index";
import { Form, notification } from "antd";
import { VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";
import { useDispatch, useSelector } from "react-redux";
import { getAuthConfigSelector } from "../Layout/selectors";
import {
  getCurrentUserSelector,
  getValidateUserPasswordErrorSelector,
  getValidateUserPasswordLoadingSelector,
  getValidateUserPasswordSuccessSelector,
} from "./selectors";
import {
  resetValidateUserPasswordState,
  validateUserPasswordRequest,
} from "./userSlice";
import "./style.less";
import OtpModal from "../OtpModal";
import { ButtonTypes } from "../Button/types";

const User = ({ variant }) => {
  const dispatch = useDispatch();

  const [formRef] = Form.useForm();
  const [formData, setFormData] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const config = useSelector(getAuthConfigSelector);
  const user = useSelector(getCurrentUserSelector);

  const validateUserPasswordSuccess = useSelector(
    getValidateUserPasswordSuccessSelector
  );
  const validateUserPasswordLoading = useSelector(
    getValidateUserPasswordLoadingSelector
  );
  const validateUserPasswordError = useSelector(
    getValidateUserPasswordErrorSelector
  );

  const otpSuccess = () => {
    setFormData(null);
    formRef.resetFields();
    setShowOtpModal(false);
    dispatch(resetValidateUserPasswordState());
    notification.success({
      message: "Password updated successfully.",
    });
  };

  const submitForm = () => {
    formRef.validateFields().then((formValues) => {
      setFormData(formValues);
      dispatch(validateUserPasswordRequest(formValues));
    });
  };

  useEffect(() => {
    if (user) {
      formRef.setFieldsValue({
        email: user.email,
      });
    }
  }, [user]);

  useEffect(() => {
    if (validateUserPasswordSuccess) {
      setShowOtpModal(true);
    }
  }, [validateUserPasswordSuccess]);
  return (
    <div className={classnames("userProfileDetailsBox", variant)}>
      <Form form={formRef} layout="vertical">
        <Form.Item
          className="passwordSection"
          hasFeedback
          name="oldPassword"
          rules={[
            { required: true, message: VALIDATION_ERROR_MESSAGES.required },
            { max: 100, message: VALIDATION_ERROR_MESSAGES.maxLength100 },
          ]}
        >
          <FormInput label="Current Password" type="password" />
        </Form.Item>

        <Form.Item
          hasFeedback
          name="newPassword"
          rules={[
            { required: true, message: VALIDATION_ERROR_MESSAGES.required },
            () => ({
              validator(_, value) {
                const regExpr = new RegExp(
                  config.passwordConfiguration.regex,
                  "g"
                );
                return value && !regExpr.test(value)
                  ? Promise.reject(VALIDATION_ERROR_MESSAGES.passwordFormat)
                  : Promise.resolve();
              },
            }),
            { max: 100, message: VALIDATION_ERROR_MESSAGES.maxLength100 },
          ]}
        >
          <FormInput label="Password" type="password" />
        </Form.Item>

        <Form.Item
          hasFeedback
          name="confirm"
          rules={[
            { required: true, message: VALIDATION_ERROR_MESSAGES.required },
            ({}) => ({
              validator(_, value) {
                if (!value || formRef.getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(VALIDATION_ERROR_MESSAGES.passwordConfirmation)
                );
              },
            }),
          ]}
        >
          <FormInput label="Password Confirmation" type="password" />
        </Form.Item>

        <Button
          type={ButtonTypes.PRIMARY}
          label="Update"
          extraClasses="submitButton"
          loading={validateUserPasswordLoading}
          onClick={() => submitForm()}
        />

        {validateUserPasswordError ? (
          <span className="generalError">
            {VALIDATION_ERROR_MESSAGES.invalidCredentials}
          </span>
        ) : null}
      </Form>
      {showOtpModal && formData ? (
        <OtpModal
          endpoint={`${process.env.REACT_APP_AUTH_API_URL}/users`}
          onSuccess={otpSuccess}
          isVisible={showOtpModal}
          additionalPayload={formData}
          recipient={user.details.phoneNumber}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default User;
