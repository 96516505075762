import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaTelegramPlane,
} from "react-icons/fa";
import { ROUTES } from "../../../_helpers/config";

export const menu = [
  {
    title: "Navigation",
    links: [
      {
        title: "Wallet",
        href: ROUTES.wallet,
      },
      {
        title: "Staking",
        href: ROUTES.stake,
      },
      {
        title: "Vesting",
        href: ROUTES.vesting,
      },
      {
        title: "Events",
        href: ROUTES.events,
      },
      {
        title: "News",
        href: ROUTES.news,
      },
    ],
  },
  {
    title: "Support",
    links: [
      {
        title: "Privacy Policy",
        href: "https://seedon.io/privacy-policy/",
        isExternal: true,
      },
      {
        title: "Cookie Policy",
        href: "https://seedon.io/cookie-policy/",
        isExternal: true,
      },
      {
        title: "Terms & Conditions",
        href: "https://seedon.io/terms-and-conditions/",
        isExternal: true,
      },
    ],
  },
  {
    title: "More",
    links: [
      {
        title: "Team",
        href: "https://seedon.io/#team",
        isExternal: true,
      },
      {
        title: "Contact",
        href: "https://seedon.io/contact",
        isExternal: true,
      },
      {
        title: "Release Notes",
        href: "release-notes",
      },
    ],
  },
];

export const socials = [
  {
    icon: <FaFacebookF />,
    title: "Facebook",
    href: "https://www.facebook.com/seedonventure",
  },
  {
    icon: <FaTwitter />,
    title: "Twitter",
    href: "https://twitter.com/seedonventure",
  },
  {
    icon: <FaLinkedinIn />,
    title: "Linkedin",
    href: "https://www.linkedin.com/company/seedon/",
  },
  {
    icon: <FaTelegramPlane />,
    title: "Telegram",
    href: "https://t.me/SeedOnVenture",
  },
];
