export const getValueTransactionsTypes = {
  depositTransactionMetadata: "Deposit",
  withdrawalTransactionMetadata: "Withdraw",
  stakingTransactionMetadata: "Stake",
  vestingTransactionMetadata: "Vesting",
  tokenSwapTransactionMetadata: "Swap",
  fundsTransferTransactionMetadata: "Transfer",
  referralCampaignTransactionMetadata: "Referral",
  fundraiseInvestmentAllocationMetadata: "Investment allocation",
  fiatDepositTransactionMetadata: "Buy",
  fiatPurchaseCampaignTransactionMetadata: "Prize",
  all: "All",
};

export const transactionsStatus = ["All", "Pending", "Successful", "Failed"];
