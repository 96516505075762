import { IForgotPasswordParams, ForgotPasswordState } from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: ForgotPasswordState = {
  loading: false,
  error: null,
  success: false,
};

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    postForgotPasswordRequest(
      state,
      action: PayloadAction<IForgotPasswordParams>
    ) {
      return {
        ...state,
        loading: true,
        params: action.payload,
        error: null,
        success: false,
      };
    },
    postForgotPasswordSuccess(state) {
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      };
    },
    postForgotPasswordFailure(state, action) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error.response.data,
      };
    },
    resetState(state) {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  postForgotPasswordRequest,
  postForgotPasswordSuccess,
  postForgotPasswordFailure,
  resetState,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
