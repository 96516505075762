import React from "react";
import { Tabs } from "antd";

// Components
import { WalletLayout } from "../index";
import VestingList from "./VestingList";

const Staking = () => {
  return (
    <WalletLayout
      showControls
      withDefaultButtons={true}
      title="Vesting"
      description="Curious about the next vesting dates? You can check them here and claim your coins, all in one place."
    >
      <Tabs
        centered
        defaultActiveKey="1"
        destroyInactiveTabPane
        className="walletTabs vestingTabs"
        items={[
          {
            label: "",
            key: "1",
            children: <VestingList />,
          },
        ]}
      />
    </WalletLayout>
  );
};

export default Staking;
