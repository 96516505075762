import React from "react";
import { IExternalLinkProps } from "./interface";

export const ExternalLink: React.FC<IExternalLinkProps> = ({
  href,
  extraClassNames,
  doFollow,
  title,
  children,
}) => {
  return (
    <a
      href={href}
      title={title}
      className={extraClassNames}
      rel={doFollow ? "" : "noopener noreferrer nofollow"}
      target="_blank"
    >
      {children}
    </a>
  );
};
