import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  postRegisterFailure,
  postRegisterRequest,
  postRegisterSuccess,
} from "./registerSlice";

import { IRegisterResponse } from "./types";
import { axios } from "../../services/axios.service";

/*
  Worker Saga: Fired on postRegisterRequest action
*/
function* postRegisterSaga(action: ReturnType<typeof postRegisterRequest>) {
  try {
    const response: AxiosResponse<IRegisterResponse> = yield call(
      axios.post,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/sign-up`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(
      postRegisterSuccess({
        register: response.data,
      })
    );
  } catch (e: any) {
    yield put(
      postRegisterFailure({
        error: e,
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `postRegisterRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* registerSaga() {
  yield all([takeLatest(postRegisterRequest, postRegisterSaga)]);
}

export default registerSaga;
