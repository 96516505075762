import "./style.less";
import pageHeaderAsset from "../../assets/images/seedon-campaign/coin_header.png";
import newInHeaderText from "../../assets/images/seedon-campaign/new_in_text.png";
import noFeeHeaderText from "../../assets/images/seedon-campaign/no_fee_text.png";
import glowline from "../../assets/images/seedon-campaign/glowline.png";
import week1 from "../../assets/images/seedon-campaign/week1.png";
import week2 from "../../assets/images/seedon-campaign/week2.png";
import crown from "../../assets/images/seedon-campaign/crown.png";
import crownUSDT from "../../assets/images/seedon-campaign/crown_usdt.png";
import { useEffect, useState } from "react";
import { Button } from "../index";
import { ButtonTypes } from "../Button/types";
import Participation from "../../assets/images/seedon-campaign/participation.png";
import { FiArrowRight } from "react-icons/fi";
import Crowdfund from "../../assets/images/seedon-campaign/crowdfund.png";
import Account from "../../assets/images/seedon-campaign/create_account.png";
import Verify from "../../assets/images/seedon-campaign/verify_complete.png";
import Buy from "../../assets/images/seedon-campaign/buy_seon_fiat.png";
import { globalVariables, ROUTES } from "../../_helpers/config";
import { useNavigate } from "react-router-dom";
import { GlowAnimateCardWrapper } from "../GlowAnimateCardWrapper/glowAnimateCardWrapper";
import { useWindowSize } from "../../hooks/windowSize";
import ModalInfo from "./modalInfo";
import { displayWalletAddress } from "../../_helpers/walletAddressTruncate";

const FiatDepositsCampaign = () => {
  const navigate = useNavigate();
  const [componentRender, setComponentRender] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const windowSize = useWindowSize();

  const winnersWeekOneWallet = [
    "0x88c3b09755da519e05533eb16db8efb543693381",
    "0x55d7a39f44a573b1f41cd193792335311b34c85e",
    "0x3ba8739d81bd338a1209e0ce49854c2a49feb70e",
    "0xf43032301907e9eb41253caecb525af20f94e5c2",
    "0xa390d948e9479e1827390353a4231db33eea072e",
  ];

  const winnersWeekTwoWallet = [
    "0xb67e7e96994b7bb0cda7ea2173f6a785722a3af7",
    "0x5008fd0f4b9d2d2838585de644c54a66ce013299",
    "0x0427fa3e9da8782c1660c27813a34814aa4ad8a7",
    "0xd6fea1399988e86695d1579c731be54df07d1de7",
    "0xdb1c03362cb873779f874fd8e16ab8bac006bd0f",
  ];

  useEffect(() => {
    if (
      window.location.pathname.includes(
        "/fiat-deposits-campaign/terms-and-conditions"
      )
    ) {
      setShowModalInfo(true);
    }
    setTimeout(() => {
      setComponentRender(true);
    }, 600);
  }, []);

  return (
    <section className="page-wrapper fiat-campaign-page-wrapper">
      <section className="container" data-aos="fade-right">
        <section className="intro-seedon-campaign">
          <div className="intro-heading">
            <img src={glowline} className="intro-heading-asset" alt="SEON" />
            <h1>
              Buy <span className="highlight-heading-text">$SEON</span> with
              <br /> VISA & Mastercard
            </h1>
          </div>
          <div className="box-coin">
            <img
              src={newInHeaderText}
              className={`header-text-asset left-text ${
                componentRender ? "left-text-animation" : ""
              }`}
              alt="SEON"
            />
            <img src={pageHeaderAsset} alt="SEON" />
            <img
              src={noFeeHeaderText}
              className={`header-text-asset right-text ${
                componentRender ? "right-text-animation" : ""
              }`}
              alt="SEON"
            />
          </div>
          <div className="box-details">
            <div className="box-details-content-wrapper">
              <div className="box-background" />
              <div className="box-details-content">
                <div className="details-item">
                  <div className="item-title-decorator" />
                  <div className="item-title">Total winners</div>
                  <div className="item-value">10</div>
                </div>
                <div className="details-item">
                  <div className="item-title-decorator" />
                  <div className="item-title">Prize per participant</div>
                  <div className="item-amount-details">
                    <div className="amount-details">
                      <div className="item-value">$250</div>
                      <div className="to-seon-coin">
                        <FiArrowRight />
                        <img src="/icon-usdt.png" alt="SEON" />
                      </div>
                    </div>
                    <div className="amount-content">Worth of USDT</div>
                  </div>
                </div>
                <div className="details-item">
                  <div className="item-title-decorator" />
                  <div className="item-title">Total prize pool</div>
                  <div className="item-amount-details">
                    <div className="amount-details">
                      <div className="item-value">$2500</div>
                      <div className="to-seon-coin">
                        <FiArrowRight />
                        <img src="/icon-usdt.png" alt="SEON" />
                      </div>
                    </div>
                    <div className="amount-content">Worth of USDT</div>
                  </div>
                </div>
                <div className="details-item">
                  <div className="item-title-decorator" />
                  <div className="item-title">Commission</div>
                  <div className="item-value">$0</div>
                </div>
              </div>
            </div>
          </div>
          <Button
            type={ButtonTypes.PRIMARY}
            label="Buy SEON with 0% fee"
            onClick={() => navigate(ROUTES.buy)}
          />
          <div className="details">
            Promotional campaign available from the 15th of March 2023 at 1PM
            UTC through the 29th of March 2023 at 1PM UTC.{" "}
            <span
              className={"openModal"}
              onClick={() => setShowModalInfo(true)}
            >
              See Terms & Conditions
            </span>
          </div>
        </section>
      </section>
      <section className="winners-week-one" id="winners">
        <div className="title-section">
          <img src={week1} className="week1-asset" alt="SEON" />
          <div className="details">
            We are pleased to announce the winners of our giveaway contest!
            <br />
            If you were among the participants in the contest, we kindly request
            you to verify the list below and see if your wallet address was
            selected as one of the lucky winners. Also, we will be contacting
            the winners through email, so we advise you to keep an eye on your
            inbox.
          </div>
        </div>
        <div className="winners-container">
          <div className="col-left">
            <div className="crown-asset">
              <img src={crown} alt="SEON" />
            </div>
          </div>
          <div className="col-right">
            <div className="amount-details">
              <div className="item-value">Token</div>
              <div className="to-seon-coin">
                <FiArrowRight />
                <img src="/icon_seon.png" alt="SEON" />
              </div>
            </div>

            <div className="amount-details">
              <div className="item-value">Amount</div>
              <div className="to-seon-coin">
                <FiArrowRight />
                <div className="item">40.000</div>
                <img src="/icon_seon.png" alt="SEON" />
              </div>
            </div>

            <div className="amount-details winners">
              <div className="item-value">Winners wallet</div>
            </div>
            {winnersWeekOneWallet.map((item, index) => (
              <div className="wallet-address-container" key={index}>
                <div className="wallet-address">
                  {displayWalletAddress(item, 10)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="winners-week-two" id="winners-second-week">
        <div className="title-section">
          <img src={week2} className="week1-asset" alt="SEON" />
          <div className="details">
            We are pleased to announce the winners of our giveaway contest!
            <br />
            If you were among the participants in the contest, we kindly request
            you to verify the list below and see if your wallet address was
            selected as one of the lucky winners. Also, we will be contacting
            the winners through email, so we advise you to keep an eye on your
            inbox.
          </div>
        </div>
        <div className="winners-container">
          <div className="col-left">
            <div className="crown-asset crown-asset-usdt">
              <img src={crownUSDT} alt="SEON" />
            </div>
          </div>
          <div className="col-right">
            <div className="amount-details">
              <div className="item-value">Token</div>
              <div className="to-seon-coin">
                <FiArrowRight />
                <img src="/icon-usdt.png" alt="SEON" />
              </div>
            </div>

            <div className="amount-details">
              <div className="item-value">Amount</div>
              <div className="to-seon-coin">
                <FiArrowRight />
                <div className="item">250</div>
                <img src="/icon-usdt.png" alt="SEON" />
              </div>
            </div>

            <div className="amount-details winners">
              <div className="item-value">Winners wallet</div>
            </div>
            {winnersWeekTwoWallet.map((item, index) => (
              <div className="wallet-address-container" key={index}>
                <div className="wallet-address">
                  {displayWalletAddress(item, 10)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="section-participation">
        <div className="details">
          <div className="details-wrapper">
            <div className="details-title">Participation</div>
            <div className="details-content">
              Purchase at least $15 worth of SEON tokens to participate in our
              contest and have a chance to win a prize of $250 worth of $USDT.
              By participating in the contest, users agree to the official rules
              and regulations governing the contest.
            </div>
            <div className="details-content-bold">
              Five random winners will be picked for the prize pool on the 22nd
              of March, having the next five random winners picked on the 29th
              of March.
            </div>
          </div>
        </div>
        <div className="img-participation">
          <img src={Participation} alt="participation" />
        </div>
      </section>
      <section className="section-disclaimer">
        <div className="disclaimer-container">
          <div className="disclaimer-cards">
            <div className="disclaimer-card-item">
              <div className="disclaimer-card-item--title">
                0% <br /> Fee
              </div>
              <div className="disclaimer-card-item--content">
                Full reliability, no fees. Buy SEON with 0% commissions.
              </div>
            </div>
            <div className="disclaimer-card-item">
              <div className="disclaimer-card-item--title">
                Seamless <br /> KYC
              </div>
              <div className="disclaimer-card-item--content">
                Lawful and stressless KYC & AML processing.
              </div>
            </div>
            <div className="disclaimer-card-item">
              <div className="disclaimer-card-item--title">
                Fiat <br /> Payments
              </div>
              <div className="disclaimer-card-item--content">
                Quick & Secure transactions with Visa or Mastercard.
              </div>
            </div>
          </div>
          <div className="disclaimer-content">
            <p className="title">DISCLAIMER</p>
            <p>
              For a limited time until the 29th of March, anyone who purchases
              at least $50 worth of SEON tokens will be eligible to receive a 5%
              cashback on their purchase amount, up to a maximum of $25. The
              cashback will be distributed 7 days after the purchase if the
              purchased tokens have been #HODL.
            </p>
          </div>
        </div>
      </section>
      <section className="section-effortless">
        <div className="container-effortless">
          <span className="container-effortless--title">
            Effortless $SEON purchases
          </span>
          <div className="container-effortless--cards">
            <div className="cards-item">
              <div className="cards-item--no">1</div>
              <div className="cards-item--title">
                Create your <br />
                SeedOn Finance account
              </div>
              <div className="cards-item--image">
                <img src={Account} alt="card" />
              </div>
              <div className="cards-item--content">
                To start buying crypto on Seedon, all you need is an email to
                sign up or login.
              </div>
            </div>
            <div className="cards-item">
              <div className="cards-item--no">2</div>
              <div className="cards-item--title">
                Verify wallet <br />
                and complete KYC
              </div>
              <div className="cards-item--image">
                <img src={Verify} alt="card" />
              </div>
              <div className="cards-item--content">
                Validate your wallet's secret phrase and complete the lawful and
                stressless KYC process.
              </div>
            </div>
            <div className="cards-item">
              <div className="cards-item--no">3</div>
              <div className="cards-item--image">
                <img src={Buy} alt="card" />
              </div>
              <div className="cards-item--title">
                Buy SEON <br />
                with fiat
              </div>
              <div className="cards-item--content">
                Now you are ready to buy $SEON with EUR or USD within SeedOn's
                ecosystem.
              </div>
            </div>
            <div className="cards-item">
              <div className="cards-item--no">4</div>
              <div className="cards-item--image">
                <img src={Crowdfund} alt="card" />
              </div>
              <div className="cards-item--title">
                Crowdfund <br />
                Your Idea
              </div>
              <div className="cards-item--content">
                Browse and pick your next crowdfunding investments via SeedOn's
                native token, $SEON.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-purchase">
        <span className="container-purchase--title">
          Purchase with fiat currency
        </span>

        <div className="container-purchase--description">
          We now offer to our users a convenient and straightforward way to buy
          $SEON tokens. You can now easily purchase $SEON using either Euro or
          USD fiat currency, providing you with the flexibility and convenience
          you need to invest in our token.
        </div>

        <div className="container-purchase--cards-container">
          {windowSize?.width > globalVariables.desktopSize ? (
            <>
              <GlowAnimateCardWrapper cardId={"1"}>
                <div className="purchase-card euro-card">
                  <div className="currency-container euro">€</div>
                  <div className="currency-name">Euro</div>
                  <div className="card-blur" />
                </div>
              </GlowAnimateCardWrapper>

              <GlowAnimateCardWrapper cardId={"2"}>
                <div className="purchase-card dollar-card">
                  <div className="currency-container">$</div>
                  <div className="currency-name">US Dollar</div>
                  <div className="card-blur" />
                </div>
              </GlowAnimateCardWrapper>
            </>
          ) : (
            <>
              <div className="purchase-card euro-card">
                <div className="currency-container euro">€</div>
                <div className="currency-name">Euro</div>
                <div className="card-blur" />
              </div>

              <div className="purchase-card dollar-card">
                <div className="currency-container">$</div>
                <div className="currency-name">US Dollar</div>
                <div className="card-blur" />
              </div>
            </>
          )}
        </div>
        <ModalInfo
          isVisible={showModalInfo}
          closeModal={() => setShowModalInfo(false)}
        />
      </section>
    </section>
  );
};

export default FiatDepositsCampaign;
