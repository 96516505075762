import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import "./styles.less";

//components
import { Tabs, Image, Spin, Row, Col, Typography } from "antd";
import CardTab from "./components/cardTab";

import CryptoTab from "./components/cryptoTab";
import { data } from "./data";
import { ROUTES } from "../../_helpers/config";

import { MdOutlineArrowBack } from "react-icons/md";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getCampaignDetails } from "../campaignDetails/campaignDetails";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignDetailsSelector } from "../campaignDetails/selectors";
import { getPoolDataRequest } from "./investSlice";

const { Title, Paragraph } = Typography;

const CampaignInvestPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const params = useParams();
  const campaignSlug = params.id;

  const [investAmount, setInvestAmount] = useState(null);
  const campaignDetails = useSelector(getCampaignDetailsSelector);

  useEffect(() => {
    if (executeRecaptcha) {
      getCampaignDetailsHandler().then(() => {});
    }
  }, [executeRecaptcha]);

  const getCampaignDetailsHandler = async () => {
    const token = await executeRecaptcha("getCampaignDetails");
    dispatch(
      getCampaignDetails({
        campaignId: campaignSlug,
        captchaToken: token,
      })
    );
  };
  useEffect(() => {
    if (campaignDetails) {
      const sendForm = async () => {
        const token = await executeRecaptcha("poolData");
        dispatch(
          getPoolDataRequest({
            captchaToken: token,
            projectId: campaignDetails.data.id,
          })
        );
      };
      sendForm();
    }
  }, [campaignDetails]);
  return (
    <>
      {isEmpty(data) ? (
        <Spin />
      ) : (
        <div className="campaignInvestPage">
          <div className="campaignInvestPageTitle" data-aos="zoom-in">
            <Row className="investHeading" id="invest-heading">
              <div className="investHeadingLogo">
                <div className="imageBlur">
                  <Image
                    src={campaignDetails?.data?.about?.companyLogo?.url}
                    preview={false}
                  />
                </div>
                <div className="logo">
                  <Image
                    src={campaignDetails?.data?.about?.companyLogo?.url}
                    preview={false}
                  />
                </div>
              </div>
              <div className="details">
                <Title level={1} className="title">
                  {campaignDetails?.data?.about?.campaignName}
                </Title>
                <Paragraph className="description">
                  {campaignDetails?.data?.product?.valueProposition}
                </Paragraph>
              </div>
              <div className="investHeadingButton">
                <Link
                  to={
                    campaignDetails?.data?.slug
                      ? `/campaign/${campaignDetails?.data?.slug}`
                      : ROUTES.launchpad
                  }
                >
                  <MdOutlineArrowBack size={16} />
                  Back to campaign
                </Link>
              </div>
            </Row>
          </div>

          <Row justify="space-between">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              data-aos="fade-up"
              data-aos-delay="900"
            >
              <div className="investPayment">
                <Tabs
                  destroyInactiveTabPane
                  className="investTabs"
                  defaultActiveKey={"2"}
                  onChange={() => {
                    setInvestAmount(null);
                  }}
                  items={[
                    {
                      label: (
                        <div className="investTabHead">
                          <span>Invest using</span>
                          <div className="investTabName">Card</div>
                        </div>
                      ),
                      key: "1",
                      children: (
                        <CardTab
                          investAmount={investAmount}
                          setInvestAmount={setInvestAmount}
                          minInvest={data?.minimumAmount}
                          maxInvest={data?.maximumAmount}
                        />
                      ),
                    },
                    {
                      label: (
                        <div className="investTabHead">
                          <span>Invest using</span>
                          <div className="investTabName">Crypto</div>
                        </div>
                      ),
                      key: "2",
                      children: (
                        <CryptoTab
                          setInvestAmount={setInvestAmount}
                          minInvest={data?.minimumAmount}
                          campaignId={data?.id}
                          projectId={campaignDetails?.data?.id}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default CampaignInvestPage;
