import React, { useEffect, useState } from "react";
import "./style.less";
import { INewsProps } from "./interface";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventCategoriesRequest,
  getEventRequest,
  getEventsRequest,
  getEventsStartDateRequest,
  resetState,
} from "./eventsSlice";
import {
  getEventCategoriesLoadingSelector,
  getEventCategoriesSelector,
  getEventSelector,
  getEventsSelector,
  getEventsStartDateLoadingSelector,
  getEventsStartDateSelector,
  getLoadingSelector,
} from "./selectors";
import { groupBy } from "../../_helpers/arrays";
import { DatePicker, List, Row, Select, Spin } from "antd";
import { Button } from "../index";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// Icons
import { FiShare } from "react-icons/fi";
import { RangePickerProps } from "antd/es/date-picker";
import Event from "./Event";
import { ButtonTypes } from "../Button/types";

const { Option } = Select;

const Events: React.FC<INewsProps> = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const event = useSelector(getEventSelector);
  const events = useSelector(getEventsSelector);
  const eventsLoading = useSelector(getLoadingSelector);
  const categories = useSelector(getEventCategoriesSelector);
  const categoriesLoading = useSelector(getEventCategoriesLoadingSelector);

  const eventsStartDate = useSelector(getEventsStartDateSelector);
  const eventsStartDateLoading = useSelector(getEventsStartDateLoadingSelector);

  const [groupedEvents, setGroupedEvents] = useState([]);
  const [ungroupedEvents, setUngroupedEvents] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [selectedStartDate, setSelectedStartDate] = useState(undefined);
  const [initialEventsLoaded, setInitialEventsLoaded] = useState(false);
  const [pager, setPager] = useState({
    page: 0,
    size: 10,
  });

  const loadMoreEvents = () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("events");

      dispatch(
        getEventsRequest({
          page: pager.page + 1,
          size: pager.size,
          from: selectedStartDate
            ? selectedStartDate.format("Y-MM-DD")
            : undefined,
          criteria:
            selectedCategory && selectedCategory !== "All events"
              ? selectedCategory
              : undefined,
          captchaToken: token,
        })
      );
    };
    sendForm().then(() => {});
    setPager({
      page: pager.page + 1,
      size: pager.size,
    });
  };

  useEffect(() => {
    if (selectedStartDate !== undefined) {
      const sendForm = async () => {
        const token = await executeRecaptcha("getEvents");
        dispatch(
          getEventsRequest({
            page: 0,
            size: 10,
            criteria:
              selectedCategory && selectedCategory !== "All events"
                ? selectedCategory
                : undefined,
            from: selectedStartDate
              ? selectedStartDate.format("Y-MM-DD")
              : undefined,
            captchaToken: token,
          })
        );
      };
      sendForm().then(() => {});
      setPager({
        page: 0,
        size: 10,
      });
      setUngroupedEvents([]);
    }
  }, [selectedStartDate]);
  useEffect(() => {
    if (selectedCategory !== undefined) {
      const sendForm = async () => {
        const token = await executeRecaptcha("getEvents");
        dispatch(
          getEventsRequest({
            page: 0,
            size: 10,
            from: selectedStartDate
              ? selectedStartDate.format("Y-MM-DD")
              : undefined,
            criteria:
              selectedCategory && selectedCategory !== "All events"
                ? selectedCategory
                : undefined,
            captchaToken: token,
          })
        );
      };
      sendForm().then(() => {});
      setPager({
        page: 0,
        size: 10,
      });
      setUngroupedEvents([]);
    }
  }, [selectedCategory]);

  const getCategories = () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("getcategories");
      dispatch(
        getEventCategoriesRequest({
          captchaToken: token,
        })
      );
    };
    sendForm().then(() => {});
  };

  const getEvents = () => {
    setPager({
      ...pager,
    });
    const sendForm = async () => {
      const token = await executeRecaptcha("events");
      dispatch(
        getEventsRequest({
          ...pager,
          captchaToken: token,
        })
      );
    };
    sendForm().then(() => {});
  };
  const getEvent = () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("events");
      dispatch(
        getEventRequest({
          eventId: params.id,
          captchaToken: token,
        })
      );
    };
    sendForm().then(() => {});
  };
  const getEventsStartDate = async () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("eventsStartDate");
      dispatch(
        getEventsStartDateRequest({
          captchaToken: token,
        })
      );
    };
    sendForm().then(() => {});
  };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < moment(eventsStartDate);
  };

  useEffect(() => {
    if (executeRecaptcha) {
      getCategories();
      getEvents();
      getEventsStartDate();

      if (params.id) {
        getEvent();
      }
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (events.content) {
      setInitialEventsLoaded(true);
      setUngroupedEvents([...ungroupedEvents, ...events.content]);
    }
  }, [events]);

  useEffect(() => {
    setGroupedEvents(groupBy(ungroupedEvents, "date_event"));
  }, [ungroupedEvents]);

  return (
    <Row className="eventsPage">
      <Row className="container">
        {params.id && !event ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          <>
            {event ? (
              <Event
                event={event}
                groupedEvents={groupedEvents}
                setGroupedEvents={setGroupedEvents}
                isSelected
              />
            ) : (
              ""
            )}

            <Row className="pageHeading" data-aos="zoom-out">
              <h1>Crypto events calendar</h1>
              <p>
                Browse the most expected and trending up-and-coming crypto
                events. Don't miss out on any event by adding it to your
                watchlist to get notified.
              </p>

              <p className="dataPartnerDisclaimer">
                Our data partner:
                <a href="https://coinmarketcal.com" target={"_blank"}>
                  <FiShare />
                  coinmarketcal.com
                </a>
              </p>
            </Row>

            <Row className="eventsFilters" data-aos="zoom-in">
              <div className="formItem">
                <p>Filter by category</p>

                <Select
                  clearIcon
                  loading={categoriesLoading}
                  defaultValue={"All events"}
                  onChange={setSelectedCategory}
                >
                  {categories ? (
                    <Option value={"All events"}>All events</Option>
                  ) : (
                    ""
                  )}

                  {categories.map((category, categoryIndex) => (
                    <Option key={categoryIndex} value={category.name}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="formItem">
                <p>Or specific date</p>

                <DatePicker
                  disabledDate={disabledDate}
                  onChange={setSelectedStartDate}
                  disabled={eventsStartDateLoading || !eventsStartDate}
                />
              </div>
            </Row>

            <Row className="listEvents" data-aos="fade-up">
              {groupedEvents.map((groupedItem, index) => (
                <div key={index} className="groupedEvent">
                  <h2 className="groupTitle">
                    {moment(groupedItem.groupByKey).format(
                      "dddd, Do MMMM YYYY"
                    )}{" "}
                    UTC
                  </h2>

                  <List
                    itemLayout="horizontal"
                    dataSource={groupedItem.data}
                    renderItem={(item: any) => (
                      <List.Item className="eventItem">
                        <Event
                          event={item}
                          groupedEvents={groupedEvents}
                          setGroupedEvents={setGroupedEvents}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              ))}

              {initialEventsLoaded &&
              groupedEvents.length === 0 &&
              eventsLoading === false ? (
                <div className="noEvents">
                  <h3>No results</h3>
                  <p>
                    We didn't managed to find any events matching your filters,
                    maybe try again?
                  </p>
                </div>
              ) : (
                ""
              )}

              {events.last === false &&
              ungroupedEvents.length &&
              initialEventsLoaded ? (
                <Button
                  label="Load More"
                  loading={eventsLoading}
                  disabled={eventsLoading}
                  onClick={() => loadMoreEvents()}
                  extraClasses="loadMoreBtn"
                  type={ButtonTypes.SECONDARY}
                />
              ) : (
                ""
              )}
            </Row>
          </>
        )}
      </Row>
    </Row>
  );
};

export default Events;
