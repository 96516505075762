const WalletIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.004"
    height="16.755"
    viewBox="0 0 19.004 16.755"
  >
    <g
      id="Group_4811"
      data-name="Group 4811"
      transform="translate(-80.691 -778.869)"
    >
      <path
        id="Path_11200"
        data-name="Path 11200"
        d="M16.626,9.665V8.578A3.227,3.227,0,0,0,13.4,5.351H4.163A3.227,3.227,0,0,0,.936,8.578v5.933a3.227,3.227,0,0,0,3.227,3.227H13.4a3.227,3.227,0,0,0,3.227-3.227V13.389"
        transform="translate(80.555 777.086)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.6"
      />
      <path
        id="Path_11201"
        data-name="Path 11201"
        d="M12.969,3.739A3,3,0,0,0,9.974.82H3.931a3,3,0,0,0-3,3V6.761"
        transform="translate(80.555 778.849)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.6"
      />
      <line
        id="Line_315"
        data-name="Line 315"
        x2="5.933"
        transform="translate(85.579 786.302)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.6"
      />
      <path
        id="Path_11202"
        data-name="Path 11202"
        d="M23.432,16.169H25.5a.913.913,0,0,0,.913-.913v-1.91a.922.922,0,0,0-.922-.922H23.432a1.756,1.756,0,0,0-1.756,1.756v.232A1.756,1.756,0,0,0,23.432,16.169Z"
        transform="translate(72.485 774.334)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.6"
      />
    </g>
  </svg>
);

export default WalletIcon;
