/**
 * When receiving errors from server, they will come as keys (for example, PASSWORD_FIELD_HINT)
 * In this case, we need to map values to i18n so that we'll have translations later
 * Each key of this object will represent the value received from the server
 */
export const errorMapper = (message: string) => {
  switch (message) {
    case "PASSWORD_FIELD_HINT":
      return "Must contain special characters";
    case "INVALID_ACCOUNT_DETAILS":
      return "Invalid account details";
    case "INVALID_CREDENTIALS":
      return "Invalid account credentials";
    case "UNAUTHORIZED_USER_REQUEST":
      return "Unauthorized";
    default:
      return "";
  }
};
