import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  getCurrentUserFailure,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  validateUserPasswordRequest,
  validateUserPasswordSuccess,
  validateUserPasswordFailure,
  updateUserCurrencyRequest,
  updateUserCurrencySuccess,
  updateUserCurrencyFailure,
  getMFAQrSuccess,
  getMFAQrFailure,
  getMFAQrRequest,
  toggleMFAQrSuccess,
  toggleMFAQrFailure,
  toggleMFAQrRequest,
} from "./userSlice";

import { IUserResponse, UpdateUserCurrencyRequest } from "./types";
import { axios } from "../../services/axios.service";

/*
  Worker Saga: Fired on getCurrentUserRequest action
*/
function* getCurrentUserSaga(action: ReturnType<typeof getCurrentUserRequest>) {
  try {
    const response: AxiosResponse<IUserResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/me`
    );
    yield put(
      getCurrentUserSuccess({
        currentUser: response.data,
      })
    );
  } catch (e: any) {
    yield put(getCurrentUserFailure());
  }
}

function* validateUserPasswordSaga(
  action: ReturnType<typeof validateUserPasswordRequest>
) {
  try {
    yield call(
      axios.post,
      `${process.env.REACT_APP_AUTH_API_URL}/users`,
      action.payload
    );
    yield put(validateUserPasswordSuccess());
  } catch (e: any) {
    yield put(validateUserPasswordFailure());
  }
}

function* updateUserCurrencySaga(
  action: ReturnType<typeof updateUserCurrencyRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/users/currency?currency=${action.payload.currency}`,
      action.payload
    );
    yield put(updateUserCurrencySuccess());
  } catch (e: any) {
    yield put(updateUserCurrencyFailure());
  }
}

function* getMFAQrSaga(action) {
  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/mfa/qr`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getMFAQrSuccess(response.data.qr));
  } catch (e: any) {
    yield put(getMFAQrFailure());
  }
}
function* toggleMFAQrSaga(action) {
  try {
    const response = yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/mfa?enabled=false`,
      {},
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(toggleMFAQrSuccess());
  } catch (e: any) {
    yield put(toggleMFAQrFailure());
  }
}
/*
  Starts worker saga on latest dispatched `getCurrentUserRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* configSaga() {
  yield all([
    takeLatest(toggleMFAQrRequest, toggleMFAQrSaga),
    takeLatest(getMFAQrRequest, getMFAQrSaga),
    takeLatest(getCurrentUserRequest, getCurrentUserSaga),
    takeLatest(validateUserPasswordRequest, validateUserPasswordSaga),
    takeLatest(updateUserCurrencyRequest, updateUserCurrencySaga),
  ]);
}

export default configSaga;
