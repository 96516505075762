import {
  NotificationState,
  IGetNotificationsParams,
  IMarkNotificationAsReadParams,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: NotificationState = {
  notifications: null,
  notificationsLoading: false,
  notificationsModalVisible: false,
  unreadNotificationsCount: 0,
  markAllNotificationsAsReadSuccess: false,
  markNotificationAsReadSuccess: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetNotificationsState(state) {
      return {
        ...state,
        notifications: null,
        notificationsLoading: false,
      };
    },
    resetState(state) {
      return {
        ...state,
        notifications: null,
        notificationsLoading: false,
        notificationsModalVisible: false,
        markAllNotificationsAsReadSuccess: false,
        markNotificationAsReadSuccess: false,
      };
    },
    getNotificationsRequest(
      state,
      action: PayloadAction<IGetNotificationsParams>
    ) {
      return {
        ...state,
        params: action.payload,
        notificationsLoading: true,
      };
    },
    getNotificationsSuccess(state, action) {
      return {
        ...state,
        notifications: action.payload.notifications,
        notificationsLoading: false,
      };
    },
    getNotificationsFailure(state) {
      return {
        ...state,
        notifications: [],
        notificationsLoading: false,
      };
    },
    markAllNotificationsAsReadRequest(state) {
      return {
        ...state,
        markAllNotificationsAsReadSuccess: false,
      };
    },
    markAllNotificationsAsReadSuccess(state) {
      return {
        ...state,
        markAllNotificationsAsReadSuccess: true,
      };
    },
    markAllNotificationsAsReadFailure(state) {
      return {
        ...state,
        markAllNotificationsAsReadSuccess: false,
      };
    },
    getUnreadNotificationsCountRequest(state) {
      return {
        ...state,
        unreadNotificationsCount: 0,
      };
    },
    getUnreadNotificationsCountSuccess(state, action) {
      return {
        ...state,
        unreadNotificationsCount: action.payload.unreadNotifications,
      };
    },
    getUnreadNotificationsCountFailure(state) {
      return {
        ...state,
        unreadNotificationsCount: 0,
      };
    },
    markNotificationAsReadRequest(
      state,
      action: PayloadAction<IMarkNotificationAsReadParams>
    ) {
      return {
        ...state,
        params: action.payload,
        markNotificationAsReadSuccess: false,
      };
    },
    markNotificationAsReadSuccess(state) {
      return {
        ...state,
        markNotificationAsReadSuccess: true,
      };
    },
    markNotificationAsReadFailure(state) {
      return {
        ...state,
        markNotificationAsReadSuccess: false,
      };
    },
  },
});

export const {
  resetState,
  getNotificationsRequest,
  getNotificationsSuccess,
  getNotificationsFailure,
  markNotificationAsReadRequest,
  resetNotificationsState,
  getUnreadNotificationsCountRequest,
  getUnreadNotificationsCountSuccess,
  getUnreadNotificationsCountFailure,
  markAllNotificationsAsReadRequest,
  markAllNotificationsAsReadSuccess,
  markAllNotificationsAsReadFailure,
  markNotificationAsReadSuccess,
  markNotificationAsReadFailure,
} = notificationSlice.actions;

export default notificationSlice.reducer;
