import React, { useEffect, useState } from "react";
import "./style.less";
import moment from "moment";

// Assets
import { FiUsers } from "react-icons/fi";
import { FaCoins, FaCreditCard } from "react-icons/fa";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getMaintenanceSelector } from "./selectors";
import { getMaintenanceRequest } from "./maintenanceSlice";
import { useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { ROUTES } from "../../_helpers/config";

const ReferralDisclaimer = () => {
  const dispatch = useDispatch();
  const maintenance = useSelector(getMaintenanceSelector);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMaintenanceRequest());
  }, []);

  return (
    <>
      {maintenance && maintenance.status === "ACTIVE" ? (
        <div className="referral-disclaimer">
          <p>
            <FiUsers />
            Maintenance: Scheduled between{" "}
            <span>
              {moment(maintenance.timeFrom).format("DD MM yyyy hh:mm")} UTC and{" "}
              {moment(maintenance.timeTo).format("DD MM yyyy hh:mm")} UTC
            </span>
            . {maintenance.message}
          </p>
        </div>
      ) : (
        showDisclaimer && (
          <div className="referral-disclaimer">
            <p>
              <FaCreditCard color="#fff" />
              <span
                className={"announcement-title"}
                onClick={() => navigate(ROUTES.buy)}
              >
                {/*  In order to invest in a fundraising campaign, a minimum of*/}
                {/*  10,000 CLDT must be staked.*/}
                {/*</span>*/}
                Buy $SEON with Visa & Mastercard
              </span>
              {/*<AiFillCloseCircle*/}
              {/*  className={"close-icon-announcement"}*/}
              {/*  onClick={() => setShowDisclaimer(false)}*/}
              {/*/>*/}
            </p>
          </div>
        )
      )}
    </>
  );
};

export default ReferralDisclaimer;
