import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getLoading = (state: AppState) => state.wallet.loading;
const getError = (state: AppState) => state.wallet.error;

const purchaseOrder = (state: AppState) => state.wallet.purchaseOrder;
const purchaseOrderLoading = (state: AppState) =>
  state.wallet.purchaseOrderLoading;

const estimateRates = (state: AppState) => state.wallet.estimateRates;
const estimateRatesLoading = (state: AppState) =>
  state.wallet.estimateRatesLoading;
const estimateRatesError = (state: AppState) => state.wallet.estimateRatesError;

const getWhitelistedCoins = (state: AppState) => state.wallet.whitelistedCoins;
const getWallet = (state: AppState) => state.wallet;
const getHistory = (state: AppState) => state.wallet.history;
const getHistoryLoading = (state: AppState) => state.wallet.historyLoading;
const getWalletData = (state: AppState) => state.wallet.walletData;
const getWalletDataLoading = (state: AppState) =>
  state.wallet.walletDataLoading;
const getFundsTotal = (state: AppState) => state.wallet.fundsTotal;
const getFundsTotalLoading = (state: AppState) =>
  state.wallet.fundsTotalLoading;
const getTransactionsPager = (state: AppState) =>
  state.wallet.transactionsPager;
const getHistoryPager = (state: AppState) => state.wallet.historyPager;
const putCancelStakingLoading = (state: AppState) =>
  state.wallet.cancelStakingLoading;
const cancelStakingState = (state: AppState) => state.wallet.cancelStakingState;
const selectOpenSearchTransactions = (state: AppState) =>
  state.wallet.openSearchTransactions;
const getTransactionTypes = (state: AppState) => state.wallet.transactionTypes;
const getTransactionDetails = (state: AppState) =>
  state.wallet.transactionDetails;
const getTransactionDetailsLoading = (state: AppState) =>
  state.wallet.transactionDetailsLoading;

export const getTransactionTypesSelector = createSelector(
  getTransactionTypes,
  (res) => res
);

export const getHistoryPagerSelector = createSelector(
  getHistoryPager,
  (res) => res
);
export const getTransactionsPagerSelector = createSelector(
  getTransactionsPager,
  (res) => res
);
export const getFundsTotalLoadingSelector = createSelector(
  getFundsTotalLoading,
  (res) => res
);
export const getFundsTotalSelector = createSelector(
  getFundsTotal,
  (res) => res
);
export const getWalletDataLoadingSelector = createSelector(
  getWalletDataLoading,
  (res) => res
);
export const getWalletDataSelector = createSelector(
  getWalletData,
  (res) => res
);
export const getLoadingSelector = createSelector(getLoading, (res) => res);
export const getHistorySelector = createSelector(getHistory, (res) => res);
export const getHistoryLoadingSelector = createSelector(
  getHistoryLoading,
  (res) => res
);
export const getErrorSelector = createSelector(getError, (res) => res);
export const getWhitelistedCoinsSelector = createSelector(
  getWhitelistedCoins,
  (res) => res
);
export const getWalletSelector = createSelector(getWallet, (res) => res);
export const putCancelStakingLoadingSelector = createSelector(
  putCancelStakingLoading,
  (res) => res
);
export const cancelStakingStateSelector = createSelector(
  cancelStakingState,
  (res) => res
);

export const getOpenSearchTransactions = createSelector(
  selectOpenSearchTransactions,
  (res) => res
);

export const getPreparedOrder = createSelector(purchaseOrder, (res) => res);
export const getPreparedOrderLoading = createSelector(
  purchaseOrderLoading,
  (res) => res
);

export const getEstimateRates = createSelector(estimateRates, (res) => res);
export const getEstimateRatesLoading = createSelector(
  estimateRatesLoading,
  (res) => res
);
export const getEstimateRatesError = createSelector(
  estimateRatesError,
  (res) => res
);

export const getTransactionDetailsSelector = createSelector(
  getTransactionDetails,
  (res) => res
);
export const getTransactionDetailsLoadingSelector = createSelector(
  getTransactionDetailsLoading,
  (res) => res
);
