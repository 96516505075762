export const shareOnFacebook = (url: string) => {
  window.location.href = `https://www.facebook.com/share.php?u=${url}`;
};

export const shareOnTwitter = (
  url: string,
  shareHardCodedText: string = "Check this out on"
) => {
  window.location.href = `https://twitter.com/intent/tweet?url=${url}&text=${
    shareHardCodedText + " @SeedOnVenture"
  }`;
};

export const shareOnLinkedIn = (url: string) => {
  window.location.href = `https://www.linkedin.com/sharing/share-offsite?url=${url}`;
};
