export const formatNumber = (number: number) => {
  return new Intl.NumberFormat().format(number);
};
export const formatStringNumber = (number: string) => {
  const formattedNumber = number.replace(/^[+-]?\d+/, (int) => {
    return int.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  });
  let returnNumber = "";
  let nonZeroCharacterFound = false;
  if (formattedNumber === "0") {
    return formattedNumber;
  }
  if (formattedNumber.indexOf(".") !== -1) {
    for (let index = formattedNumber.length - 1; index >= 0; index--) {
      if (formattedNumber[index] === "0" && nonZeroCharacterFound === false) {
        continue;
      } else {
        nonZeroCharacterFound = true;
        returnNumber = formattedNumber[index] + returnNumber;
      }
    }
    return returnNumber[returnNumber.length - 1] === "."
      ? returnNumber.substring(0, returnNumber.length - 1)
      : returnNumber;
  }
  return formattedNumber;
};
