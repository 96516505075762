import React, { useEffect } from "react";

// Utils
import "./style.less";
import "chart.js/auto";

// Components
import { Collapse, Spin } from "antd";
import CoinPanelHead from "./CoinPanelHead";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getLoadingSelector,
  getWalletSelector,
  getWhitelistedCoinsSelector,
} from "../selectors";
import { getAvailableFundsRequest } from "../walletSlice";
import CoinPanelBody from "./CoinPanelBody";

const AssetsTab = () => {
  const dispatch = useDispatch();

  const whitelistedCoinsLoading = useSelector(getLoadingSelector);
  const whitelistedCoins = useSelector(getWhitelistedCoinsSelector);
  const wallet = useSelector(getWalletSelector);

  useEffect(() => {
    if (whitelistedCoins.length > 0) {
      whitelistedCoins.map((whitelistedCoin) => {
        dispatch(
          getAvailableFundsRequest({
            contractAddress: whitelistedCoin.contractAddress,
          })
        );
      });
    }
  }, [whitelistedCoins]);

  if (whitelistedCoinsLoading) {
    return (
      <div className="loading inline">
        <Spin />
      </div>
    );
  }

  return (
    <Collapse accordion expandIconPosition="end" destroyInactivePanel>
      {whitelistedCoins.map((coin, index) => (
        <Collapse.Panel
          key={index}
          header={
            <CoinPanelHead
              coin={coin}
              availableFunds={wallet[`${coin.contractAddress}Funds`]}
            />
          }
        >
          {wallet[`${coin.contractAddress}FundsLoading`] !== false ? (
            <div className="loading inline">
              <Spin />
            </div>
          ) : (
            <CoinPanelBody
              coin={coin}
              availableFunds={wallet[`${coin.contractAddress}Funds`]}
            />
          )}
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

export default AssetsTab;
