import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaTelegramPlane,
} from "react-icons/fa";

export const menu = [
  {
    title: "About",
    isScroll: true,
    links: [
      {
        title: "About us",
        href: "about",
      },
      {
        title: "Benefits",
        href: "benefits",
      },
      // {
      //   title: "Coming Soon",
      //   href: "coming-soon",
      // },
      {
        title: "How?",
        href: "create-account",
      },
    ],
  },
  {
    title: "Support",
    isScroll: false,
    links: [
      {
        title: "Privacy Policy",
        href: "https://seedon.io/privacy-policy/",
        isExternal: true,
      },
      {
        title: "Cookie Policy",
        href: "https://seedon.io/cookie-policy/",
        isExternal: true,
      },
      {
        title: "Terms & Conditions",
        href: "https://seedon.io/terms-and-conditions/",
        isExternal: true,
      },
    ],
  },
  {
    title: "More",
    isScroll: false,
    links: [
      {
        title: "Staking",
        href: "/stake",
      },
      {
        title: "Referral",
        href: "/referral",
      },
      {
        id: "news",
        title: "News",
        href: "/news",
      },
      {
        id: "events",
        title: "Events",
        href: "/events",
      },
      {
        title: "Team",
        href: "https://seedon.io/#team",
        isExternal: true,
      },
      {
        title: "Contact",
        href: "https://seedon.io/contact",
        isExternal: true,
      },
      {
        title: "Release Notes",
        href: "release-notes",
      },
    ],
  },
];

export const socials = [
  {
    icon: <FaFacebookF />,
    title: "Facebook",
    href: "https://www.facebook.com/seedonventure",
  },
  {
    icon: <FaTwitter />,
    title: "Twitter",
    href: "https://twitter.com/seedonventure",
  },
  {
    icon: <FaLinkedinIn />,
    title: "Linkedin",
    href: "https://www.linkedin.com/company/seedon/",
  },
  {
    icon: <FaTelegramPlane />,
    title: "Telegram",
    href: "https://t.me/SeedOnVenture",
  },
];
