import React, { useEffect, useState } from "react";
import "./style.less";
import { VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";

// Selectors
import { getCurrentUserSelector } from "./selectors";
import { getCanSendEmailCode } from "../CodeVerification/selectors";

// Components
import { Button } from "../index";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getCanSendEmailCodeRequest,
  resetCanSendCodeState,
} from "../CodeVerification/codeVerificationSlice";
import OtpModal from "../OtpModal";
import { generateRecoveryOtpRequest } from "../SecretRecoveryModal/secretRecoverySlice";
import SecretRecoveryReadOnlyModal from "../SecretRecoveryModal/SecretRecoveryReadOnlyModal";

const ViewRecoveryPhrase = () => {
  const dispatch = useDispatch();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showRecoveryPhraseModal, setShowRecoveryPhraseModal] = useState(false);
  const [secretRecoveryPhraseArray, setSecretRecoveryPhraseArray] =
    useState(null);
  const canSendEmailCode = useSelector(getCanSendEmailCode);

  const user = useSelector(getCurrentUserSelector);

  const checkIfUserCanReceiveOtp = () => {
    if (user?.state?.properties?.isGoogleTfaSetup) {
      setShowOtpModal(true);
    } else {
      dispatch(
        getCanSendEmailCodeRequest({
          type: "EMAIL",
          userIsNotAuthenticated: false,
        })
      );
    }
  };

  useEffect(() => {
    if (canSendEmailCode) {
      dispatch(resetCanSendCodeState());
      dispatch(generateRecoveryOtpRequest());
      setShowOtpModal(true);
    }
  }, [canSendEmailCode]);

  const otpConfirmed = (res) => {
    setSecretRecoveryPhraseArray(res.phrase);
    setShowRecoveryPhraseModal(true);
    setShowOtpModal(false);
  };
  useEffect(() => {
    return () => {
      setShowOtpModal(false);
      setShowRecoveryPhraseModal(false);
      setSecretRecoveryPhraseArray(null);
    };
  }, []);
  return (
    <div id={"ChangeOtpSettings"}>
      <Button
        label="View Recovery Phrase"
        onClick={() => checkIfUserCanReceiveOtp()}
      />

      {canSendEmailCode === false ? (
        <span className="generalError">
          {VALIDATION_ERROR_MESSAGES.tooManyEmailOtp}
        </span>
      ) : null}

      {showOtpModal ? (
        <OtpModal
          isVisible={showOtpModal}
          additionalPayload={{}}
          recipient={user.email}
          onSuccess={(res) => otpConfirmed(res)}
          endpoint={"/wallet/recovery-phrase/generate"}
          type={`${
            user?.state?.properties?.isGoogleTfaSetup ? "MFA" : "email"
          }`}
        />
      ) : (
        ""
      )}

      {showRecoveryPhraseModal ? (
        <SecretRecoveryReadOnlyModal
          formData={secretRecoveryPhraseArray}
          isVisible={showRecoveryPhraseModal}
          closeModal={() => setShowRecoveryPhraseModal(false)}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ViewRecoveryPhrase;
