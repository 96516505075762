import React, { useEffect, useState } from "react";
import "./style.less";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";
import { ISuccessModal, SuccessModalTypes } from "./interface";
import seonCoin from "../../assets/images/seon_coin.png";
import { Button } from "../index";
import { useNavigate } from "react-router-dom";
import lottie, { AnimationItem } from "lottie-web";
import SwapCircle from "../../assets/animations/SwapCircle.json";
import BNBAnimation from "../../assets/animations/BNB.json";
import SwapCheckmark from "../../assets/animations/SwapCheckmark.json";
import { ReversedAnimations, SwapAnimations } from "./data";

const StakingModal = (props: ISuccessModal) => {
  const navigate = useNavigate();
  const [swapFromTokenAnimation, setSwapFromTokenAnimation] =
    useState<AnimationItem>();
  const [swapToTokenAnimation, setSwapToTokenAnimation] =
    useState<AnimationItem>();
  const [swapCircleAnimation, setSwapCircleAnimation] =
    useState<AnimationItem>();
  const [swapCheckmarkAnimation, setSwapCheckmarkAnimation] =
    useState<AnimationItem>();
  const [swapFromTokenAnimationVisible, setSwapFromTokenAnimationVisible] =
    useState(true);
  const [swapCheckmarkAnimationVisible, setSwapCheckmarkAnimationVisible] =
    useState(false);
  const [animationsReady, setAnimationsReady] = useState(false);

  const setAnimation = (
    containerId: string,
    animationObject: any,
    setAnimation: (value: AnimationItem) => void
  ) => {
    const newAnimation = lottie.loadAnimation({
      container: document.getElementById(containerId),
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationObject,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
      },
    });
    setAnimation(newAnimation);
  };

  const startAnimation = () => {
    swapCircleAnimation.play();
    swapFromTokenAnimation.play();
    if (!ReversedAnimations.includes(props.toTokenName)) {
      swapToTokenAnimation.setSpeed(100);
      swapToTokenAnimation.play();
    }
  };

  const continueAnimation = () => {
    swapCircleAnimation.play();
    if (!ReversedAnimations.includes(props.toTokenName)) {
      swapToTokenAnimation.setDirection(-1);
      swapToTokenAnimation.setSpeed(1);
    }
    swapToTokenAnimation.play();
  };
  const redirectExternal = () => {
    localStorage.setItem("intended", window.location.pathname);
    window.location.href = props.ctaRedirect;
  };
  useEffect(() => {
    if (props.modalType === SuccessModalTypes.SWAP && props.isVisible) {
      if (!swapCheckmarkAnimation) {
        setAnimation(
          "swap-checkmark-animation",
          SwapCheckmark,
          setSwapCheckmarkAnimation
        );
      }
      if (!swapCircleAnimation) {
        setAnimation(
          "swap-circle-animation",
          SwapCircle,
          setSwapCircleAnimation
        );
      }
      if (!swapFromTokenAnimation) {
        setAnimation(
          "swap-from-token-animation",
          SwapAnimations[props.fromTokenName] || BNBAnimation,
          setSwapFromTokenAnimation
        );
      }
      if (!swapToTokenAnimation) {
        setAnimation(
          "swap-to-token-animation",
          SwapAnimations[props.toTokenName] || BNBAnimation,
          setSwapToTokenAnimation
        );
      }
    }
  }, [props.isVisible]);

  useEffect(() => {
    if (swapFromTokenAnimation) {
      if (ReversedAnimations.includes(props.fromTokenName)) {
        swapFromTokenAnimation.setSpeed(100);
        swapFromTokenAnimation.play();
        swapFromTokenAnimation.addEventListener("complete", () => {
          setAnimationsReady(true);
          swapFromTokenAnimation.setDirection(-1);
          swapFromTokenAnimation.setSpeed(1);
        });
      } else {
        setAnimationsReady(true);
      }
    }
  }, [swapFromTokenAnimation]);

  useEffect(() => {
    if (
      swapCheckmarkAnimation &&
      swapCircleAnimation &&
      swapToTokenAnimation &&
      swapFromTokenAnimation &&
      animationsReady
    ) {
      startAnimation();
      swapFromTokenAnimation.addEventListener("complete", () => {
        setSwapFromTokenAnimationVisible(false);
        continueAnimation();
      });
    }
  }, [
    swapCheckmarkAnimation,
    swapCircleAnimation,
    swapToTokenAnimation,
    swapFromTokenAnimation,
    animationsReady,
  ]);

  useEffect(() => {
    if (!swapFromTokenAnimationVisible) {
      swapToTokenAnimation.addEventListener("complete", () => {
        setSwapCheckmarkAnimationVisible(true);
        swapCheckmarkAnimation.play();
      });
    }
  }, [swapFromTokenAnimationVisible]);

  return (
    <>
      <Modal
        closeIcon={<IoClose />}
        open={props.isVisible}
        className="authModals walletModals"
        maskClosable={false}
        footer={null}
        closable={!!props.onCancel}
        onCancel={props.onCancel}
        forceRender
      >
        <div className="heading">
          {props.modalType === SuccessModalTypes.STAKE ? (
            <>
              <img src={seonCoin} alt="deposit-token" />
              <h2>{props.title}</h2>
            </>
          ) : (
            <>
              <h2>{props.title}</h2>
              <div className="swap-animation-container">
                <div
                  id="swap-circle-animation"
                  className="animation-container"
                />
                <div
                  id="swap-from-token-animation"
                  className={
                    swapFromTokenAnimationVisible
                      ? "animation-container swap-token-animation-visible"
                      : "animation-container"
                  }
                />
                <div
                  id="swap-to-token-animation"
                  className={`animation-container ${
                    !swapFromTokenAnimationVisible
                      ? "swap-token-animation-visible"
                      : ""
                  } ${
                    swapCheckmarkAnimationVisible
                      ? "swap-token-animation-disappear"
                      : ""
                  }`}
                />
                <div
                  id="swap-checkmark-animation"
                  className={
                    swapCheckmarkAnimationVisible
                      ? "animation-container swap-token-animation-visible"
                      : "animation-container"
                  }
                />
              </div>
            </>
          )}
          {props.subtitle ? <p>{props.subtitle}</p> : ""}
          <p>{props.description}</p>
        </div>

        <Button
          label={props.ctaLabel}
          extraClasses="submitBtn"
          onClick={() =>
            props.isExternalRedirect
              ? redirectExternal()
              : navigate(props.ctaRedirect)
          }
        />
      </Modal>
    </>
  );
};

export default StakingModal;
