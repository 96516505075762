import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const postLoading = (state: AppState) => state.codeVerification.loading;
const postError = (state: AppState) => state.codeVerification.error;
const selectCodeVerificationResponse = (state: AppState) =>
  state.codeVerification.codeVerificationResponse;
const selectCodeVerificationSuccess = (state: AppState) =>
  state.codeVerification.codeVerificationSuccess;

const selectCanSendCodeLoading = (state: AppState) =>
  state.codeVerification.canSendCodeLoading;
const selectCanSendCode = (state: AppState) =>
  state.codeVerification.canSendCode;

const selectCanSendEmailCodeLoading = (state: AppState) =>
  state.codeVerification.canSendEmailCodeLoading;
const selectCanSendEmailCode = (state: AppState) =>
  state.codeVerification.canSendEmailCode;

const selectCanSendSmsCodeLoading = (state: AppState) =>
  state.codeVerification.canSendSmsCodeLoading;
const selectCanSendSmsCode = (state: AppState) =>
  state.codeVerification.canSendSmsCode;
const selectToggleOtpLoading = (state: AppState) =>
  state.codeVerification.toggleOtpLoading;

export const getCanSendEmailCodeLoading = createSelector(
  selectCanSendEmailCodeLoading,
  (res) => res
);
export const getCanSendEmailCode = createSelector(
  selectCanSendEmailCode,
  (res) => res
);

export const getCanSendSmsCodeLoading = createSelector(
  selectCanSendSmsCodeLoading,
  (res) => res
);
export const getCanSendSmsCode = createSelector(
  selectCanSendSmsCode,
  (res) => res
);

export const getCanSendCodeLoading = createSelector(
  selectCanSendCodeLoading,
  (res) => res
);
export const getCanSendCode = createSelector(selectCanSendCode, (res) => res);
export const getCodeVerificationResponse = createSelector(
  selectCodeVerificationResponse,
  (res) => res
);
export const postLoadingSelector = createSelector(
  postLoading,
  (pending) => pending
);
export const getErrorSelector = createSelector(postError, (error) => error);
export const getCodeVerificationSuccess = createSelector(
  selectCodeVerificationSuccess,
  (res) => res
);
export const getToggleOtpLoading = createSelector(
  selectToggleOtpLoading,
  (res) => res
);
