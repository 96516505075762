import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  postResetPasswordFailure,
  postResetPasswordRequest,
  postResetPasswordSuccess,
} from "./resetPasswordSlice";

import { axios } from "../../services/axios.service";

/*
  Worker Saga: Fired on postResetPasswordRequest action
*/
function* postResetPasswordSaga(
  action: ReturnType<typeof postResetPasswordRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/reset-password`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(postResetPasswordSuccess());
  } catch (e: any) {
    yield put(
      postResetPasswordFailure({
        error: e,
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `postResetPasswordRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* loginSaga() {
  yield all([takeLatest(postResetPasswordRequest, postResetPasswordSaga)]);
}

export default loginSaga;
