import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ICampaignInteractionParams,
  IFavoriteCampaignsParams,
  WatchlistCampaignsState,
} from "./types";

const initialState: WatchlistCampaignsState = {
  loading: false,
  error: null,
  watchlistCampaigns: null,
  watchlistCampaignsLoading: true,
  campaignInteractionLoading: false,
  campaignInteractionSuccess: false,
};

const watchlistCampaignsSlice = createSlice({
  name: "watchlistCampaigns",
  initialState,
  reducers: {
    getWatchlistCampaignsRequest(
      state,
      action: PayloadAction<IFavoriteCampaignsParams>
    ) {
      return {
        ...state,
        params: action.payload,
        watchlistCampaigns: null,
        watchlistCampaignsLoading: true,
      };
    },
    getWatchlistCampaignsSuccess(state, action) {
      return {
        ...state,
        watchlistCampaigns: action.payload,
        watchlistCampaignsLoading: false,
      };
    },
    getWatchlistCampaignsFailure(state) {
      return {
        ...state,
        watchlistCampaigns: null,
        watchlistCampaignsLoading: false,
      };
    },
    campaignInteractionRequest(
      state,
      action: PayloadAction<ICampaignInteractionParams>
    ) {
      return {
        ...state,
        params: action.payload,
        campaignInteractionLoading: true,
        campaignInteractionSuccess: false,
      };
    },
    campaignInteractionSuccess(state) {
      return {
        ...state,
        campaignInteractionLoading: false,
        campaignInteractionSuccess: true,
      };
    },
    campaignInteractionFailure(state) {
      return {
        ...state,
        campaignInteractionLoading: false,
        campaignInteractionSuccess: false,
      };
    },
    resetCampaignInteractionState(state) {
      return {
        ...state,
        campaignInteractionSuccess: false,
        campaignInteractionLoading: false,
      };
    },
  },
});

export const {
  getWatchlistCampaignsRequest,
  getWatchlistCampaignsSuccess,
  getWatchlistCampaignsFailure,
  campaignInteractionFailure,
  campaignInteractionRequest,
  campaignInteractionSuccess,
  resetCampaignInteractionState,
} = watchlistCampaignsSlice.actions;

export default watchlistCampaignsSlice.reducer;
