export const notes = [
  {
    no: "1.8.7-RC16",
    date: "27.01.2023",
    features: [
      "🆕 Launchpad teaser page added.",
      "🆕 Redesigned & improved application layout.",
      "🆕 Token details page added.",
    ],
    fixes: [],
  },
  {
    no: "1.8.6-RC15",
    date: "20.01.2023",
    features: ["🆕 Application improvements."],
    fixes: [],
  },
  {
    no: "1.8.5-RC14",
    date: "24.12.2022",
    features: ["🆕 Wallet section redesign."],
    fixes: [],
  },
  {
    no: "1.8.4-RC13",
    date: "05.12.2022",
    features: ["🆕 Application improvements."],
    fixes: [],
  },
  {
    no: "1.8.3-RC12",
    date: "05.11.2022",
    features: [
      "🆕 Public Staking page has been added.",
      "🆕 Public Referral Program page has been added.",
    ],
    fixes: [],
  },
  {
    no: "1.8.2-RC11",
    date: "31.10.2022",
    features: ["🆕 Fixes and improvements."],
    fixes: [],
  },
  {
    no: "1.8.1-RC10",
    date: "22.10.2022",
    features: ["🆕 Fixes and improvements."],
    fixes: [],
  },
  {
    no: "1.8.0-RC9",
    date: "15.10.2022",
    features: [
      "🆕 Flexible staking functionality.",
      "🆕 Cancelable staking functionality.",
      "🆕 Referral program.",
    ],
    fixes: [],
  },
  {
    no: "1.7.0-RC8",
    date: "21.09.2022",
    features: ["🆕 Price impact functionality."],
    fixes: [],
  },
  {
    no: "1.6.0-RC7",
    date: "07.09.2022",
    features: [
      "🆕 Swap functionality speed improvement.",
      "🆕 Added possibility to opt for receiving otp codes via multi factor authentication instead of email.",
      "🆕 Facebook login.",
    ],
    fixes: [],
  },
  {
    no: "1.5.0-RC6",
    date: "19.08.2022",
    features: ["🆕 Swap functionality has been added."],
    fixes: [
      "🐞 Fixed bug on staking page where users could not input the max amount of tokens.",
    ],
  },
  {
    no: "1.4.0-RC5",
    date: "25.07.2022",
    features: [
      "🆕 A new module for new features announcements has been added.",
      "🆕 Crypto news section.",
      "🆕 Notifications module.",
      "🆕 Crypto events section.",
      "🆕 Multiple currencies support.",
      "🆕 Staking statistics data.",
      "🆕 Social Login.",
    ],
    fixes: [],
  },
  {
    no: "1.3.0-RC4",
    date: "24.05.2022",
    features: [
      "🆕 The sum of locked funds is now displayed in wallet for every token.",
      "🆕 Currently selected blockchain network is now displayed on the wallet page.",
      "🆕 Added Possibility to copy the recovery phrase.",
    ],
    fixes: [],
  },
  {
    no: "1.2.0-RC3",
    date: "18.05.2022",
    features: [
      "🆕 UI improvements.",
      "🆕 Added possibility for users to toggle between Telegram and SMS even when the phone number is not confirmed.",
      "🆕 Added possibility for users to view the secret recovery phrase.",
    ],
    fixes: [
      "🐞 Fixed bug where the password structure validation message was not descriptive enough.",
    ],
  },
  {
    no: "1.1.0-RC2",
    date: "14.05.2022",
    features: ["🆕 UI improvements."],
    fixes: [
      "🐞 Fixed bug where Vesting release date was displayed as today if there was one more day left.",
      "🐞 Fixed bug where for some users deposit transactions would be displayed as failed first.",
    ],
  },
  {
    no: "1.0.0-RC1",
    date: "11.05.2022",
    features: [
      "🆕 Sms Otp now supports telegram toggle.",
      "🆕 Users have now the possibility to update their phone number.",
      "🆕 Current app version can now be found in the footer section.",
    ],
    fixes: [
      "🐞 Fixed scroll on different application sections.",
      "🐞 Fixed homepage menu to have the navigation buttons more visible.",
      "🛠 Validation errors on withdraw functionality are more specific.",
    ],
  },
];
