import { Input } from "antd";

import { IFormInputProps } from "./interface";
import { useState } from "react";
import "./style.less";

const FormInput = (props: IFormInputProps) => {
  const { label, value, hidelabel, icon } = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="form-item">
      {hidelabel !== "true" && (
        <div className={`${isFocused || value ? "label" : "placeholder"} `}>
          {label}
        </div>
      )}

      <div className="input-wrapper">
        <Input
          autoComplete={"off"}
          style={{
            borderRightWidth: "2px !important",
          }}
          maxLength={props.maxLength}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          className="input-with-placeholder"
          disabled={props.disabled ? props.disabled : false}
          prefix={props.prefix}
          suffix={props.suffix}
          onChange={props.onChange}
          onPaste={(e) => {
            if (props.disablepaste === "true") {
              e.preventDefault();
            }
            return !(props.disablepaste === "true");
          }}
          min={props.min}
          {...props}
        />
        {icon && <div className="cardTypeIcon">{icon}</div>}
      </div>
    </div>
  );
};
export default FormInput;
