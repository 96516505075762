import React, { useEffect, useState } from "react";
import { WalletLayout } from "../index";
import { useDispatch, useSelector } from "react-redux";
import { getStakingPoolsRequest } from "../Staking/stakingSlice";
import FormDropdownSelect from "../DropdownSelect";
import { Form } from "antd";
import { CURRENCIES, VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";
import {
  getLoadingSelector,
  getWalletDataSelector,
  getWalletSelector,
  getWhitelistedCoinsSelector,
} from "../Wallet/selectors";
import { getStakingPoolsSelector } from "../Staking/selectors";
import {
  getStakingStatisticsRequest,
  resetStakingStatisticsState,
} from "./stakingStatisticsSlice";
import {
  getAvailableFundsRequest,
  getWalletDataRequest,
} from "../Wallet/walletSlice";
import StakingChart from "./StakingChart";
import {
  getStakingStatisticsLoadingSelector,
  getStakingStatisticsSelector,
} from "./selectors";
import { formatNumber } from "../../_helpers/numberFormatter";
import {
  getChangeCurrencySuccessSelector,
  getCurrentUserSelector,
} from "../User/selectors";
import Loading from "../Loading";
import moment from "moment";

const StakingStatistics = () => {
  const dispatch = useDispatch();
  const [formRef] = Form.useForm();

  const [selectedToken, setSelectedToken] = useState(null);
  const [selectedStakingPool, setSelectedStakingPool] = useState(null);
  const [formattedStakingPools, setFormattedStakingPools] = useState({});

  const whitelistedCoins = useSelector(getWhitelistedCoinsSelector);
  const stakingPools = useSelector(getStakingPoolsSelector);
  const walletData = useSelector(getWalletDataSelector);
  const stakingStatistics = useSelector(getStakingStatisticsSelector);
  const stakingStatisticsLoading = useSelector(
    getStakingStatisticsLoadingSelector
  );
  const wallet = useSelector(getWalletSelector);
  const user = useSelector(getCurrentUserSelector);
  const changeCurrencySuccess = useSelector(getChangeCurrencySuccessSelector);
  const whitelistedCoinsLoading = useSelector(getLoadingSelector);

  const getStatistics = () => {
    if (selectedToken && walletData) {
      dispatch(
        getAvailableFundsRequest({
          contractAddress: selectedToken.contractAddress,
        })
      );
      dispatch(
        getStakingStatisticsRequest({
          pool: selectedStakingPool.id,
          wallet: walletData.id,
          contract: selectedToken.contractAddress,
        })
      );
    }
  };
  useEffect(() => {
    dispatch(getStakingPoolsRequest());
    dispatch(getWalletDataRequest());
    return () => {
      dispatch(resetStakingStatisticsState());
    };
  }, []);

  useEffect(() => {
    if (stakingPools) {
      let newStakingPoolsCollection = {};
      const keys = Object.keys(stakingPools);
      keys.map((key) => {
        newStakingPoolsCollection[key] = stakingPools[key].map((item) => {
          return {
            ...item,
            displayKey: `${item.apy}% For ${item.durationInDays} Days`,
          };
        });
      });
      setFormattedStakingPools(newStakingPoolsCollection);
    }
  }, [stakingPools]);
  useEffect(() => {
    if (selectedToken) {
      formRef.setFieldsValue({
        pool: null,
      });
      setSelectedStakingPool(null);
      dispatch(resetStakingStatisticsState());
    }
  }, [selectedToken]);
  useEffect(() => {
    if (selectedStakingPool) {
      getStatistics();
    }
  }, [selectedStakingPool]);
  useEffect(() => {
    if (changeCurrencySuccess) {
      getStatistics();
    }
  }, [changeCurrencySuccess]);
  return (
    <div className="stakeStatistics">
      <Loading loading={stakingStatisticsLoading || whitelistedCoinsLoading}>
        <div className="filters">
          <Form form={formRef}>
            <Form.Item
              hasFeedback
              name="token"
              rules={[
                {
                  required: true,
                  message: VALIDATION_ERROR_MESSAGES.required,
                },
              ]}
            >
              <FormDropdownSelect
                label="Token"
                onChange={(val) =>
                  (selectedToken && selectedToken.id !== val.id) ||
                  selectedToken === null
                    ? setSelectedToken(val)
                    : null
                }
                items={whitelistedCoins}
                keyToShow={"tokenSymbol"}
                keyForImage={"iconUrl"}
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              name="pool"
              rules={[
                {
                  required: true,
                  message: VALIDATION_ERROR_MESSAGES.required,
                },
              ]}
            >
              <FormDropdownSelect
                label="Staking Pool"
                onChange={(val) => setSelectedStakingPool(val)}
                items={
                  selectedToken &&
                  formattedStakingPools[selectedToken.contractAddress]
                    ? formattedStakingPools[selectedToken.contractAddress]
                    : []
                }
                keyToShow={"displayKey"}
              />
            </Form.Item>
          </Form>
        </div>

        {stakingStatistics ? (
          <>
            <div className="stats">
              <div className="stat">
                <p>Your {selectedToken.tokenSymbol} staked</p>
                <h3>
                  {formatNumber(
                    stakingStatistics.investorStakingData.totalTokensStaked
                  )}
                </h3>
              </div>

              <div className="stat">
                <p>Your {selectedToken.tokenSymbol} staking rewards</p>
                <h3>
                  {formatNumber(
                    stakingStatistics.investorStakingData.totalStakingRewards
                  )}
                </h3>
              </div>

              <div className="stat">
                <p>Total {selectedToken.tokenSymbol} staked</p>
                <h3>
                  {formatNumber(
                    stakingStatistics.stakingPoolData.totalTokensStaked
                  )}
                </h3>
              </div>

              <div className="stat">
                <p>Total {selectedToken.tokenSymbol} staking rewards</p>
                <h3>
                  {" "}
                  {formatNumber(
                    stakingStatistics.stakingPoolData.totalStakingRewards
                  )}
                </h3>
              </div>
              <div className="stat">
                <p>Staking rewards left</p>
                <h3>
                  {formatNumber(stakingStatistics.stakingPoolData.rewardsLeft)}
                </h3>
              </div>
              <div className="stat">
                <p>{selectedToken.tokenSymbol} Price</p>
                <h3>
                  {user
                    ? CURRENCIES[user.state.properties.selectedCurrency]
                    : ""}{" "}
                  {wallet &&
                  wallet[`${selectedToken.contractAddress}Funds`] &&
                  wallet[`${selectedToken.contractAddress}Funds`].tokenData
                    ? wallet[`${selectedToken.contractAddress}Funds`].tokenData
                        .currentPrice
                    : ""}
                </h3>
              </div>
            </div>
            <StakingChart
              data={stakingStatistics}
              selectedToken={selectedToken}
            />
          </>
        ) : (
          ""
        )}
      </Loading>
    </div>
  );
};

export default StakingStatistics;
