import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  generateRecoveryOtpRequest,
  postGenerateSecretRecoveryFailure,
  postGenerateSecretRecoveryRequest,
  postGenerateSecretRecoverySuccess,
  validateSecretRecoveryFailure,
  validateSecretRecoveryRequest,
  validateSecretRecoverySuccess,
} from "./secretRecoverySlice";

import { axios } from "../../services/axios.service";
import { AxiosResponse } from "axios";
import { IPostGenerateSecretRecoveryResponse } from "./types";

/*
  Worker Saga: Fired on postGenerateSecretRecoveryRequest action
*/
function* postGenerateSecretRecoverySaga(
  action: ReturnType<typeof postGenerateSecretRecoveryRequest>
) {
  try {
    const response: AxiosResponse<IPostGenerateSecretRecoveryResponse> =
      yield call(axios.put, `/wallet/recovery-phrase/generate`, action.payload);
    yield put(postGenerateSecretRecoverySuccess(response.data));
  } catch (e: any) {
    yield put(
      postGenerateSecretRecoveryFailure({
        error: e,
      })
    );
  }
}

function* validateSecretRecoverySaga(
  action: ReturnType<typeof validateSecretRecoveryRequest>
) {
  try {
    const response: AxiosResponse<IPostGenerateSecretRecoveryResponse> =
      yield call(axios.put, `/wallet/recovery-phrase/validate`, action.payload);
    yield put(validateSecretRecoverySuccess(response.data));
  } catch (e: any) {
    yield put(
      validateSecretRecoveryFailure({
        error: e,
      })
    );
  }
}
function* generateRecoveryOtpSaga() {
  try {
    yield call(axios.put, `/wallet/recovery-phrase/otp`);
  } catch (e: any) {}
}

/*
  Starts worker saga on latest dispatched `postGenerateSecretRecoveryRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* loginSaga() {
  yield all([
    takeLatest(
      postGenerateSecretRecoveryRequest,
      postGenerateSecretRecoverySaga
    ),
    takeLatest(validateSecretRecoveryRequest, validateSecretRecoverySaga),
    takeLatest(generateRecoveryOtpRequest, generateRecoveryOtpSaga),
  ]);
}

export default loginSaga;
