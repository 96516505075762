import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const selectSecretRecoveryGenerationLoading = (state: AppState) =>
  state.secretRecovery.secretRecoveryGenerationLoading;
const selectGeneratedRecoveryPhrase = (state: AppState) =>
  state.secretRecovery.generatedRecoveryPhrase;
const selectValidateRecoveryPhraseError = (state: AppState) =>
  state.secretRecovery.validateRecoveryPhraseError;
const selectValidateRecoveryPhraseSuccess = (state: AppState) =>
  state.secretRecovery.validateRecoveryPhraseSuccess;

export const getSecretRecoveryGenerationLoading = createSelector(
  selectSecretRecoveryGenerationLoading,
  (res) => res
);
export const getGeneratedRecoveryPhrase = createSelector(
  selectGeneratedRecoveryPhrase,
  (res) => res
);
export const getValidateRecoveryPhraseError = createSelector(
  selectValidateRecoveryPhraseError,
  (res) => res
);
export const getValidateRecoveryPhraseSuccess = createSelector(
  selectValidateRecoveryPhraseSuccess,
  (res) => res
);
