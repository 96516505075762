const StakeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23.549"
    height="23.543"
    viewBox="0 0 23.549 23.543"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2495"
          data-name="Rectangle 2495"
          width="23.549"
          height="23.543"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_4813" data-name="Group 4813" transform="translate(0 0)">
      <g
        id="Group_4812"
        data-name="Group 4812"
        transform="translate(0 0)"
        clipPath="url(#clip-path)"
      >
        <path
          id="Path_11203"
          data-name="Path 11203"
          d="M20.751,17.222a7.552,7.552,0,0,1-1.672,5.457c-.178.22-.368.431-.553.646a2.1,2.1,0,0,0-.5,1.4c0,.306,0,.613,0,.92a1.174,1.174,0,0,1-1.214,1.222q-1.2.011-2.391,0A1.176,1.176,0,0,1,13.2,25.67c0-.052,0-.1,0-.171H9c-.012.014-.021.019-.021.026-.1,1-.47,1.34-1.475,1.34-.7,0-1.4,0-2.093,0A1.183,1.183,0,0,1,4.141,25.6c0-.475-.006-.95,0-1.425a.837.837,0,0,0-.271-.657,7.93,7.93,0,0,1-1.725-2.3.6.6,0,0,0-.186-.194c-.342-.247-.687-.487-1.031-.731A2.092,2.092,0,0,1,0,18.489c0-.636-.005-1.272,0-1.908a2.062,2.062,0,0,1,2-2.121.361.361,0,0,0,.315-.2c.325-.482.659-.957,1.01-1.419a.43.43,0,0,0,.093-.413C3.208,11.567,3,10.7,2.8,9.84a.682.682,0,0,1,.708-.9c.842.006,1.687-.011,2.525.053a2.9,2.9,0,0,1,1.894.942,1.18,1.18,0,0,0,.951.394c1.418-.019,2.835-.013,4.253-.011A7.6,7.6,0,0,1,20.4,15.561a.327.327,0,0,0,.353.268,1.4,1.4,0,0,0,1.405-1.4c.006-.214,0-.429,0-.644a.689.689,0,1,1,1.378-.007,3.945,3.945,0,0,1-.2,1.7A2.734,2.734,0,0,1,20.984,17.2c-.068.008-.136.013-.233.021M4.327,10.307c.182.75.345,1.473.538,2.189a.926.926,0,0,1-.271,1.017,4.4,4.4,0,0,0-1.105,1.51,1.154,1.154,0,0,1-1.325.819c-.5-.048-.78.262-.782.769,0,.613.008,1.226,0,1.838a.828.828,0,0,0,.395.752c.394.266.785.536,1.161.825a1.265,1.265,0,0,1,.369.425,6.932,6.932,0,0,0,1.461,2.014,2.1,2.1,0,0,1,.681,1.094,6.017,6.017,0,0,1,.072.935c.011.325,0,.652,0,.971H7.589c0-.685,0-1.352,0-2.018a.69.69,0,1,1,1.378,0c0,.212,0,.424,0,.637H13.2c0-.207,0-.4,0-.588a.693.693,0,1,1,1.379-.01c0,.582,0,1.164,0,1.747v.233h2.069c0-.257-.01-.5,0-.744a5.93,5.93,0,0,1,.077-.775A4.014,4.014,0,0,1,17.8,22.04a6.047,6.047,0,0,0,1.516-5.1,6.185,6.185,0,0,0-5.9-5.237c-1.539-.034-3.08-.016-4.62,0a2.392,2.392,0,0,1-1.807-.746,2.551,2.551,0,0,0-.8-.505,5.743,5.743,0,0,0-1.858-.139"
          transform="translate(0 -3.325)"
          fill="#fff"
        />
        <path
          id="Path_11204"
          data-name="Path 11204"
          d="M21.046,2.764A2.759,2.759,0,1,1,18.288,0a2.766,2.766,0,0,1,2.758,2.764m-1.381-.011a1.377,1.377,0,1,0-1.372,1.382,1.39,1.39,0,0,0,1.372-1.382"
          transform="translate(-5.779 0)"
          fill="#fff"
        />
        <path
          id="Path_11205"
          data-name="Path 11205"
          d="M17.086,16.9c-.514,0-1.027,0-1.54,0a.692.692,0,1,1-.006-1.378q1.551,0,3.1,0a.691.691,0,1,1,.006,1.378c-.52,0-1.041,0-1.562,0"
          transform="translate(-5.504 -5.776)"
          fill="#fff"
        />
        <path
          id="Path_11206"
          data-name="Path 11206"
          d="M7.281,20.2a.688.688,0,1,1,.692-.679.695.695,0,0,1-.692.679"
          transform="translate(-2.455 -7.004)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default StakeIcon;
