import { all, call, put, takeLatest } from "redux-saga/effects";
import { axios } from "services/axios.service";
import {
  swapFailure,
  swapQuoteFailure,
  swapQuoteRequest,
  swapQuoteSuccess,
  swapRequest,
  swapSuccess,
} from "./swapSlice";
//
// /*
//   Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
//   Allows concurrent increments.
// */
function* swapQuoteSaga(action) {
  try {
    const response = yield call(
      axios.put,
      action.payload.public
        ? `${process.env.REACT_APP_API_URL}/public/swap/quote`
        : `${process.env.REACT_APP_API_URL}/swap/quote`,
      action.payload,
      {
        params: {
          currency: action.payload.currency,
        },
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(swapQuoteSuccess(response.data));
  } catch (e: any) {
    yield put(swapQuoteFailure(e.response.data.message));
  }
}
function* swapSaga(action) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_API_URL}/swap`,
      action.payload,
      {
        params: {
          currency: action.payload.currency,
        },
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(swapSuccess());
  } catch (e: any) {
    yield put(swapFailure());
  }
}

function* walletSaga() {
  yield all([
    takeLatest(swapQuoteRequest, swapQuoteSaga),
    takeLatest(swapRequest, swapSaga),
  ]);
}

export default walletSaga;
