import React from "react";
import { Modal, notification } from "antd";
import "./style.less";
import { IReadOnlyModalProps } from "./interface";
import WordsForm from "./WordsForm";
import { IoClose } from "react-icons/io5";
import copy from "copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import { Button } from "../index";
import { ButtonTypes } from "../Button/types";

const SecretRecoveryModal: React.FC<IReadOnlyModalProps> = (
  props: IReadOnlyModalProps
) => {
  const copyToClipboard = () => {
    let recoveryPhraseString = "";
    for (let key in props.formData) {
      recoveryPhraseString += `${key}-${props.formData[key]}`;
      if (Number.parseInt(key) !== Object.keys(props.formData).length) {
        recoveryPhraseString += ", \n";
      }
    }
    copy(recoveryPhraseString);
    copy(recoveryPhraseString);
    notification.success({
      message: "Recovery Phrase successfully copied to clipboard.",
    });
  };

  return (
    <Modal
      closeIcon={<IoClose />}
      footer={null}
      className="authModals"
      maskClosable={false}
      onCancel={() => props.closeModal()}
      open={props.isVisible}
      forceRender
    >
      <WordsForm
        action={"readOnly"}
        formData={props.formData}
        closeWordsForm={() => props.closeModal()}
      />

      <div className="heading copySecretRecovery">
        <Button
          type={ButtonTypes.SECONDARY}
          label="Copy Secret Recovery Phrase"
          extraClasses="goBackButton"
          onClick={() => copyToClipboard()}
          icon={<FiCopy />}
        />
      </div>
    </Modal>
  );
};

export default SecretRecoveryModal;
