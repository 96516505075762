import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetPoolDataRequest, InvestState, IPoolData } from "./type";

const initialState: InvestState = {
  poolData: null,
  poolDataLoading: false,
};

const investSlice = createSlice({
  name: "invest",
  initialState,
  reducers: {
    resetState: (state) => {
      return initialState;
    },
    getPoolDataRequest(state, action: PayloadAction<IGetPoolDataRequest>) {
      return {
        ...state,
        poolData: null,
        poolDataLoading: true,
        params: action.payload,
      };
    },
    getPoolDataSuccess(state, action: PayloadAction<IPoolData>) {
      return {
        ...state,
        poolDataLoading: false,
        poolData: action.payload,
      };
    },
    getPoolDataFailure(state) {
      return {
        ...state,
        poolData: null,
        poolDataLoading: false,
      };
    },
  },
});

export const {
  getPoolDataRequest,
  getPoolDataSuccess,
  getPoolDataFailure,
  resetState,
} = investSlice.actions;

export default investSlice.reducer;
