import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getReport = (state: AppState) => state.report.report;
const getReportLoading = (state: AppState) => state.report.reportLoading;
const getReportError = (state: AppState) => state.report.reportError;
const getReportDownload = (state: AppState) => state.report.reportDownload;

export const getReportDownloadSelector = createSelector(
  getReportDownload,
  (res) => res
);
export const getReportErrorSelector = createSelector(
  getReportError,
  (res) => res
);
export const getReportSelector = createSelector(getReport, (res) => res);
export const getReportLoadingSelector = createSelector(
  getReportLoading,
  (res) => res
);
