import { FiChevronDown } from "react-icons/fi";
import { IDropdownSelectProps } from "./interface.formDropdownSelect";
import React, { useEffect, useState } from "react";
import { Select } from "antd";

const DropdownSelect: React.FC<IDropdownSelectProps> = (
  props: IDropdownSelectProps
) => {
  const {
    label,
    items,
    keyToShow,
    keyForImage,
    disabled,
    onChange,
    value,
    withFilter,
    allowClear,
    onClear,
    dropdownRender,
    defaultValue,
    extraClasses,
  } = props;
  const [selectWithFilterProps, setSelectWithFilterProps] = useState(null);

  const renderValue = () => {
    if (value) {
      return keyToShow ? value[keyToShow] : value;
    }
  };

  const optionsFilterFunction = (input: string, option: any) => {
    return (
      JSON.parse(option.value)
        .name.toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  };

  useEffect(() => {
    if (withFilter) {
      setSelectWithFilterProps({
        showSearch: true,
        filterOption: optionsFilterFunction,
        autoComplete: "none",
      });
    }
  }, []);

  return (
    <Select
      onChange={(onChangeValue) =>
        onChange &&
        onChange(onChangeValue ? JSON.parse(onChangeValue) : onChangeValue)
      }
      disabled={disabled}
      placeholder={label}
      className="dropdown-select"
      suffixIcon={<FiChevronDown className="dropdown-button-icon" />}
      value={renderValue()}
      allowClear={allowClear}
      onClear={onClear}
      dropdownRender={dropdownRender}
      defaultValue={defaultValue}
      popupClassName={extraClasses}
      {...selectWithFilterProps}
    >
      {items.length > 0 &&
        items.map((item, index) => (
          <Select.Option key={index} value={JSON.stringify(item)}>
            {keyToShow ? item[keyToShow] : item}{" "}
            {keyForImage ? <img src={item[keyForImage]} /> : ""}
          </Select.Option>
        ))}
    </Select>
  );
};

export default DropdownSelect;
