import { formatNumber } from "./numberFormatter";

const API_URL = "https://dev-api.hungrybytes.co/api/v1";
export const API_URLS = {
  BASE_URL: API_URL,
  LOGIN: "/users/sign-in",
};

export const globalVariables = {
  desktopSize: 991,
  mobile: 480,
  portfolioPageSize: 10,
};

export const ROUTES = {
  resetPassword: "reset-password",
  homePage: "/",
  404: "/error/404",
  500: "/error",
  publicStake: "/stake",
  stakingStatistics: "/staking-statistics",
  vesting: "/vesting",
  launchpad: "/launchpad",
  news: "/news",
  favoriteNews: "/favorite-news",
  notifications: "/notifications",
  events: "/events",
  portfolio: "/portfolio",
  watchlist: "/watchlist",
  event: "/events/:id",
  article: "/article/:id",
  transactions: "/transactions",
  referral: "/referral",
  newsItem: "/news/:id",
  userProfile: `${process.env.REACT_APP_AUTH_APP_URL}/profile?source=finance`,
  cookiePolicy: "https://seedon.io/cookie-policy/",
  releaseNotes: "/release-notes",
  privacyPolicy: "https://seedon.io/privacy-policy/",
  campaignDetails: "/campaign/:id",
  campaignInvestment: "/campaign/invest/:id",
  tokenDetails: "/token-details/:chainId/:tokenSymbol",
  wallet: "/wallet",
  swap: "/wallet/swap",
  deposit: "/wallet/deposit",
  withdraw: "/wallet/withdraw",
  stake: "/wallet/stake",
  buy: "/buy",
  fiatDepositsCampaign: "/fiat-deposits-campaign",
  fiatDepositsCampaignTC: "/fiat-deposits-campaign/terms-and-conditions",
};

export const ROUTES_WITHOUT_WALLET = [
  ROUTES.events,
  ROUTES.article,
  ROUTES.news,
  ROUTES.event,
  ROUTES.homePage,
  ROUTES["404"],
  ROUTES["500"],
  ROUTES.referral,
  ROUTES.fiatDepositsCampaign,
  ROUTES.fiatDepositsCampaignTC,
];

export const VALIDATION_ERROR_MESSAGES = {
  required: "This field is required.",
  email: "Please enter a valid email.",
  passwordConfirmation: "The two passwords that you entered do not match.",
  maxLength: "Max length is 255 characters.",
  invalidPhoneNumber: "Phone number is invalid.",
  maxLength100: "This field cannot be longer than 100 characters.",
  passwordFormat:
    "Please input a valid password that has at least: one uppercase, one lowercase, one digit and a special symbol !@#$%^&*, and has between 10 and 100 characters.",
  invalidCredentials: "Invalid Credentials.",
  tooManyOtpAttempts: "Too many codes requested. Please try again later.",
  userBalanceExceeded: "Insufficient funds.",
  maximumRewardsPerUserExceeded: "Maximum reward exceeded.",
  minimumAmount: "Amount must be greater than 0",
  invalidWalletAddress: "Invalid wallet address.",
  tooManySmsOtp: "Too many sms codes requested. Please try again later.",
  tooManySmsOtpShort: "Too many sms codes requested.",
  tooManyEmailOtpShort: "Too many email codes requested.",
  tooManyEmailOtp: "Too many email codes requested. Please try again later.",
  somethingWentWrong: "Something went wrong. Please try again.",
  invalidSlippage: "Slippage must be greater than 1% and below 49.99%",
  slippageAlreadyExists: "Slippage already defined",
};

export const API_VALIDATION_ERROR_MESSAGES = {
  INVALID_ACCOUNT_DETAILS: "Invalid account details.",
  LOGIN_OTP_VERIFICATION_FAILED: "Invalid code.",
  OTP_VERIFICATION_FAILED: "Invalid or expired code.",
  FORGOT_PASSWORD_TOKEN_EXPIRED: "Invalid or expired token.",
  RECOVERY_PHRASE_VERIFICATION_FAILED: "Recovery phrase is not valid.",
  STAKING_POOL_NOT_AVAILABLE: "Staking pool is not available.",
  STAKING_POOL_MIN_AMOUNT_NOT_MET: "Staking pool minimum amount is not met.",
  AVAILABLE_AMOUNT_TO_STAKE: "Available amount to stake exceeded.",
  AVAILABLE_AMOUNT_TO_STAKE_HINT: (hint, tokenName) =>
    `You can stake maximum ${formatNumber(hint)} ${tokenName}.`,
  STAKING_AMOUNT_EXCEEDS_POOLS_REMAINING_BALANCE:
    "Staking amount exceeds pools remaining balance.",
  STAKING_AMOUNT_EXCEEDS_POOLS_REMAINING_BALANCE_HINT: (hint, tokenName) =>
    `Max reward left in pool is ${formatNumber(hint)} ${tokenName}.`,
  WITHDRAWAL_MIN_AMOUNT_CONDITION_NOT_MET: (hint, tokenName) =>
    `Minimum withdrawal amount is ${hint} ${tokenName}.`,
  STAKING_POOL_MAX_ALLOCATION_PER_USER_EXCEEDED:
    "Max reward per user allocation has been exceeded.",
  SMS_OTP_VERIFICATION_FAILED: "Invalid SMS code.",
  EMAIL_OTP_VERIFICATION_FAILED: "Invalid Email code.",
  USER_BALANCE_EXCEEDED_FOR_WITHDRAWAL_REQUEST: "Insufficient funds.",
  PROVIDED_TOKENS_FOR_SWAP_ARE_NOT_SUPPORTED:
    "The network is busy at the moment. Please try again in a  couple of minutes.",
};

export const CURRENCIES = {
  usd: "$",
  eur: "€",
  gbp: "£",
};
