import {
  IGetCountriesFailure,
  IGetCountriesSuccess,
  IPutRequestSmsOtpFailure,
  IRequestSmsOtpParams,
  SecretRecoveryState,
} from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: SecretRecoveryState = {
  countries: [],
  getCountriesLoading: false,
  getCountriesError: null,
  requestSmsOtpLoading: false,
  requestSmsOtpSuccess: false,
  requestSmsOtpError: null,
};

const phoneValidationSlice = createSlice({
  name: "phoneValidation",
  initialState,
  reducers: {
    getCountriesRequest(state) {
      return {
        ...state,
        getCountriesLoading: true,
        countries: [],
      };
    },
    getCountriesSuccess(state, action: PayloadAction<IGetCountriesSuccess>) {
      return {
        ...state,
        getCountriesLoading: false,
        countries: action.payload.countries,
      };
    },
    getCountriesFailure(state, action: PayloadAction<IGetCountriesFailure>) {
      return {
        ...state,
        getCountriesLoading: false,
        countries: [],
        getCountriesError: action.payload.error.response.data,
      };
    },
    putRequestSmsOtpRequest(
      state,
      action: PayloadAction<IRequestSmsOtpParams>
    ) {
      return {
        ...state,
        params: action.payload,
        requestSmsOtpLoading: true,
        requestSmsOtpSuccess: false,
        requestSmsOtpError: null,
      };
    },
    putRequestSmsOtpSuccess(state) {
      return {
        ...state,
        requestSmsOtpLoading: false,
        requestSmsOtpSuccess: true,
        requestSmsOtpError: null,
      };
    },
    putRequestSmsOtpFailure(
      state,
      action: PayloadAction<IPutRequestSmsOtpFailure>
    ) {
      return {
        ...state,
        requestSmsOtpLoading: false,
        requestSmsOtpSuccess: true,
        requestSmsOtpError: action.payload.error.response.data,
      };
    },
    putResendSmsOtpRequest(state, action) {
      return {
        ...state,
        params: action.payload,
      };
    },
    resetState() {
      return {
        ...initialState,
      };
    },
    resetRequestSmsOtpSuccessState(state) {
      return {
        ...state,
        requestSmsOtpSuccess: false,
      };
    },
  },
});

export const {
  getCountriesRequest,
  getCountriesSuccess,
  getCountriesFailure,

  putRequestSmsOtpRequest,
  putRequestSmsOtpSuccess,
  putRequestSmsOtpFailure,
  resetRequestSmsOtpSuccessState,
  putResendSmsOtpRequest,
  resetState,
} = phoneValidationSlice.actions;

export default phoneValidationSlice.reducer;
