export interface IApiError {
  error: string;
  message: string;
  path: string;
  requestId: string;
  status: number;
  timestamp: number;
  validationErrors?: IValidationError[];
}

export interface IValidationError {
  error: string;
  field: string;
}

export enum GtmEvents {
  REGISTER = "REGISTER",
  REGISTER_ERROR = "REGISTER_ERROR",
  REGISTER_SUCCESS = "REGISTER_SUCCESS",
  CREATE_WALLET = "CREATE_WALLET",
  CREATE_WALLET_STEP_1 = "CREATE_WALLET_STEP_1",
  CREATE_WALLET_STEP_2 = "CREATE_WALLET_STEP_2",
  CREATE_WALLET_ERROR = "CREATE_WALLET_ERROR",
  CREATE_WALLET_SUCCESS = "CREATE_WALLET_SUCCESS",
  MENU_NAVIGATION_LAUNCHPAD = "MENU_NAVIGATION_LAUNCHPAD",
  MENU_NAVIGATION_REFERRAL = "MENU_NAVIGATION_REFERRAL",
  MENU_NAVIGATION_SWAP = "MENU_NAVIGATION_SWAP",
  MENU_NAVIGATION_TRANSACTIONS = "MENU_NAVIGATION_TRANSACTIONS",
  MENU_NAVIGATION_NEWS = "MENU_NAVIGATION_NEWS",
  MENU_NAVIGATION_VESTING = "MENU_NAVIGATION_VESTING",
  MENU_NAVIGATION_BUY = "MENU_NAVIGATION_BUY",
  MENU_NAVIGATION_EVENTS = "MENU_NAVIGATION_EVENTS",
  MENU_NAVIGATION_STAKING = "MENU_NAVIGATION_STAKING",
  MENU_NAVIGATION_WALLET = "MENU_NAVIGATION_WALLET",
  PREVIEW_HOMEPAGE_PROSPECT = "PREVIEW_HOMEPAGE_PROSPECT",
  PREVIEW_HOMEPAGE_CLIENT = "PREVIEW_HOMEPAGE_CLIENT",
  DEPOSIT_BUTTON_CLICKED = "DEPOSIT_BUTTON_CLICKED",
  COPY_WALLET_TO_CLIPBOARD = "COPY_WALLET_TO_CLIPBOARD",
  STAKE_BUTTON_CLICKED = "STAKE_BUTTON_CLICKED",
  BUY_BUTTON_CLICKED = "BUY_BUTTON_CLICKED",
  WITHDRAW_BUTTON_CLICKED = "WITHDRAW_BUTTON_CLICKED",
  VALIDATE_PHONE = "VALIDATE_PHONE",
  VALIDATE_PHONE_SUCCESS = "VALIDATE_PHONE_SUCCESS",
  LOGIN_STEP_1 = "LOGIN_STEP_1",
  LOGIN_STEP_2 = "LOGIN_STEP_2",
  LOGIN_ERROR = "LOGIN_ERROR",
  LOGIN_STEP_2_RETRY = "LOGIN_STEP_2_RETRY",
  LOGIN_STEP_2_INVALID_CODE = "LOGIN_STEP_2_INVALID_CODE",
  LOGIN_STEP_2_TOO_MANY_CODES = "LOGIN_STEP_2_TOO_MANY_CODES",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  STAKING_ERROR = "STAKING_ERROR",
  STAKING_SUCCESS = "STAKING_SUCCESS",
  WITHDRAW_ERROR = "WITHDRAW_ERROR",
  WITHDRAW_STEP_1_ERROR = "WITHDRAW_STEP_1_ERROR",
  WITHDRAW_STEP_1 = "WITHDRAW_STEP_1",
  WITHDRAW_STEP_2 = "WITHDRAW_STEP_2",
  WITHDRAW_STEP_2_RETRY = "WITHDRAW_STEP_2_RETRY",
  WITHDRAW_STEP_2_INVALID_SMS_CODE = "WITHDRAW_STEP_2_INVALID_SMS_CODE",
  WITHDRAW_STEP_2_INVALID_EMAIL_CODE = "WITHDRAW_STEP_2_INVALID_EMAIL_CODE",
  WITHDRAW_STEP_2_TOO_MANY_EMAIL_CODES = "WITHDRAW_STEP_2_TOO_MANY_EMAIL_CODES",
  WITHDRAW_STEP_2_TOO_MANY_SMS_CODES = "WITHDRAW_STEP_2_TOO_MANY_SMS_CODES",
  WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS",
}
