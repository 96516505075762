import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { axios } from "services/axios.service";
import {
  getCampaignDetails,
  getCampaignDetailsFailure,
  getCampaignDetailsSuccess,
  getCampaignPoolData,
  getCampaignPoolDataFailure,
  getCampaignPoolDataSuccess,
} from "./campaignDetails";
import { ICampaignDetails } from "./types";

function* getCampaignDetailsSaga(
  action: ReturnType<typeof getCampaignDetails>
) {
  try {
    const response: AxiosResponse<ICampaignDetails> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/launchpad/${action.payload.campaignId}`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getCampaignDetailsSuccess({ ...response.data }));
  } catch (e: any) {
    yield put(getCampaignDetailsFailure());
  }
}

function* getCampaignPoolDataSaga(
  action: ReturnType<typeof getCampaignPoolData>
) {
  try {
    const response: AxiosResponse<any> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/launchpad/${action.payload.campaignId}/pool-data`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getCampaignPoolDataSuccess({ ...response.data }));
  } catch (e: any) {
    yield put(getCampaignPoolDataFailure());
  }
}

function* campaignDetailsSaga() {
  yield all([
    takeLatest(getCampaignDetails, getCampaignDetailsSaga),
    takeLatest(getCampaignPoolData, getCampaignPoolDataSaga),
  ]);
}

export default campaignDetailsSaga;
