import React, { useEffect, useState } from "react";
import "./style.less";
import { IPhoneInputForm } from "./interface";
import { Button, PhoneNumberInput } from "../index";
import { Avatar, Form, List } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountries,
  getCountriesLoading,
  getRequestSmsOtpLoading,
  getRequestSmsOtpSuccess,
} from "./selectors";
import {
  getCountriesRequest,
  putRequestSmsOtpRequest,
  resetRequestSmsOtpSuccessState,
} from "./phoneValidationSlice";
import {
  getCanSendCode,
  getCanSendCodeLoading,
} from "../CodeVerification/selectors";
import {
  getCanSendCodeRequest,
  resetCanSendCodeState,
} from "../CodeVerification/codeVerificationSlice";
import { VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";
import TelegramOtpsGuideModal from "./TelegramOtpsGuideModal";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ButtonTypes } from "../Button/types";

const PhoneInputForm: React.FC<IPhoneInputForm> = (props: IPhoneInputForm) => {
  const dispatch = useDispatch();
  const [formRef] = Form.useForm();
  const [phoneCode, setPhoneCode] = useState("");

  const requestSmsOtpLoading = useSelector(getRequestSmsOtpLoading);
  const requestSmsOtpSuccess = useSelector(getRequestSmsOtpSuccess);
  const countriesLoading = useSelector(getCountriesLoading);
  const countries = useSelector(getCountries);
  const canSendCodeLoading = useSelector(getCanSendCodeLoading);
  const canSendCode = useSelector(getCanSendCode);
  const [formData, setFormData] = useState(null);
  const [showTelegramTutorial, setShowTelegramTutorial] = useState(false);
  const [countryIsoCode, setCountryIsoCode] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    dispatch(getCountriesRequest());
    return () => {
      setFormData(null);
      dispatch(resetRequestSmsOtpSuccessState());
    };
  }, []);

  const submitForm = () => {
    formRef.validateFields().then((formValues) => {
      setFormData({
        phoneNumber: (phoneCode + formValues.phoneNumber).replace(/\s/g, ""),
        countryIsoCode: countryIsoCode,
      });
      dispatch(getCanSendCodeRequest({ type: "SMS" }));
    });
  };
  useEffect(() => {
    if (countries.length) {
      formRef.setFieldsValue({
        phoneNumberCountry: JSON.stringify(countries[0]),
      });
      setCountryIsoCode(countries[0].iso2);
    }
  }, [countries]);

  useEffect(() => {
    if (requestSmsOtpSuccess && formData) {
      dispatch(resetCanSendCodeState());
      props.callback("phoneConfirmation", formData);
    }
  }, [requestSmsOtpSuccess]);

  useEffect(() => {
    if (canSendCode && formData) {
      const sendForm = async () => {
        const token = await executeRecaptcha("phoneNumber");
        dispatch(putRequestSmsOtpRequest({ ...formData, captchaToken: token }));
      };
      sendForm().then(() => {});
    }
  }, [canSendCode]);

  const phoneCodeChanged = (code) => {
    countries.map((country) => {
      if (
        formRef.getFieldValue("phoneNumberCountry") &&
        country.id ===
          JSON.parse(formRef.getFieldValue("phoneNumberCountry")).id
      ) {
        setShowTelegramTutorial(country.restricted);
      }
    });
    if (formRef.getFieldValue("phoneNumberCountry")) {
      setCountryIsoCode(
        JSON.parse(formRef.getFieldValue("phoneNumberCountry")).iso2
      );
    }
    setPhoneCode(code);
  };

  return (
    <>
      <div className="phone-validation-form">
        <Form form={formRef} name="phone-validation-form" layout="vertical">
          {countries.length ? (
            <PhoneNumberInput
              loading={countriesLoading}
              formInputName={"phoneNumber"}
              formSelectName={"phoneNumberCountry"}
              dropdownCountries={countries}
              onCodeChange={phoneCodeChanged}
              label={"Phone number"}
              defaultPhonePrefix={countries[0].phoneCode}
            />
          ) : (
            ""
          )}
        </Form>
      </div>

      {canSendCode === false ? (
        <span className="generalError">
          {VALIDATION_ERROR_MESSAGES.tooManyOtpAttempts}
        </span>
      ) : null}

      <div className="importCreateWalletBtns">
        <Button
          type={ButtonTypes.PRIMARY}
          label="Send code"
          onClick={() => submitForm()}
          disabled={
            countriesLoading || requestSmsOtpLoading || canSendCodeLoading
          }
        />
      </div>

      <TelegramOtpsGuideModal
        isVisible={showTelegramTutorial}
        closeModal={() => setShowTelegramTutorial(false)}
      />
    </>
  );
};

export default PhoneInputForm;
