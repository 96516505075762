import React, { useEffect, useState } from "react";
import "./style.less";
import { Checkbox, Form, Modal, Radio, Spin } from "antd";
import { IoClose } from "react-icons/io5";
import { IStakingModal } from "./interface";
import { Button } from "../../index";
import { formatNumber } from "../../../_helpers/numberFormatter";
import { getCoinDataRequest } from "../../Wallet/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import { getWalletSelector } from "../../Wallet/selectors";
import moment from "moment";
import debounce from "lodash/debounce";
import {
  depositStakingRequest,
  getStakingPoolDetailsRequest,
  getStakingPoolSummaryRequest,
  resetStakingPoolDetails,
  resetStakingPoolSummary,
} from "../stakingSlice";
import {
  depositStakingErrorsSelector,
  depositStakingLoadingSelector,
  getStakingPoolDetailsLoadingSelector,
  getStakingPoolDetailsSelector,
  getStakingPoolSummaryLoadingSelector,
  getStakingPoolSummarySelector,
} from "../selectors";
import {
  API_VALIDATION_ERROR_MESSAGES,
  CURRENCIES,
  VALIDATION_ERROR_MESSAGES,
} from "../../../_helpers/config";
// Google Analytics
import { GtmEvents } from "../../../_helpers/types";
import { pushGtmEvent } from "../../../_helpers/gtm";

// Components
import { Scrollbars } from "react-custom-scrollbars-2";
import AnimatedScrollDown from "../../AnimatedScrollDown";
import { getCurrentUserSelector } from "../../User/selectors";
import FormInputNumber from "../../FormInputNumber";
import { isGreater, isLower } from "../../../_helpers/numberFunctions";
import { StakeType } from "./types";
import { ButtonTypes } from "../../Button/types";

const StakingModal = (props: IStakingModal) => {
  const [formRef] = Form.useForm();
  const dispatch = useDispatch();

  const [selectedStakingOption, setSelectedStakingOption] = useState(null);

  const wallet = useSelector(getWalletSelector);

  const stakingPoolDetails = useSelector(getStakingPoolDetailsSelector);
  const stakingPoolDetailsLoading = useSelector(
    getStakingPoolDetailsLoadingSelector
  );
  const user = useSelector(getCurrentUserSelector);

  const stakingPoolSummary = useSelector(getStakingPoolSummarySelector);
  const stakingPoolSummaryLoading = useSelector(
    getStakingPoolSummaryLoadingSelector
  );

  const [userAvailableFunds, setUserAvailableFunds] = useState("0");

  const depositStakingLoading = useSelector(depositStakingLoadingSelector);
  const depositStakingErrors = useSelector(depositStakingErrorsSelector);

  const insertMaxAmount = () => {
    formRef.setFieldsValue({
      amount: userAvailableFunds,
    });
    dispatch(
      getStakingPoolSummaryRequest({
        stakingPoolId: props.stakingOption.id,
        amount: userAvailableFunds,
      })
    );
  };

  const stakingOptionChanged = (option) => {
    props.selectedCoin.pools.map((pool) => {
      if (pool.id === option) {
        dispatch(
          getStakingPoolDetailsRequest({
            stakingPoolId: pool.id,
          })
        );
        setSelectedStakingOption(pool);
        if (formRef.getFieldValue("amount")) {
          formRef.validateFields(["amount"]);
          getInterestBasedOnAmount(formRef.getFieldValue("amount"), option);
        } else {
          dispatch(resetStakingPoolSummary());
        }
      }
    });
  };

  const amountChanged = (val) => {
    getInterestBasedOnAmount(val, selectedStakingOption.id);
  };

  const getInterestBasedOnAmount = (value, stakingPoolId) => {
    if (
      (isGreater(value, selectedStakingOption.minStakingAmount.toString()) ||
        value === selectedStakingOption.minStakingAmount.toString()) &&
      !isGreater(value, userAvailableFunds)
    ) {
      dispatch(
        getStakingPoolSummaryRequest({
          stakingPoolId: stakingPoolId,
          amount: value,
        })
      );
    }
  };
  const canStake = (stakingPool) => {
    return !(
      stakingPool?.newJoinersOnly &&
      moment(stakingPool?.creationDate) > moment(user?.createdAt)
    );
  };
  const submitForm = () => {
    formRef.validateFields().then((formValues) => {
      if (validateAmountBasedOnMaxUserReward()) {
        dispatch(depositStakingRequest(formValues));
      }
    });
  };

  const validateAmountBasedOnMaxUserReward = () => {
    if (
      stakingPoolSummary &&
      stakingPoolDetails.cumulatedUserStakingRewards +
        stakingPoolSummary.amount >
        selectedStakingOption.maxRewardPerUser
    ) {
      formRef.setFields([
        {
          name: "amount",
          errors: [VALIDATION_ERROR_MESSAGES.maximumRewardsPerUserExceeded],
        },
      ]);
      return false;
    }
    return true;
  };

  useEffect(() => {
    formRef.setFieldsValue({
      stakingPoolId: props.stakingOption.id,
    });

    setSelectedStakingOption(props.stakingOption);
    dispatch(
      getStakingPoolDetailsRequest({ stakingPoolId: props.stakingOption.id })
    );
    dispatch(
      getCoinDataRequest({
        contractAddress: props.selectedCoin.contractAddress,
      })
    );

    return () => {
      dispatch(resetStakingPoolSummary());
      dispatch(resetStakingPoolDetails());
    };
  }, []);

  useEffect(() => {
    if (stakingPoolDetails) {
      setUserAvailableFunds(stakingPoolDetails.plainUserAvailableFunds);
    }
  }, [stakingPoolDetails]);

  useEffect(() => {
    validateAmountBasedOnMaxUserReward();
  }, [stakingPoolSummary]);

  useEffect(() => {
    if (depositStakingErrors && depositStakingErrors.length > 0) {
      pushGtmEvent(GtmEvents.STAKING_ERROR);
    }
  }, [depositStakingErrors]);

  return (
    <>
      <Modal
        closeIcon={<IoClose />}
        open={props.isVisible}
        onCancel={() => props.onCancel()}
        className="authModals walletModals stakeModals"
        maskClosable={false}
        footer={null}
        forceRender
      >
        <Scrollbars
          universal
          style={{ height: "70vh" }}
          renderView={(props) => <div {...props} className="scroll-view" />}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
        >
          <Form form={formRef}>
            {wallet[`${props.selectedCoin.contractAddress}Loading`] !==
            false ? (
              <div className="loading">
                <Spin />
              </div>
            ) : (
              <>
                <div className="heading">
                  <h2>Staking</h2>
                  <p>Select the duration and the amount you want to stake.</p>
                </div>
                <p className="fixedLabel">Duration</p>

                <Form.Item
                  hasFeedback
                  name="stakingPoolId"
                  className="durationRadios"
                >
                  <Radio.Group
                    onChange={(e) => stakingOptionChanged(e.target.value)}
                  >
                    {props.selectedCoin.pools.map((option, key) => (
                      <Radio.Button key={key} value={option.id}>
                        {option.durationInDays} Days
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <p className="fixedLabel">Amount</p>

                <Form.Item
                  name="amount"
                  className="amountInput"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: VALIDATION_ERROR_MESSAGES.required,
                    },
                    () => ({
                      validator(_, value) {
                        if (value && isGreater(value, userAvailableFunds)) {
                          dispatch(resetStakingPoolSummary());
                          return Promise.reject(
                            `Amount is greater than available funds.`
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                    () => ({
                      validator(_, value) {
                        if (
                          value &&
                          isLower(
                            value,
                            selectedStakingOption.minStakingAmount.toString()
                          )
                        ) {
                          dispatch(resetStakingPoolSummary());
                          return Promise.reject(
                            `Minimum amount is ${selectedStakingOption.minStakingAmount}`
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <FormInputNumber
                    min={"0"}
                    step={"0.000000000000000001"}
                    onChange={debounce(amountChanged, 300)}
                    hidelabel={"true"}
                    label="Amount"
                    prefix={
                      <span
                        className="maxValue"
                        onClick={() => insertMaxAmount()}
                      >
                        MAX
                      </span>
                    }
                  />
                </Form.Item>

                {selectedStakingOption && stakingPoolDetails ? (
                  <>
                    <p className="availableAmount">
                      Available amount:{" "}
                      {formatNumber(stakingPoolDetails.userAvailableFunds)}{" "}
                      {props.selectedCoin.tokenSymbol}
                    </p>

                    <hr />

                    <div className="stakingSummary">
                      <p>
                        <span>
                          <b>1 {props.selectedCoin.tokenSymbol}</b>
                        </span>
                        <span>
                          <b>
                            {user
                              ? CURRENCIES[
                                  user.state.properties.selectedCurrency
                                ]
                              : ""}
                            {
                              wallet[
                                `${props.selectedCoin.contractAddress}Data`
                              ].currentPrice
                            }
                          </b>
                          <b
                            className={`trend ${
                              wallet[
                                `${props.selectedCoin.contractAddress}Data`
                              ].priceChangePercentage24h > 0
                                ? "green"
                                : ""
                            }`}
                          >
                            {
                              wallet[
                                `${props.selectedCoin.contractAddress}Data`
                              ].priceChangePercentage24h
                            }
                            %
                          </b>
                        </span>
                      </p>
                    </div>

                    <hr />
                    <div className="stakingSummary">
                      <p>
                        <span>Type</span>
                        <span>
                          <b>{selectedStakingOption?.type?.toLowerCase()}</b>
                        </span>
                      </p>
                      <p>
                        <span>Min. deposit</span>
                        <span>
                          <b>
                            {formatNumber(
                              selectedStakingOption.minStakingAmount
                            )}{" "}
                            {props.selectedCoin.tokenSymbol}
                          </b>
                        </span>
                      </p>
                      <p>
                        <span>Pool size</span>
                        <span>
                          <b>
                            {formatNumber(selectedStakingOption.poolSize)}{" "}
                            {props.selectedCoin.tokenSymbol}
                          </b>
                        </span>
                      </p>
                      <p>
                        <span>Rewards left</span>
                        <span>
                          {formatNumber(
                            stakingPoolDetails.stakingPoolAvailableFunds
                          )}{" "}
                          {props.selectedCoin.tokenSymbol}
                        </span>
                      </p>
                      <p>
                        <span>Total value</span>
                        <span>
                          <b>
                            $
                            {formatNumber(
                              selectedStakingOption.poolSize *
                                wallet[
                                  `${props.selectedCoin.contractAddress}Data`
                                ].currentPrice
                            )}
                          </b>
                        </span>
                      </p>
                    </div>

                    <hr />

                    <div className="stakingSummary">
                      <h3>Summary</h3>

                      <p>
                        <span>Stake date</span>
                        <span>{moment().local().format("YYYY-MM-DD")}</span>
                      </p>

                      <p>
                        <span>Redemption date</span>
                        <span>
                          {moment()
                            .add(selectedStakingOption.durationInDays, "days")
                            .local()
                            .format("YYYY-MM-DD")}
                        </span>
                      </p>
                      <p>
                        <span>Available until</span>
                        <span>
                          {moment(selectedStakingOption.availableUntil).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      </p>
                    </div>

                    <hr />

                    <div className="stakingSummary">
                      <p>
                        <span>
                          <b>APY</b>
                        </span>
                        <span className="green">
                          <b>{selectedStakingOption.apy}%</b>
                        </span>
                      </p>

                      <p>
                        <span>
                          <b>Interests</b>
                        </span>
                        <span>
                          <b>
                            +
                            {stakingPoolSummary
                              ? stakingPoolSummary.calculatedStakingReward
                              : 0}{" "}
                            {props.selectedCoin.tokenSymbol}
                          </b>
                        </span>
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <Form.Item
                  name="tc"
                  valuePropName="checked"
                  className="termsAgreement"
                  rules={[
                    {
                      required: true,
                      message: VALIDATION_ERROR_MESSAGES.required,
                      transform: (value) => value || undefined,
                      type: "boolean",
                    },
                  ]}
                >
                  <Checkbox>
                    I have read and I agree to{" "}
                    <a
                      href="https://seedon.io/terms-and-conditions#staking"
                      target="_blank"
                    >
                      SeedOn Staking Service Agreement
                    </a>
                    .
                  </Checkbox>
                </Form.Item>
                <div className={"row"}>
                  {canStake(selectedStakingOption) ? (
                    <p className="slippage-warning">
                      {!selectedStakingOption?.cancellable
                        ? "This staking deposit can't be canceled. You need to wait until the end of the lock period to redeem your reward and your locked tokens."
                        : selectedStakingOption?.type === StakeType.LOCKED
                        ? "Locked staking pools offer higher APYs compared to flexible ones, yet staking rewards will be redeemed at the end of the lock period. Nevertheless, you can still cancel your staking deposit at any moment, but you will lose your rewards."
                        : "When locking in tokens in a flexible pool, you will receive a daily reward in relation to the APY and amount of tokens staked. You can cancel your staking deposit at any time, remaining with your staking rewards already redeemed.\n"}
                    </p>
                  ) : (
                    <p className="slippage-warning">
                      This locked staking pool is available for SeedOn Finance
                      users meeting a specific campaign criteria, in order to be
                      eligible to stake. Don't worry. You can still choose from
                      the other available staking pools!
                    </p>
                  )}
                </div>

                {depositStakingErrors.map((item, key) => (
                  <div key={key}>
                    <span className="generalError" key={key}>
                      {API_VALIDATION_ERROR_MESSAGES[item.error]}
                    </span>

                    {item.hint ? (
                      <span className="generalError">
                        {API_VALIDATION_ERROR_MESSAGES[`${item.error}_HINT`](
                          item.hint.data[0],
                          props.selectedCoin.tokenSymbol
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ))}

                {canStake(selectedStakingOption) && (
                  <Button
                    onClick={() => submitForm()}
                    loading={
                      depositStakingLoading ||
                      stakingPoolSummaryLoading ||
                      stakingPoolDetailsLoading
                    }
                    type={ButtonTypes.PRIMARY}
                    label="Confirm stake"
                    extraClasses="submitButton"
                  />
                )}
                <Button
                  onClick={() => props.onCancel()}
                  label="Cancel"
                  extraClasses="cancelButton"
                />
              </>
            )}
          </Form>
        </Scrollbars>

        <AnimatedScrollDown />
      </Modal>
    </>
  );
};

export default StakingModal;
