import {
  GetTokenDataRequestPayload,
  GetTokenDataResponsePayload,
  GetTokenDetailsResponsePayload,
  TokenDetailsState,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: TokenDetailsState = {
  tokenData: null,
  tokenDataLoading: false,
  tokenDetails: null,
  tokenDetailsLoading: false,
};

const tokenDetails = createSlice({
  name: "tokenDetails",
  initialState,
  reducers: {
    resetState(state) {
      return {
        ...initialState,
      };
    },
    getTokenDataRequest(
      state,
      action: PayloadAction<GetTokenDataRequestPayload>
    ) {
      return {
        ...state,
        tokenData: null,
        tokenDataLoading: true,
        params: action.payload,
      };
    },
    getTokenDataSuccess(
      state,
      action: PayloadAction<GetTokenDataResponsePayload>
    ) {
      return {
        ...state,
        tokenData: action.payload.tokenData,
        tokenDataLoading: false,
      };
    },
    getTokenDataFailure(state) {
      return {
        ...state,
        tokenData: null,
        tokenDataLoading: false,
      };
    },
  },
});

export const {
  resetState,
  getTokenDataRequest,
  getTokenDataSuccess,
  getTokenDataFailure,
} = tokenDetails.actions;

export default tokenDetails.reducer;
