import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const getLoading = (state: AppState) => state.events.loading;
const getError = (state: AppState) => state.events.error;
const getEvents = (state: AppState) => state.events.events;

const categoriesLoading = (state: AppState) => state.events.categoriesLoading;
const categories = (state: AppState) => state.events.categories;

const eventsStartDateLoading = (state: AppState) =>
  state.events.eventsStartDateLoading;
const eventsStartDate = (state: AppState) => state.events.eventsStartDate;

const eventLoading = (state: AppState) => state.events.eventLoading;
const event = (state: AppState) => state.events.event;

const eventInteractionLoading = (state: AppState) =>
  state.events.eventInteractionLoading;

export const getEventSelector = createSelector(event, (res) => res);
export const getEventLoadingSelector = createSelector(
  eventLoading,
  (res) => res
);

export const getEventsStartDateSelector = createSelector(
  eventsStartDate,
  (res) => res
);
export const getEventsStartDateLoadingSelector = createSelector(
  eventsStartDateLoading,
  (res) => res
);

export const getEventCategoriesSelector = createSelector(
  categories,
  (res) => res
);
export const getEventCategoriesLoadingSelector = createSelector(
  categoriesLoading,
  (res) => res
);

export const getLoadingSelector = createSelector(getLoading, (res) => res);
export const getErrorSelector = createSelector(getError, (res) => res);
export const getEventsSelector = createSelector(getEvents, (res) => res);
export const getEventInteractionLoading = createSelector(
  eventInteractionLoading,
  (res) => res
);
