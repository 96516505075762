import "./style.less";
import { FiArrowUpRight, FiLock, FiUnlock } from "react-icons/fi";
import { ITransactionPanelHeadProps } from "./interface";
import React, { useEffect, useState } from "react";
import { formatStringNumber } from "../../../../_helpers/numberFormatter";
import moment from "moment";
import { useSelector } from "react-redux";
import { getWhitelistedCoinsSelector } from "../../selectors";
import { AiOutlineSwap } from "react-icons/ai";
import { Tooltip } from "antd";
import { MdAttachMoney } from "react-icons/md";
import { GiTrophyCup } from "react-icons/gi";

const TransactionPanelHead = (props: ITransactionPanelHeadProps) => {
  const [formattedTransaction, setFormattedTransaction] = useState(null);
  const whitelistedCoins = useSelector(getWhitelistedCoinsSelector);

  useEffect(() => {
    setFormattedTransaction(formatTransactionForDisplay(props.transaction));
    /**
     * Daca e available
     * Locked - STAKED ---> Lacatel
     * Unlocked - Staked ---> lacatel open
     * Available + sageata verde IN
     * Available e doar sageata
     * Error class ---> .errorState
     * Error icon ---> <FiAlertCircle />
     */
  }, []);
  const formatTransactionForDisplay = (data) => {
    const transaction = { ...data };
    switch (transaction.type) {
      case "AVAILABLE":
        transaction.icon =
          transaction.correlationId === "NO_FEE_CAMPAIGN_PRIZE" ? (
            <GiTrophyCup />
          ) : transaction.metadata.type === "tokenSwapTransactionMetadata" ? (
            <AiOutlineSwap />
          ) : transaction.metadata.type === "fiatDepositTransactionMetadata" ? (
            <MdAttachMoney />
          ) : (
            <FiArrowUpRight />
          );
        transaction.iconCLass =
          transaction.value > 0
            ? `received ${
                transaction.correlationId === "NO_FEE_CAMPAIGN_PRIZE"
                  ? "prize"
                  : ""
              }`
            : "sent";
        transaction.actionName =
          transaction.metadata.type === "tokenSwapTransactionMetadata"
            ? "Swap"
            : transaction.value > 0
            ? "Received"
            : "Sent";
        transaction.date = transaction.timestamp;
        break;
      case "STAKED":
        transaction.icon =
          transaction.status === "LOCKED" ? <FiLock /> : <FiUnlock />;
        transaction.iconCLass =
          transaction.status === "LOCKED" ? "stakedLocked" : "stakedUnlocked";
        transaction.actionName =
          transaction.status === "LOCKED" ? "Staked" : "Stake unlocked";
        transaction.date = transaction.timestamp;
        break;
      case "VESTED":
        transaction.icon =
          transaction.status === "LOCKED" ? <FiLock /> : <FiUnlock />;
        transaction.iconCLass =
          transaction.status === "LOCKED" ? "vestingLocked" : "vestingUnlocked";
        transaction.actionName =
          transaction.status === "LOCKED" ? "Vested" : "Vesting unlocked";
        transaction.date =
          transaction.status === "LOCKED"
            ? transaction.timestamp
            : transaction.metadata.releaseDate;
        break;
      default:
        break;
    }
    whitelistedCoins.map((coin) => {
      if (
        transaction?.metadata?.type === "fiatDepositTransactionMetadata" &&
        transaction?.metadata?.conversionCurrency
      ) {
        if (coin.contractAddress === transaction.metadata.conversionCurrency) {
          transaction.tokenSymbol = coin.tokenSymbol;
          return coin;
        }
      } else {
        if (coin.contractAddress === transaction.contractAddress) {
          transaction.tokenSymbol = coin.tokenSymbol;
          return coin;
        }
      }
      return coin;
    });
    if (
      transaction?.metadata?.type === "fiatDepositTransactionMetadata" &&
      transaction?.metadata?.conversionCurrency
    ) {
      transaction.value = transaction?.metadata?.conversionAmount;
    }
    return transaction;
  };
  return (
    <div className="transactionPanelHead">
      {formattedTransaction ? (
        <>
          {/* sent or received class */}
          <div
            className={`icon ${
              formattedTransaction.status === "LOCKED"
                ? "success"
                : formattedTransaction.status.toLowerCase()
            } ${formattedTransaction.iconCLass}`}
          >
            {formattedTransaction.icon}
          </div>

          <div className="infos">
            <h3>
              <b>{formattedTransaction.tokenSymbol}</b>
              <span>{formattedTransaction.actionName}</span>{" "}
            </h3>

            <p>
              <span>
                {moment(formattedTransaction.date).format("Do MMMM yyyy")}
              </span>
            </p>
          </div>

          <div className="transaction">
            <Tooltip
              title={`${formatStringNumber(formattedTransaction.value)} ${
                formattedTransaction.tokenSymbol
              }`}
            >
              <h3>
                <b>{formattedTransaction.tokenSymbol}</b>
                <span>
                  {formatStringNumber(formattedTransaction.value)}
                </span>{" "}
              </h3>
            </Tooltip>

            <p>View more details</p>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default TransactionPanelHead;
