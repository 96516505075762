import TagManager from "react-gtm-module";
import { GtmEvents } from "./types";

const events = {
  [GtmEvents.REGISTER]: {
    event: "create_account",
    step: "click",
    user_type: "investor",
  },
  [GtmEvents.REGISTER_ERROR]: {
    event: "create_account",
    step: "error",
    user_type: "investor",
  },
  [GtmEvents.REGISTER_SUCCESS]: {
    event: "create_account",
    step: "success",
    user_type: "investor",
  },
  [GtmEvents.CREATE_WALLET]: {
    event: "create_wallet",
    step: "click",
    user_type: "investor",
  },

  [GtmEvents.CREATE_WALLET_STEP_1]: {},

  [GtmEvents.CREATE_WALLET_STEP_2]: {
    event: "create_wallet",
    step: "step_2",
    user_type: "investor",
  },

  [GtmEvents.CREATE_WALLET_ERROR]: {},

  [GtmEvents.CREATE_WALLET_SUCCESS]: {
    event: "create_wallet",
    step: "success",
    user_type: "investor",
  },

  [GtmEvents.LOGIN_STEP_1]: {
    event: "login",
    step: "step_1",
    user_type: "investor",
  },
  [GtmEvents.LOGIN_STEP_2]: {
    event: "login",
    step: "step_2",
    user_type: "investor",
  },
  [GtmEvents.LOGIN_ERROR]: {
    event: "login",
    step: "error",
    user_type: "investor",
  },
  [GtmEvents.LOGIN_STEP_2_RETRY]: {
    event: "login",
    step: "step_2_retry",
    user_type: "investor",
  },
  [GtmEvents.LOGIN_STEP_2_INVALID_CODE]: {
    event: "login",
    step: "step_2_invalid_email",
    user_type: "investor",
  },
  [GtmEvents.LOGIN_STEP_2_TOO_MANY_CODES]: {
    event: "login",
    step: "step_2_too_many_email",
    user_type: "investor",
  },
  [GtmEvents.LOGIN_SUCCESS]: {
    event: "login",
    step: "success",
    user_type: "investor",
  },

  [GtmEvents.MENU_NAVIGATION_VESTING]: {
    event: "click_menu",
    menu_item: "vesting",
    user_type: "investor",
  },
  [GtmEvents.MENU_NAVIGATION_BUY]: {
    event: "click_menu",
    menu_item: "buy",
    user_type: "investor",
  },
  [GtmEvents.MENU_NAVIGATION_EVENTS]: {
    event: "click_menu",
    menu_item: "events",
    user_type: "investor",
  },
  [GtmEvents.MENU_NAVIGATION_STAKING]: {
    event: "click_menu",
    menu_item: "staking",
    user_type: "investor",
  },
  [GtmEvents.MENU_NAVIGATION_WALLET]: {
    event: "click_menu",
    menu_item: "wallet",
    user_type: "investor",
  },
  [GtmEvents.MENU_NAVIGATION_LAUNCHPAD]: {
    event: "click_menu",
    menu_item: "launchpad",
    user_type: "investor",
  },
  [GtmEvents.MENU_NAVIGATION_REFERRAL]: {
    event: "click_menu",
    menu_item: "referral",
    user_type: "investor",
  },
  [GtmEvents.MENU_NAVIGATION_SWAP]: {
    event: "click_menu",
    menu_item: "swap",
    user_type: "investor",
  },
  [GtmEvents.MENU_NAVIGATION_TRANSACTIONS]: {
    event: "click_menu",
    menu_item: "transactions",
    user_type: "investor",
  },

  [GtmEvents.PREVIEW_HOMEPAGE_PROSPECT]: {
    event: "page_vew",
    page_detail: "homepage_prospect",
    user_type: "investor",
  },
  [GtmEvents.PREVIEW_HOMEPAGE_CLIENT]: {
    event: "page_vew",
    page_detail: "homepage_client",
    user_type: "investor",
  },

  [GtmEvents.DEPOSIT_BUTTON_CLICKED]: {
    event: "deposit",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.COPY_WALLET_TO_CLIPBOARD]: {
    event: "copy_address",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },

  [GtmEvents.STAKE_BUTTON_CLICKED]: {
    event: "stake",
    step: "click",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.BUY_BUTTON_CLICKED]: {
    event: "buy",
    step: "click",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.STAKING_ERROR]: {
    event: "stake",
    step: "error",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },

  [GtmEvents.STAKING_SUCCESS]: {
    event: "stake",
    step: "success",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_BUTTON_CLICKED]: {
    event: "withdraw",
    step: "click",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_ERROR]: {
    event: "withdraw",
    step: "error",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_STEP_1_ERROR]: {
    event: "withdraw",
    step: "step_1_error",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_STEP_1]: {
    event: "withdraw",
    step: "step_1",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_STEP_2]: {
    event: "withdraw",
    step: "step_2",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_STEP_2_RETRY]: {
    event: "withdraw",
    step: "step_2_retry",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_STEP_2_INVALID_SMS_CODE]: {
    event: "withdraw",
    step: "step_2_invalid_sms",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_STEP_2_INVALID_EMAIL_CODE]: {
    event: "withdraw",
    step: "step_2_invalid_email",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_STEP_2_TOO_MANY_EMAIL_CODES]: {
    event: "withdraw",
    step: "step_2_too_many_email",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_STEP_2_TOO_MANY_SMS_CODES]: {
    event: "withdraw",
    step: "step_2_too_many_sms",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.WITHDRAW_SUCCESS]: {
    event: "withdraw",
    step: "success",
    page_detail: "{{page_location}}",
    user_type: "investor",
  },
  [GtmEvents.VALIDATE_PHONE]: {
    event: "validate_phone",
    step: "click",
    user_type: "investor",
  },
  [GtmEvents.VALIDATE_PHONE_SUCCESS]: {
    event: "validate_phone",
    step: "success",
    user_type: "investor",
  },
};

export const initGTM = () => {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
};

export const pushGtmEvent = (eventKey, params = {}) => {
  TagManager.dataLayer({
    dataLayer: {
      ...events[eventKey],
      ...params,
    },
  });
};
