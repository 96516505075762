import BNBAnimation from "../../assets/animations/BNB.json";
import USDTAnimation from "../../assets/animations/USDT.json";
import SEONAnimation from "../../assets/animations/SEON.json";

export const SwapAnimations = {
  BNB: BNBAnimation,
  USDT: USDTAnimation,
  SEON: SEONAnimation,
  CLDT: SEONAnimation,
};

export const ReversedAnimations = ["USDT", "SEON", "CLDT"];
