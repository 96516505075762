import { DatePicker, Drawer, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

//Components
import { transactionsStatus, getValueTransactionsTypes } from "./config";
import { useWindowSize } from "../../../hooks/windowSize";
import {
  getTransactionTypesRequest,
  setOpenSearchTransactions,
} from "../walletSlice";
import {
  getOpenSearchTransactions,
  getTransactionTypesSelector,
} from "../selectors";
import FormDropdownSelect from "../../DropdownSelect";
import Button from "../../Button";

//Assets
import { FiSearch } from "react-icons/fi";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { AiOutlineLeft } from "react-icons/ai";
import logo from "../../../assets/images/logo_finance.svg";
import moment from "moment";
import { ROUTES } from "../../../_helpers/config";
import { useNavigate } from "react-router-dom";
import { ButtonTypes } from "../../Button/types";

const { RangePicker } = DatePicker;

const FiltersTransactions = (props) => {
  const {
    setSearch,
    type,
    setType,
    status,
    setStatus,
    endDate,
    setEndDate,
    startDate,
    setStartDate,
  } = props;

  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 991;
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const openSearchTransactions = useSelector(getOpenSearchTransactions);
  const transactionTypes = useSelector(getTransactionTypesSelector);

  useEffect(() => {
    if (isEmpty(transactionTypes)) {
      dispatch(getTransactionTypesRequest());
    }
  }, []);

  useEffect(() => {
    setOpen(openSearchTransactions);
  }, [openSearchTransactions]);

  const clearFilter = () => {
    setType(null);
    setEndDate(undefined);
    setStartDate(undefined);
    setStatus(null);
    setSearch(true);
    dispatch(setOpenSearchTransactions(false));
  };

  return (
    <div className="searchContainer transactionsSearchContainer">
      {isMobile ? (
        <Drawer
          title={
            <div className="logo" onClick={() => navigate(ROUTES.homePage)}>
              <Image
                src={logo}
                height={35}
                preview={false}
                alt="SeedOn Finance"
                onClick={() => navigate(ROUTES.homePage)}
              />
            </div>
          }
          placement="left"
          className="drawer-search"
          width="75vw"
          onClose={() => dispatch(setOpenSearchTransactions(false))}
          visible={open}
          closeIcon={""}
          extra={
            <div
              className="close-drawer"
              onClick={() => dispatch(setOpenSearchTransactions(false))}
            >
              <AiOutlineLeft size={17} color="#fff" />
            </div>
          }
        >
          <div>
            <div className="drawer-search-section-filters">
              <FormDropdownSelect
                label="Type"
                items={["all", ...transactionTypes].map((str) => ({
                  value: getValueTransactionsTypes[str],
                  id: str,
                }))}
                keyToShow={"value"}
                onChange={(val) => {
                  setType(val);
                }}
                value={type}
              />

              <DatePicker
                className="rangePickerDate"
                value={startDate}
                placeholder="Start date"
                onChange={(start) => {
                  setStartDate(start);
                }}
                disabledDate={(date) => !date || date.isAfter(moment())}
              />

              <DatePicker
                className="rangePickerDate"
                value={endDate}
                placeholder="End date"
                onChange={(end) => {
                  setEndDate(end);
                }}
                disabledDate={(date) => !date || date.isAfter(moment())}
              />

              <FormDropdownSelect
                label="Status"
                items={transactionsStatus}
                onChange={(val) => {
                  setStatus(val);
                }}
                value={status}
              />
            </div>
          </div>

          <div className="drawer-search-section-buttons">
            <Button
              type={ButtonTypes.PRIMARY}
              label="Search"
              disabled={!(type || status || startDate || endDate)}
              onClick={() => {
                setSearch(true);
                dispatch(setOpenSearchTransactions(false));
              }}
            />
            <Button
              label="Clear filters"
              type={ButtonTypes.SECONDARY}
              disabled={!(type || status || startDate || endDate)}
              onClick={() => clearFilter()}
            />
          </div>
        </Drawer>
      ) : (
        <div className="search-filters">
          <FormDropdownSelect
            label="Type"
            items={["all", ...transactionTypes].map((str) => ({
              value: getValueTransactionsTypes[str],
              id: str,
            }))}
            keyToShow={"value"}
            onChange={(val) => {
              setType(val);
              setSearch(true);
            }}
            value={type}
          />

          <RangePicker
            className="rangePickerDate"
            value={[startDate, endDate]}
            placeholder={["Start date", "End date"]}
            separator={<CgArrowsExchangeAlt color="#fff" size={18} />}
            onChange={(data) => {
              setStartDate(data ? data[0] : undefined);
              setEndDate(data ? data[1] : undefined);
              setSearch(true);
            }}
            allowClear
            disabledDate={(date) => !date || date.isAfter(moment())}
          />

          <FormDropdownSelect
            label="Status"
            items={transactionsStatus}
            onChange={(val) => {
              setStatus(val);
              setSearch(true);
            }}
            value={status}
          />

          <Button
            label={<FiSearch size={24} color="#fff" />}
            type={ButtonTypes.PRIMARY}
            extraClasses="btn-search"
            disabled={!(type || status || startDate || endDate)}
            onClick={() => setSearch(true)}
          />
        </div>
      )}
    </div>
  );
};

export default FiltersTransactions;
