import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const quoteLoading = (state: AppState) => state.swap.swapQuoteLoading;
const quoteError = (state: AppState) => state.swap.swapQuoteError;
const quote = (state: AppState) => state.swap.swapQuote;
const swapLoading = (state: AppState) => state.swap.swapLoading;
const swapError = (state: AppState) => state.swap.swapError;
const swapSuccess = (state: AppState) => state.swap.swapSuccess;

export const getQuoteLoadingSelector = createSelector(
  quoteLoading,
  (res) => res
);
export const getQuoteErrorSelector = createSelector(quoteError, (res) => res);
export const getQuoteSelector = createSelector(quote, (res) => res);
export const getSwapLoadingSelector = createSelector(swapLoading, (res) => res);
export const getSwapErrorSelector = createSelector(swapError, (res) => res);
export const getSwapSuccessSelector = createSelector(swapSuccess, (res) => res);
