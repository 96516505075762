import {
  Avatar,
  Dropdown,
  Layout,
  Menu,
  Badge,
  Select,
  notification,
  Image,
  Tooltip,
  Drawer,
} from "antd";
import "./style.less";
import logo from "../../../assets/images/logo_finance.svg";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  getChangeCurrencySuccessSelector,
  getCurrentUserSelector,
} from "../../User/selectors";
import { useWindowSize } from "../../../hooks/windowSize";

import { menu, menuProtected, spotWalletDropdown } from "./data";
import classnames from "classnames";
import Hamburger from "react-hamburgers";
import {
  FiChevronDown,
  FiLogOut,
  FiUser,
  FiBell,
  FiThumbsUp,
  FiUsers,
  FiMessageCircle,
  FiFolder,
} from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../_helpers/config";
import { pushGtmEvent } from "../../../_helpers/gtm";
import { GtmEvents } from "../../../_helpers/types";
import { getConfigRequest, getCurrenciesRequest } from "../layoutSlice";
import {
  getCurrenciesLoadingSelector,
  getCurrenciesSelector,
} from "../selectors";
import {
  getCurrentUserRequest,
  resetUpdateUserCurrency,
  updateUserCurrencyRequest,
} from "../../User/userSlice";
import {
  getFundsTotalRequest,
  getWhitelistedCoinsRequest,
} from "../../Wallet/walletSlice";
import { getAnnouncementsRequest } from "../../AnnouncementsModal/announcementSlice";
import { getUnreadNotificationsCountSelector } from "../../Notifications/selectors";
import { logoutRequest } from "../../LoginForm/loginSlice";
import { logoutSuccessSelector } from "../../LoginForm/selectors";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Button from "../../Button";
import { MdFavoriteBorder, MdLogout } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import { ButtonTypes } from "../../Button/types";

const { Header } = Layout;
const { Option } = Select;

const HeaderLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1070;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [token, _, removeCookie] = useCookies(["sOnFinanceToken"]);
  const [showDrawer, toggleDrawer] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [current, setCurrent] = useState(null);

  const currencies = useSelector(getCurrenciesSelector);
  const currenciesLoading = useSelector(getCurrenciesLoadingSelector);
  const changeCurrencySuccess = useSelector(getChangeCurrencySuccessSelector);
  const unreadNotificationsCount = useSelector(
    getUnreadNotificationsCountSelector
  );
  const currentUser = useSelector(getCurrentUserSelector);
  const logoutSuccess = useSelector(logoutSuccessSelector);

  const logout = () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("logout");
      dispatch(logoutRequest({ captchaToken: token }));
    };
    sendForm().then(() => {});
  };
  const goToUserProfile = () => {
    localStorage.setItem("intended", window.location.pathname);
    window.location.href = ROUTES.userProfile;
  };

  const getUsername = () => {
    if (currentUser?.details?.firstName) {
      return (
        <Tooltip
          title={`${currentUser?.details?.firstName} ${currentUser?.details?.lastName}`}
        >
          {currentUser?.details?.firstName} {currentUser?.details?.lastName}
        </Tooltip>
      );
    } else if (currentUser?.email) {
      return <Tooltip title={currentUser?.email}>{currentUser?.email}</Tooltip>;
    } else {
      return <span>Seeder 🌱</span>;
    }
  };

  const profileDropdown = (
    <Menu
      items={[
        {
          key: "user",
          label: <div className="userName">Hello, {getUsername()}</div>,
        },
        {
          key: "announcements",
          label: (
            <div
              onClick={() =>
                dispatch(
                  getAnnouncementsRequest({
                    all: true,
                  })
                )
              }
            >
              Announcements
              <FiMessageCircle />
            </div>
          ),
        },
        {
          key: "favorite-news",
          label: (
            <div onClick={() => navigate(ROUTES.favoriteNews)}>
              Favorite news
              <FiThumbsUp />
            </div>
          ),
        },
        {
          key: "notifications",
          label: (
            <div onClick={() => navigate(ROUTES.notifications)}>
              Notifications
              <Badge
                count={unreadNotificationsCount}
                size="small"
                overflowCount={99}
              >
                <FiBell />
              </Badge>
            </div>
          ),
        },
        {
          key: "referral",
          label: (
            <div onClick={() => navigate(ROUTES.referral)}>
              Invite friends
              <FiUsers />
            </div>
          ),
        },
        // {
        //   key: "portfolio",
        //   label: (
        //     <div onClick={() => navigate(ROUTES.portfolio)}>
        //       Portfolio
        //       <FiFolder />
        //     </div>
        //   ),
        // },
        // {
        //   key: "watchlist",
        //   label: (
        //     <div onClick={() => navigate(ROUTES.watchlist)}>
        //       Watchlist
        //       <MdFavoriteBorder />
        //     </div>
        //   ),
        // },
        {
          key: "user-profile",
          label: (
            <div onClick={() => goToUserProfile()}>
              Profile
              <Badge count={badgeCount} size="small" overflowCount={99}>
                <FiUser />
              </Badge>
            </div>
          ),
        },
        {
          key: "logout",
          label: (
            <div onClick={() => logout()}>
              Logout
              <FiLogOut />
            </div>
          ),
        },
      ]}
    />
  );

  const menuItemClicked = (item) => {
    if (item?.keyPath && item?.keyPath[1] !== "currency") {
      setCurrent(item.key);
    }

    if (item?.GtmKey) {
      pushGtmEvent(GtmEvents[item.GtmKey]);
    }

    if (isMobile) {
      toggleDrawer(!showDrawer);
    }
  };

  const getOpenMenu = (step) => {
    if (
      [
        ROUTES.buy,
        ROUTES.vesting,
        ROUTES.wallet,
        ROUTES.swap,
        ROUTES.transactions,
        ROUTES.stake,
      ].includes(step)
    ) {
      return ["spotWallet"];
    } else if (
      [
        ROUTES.favoriteNews,
        ROUTES.notifications,
        ROUTES.portfolio,
        ROUTES.watchlist,
        ROUTES.userProfile,
      ].includes(step)
    ) {
      return ["profile"];
    } else {
      return null;
    }
  };

  const currencyChanged = (currency) => {
    setSelectedCurrency(currency);
    dispatch(
      updateUserCurrencyRequest({
        currency: currency.toLowerCase(),
      })
    );
  };

  useEffect(() => {
    if (logoutSuccess) {
      removeCookie("sOnFinanceToken");
      document.cookie = `sOnFinanceToken=; Max-Age=0;secure'; path=/; domain=${process.env.REACT_APP_APP_DOMAIN};`;
      document.cookie = `sOnFinanceToken=; Max-Age=0;secure'; path=/; domain=finance.${process.env.REACT_APP_APP_DOMAIN};`;
      window.location.href = process.env.REACT_APP_PUBLIC_URL;
    }
  }, [logoutSuccess]);

  useEffect(() => {
    dispatch(getCurrenciesRequest());
    setCurrent(window.location.pathname);
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.state) {
      setSelectedCurrency(
        currentUser.state.properties?.selectedCurrency.toUpperCase()
      );
      setBadgeCount(!currentUser.state.properties?.phoneNumberVerified ? 1 : 0);
    }
  }, [currentUser]);

  useEffect(() => {
    if (changeCurrencySuccess) {
      dispatch(getCurrentUserRequest());
      dispatch(getWhitelistedCoinsRequest());
      dispatch(getConfigRequest());
      if (currentUser?.state?.properties?.recoveryPhraseVerified) {
        dispatch(getFundsTotalRequest());
      }

      notification.success({
        message: "Currency changed successfully.",
      });

      dispatch(resetUpdateUserCurrency());
    }
  }, [changeCurrencySuccess]);

  useEffect(() => {
    if (!isMobile) {
      toggleDrawer(false);
    }
  }, [isMobile]);

  const getCurrencyItem = currencies
    ? currencies.map((item) => ({
        key: item.name,
        label: (
          <div
            className="submenuItem currencySubmenuItem"
            onClick={() => currencyChanged(item.name)}
          >
            {item.name}
          </div>
        ),
      }))
    : [];

  return (
    <Header className="header">
      <Image
        src={logo}
        className="logo"
        preview={false}
        alt="SeedOn Finance - Logo"
        onClick={() => navigate(ROUTES.homePage)}
      />

      <div className="menu">
        {isMobile ? (
          <div className={classnames("menuIcon", showDrawer ? "active" : "")}>
            <Hamburger
              type="emphatic"
              active={showDrawer}
              onClick={() => toggleDrawer(!showDrawer)}
            />
          </div>
        ) : (
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            className="profile"
            overlayClassName="dropdown-profile"
            overlay={profileDropdown}
          >
            <div className="userAvatar">
              <Badge count={badgeCount + unreadNotificationsCount}>
                <Avatar alt="profile-avatar" className="thumbnail">
                  <FiUser />
                </Avatar>
              </Badge>
              <FiChevronDown className="userChevron" />
            </div>
          </Dropdown>
        )}
        {isMobile ? (
          <div id="drawer-active" className="drawer">
            <Drawer
              title={
                <div className="userSection">
                  <div className="userAvatar">
                    <Avatar alt="profile-avatar" className="thumbnail">
                      <FiUser />
                    </Avatar>
                  </div>
                  <div className="userSectionName">
                    Hello,
                    <div>
                      {currentUser?.details?.firstName ? (
                        <Tooltip
                          title={`${currentUser?.details?.firstName} ${currentUser?.details?.lastName}`}
                        >
                          {currentUser?.details?.firstName}{" "}
                          {currentUser?.details?.lastName}
                        </Tooltip>
                      ) : currentUser?.email ? (
                        <Tooltip title={currentUser?.email}>
                          {currentUser?.email}
                        </Tooltip>
                      ) : (
                        <div>Seeder 🌱</div>
                      )}
                    </div>
                  </div>
                  <div
                    className="closeDrawer"
                    onClick={() => toggleDrawer(false)}
                  >
                    <BsArrowLeft size={20} color="#fff" />
                  </div>
                </div>
              }
              className="drawer-menu"
              placement="left"
              onClose={() => toggleDrawer(false)}
              open={showDrawer}
              footer={
                <Button
                  label="Logout"
                  type={ButtonTypes.SECONDARY}
                  icon={<MdLogout size={20} />}
                  extraClasses="logoutButton"
                  onClick={() => logout()}
                />
              }
            >
              <div className="drawer-content">
                <div className="drawerBody">
                  <Menu
                    mode="inline"
                    items={menuProtected(
                      dispatch,
                      navigate,
                      unreadNotificationsCount,
                      badgeCount,
                      getCurrencyItem,
                      selectedCurrency
                    )}
                    selectedKeys={current}
                    defaultOpenKeys={getOpenMenu(current)}
                    style={{ width: 256 }}
                    onClick={(e) => menuItemClicked(e)}
                  />
                </div>
              </div>
            </Drawer>
          </div>
        ) : (
          <div className="drawer">
            <Dropdown
              trigger={["hover"]}
              placement="bottomRight"
              overlay={spotWalletDropdown}
            >
              <div
                className={
                  [
                    ROUTES.buy,
                    ROUTES.vesting,
                    ROUTES.wallet,
                    ROUTES.swap,
                    ROUTES.transactions,
                    ROUTES.stake,
                  ].includes(window.location.pathname)
                    ? "menuItem menu-item-active menuItemWithSubmenu"
                    : "menuItem menuItemWithSubmenu"
                }
              >
                Spot wallet
                <FiChevronDown className="submenuArrow" />
              </div>
            </Dropdown>

            {menu.map((item) => (
              <Link
                key={item.id}
                to={item.href}
                onClick={() => menuItemClicked(item)}
                className={`menuItem ${
                  window.location.pathname === item.href ||
                  (window.location.pathname.indexOf("article") !== -1 &&
                    item.href === "/news") ||
                  (window.location.pathname.indexOf("events") !== -1 &&
                    item.href === "/events")
                    ? "menu-item-active"
                    : ""
                }`}
              >
                {item.name}
              </Link>
            ))}

            <Select
              loading={currenciesLoading}
              value={selectedCurrency}
              style={{ width: 90 }}
              onChange={(val) => currencyChanged(val)}
              suffixIcon={<FiChevronDown />}
              className="selectCurrency"
              optionLabelProp={"name"}
            >
              {currencies.length > 0 &&
                currencies.map((item, key) => (
                  <Option value={item.name} key={key} title={item.name}>
                    {item.name} <span>{item.symbol}</span>
                  </Option>
                ))}
            </Select>
          </div>
        )}
      </div>
    </Header>
  );
};

export default HeaderLayout;
