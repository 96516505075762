export const trendingNews = [
  {
    thumbnail: "https://picsum.photos/300/350",
    title: "Crypto in Chaos, but Blockchain-powered Pay and Stablecoins",
    href: "#",
  },
  {
    thumbnail: "https://picsum.photos/305/350",
    title: "Dogecoin 2.0 Revealed: Revelations From A Big, Anonymous Investor",
    href: "#",
  },
  {
    thumbnail: "https://picsum.photos/320/350",
    title: "What Are Salvadorans Doing with their USD 30 Bitcoin Giveaways?",
    href: "#",
  },
  {
    thumbnail: "https://picsum.photos/325/350",
    title: "What Are Salvadorans Doing with their USD 30 Bitcoin Giveaways?",
    href: "#",
  },
  {
    thumbnail: "https://picsum.photos/300/350",
    title: "Crypto in Chaos, but Blockchain-powered Pay and Stablecoins",
    href: "#",
  },
  {
    thumbnail: "https://picsum.photos/305/350",
    title: "Dogecoin 2.0 Revealed: Revelations From A Big, Anonymous Investor",
    href: "#",
  },
  {
    thumbnail: "https://picsum.photos/320/350",
    title: "What Are Salvadorans Doing with their USD 30 Bitcoin Giveaways?",
    href: "#",
  },
  {
    thumbnail: "https://picsum.photos/325/350",
    title: "What Are Salvadorans Doing with their USD 30 Bitcoin Giveaways?",
    href: "#",
  },
];

export const publicHighlightedArticles = [
  {
    href: "",
    cover: "https://picsum.photos/1000/750",
    title: "Can Blockchain Help Underfunded Startups Make It Past Infancy?",
    categories: ["Technology", "Crypto", "Blockchain", "Banking", "NFTs"],
    date: "21 June 2022",
    excerpt:
      "FashionGuru is the up and coming clothing store that will have a significant impact on all fashion lovers lives. Simple, elegant and qualitative.",
    feeling: {
      label: "Positive",
      class: "good",
    },
  },
  {
    href: "",
    cover:
      "https://d32bfp67k1q0s7.cloudfront.net/300b4effad52f410f46878f9fe9dc2d5.png",
    title: "Swappi (PPI) Announces First Exchange Listing on BitMart",
    categories: ["Technology", "Crypto", "Blockchain", "Banking", "NFTs"],
    date: "21 June 2022",
    excerpt:
      "PPI, the native token of Swappi, is now available for trading on global crypto exchange BitMart Swappi, an AMM-based decentralized exchange (DEX) deployed on Conflux eSpace, today announced its first exchange listing on BitMart. Swappi’s native token PPI is now available for trading on BitMart, a leading crypto exchange with millions of users globally. As the first exchange to list Swappi, BitMart lets users trade the PPI/USDT pair.",
    feeling: {
      label: "Negative",
      class: "bad",
    },
  },
  {
    href: "",
    cover:
      "https://www.tbstat.com/wp/uploads/2018/12/European_Parliament_Strasbourg_2015-10-28_02-688x450.jpg",
    title: "EU reaches agreement on final landmark crypto regulation",
    categories: ["EU", "Crypto Market", "Blockchain", "Regulations"],
    date: "30 June 2022",
    excerpt:
      "The European Union has finalized its massive Markets in Crypto Assets regulation. Stefan Berger, MiCA's rapporteur in European Parliament,  tweeted the news on June 30. The bill had been in trilogue debates, bringing the European Parliament, Council and Commission together to hash out a final compromise.",
    feeling: {
      label: "Neutral",
      class: "",
    },
  },
  {
    href: "",
    cover:
      "https://www.tbstat.com/wp/uploads/2021/11/211029_SEC_General4-800x450.jpg",
    title:
      "Grayscale files suit against SEC following rejection of GBTC conversion bid",
    categories: ["Technology", "Crypto", "Blockchain", "Banking", "NFTs"],
    date: "21 June 2022",
    excerpt:
      "Crypto asset management firm Grayscale Investments has filed a lawsuit against the Securities and Exchange Commission, the United States regulator. The company announced on June 29 that it would challenge a decision by the watchdog to deny its bid to convert the Grayscale Bitcoin Trust into a spot bitcoin exchange-traded fund.",
    feeling: {
      label: "Neutral",
      class: "",
    },
  },
];

export const protectedSliderSettings = {
  dots: false,
  infinite: true,
  centerMode: false,
  autoplay: true,
  speed: 5000,
  autoplaySpeed: 1,
  slidesToShow: 6,
  slidesToScroll: 1,
  initialSlide: 1,
  responsive: [
    { breakpoint: 1550, settings: { slidesToShow: 5 } },
    { breakpoint: 1250, settings: { slidesToShow: 4 } },
    { breakpoint: 960, settings: { slidesToShow: 3 } },
    {
      breakpoint: 570,
      settings: {
        slidesToShow: 2,
        autoplay: false,
        centerMode: false,
        infinite: false,
      },
    },
  ],
};

export const publicSliderSettings = {
  dots: true,
  infinite: true,
  centerMode: false,
  autoplay: true,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    { breakpoint: 1550, settings: { slidesToShow: 5 } },
    { breakpoint: 1250, settings: { slidesToShow: 3 } },
    { breakpoint: 960, settings: { slidesToShow: 3 } },
    {
      breakpoint: 570,
      settings: {
        slidesToShow: 2,
        autoplay: false,
        centerMode: false,
        infinite: false,
      },
    },
  ],
};
