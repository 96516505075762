import { Avatar, Dropdown, Image, Layout, Menu, Drawer } from "antd";
import "./style.less";
import logo from "../../../assets/images/logo_finance.svg";
import { useEffect, useState } from "react";
import { useWindowSize } from "../../../hooks/windowSize";
import { scrollTo } from "../../../_helpers/scroll";
import { menu, menuPublic, menuPublicMobile } from "./data";
import classnames from "classnames";
import Hamburger from "react-hamburgers";
import { Button } from "../../index";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../../_helpers/config";
import { FiChevronDown, FiUser } from "react-icons/fi";
import { BsArrowLeft } from "react-icons/bs";
import { MdLogin } from "react-icons/md";
import { pushGtmEvent } from "../../../_helpers/gtm";
import { GtmEvents } from "../../../_helpers/types";
import { ButtonTypes } from "../../Button/types";

const { Header } = Layout;

const HeaderLayout = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1070;
  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState(null);
  const [showDrawer, toggleDrawer] = useState(false);

  useEffect(() => {
    setCurrent(window.location.pathname);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      toggleDrawer(false);
    }
  }, [isMobile]);

  const menuItemClicked = (item) => {
    if (item?.keyPath[1] !== "currency") {
      setCurrent(item.key);
    }

    if (item?.href) {
      scrollTo(item.href);
    }

    if (item?.GtmKey) {
      pushGtmEvent(GtmEvents[item.GtmKey]);
    }

    if (isMobile) {
      toggleDrawer(!showDrawer);
    }
  };

  const goToSection = (section) => {
    if (location.pathname === ROUTES.releaseNotes) {
      navigate(ROUTES.homePage);
    }
    if (isMobile) {
      toggleDrawer(!showDrawer);
    }
    scrollTo(section);
  };

  const getOpenMenu = (step) => {
    if (
      [
        ROUTES.news,
        ROUTES.events,
        ROUTES.launchpad,
        ROUTES.publicStake,
        ROUTES.referral,
        ROUTES.wallet,
        ROUTES.swap,
      ].includes(step)
    ) {
      return ["products"];
    } else {
      return null;
    }
  };

  return (
    <Header className="header">
      <Image
        src={logo}
        className="logo"
        preview={false}
        alt="SeedOn Finance - Logo"
        onClick={() => navigate(ROUTES.homePage)}
      />

      <div className="menu">
        {isMobile ? (
          <div className={classnames("menuIcon", showDrawer ? "active" : "")}>
            <Hamburger
              type="emphatic"
              active={showDrawer}
              onClick={() => toggleDrawer(!showDrawer)}
            />
          </div>
        ) : null}

        {isMobile ? (
          <div id="drawer-active" className="drawer">
            <Drawer
              title={
                <div className="userSection">
                  <div className="userAvatar">
                    <Avatar alt="profile-avatar" className="thumbnail">
                      <FiUser />
                    </Avatar>
                  </div>
                  <div className="userSectionName">
                    Hello,
                    <br /> Seeder 🌱
                  </div>
                  <div
                    className="closeDrawer"
                    onClick={() => toggleDrawer(false)}
                  >
                    <BsArrowLeft size={20} color="#fff" />
                  </div>
                </div>
              }
              className="drawer-menu"
              placement="left"
              onClose={() => toggleDrawer(false)}
              open={showDrawer}
              footer={
                <Button
                  label="Login"
                  type={ButtonTypes.SECONDARY}
                  icon={<MdLogin size={20} />}
                  extraClasses="logoutButton"
                  onClick={() =>
                    (window.location.href = process.env.REACT_APP_AUTH_URL)
                  }
                />
              }
            >
              <div className="drawer-content">
                <div className="drawerBody">
                  <Menu
                    mode="inline"
                    items={menuPublicMobile(goToSection)}
                    selectedKeys={current}
                    defaultOpenKeys={getOpenMenu(current)}
                    style={{ width: 256 }}
                    onClick={(e) => menuItemClicked(e)}
                  />
                </div>
              </div>
            </Drawer>
          </div>
        ) : (
          <div className="drawer">
            <Dropdown
              trigger={["hover"]}
              placement="bottomRight"
              overlay={menuPublic}
            >
              <div
                className={
                  [
                    ROUTES.news,
                    ROUTES.events,
                    ROUTES.referral,
                    ROUTES.publicStake,
                    ROUTES.wallet,
                    ROUTES.swap,
                  ].includes(window.location.pathname)
                    ? "menuItem menu-item-active menuItemWithSubmenu"
                    : "menuItem menuItemWithSubmenu"
                }
              >
                Products
                <FiChevronDown className="submenuArrow" />
              </div>
            </Dropdown>

            {menu.map((item, index) => (
              <a
                href={item.href}
                key={index}
                className={`menuItem ${
                  window.location.pathname === item.href
                    ? "menu-item-active"
                    : ""
                }`}
                onClick={() => goToSection(item.id)}
              >
                {item.name}
              </a>
            ))}

            <Button
              label="Login"
              type={ButtonTypes.PRIMARY}
              extraClasses="loginButton"
              onClick={() =>
                (window.location.href = process.env.REACT_APP_AUTH_URL)
              }
            />
          </div>
        )}
      </div>
    </Header>
  );
};

export default HeaderLayout;
