import React, { useState, useEffect } from "react";
import "./style.less";
import { IModalProps } from "./interface";
import { Modal, notification } from "antd";
import PhoneInputForm from "./PhoneInputForm";
import { Button, CodeVerification } from "../index";
import { getCurrentUserRequest } from "../User/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../_helpers/config";
import { IoClose } from "react-icons/io5";
import { pushGtmEvent } from "../../_helpers/gtm";
import { GtmEvents } from "../../_helpers/types";
import { ButtonTypes } from "../Button/types";

const PhoneValidationModal: React.FC<IModalProps> = (props: IModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [chosenAction, setChosenAction] = useState("phoneInput");

  const phoneNumberValidationSuccess = () => {
    pushGtmEvent(GtmEvents.VALIDATE_PHONE_SUCCESS);
    dispatch(getCurrentUserRequest());
    notification.success({
      message: "Your phone number has been successfully validated.",
    });
    props.closeModal();
    navigate(ROUTES.homePage);
  };

  const phoneNumberInputCallback = (action, phone) => {
    setPhoneNumber(phone);
    setChosenAction(action);
  };

  return (
    <Modal
      closeIcon={<IoClose />}
      footer={null}
      className="authModals"
      open={props.isVisible}
      onCancel={() => props.closeModal()}
      forceRender
    >
      {chosenAction === "phoneInput" ? (
        <>
          <div className="heading">
            <h2>Phone validation</h2>
            <p>Add your phone number along with the area code.</p>
          </div>
          <PhoneInputForm
            action={chosenAction}
            callback={(newAction, formData) =>
              phoneNumberInputCallback(newAction, formData.phoneNumber)
            }
          />
        </>
      ) : (
        <>
          <CodeVerification
            type="sms"
            submitLabel="Continue"
            recipient={phoneNumber}
            endpoint={`${process.env.REACT_APP_AUTH_API_URL}/phone-number/validate`}
            onSuccess={() => phoneNumberValidationSuccess()}
          />

          <Button
            label="Back"
            extraClasses="goBackButton"
            onClick={() => setChosenAction("phoneInput")}
          />
        </>
      )}
    </Modal>
  );
};

export default PhoneValidationModal;
