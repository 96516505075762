import "./style.less";

const AnimatedScrollDown = () => {
  return (
    <div className="scrolLDown">
      <span />
      <span />
      <span />
    </div>
  );
};

export default AnimatedScrollDown;
