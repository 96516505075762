import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getStakingPoolsLoading = (state: AppState) => state.staking.loading;
const getStakingPoolsError = (state: AppState) => state.staking.error;
const getStakingPools = (state: AppState) => state.staking.stakingPools;

const getStakingPoolSummaryLoading = (state: AppState) =>
  state.staking.stakingPoolSummaryLoading;
const getStakingPoolSummary = (state: AppState) =>
  state.staking.stakingPoolSummary;

const getStakingPoolDetailsLoading = (state: AppState) =>
  state.staking.stakingPoolDetailsLoading;
const getStakingPoolDetails = (state: AppState) =>
  state.staking.stakingPoolDetails;

const getDepositStakingLoading = (state: AppState) =>
  state.staking.depositStakingLoading;
const getDepositStakingErrors = (state: AppState) =>
  state.staking.depositStakingErrors;
const getDepositStakingSuccess = (state: AppState) =>
  state.staking.depositStakingSuccess;

export const getStakingPoolsLoadingSelector = createSelector(
  getStakingPoolsLoading,
  (res) => res
);
export const getStakingPoolsErrorSelector = createSelector(
  getStakingPoolsError,
  (res) => res
);
export const getStakingPoolsSelector = createSelector(
  getStakingPools,
  (res) => res
);

export const depositStakingLoadingSelector = createSelector(
  getDepositStakingLoading,
  (res) => res
);
export const depositStakingErrorsSelector = createSelector(
  getDepositStakingErrors,
  (res) => res
);
export const depositStakingSuccessSelector = createSelector(
  getDepositStakingSuccess,
  (res) => res
);

export const getStakingPoolSummaryLoadingSelector = createSelector(
  getStakingPoolSummaryLoading,
  (res) => res
);
export const getStakingPoolSummarySelector = createSelector(
  getStakingPoolSummary,
  (res) => res
);

export const getStakingPoolDetailsLoadingSelector = createSelector(
  getStakingPoolDetailsLoading,
  (res) => res
);
export const getStakingPoolDetailsSelector = createSelector(
  getStakingPoolDetails,
  (res) => res
);
