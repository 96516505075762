export interface ISwapPreviewProps {
  toSwapTokenContractAddress: string;
}

export interface ISwapPreviewCoinSelectProps {
  availableTokens: Array<IWhitelistedCoin>;
  onValueChange: (value: number) => void;
  currentSelectedToken: IWhitelistedCoin;
  currentValue: number;
  onTokenChange?: (token: IWhitelistedCoin) => void;
}

export interface IWhitelistedCoin {
  chainId: string;
  contractAddress: string;
  decimals: number;
  disabled: boolean;
  iconUrl: string;
  id: string;
  projectId: string;
  tokenName: string;
  tokenSymbol: string;
}

export enum SwapDirection {
  TO_TOKEN_OF_INTEREST = 0,
  FROM_TOKEN_OF_INTEREST = 1,
}
