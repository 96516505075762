import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axios } from "../../services/axios.service";
import { IPortfolioResponse } from "./types";
import {
  getUserInvestmentDetailsFailure,
  getUserInvestmentDetailsRequest,
  getUserInvestmentDetailsSuccess,
  getUserPortfolioFailure,
  getUserPortfolioRequest,
  getUserPortfolioSuccess,
} from "./slice";
import { globalVariables } from "../../_helpers/config";

function* getUserPortfolio(action: ReturnType<typeof getUserPortfolioRequest>) {
  try {
    const response: AxiosResponse<IPortfolioResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/fundraising/launchpad/portfolio`,
      {
        params: {
          ...action.payload,
          pageSize: globalVariables.portfolioPageSize,
        },
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getUserPortfolioSuccess(response.data));
  } catch (e: any) {
    yield put(getUserPortfolioFailure(e));
  }
}

function* getUserInvestmentDetails(
  action: ReturnType<typeof getUserInvestmentDetailsRequest>
) {
  try {
    const response: AxiosResponse<IPortfolioResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/fundraising/launchpad/investments/${action.payload.projectId}`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getUserInvestmentDetailsSuccess(response.data));
  } catch (e: any) {
    yield put(getUserInvestmentDetailsFailure());
  }
}

function* userPortfolioSaga() {
  yield all([
    takeLatest(getUserPortfolioRequest, getUserPortfolio),
    takeLatest(getUserInvestmentDetailsRequest, getUserInvestmentDetails),
  ]);
}

export default userPortfolioSaga;
