import React, { useEffect } from "react";
import "./style.less";
import moment from "moment";

// Assets
import { FiSettings } from "react-icons/fi";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getMaintenanceSelector } from "./selectors";
import { getMaintenanceRequest } from "./maintenanceSlice";

const Maintenance = () => {
  const dispatch = useDispatch();
  const maintenance = useSelector(getMaintenanceSelector);

  useEffect(() => {
    dispatch(getMaintenanceRequest());
  }, []);

  return (
    <>
      {maintenance && maintenance.status === "ACTIVE" ? (
        <div className="maintenance">
          <p>
            <FiSettings />
            Maintenance: Scheduled between{" "}
            <span>
              {moment(maintenance.timeFrom).format("DD MM yyyy hh:mm")} UTC and{" "}
              {moment(maintenance.timeTo).format("DD MM yyyy hh:mm")} UTC
            </span>
            . {maintenance.message}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default Maintenance;
