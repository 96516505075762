import React, { useEffect, useState } from "react";
import "./style.less";
import { Form, Modal } from "antd";
import { IoClose } from "react-icons/io5";
import { Button, FormInput } from "../../index";
import { IWithdrawModal } from "./interface";
import {
  API_VALIDATION_ERROR_MESSAGES,
  CURRENCIES,
  VALIDATION_ERROR_MESSAGES,
} from "../../../_helpers/config";
import primaryAccount from "../../../assets/images/primary-account.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletSelector,
  getWhitelistedCoinsSelector,
} from "../../Wallet/selectors";
import FormDropdownSelect from "../../DropdownSelect";
import { getAvailableFundsRequest } from "../../Wallet/walletSlice";
import debounce from "lodash/debounce";
import {
  generateOTPCodeRequest,
  getWithdrawEstimateRequest,
  resetWalletValidation,
  resetWithdrawEstimate,
  validateWalletRequest,
} from "./withdrawSlice";
import {
  getWalletValidationErrorSelector,
  getWalletValidationLoadingSelector,
  getWithdrawEstimateErrorSelector,
  getWithdrawEstimateLoadingSelector,
  getWithdrawEstimateSelector,
} from "./selectors";

import {
  formatNumber,
  formatStringNumber,
} from "../../../_helpers/numberFormatter";
import { getConfigSelector } from "../../Layout/selectors";
import {
  getCanSendCodeRequest,
  getCanSendEmailCodeRequest,
  resetCanSendCodeState,
} from "../../CodeVerification/codeVerificationSlice";
import {
  getCanSendEmailCode,
  getCanSendSmsCode,
} from "../../CodeVerification/selectors";
import { pushGtmEvent } from "../../../_helpers/gtm";
import { GtmEvents } from "../../../_helpers/types";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getCurrentUserSelector } from "../../User/selectors";
import FormInputNumber from "../../FormInputNumber";
import { ButtonTypes } from "../../Button/types";

const WithdrawModal = (props: IWithdrawModal) => {
  const [formRef] = Form.useForm();
  const dispatch = useDispatch();
  const config = useSelector(getConfigSelector);

  const [selectedToken, setSelectedToken] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [formData, setFormData] = useState(null);

  const whitelistedCoins = useSelector(getWhitelistedCoinsSelector);
  const wallet = useSelector(getWalletSelector);
  const user = useSelector(getCurrentUserSelector);

  const estimation = useSelector(getWithdrawEstimateSelector);
  const estimationLoading = useSelector(getWithdrawEstimateLoadingSelector);
  const estimationError = useSelector(getWithdrawEstimateErrorSelector);

  const walletValidationLoading = useSelector(
    getWalletValidationLoadingSelector
  );
  const walletValidationError = useSelector(getWalletValidationErrorSelector);

  const canSendEmailCode = useSelector(getCanSendEmailCode);
  const canSendPhoneCode = useSelector(getCanSendSmsCode);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (canSendEmailCode && canSendPhoneCode) {
      const generateOtpCode = async () => {
        const token = await executeRecaptcha("withdrawOtp");
        dispatch(generateOTPCodeRequest({ captchaToken: token }));
      };
      generateOtpCode().then(() => {});
      dispatch(resetCanSendCodeState());
      props.onSubmit(formData);
    }
  }, [canSendEmailCode, canSendPhoneCode]);

  const tokenChanged = (val) => {
    setSelectedToken(val.contractAddress);
    setTokenSymbol(val.tokenSymbol);

    if (!wallet[`${val.contractAddress}Funds`]) {
      dispatch(
        getAvailableFundsRequest({
          contractAddress: val.contractAddress,
        })
      );
    }

    if (formRef.getFieldValue("amount")) {
      dispatch(
        getWithdrawEstimateRequest({
          contractAddress: val.contractAddress,
          withdrawalAmount: formRef.getFieldValue("amount"),
        })
      );
    }
  };

  const insertMaxAmount = () => {
    if (selectedToken) {
      formRef.setFieldsValue({
        amount: wallet[`${selectedToken}Funds`].availableFunds,
      });
      dispatch(
        getWithdrawEstimateRequest({
          contractAddress: formRef.getFieldValue("token").contractAddress,
          withdrawalAmount: wallet[`${selectedToken}Funds`].availableFunds,
        })
      );
    }
  };

  const amountChanged = (e) => {
    if (formRef.getFieldValue("token") && e) {
      dispatch(
        getWithdrawEstimateRequest({
          contractAddress: formRef.getFieldValue("token").contractAddress,
          withdrawalAmount: e,
        })
      );
    } else {
      dispatch(resetWithdrawEstimate());
    }
  };

  const submitWithdraw = () => {
    formRef.validateFields().then((formValues) => {
      dispatch(
        getCanSendEmailCodeRequest({
          type: "EMAIL",
          userIsNotAuthenticated: false,
        })
      );
      dispatch(
        getCanSendCodeRequest({ type: "SMS", userIsNotAuthenticated: false })
      );
      setFormData(formValues);
    });
  };

  const walletAddressChanged = (e) => {
    if (e.target.value) {
      dispatch(
        validateWalletRequest({
          walletAddress: e.target.value,
        })
      );
    }
  };
  useEffect(() => {
    pushGtmEvent(GtmEvents.WITHDRAW_STEP_1);
    return () => {
      formRef.resetFields();
      dispatch(resetCanSendCodeState());
      dispatch(resetWithdrawEstimate());
      dispatch(resetWalletValidation());
    };
  }, []);

  useEffect(() => {
    if (formRef.getFieldValue("to")) {
      pushGtmEvent(GtmEvents.WITHDRAW_STEP_1_ERROR);
      formRef.setFields([
        {
          name: "to",
          errors: walletValidationError
            ? [VALIDATION_ERROR_MESSAGES.invalidWalletAddress]
            : [],
        },
      ]);
    }
  }, [walletValidationError]);

  useEffect(() => {
    if (formRef.getFieldValue("amount")) {
      pushGtmEvent(GtmEvents.WITHDRAW_STEP_1_ERROR);
      if (
        estimationError &&
        estimationError.message === "WITHDRAWAL_MIN_AMOUNT_CONDITION_NOT_MET"
      ) {
        const minAmount =
          config.withdrawalConfiguration.minFiatAmount /
          wallet[`${selectedToken}Funds`].tokenData.currentPrice;
        formRef.setFields([
          {
            name: "amount",
            errors: estimationError
              ? [
                  API_VALIDATION_ERROR_MESSAGES.WITHDRAWAL_MIN_AMOUNT_CONDITION_NOT_MET(
                    formatStringNumber(estimationError.payload),
                    tokenSymbol
                  ),
                ]
              : [],
          },
        ]);
      } else {
        formRef.setFields([
          {
            name: "amount",
            errors: estimationError
              ? [VALIDATION_ERROR_MESSAGES.userBalanceExceeded]
              : [],
          },
        ]);
      }
    }
  }, [estimationError]);

  return (
    <>
      <Modal
        closeIcon={<IoClose />}
        open={props.isVisible}
        onCancel={() => props.onCancel()}
        className="authModals walletModals withdrawModal"
        maskClosable={false}
        forceRender
        footer={null}
      >
        <Scrollbars
          universal
          style={{ height: "75vh" }}
          renderView={(props) => <div {...props} className="scroll-view" />}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
        >
          <div className="heading">
            <h2>Withdraw funds</h2>
          </div>

          <Form
            form={formRef}
            className="withdrawForm"
            initialValues={{
              from: "Primary Account",
            }}
          >
            <Form.Item
              hasFeedback
              name="token"
              rules={[
                { required: true, message: VALIDATION_ERROR_MESSAGES.required },
              ]}
            >
              <FormDropdownSelect
                label="Token"
                onChange={tokenChanged}
                items={whitelistedCoins}
                keyToShow={"tokenSymbol"}
                keyForImage={"iconUrl"}
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              name={"from"}
              rules={[
                { required: true, message: VALIDATION_ERROR_MESSAGES.required },
              ]}
            >
              <FormInput
                label="From"
                disabled
                prefix={
                  <img
                    className="accountIcon"
                    src={primaryAccount}
                    alt="account-icon"
                  />
                }
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              name={"to"}
              rules={[
                { required: true, message: VALIDATION_ERROR_MESSAGES.required },
              ]}
            >
              <FormInput
                label="To"
                min={1}
                onChange={debounce(walletAddressChanged, 300)}
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              name={"amount"}
              rules={[
                { required: true, message: VALIDATION_ERROR_MESSAGES.required },
                ({}) => ({
                  validator(_, value) {
                    if (value <= 0) {
                      return Promise.reject(
                        new Error(VALIDATION_ERROR_MESSAGES.minimumAmount)
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <FormInputNumber
                min={"0"}
                step={"0.000000000000000001"}
                onChange={debounce(amountChanged, 300)}
                hidelabel={"true"}
                label="Amount"
                prefix={
                  selectedToken ? (
                    <span
                      className="maxValue"
                      onClick={() => insertMaxAmount()}
                    >
                      MAX
                    </span>
                  ) : (
                    <></>
                  )
                }
              />
            </Form.Item>
          </Form>

          <div className="disclaimers">
            <p>
              <span>
                Available Tokens {tokenSymbol ? `(${tokenSymbol})` : ""}
              </span>
              <span>
                {wallet[`${selectedToken}Funds`]
                  ? `${formatNumber(
                      wallet[`${selectedToken}Funds`].availableFunds
                    )}`
                  : "N/A"}
              </span>
            </p>

            <p>
              <span>Commission</span>
              <span>
                {estimation && wallet[`${selectedToken}Funds`]
                  ? `${
                      user
                        ? CURRENCIES[user.state.properties.selectedCurrency]
                        : ""
                    }${formatNumber(
                      estimation.commission *
                        wallet[`${selectedToken}Funds`].tokenData.currentPrice
                    )}`
                  : "N/A"}
              </span>
            </p>

            <p>
              <span>
                Amount to Receive {tokenSymbol ? `(${tokenSymbol})` : ""}
              </span>
              <span>
                {estimation && wallet[`${selectedToken}Funds`]
                  ? `${formatNumber(estimation.amountToReceive)} ${tokenSymbol}`
                  : "N/A"}
              </span>
            </p>

            {config && config.withdrawalConfiguration ? (
              <p className="disclaimer">
                <span>
                  Minimum withdrawal amount is{" "}
                  {user
                    ? CURRENCIES[user.state.properties.selectedCurrency]
                    : ""}
                  {config.withdrawalConfiguration.minFiatAmount} worth of your
                  token.
                </span>
              </p>
            ) : (
              ""
            )}
          </div>

          <Button
            label="Withdraw"
            type={ButtonTypes.PRIMARY}
            extraClasses="submitBtn"
            disabled={!estimation || walletValidationError}
            // disabled
            loading={estimationLoading || walletValidationLoading}
            onClick={submitWithdraw}
          />
          {canSendEmailCode === false ? (
            <span className="generalError">
              {VALIDATION_ERROR_MESSAGES.tooManyEmailOtpShort}
            </span>
          ) : null}
          {canSendPhoneCode === false ? (
            <span className="generalError">
              {VALIDATION_ERROR_MESSAGES.tooManySmsOtpShort}
            </span>
          ) : null}
        </Scrollbars>
      </Modal>
    </>
  );
};

export default WithdrawModal;
