import { useDispatch, useSelector } from "react-redux";
import {
  getEstimateRates,
  getEstimateRatesError,
  getEstimateRatesLoading,
  getPreparedOrder,
  getPreparedOrderLoading,
  getWalletSelector,
} from "../Wallet/selectors";
import React, { useCallback, useEffect, useState } from "react";
import {
  estimateRatesRequest,
  getAvailableFundsRequest,
  purchaseOrderRequest,
  resetEstimateRatesState,
  resetPurChaseOrderState,
} from "../Wallet/walletSlice";
import { getCurrentUserSelector } from "../User/selectors";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import "./styles.less";
import { Col, Form, Image, notification, Row, Tooltip } from "antd";
import Asset from "../../assets/images/asset-card-payment.png";
import { Button } from "../index";
import { ButtonTypes } from "../Button/types";
import FormInputNumber from "../FormInputNumber";
import debounce from "lodash/debounce";
import FormDropdownSelect from "../DropdownSelect";
import SwipeLuxModal from "./SwipeLuxModal";
import { ROUTES, VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";
import { formatStringNumber } from "../../_helpers/numberFormatter";
import { isGreater, isLower } from "../../_helpers/numberFunctions";
import seonCoin from "../../assets/images/seon-token.png";
import { FiHelpCircle, FiRotateCw } from "react-icons/fi";
import Counter from "../Counter";
import moment from "moment";
import { BiRightArrowAlt } from "react-icons/bi";

const Buy = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formRef] = Form.useForm();
  const currencyItems = [
    {
      symbol: "EUR",
      image: "/icon_eur.png",
    },
    {
      symbol: "USD",
      image: "/icon_usd.png",
    },
  ];
  const dueDate = "2023-03-15 13:00:00";
  // const dueDate = "2023-03-10 15:01:00";
  const [displayVideo, setDisplayVideo] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<any>(
    currencyItems[0]
  );
  const user = useSelector(getCurrentUserSelector);
  const fiatValue = Form.useWatch("value", formRef);
  const [swipeLuxModalVisible, setSwipeLuxModalVisible] = useState(false);
  const [counterFinished, setCounterFinished] = useState(
    moment.utc(dueDate).diff(moment(), "seconds") <= 0
  );
  const [interval, setIntervalVariable] = useState(null);

  const purchaseOrder = useSelector(getPreparedOrder);
  const purchaseOrderLoading = useSelector(getPreparedOrderLoading);

  const rateEstimates = useSelector(getEstimateRates);
  const rateEstimatesLoading = useSelector(getEstimateRatesLoading);
  const rateEstimatesError = useSelector(getEstimateRatesError);

  const wallet = useSelector(getWalletSelector);

  const initWidget = async () => {
    formRef.validateFields().then(async () => {
      if (user) {
        const token = await executeRecaptcha("purchaseOrder");
        dispatch(
          purchaseOrderRequest({
            from: {
              currency: selectedCurrency.symbol,
              amount: fiatValue,
            },
            to: {
              currency: "SEON",
            },
            captchaToken: token,
          })
        );
      } else {
        localStorage.setItem("intended", window.location.pathname);
        window.location.href = process.env.REACT_APP_AUTH_URL;
      }
    });
  };

  const estimateRates = (value) => {
    if (value && !isLower(value, "15") && !isGreater(value, "2500")) {
      const sendForm = async () => {
        if (value && isGreater(value, "0")) {
          const token = await executeRecaptcha("estimateRates");
          dispatch(
            estimateRatesRequest({
              from: {
                currency: formRef.getFieldValue("selectedToken").symbol,
                amount: value,
              },
              to: {
                currency: "SEON",
              },
              captchaToken: token,
            })
          );
        }
      };
      sendForm().then();
    }
  };

  const debouncedEstimateChanged = useCallback(debounce(estimateRates, 500), [
    executeRecaptcha,
    selectedCurrency,
  ]);
  useEffect(() => {
    if (user) {
      dispatch(
        getAvailableFundsRequest({
          contractAddress: process.env.REACT_APP_SEON_CONTRACT_ADDRESS,
        })
      );
    }
  }, [user]);
  useEffect(() => {
    setTimeout(() => {
      setDisplayVideo(true);
    }, 1000);

    formRef.setFieldsValue({ selectedToken: currencyItems[0] });
    return () => {
      dispatch(resetPurChaseOrderState());
      dispatch(resetEstimateRatesState());
    };
  }, []);
  useEffect(() => {
    if (rateEstimatesError) {
      notification.error({
        icon: <FiRotateCw />,
        message:
          "Ooops, looks like something went wrong. Please try again in a few seconds.",
      });
    }
  }, [rateEstimatesError]);
  useEffect(() => {
    if (selectedCurrency) {
      estimateRates(fiatValue);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (purchaseOrder) {
      setSwipeLuxModalVisible(true);
    }
  }, [purchaseOrder]);
  useEffect(() => {
    if (swipeLuxModalVisible && interval) {
      clearInterval(interval);
    }
    if (!swipeLuxModalVisible && !interval && executeRecaptcha) {
      setIntervalVariable(
        setInterval(() => {
          if (!swipeLuxModalVisible) {
            estimateRates(formRef.getFieldValue("value"));
          }
        }, 15000)
      );
    }
    return () => {
      clearInterval(interval);
      setIntervalVariable(null);
    };
  }, [swipeLuxModalVisible, executeRecaptcha]);
  return !counterFinished ? (
    <Counter
      dueDate={dueDate}
      callback={() => setCounterFinished(true)}
      title={"Get Ready to Seize SEON!"}
      description={
        "The future of finance is here, and SeedOn is leading the charge! As the world's first blockchain-based crowdfunding platform, SeedOn is changing the way we invest and raise capital. And now, you can be a part of it all by buying SEON tokens with your debit card. Starting March 15, 2023 at 1:00 PM (UTC), you can join the SeedOn community and invest in a better tomorrow."
      }
    />
  ) : (
    <>
      <section className="page-wrapper page-buy">
        <section className="container" data-aos="fade-right">
          <section className="content-buy">
            <Row>
              <Col xs={0} lg={12} className="col-left">
                <div className="asset-card-payment">
                  <div className="container-asset">
                    <div className="user-details">
                      <div className="avatar">
                        <Image src={seonCoin} preview={false} alt="user" />
                      </div>
                      <div className="current-amount">
                        {wallet &&
                          wallet[
                            `${process.env.REACT_APP_SEON_CONTRACT_ADDRESS}Funds`
                          ] &&
                          wallet[
                            `${process.env.REACT_APP_SEON_CONTRACT_ADDRESS}Funds`
                          ].availableFunds && (
                            <Tooltip
                              title={`${formatStringNumber(
                                wallet[
                                  `${process.env.REACT_APP_SEON_CONTRACT_ADDRESS}Funds`
                                ].availableFunds
                              )} SEON`}
                            >
                              <div>Current amount</div>
                              <div className="amount">
                                {formatStringNumber(
                                  wallet[
                                    `${process.env.REACT_APP_SEON_CONTRACT_ADDRESS}Funds`
                                  ].availableFunds
                                )}
                              </div>
                              <span className="amount"> SEON</span>
                            </Tooltip>
                          )}
                      </div>
                    </div>
                    {displayVideo ? (
                      <video
                        playsInline
                        autoPlay
                        loop
                        muted
                        className="video-card-payment"
                        poster={Asset}
                      >
                        <source src="/asset-card-payment.mp4" />
                      </video>
                    ) : (
                      <Image src={Asset} preview={false} alt={"SeedOn"} />
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={12} className="col-right">
                <div className="content-card-payment">
                  <div className="title-card-payment">
                    <div className="title-payment">Buy SEON</div>
                  </div>

                  <div className="pay">
                    <div className="bg-left" />
                    <div className="content-pay">
                      <p>You will pay</p>
                      <Form
                        form={formRef}
                        initialValues={{
                          value: "0",
                        }}
                      >
                        <div className="payment-container">
                          <Form.Item
                            name="value"
                            rules={[
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    dispatch(resetEstimateRatesState());
                                    return Promise.reject(
                                      VALIDATION_ERROR_MESSAGES.required
                                    );
                                  }
                                  if (value && isLower(value, "15")) {
                                    dispatch(resetEstimateRatesState());
                                    return Promise.reject(
                                      `Minimum purchase is 15 ${selectedCurrency.symbol}`
                                    );
                                  }
                                  if (value && isGreater(value, "2500")) {
                                    dispatch(resetEstimateRatesState());
                                    return Promise.reject(
                                      `Maximum purchase is 2500 ${selectedCurrency.symbol}`
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <FormInputNumber
                              min={"0"}
                              label=""
                              hidelabel={"true"}
                              step={"0.000000000000000001"}
                              onChange={debouncedEstimateChanged}
                              disabled={true}
                            />
                          </Form.Item>
                          <div className="img-currency">
                            <Form.Item name="selectedToken">
                              <FormDropdownSelect
                                onChange={(val) => setSelectedCurrency(val)}
                                label=""
                                items={currencyItems}
                                keyToShow={"symbol"}
                                keyForImage={"image"}
                                onClear={() => {}}
                                extraClasses={"payment-dropdown-select"}
                              />
                            </Form.Item>

                            <img
                              src={selectedCurrency?.image}
                              alt="currency"
                              className={"selected-currency"}
                            />
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="bg-right" />
                  </div>
                  <div className="receive">
                    <div className="bg-left" />
                    <div className="content-receive">
                      <div className="payment-receive">
                        <p>SEON to receive</p>
                        <img src="/icon_seon.png" alt={"seon"} />
                      </div>
                      <div className="payment-container">
                        <FormInputNumber
                          label=""
                          value={
                            rateEstimates
                              ? formatStringNumber(rateEstimates.toAmount)
                              : "0"
                          }
                          hidelabel={"true"}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="bg-right" />
                  </div>
                  {/*<div className="details-payment">*/}
                  {/*  <div className="bg-bottom" />*/}
                  {/*  <div className="details-payment-container">*/}
                  {/*    {rateEstimates && (*/}
                  {/*      <>*/}
                  {/*        <div className="details-row">*/}
                  {/*          <span className="details-row-item">*/}
                  {/*            Rate updates*/}
                  {/*          </span>*/}
                  {/*          <span className="details-row-value">*/}
                  {/*            {formatStringNumber(rateEstimates.unitAmount)*/}
                  {/*              .length >= 20*/}
                  {/*              ? formatStringNumber(*/}
                  {/*                  rateEstimates.unitAmount*/}
                  {/*                ).slice(0, -10)*/}
                  {/*              : formatStringNumber(*/}
                  {/*                  rateEstimates.unitAmount*/}
                  {/*                )}{" "}*/}
                  {/*            SEON = 1 {selectedCurrency.symbol}*/}
                  {/*          </span>*/}
                  {/*        </div>*/}
                  {/*      </>*/}
                  {/*    )}*/}

                  {/*    <div className="details-row">*/}
                  {/*      {rateEstimates && (*/}
                  {/*        <>*/}
                  {/*          <span className="details-row-item">*/}
                  {/*            System fee included{" "}*/}
                  {/*             <Tooltip title="The conversion rate may differ on the last step of the payment process due to the volatility of prices.">*/}
                  {/*               <FiHelpCircle className="infoIcon" />*/}
                  {/*             </Tooltip>*/}
                  {/*           </span>*/}
                  {/*          <span className="details-row-value">*/}
                  {/*            {formatStringNumber(rateEstimates.feeAmount)}{" "}*/}
                  {/*            {selectedCurrency.symbol}*/}
                  {/*          </span>*/}
                  {/*        </>*/}
                  {/*      )}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="phoneConfirmationBox">
                    <div>
                      <p>
                        Temporarily, the option to make SEON purchases using a
                        debit card is unavailable.
                      </p>
                    </div>
                  </div>
                  {user && !user.state.properties.phoneNumberVerified && (
                    <div className="phoneConfirmationBox">
                      <div>
                        <p>
                          In order to buy SEON using a credit card, it is
                          necessary to validate your phone number first.{" "}
                        </p>
                        <span
                          className="validatePhoneAction"
                          onClick={() => {
                            localStorage.setItem(
                              "intended",
                              window.location.pathname
                            );
                            window.location.href = ROUTES.userProfile;
                          }}
                        >
                          Validate now <BiRightArrowAlt size={18} />
                        </span>
                      </div>
                    </div>
                  )}
                  {user && !user.state.properties.phoneNumberVerified ? (
                    <Button
                      type={ButtonTypes.PRIMARY}
                      label={
                        rateEstimates
                          ? `Buy ${formatStringNumber(
                              rateEstimates.toAmount
                            )} SEON`
                          : "BUY NOW"
                      }
                      extraClasses="btn-payment"
                      disabled
                      loading={purchaseOrderLoading || rateEstimatesLoading}
                      onClick={() => initWidget()}
                    />
                  ) : (
                    <Button
                      type={ButtonTypes.PRIMARY}
                      label={
                        rateEstimates
                          ? `Buy ${formatStringNumber(
                              rateEstimates.toAmount
                            )} SEON`
                          : "BUY NOW"
                      }
                      extraClasses="btn-payment"
                      disabled={true}
                      loading={purchaseOrderLoading || rateEstimatesLoading}
                      onClick={() => initWidget()}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </section>
          {swipeLuxModalVisible && (
            <SwipeLuxModal
              onCancel={() => {
                setSwipeLuxModalVisible(false);
                estimateRates(fiatValue);
              }}
              onSubmit={() => console.log("cancel")}
              orderToken={purchaseOrder?.accessToken}
              isVisible={swipeLuxModalVisible}
            />
          )}
        </section>
      </section>
    </>
  );
};

export default Buy;
