import { ROUTES } from "../../../_helpers/config";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { BsNewspaper } from "react-icons/bs";
import { AiOutlineCalendar } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { CgDollar } from "react-icons/cg";
import { FaHandHoldingUsd } from "react-icons/fa";
import { MdPersonAddAlt, MdOutlineHowToVote } from "react-icons/md";
import { TbArrowsLeftRight, TbPlus } from "react-icons/tb";
import { RiWalletLine } from "react-icons/ri";
import { TiInfoLargeOutline, TiThLargeOutline } from "react-icons/ti";

export const menu = [
  // {
  //   id: "launchpad",
  //   name: " Launchpad ",
  //   href: ROUTES.launchpad,
  // },
  {
    id: "about",
    name: "About",
    href: "/#about",
  },
  {
    id: "benefits",
    name: "Benefits",
    href: "/#benefits",
  },
  {
    id: "create-account",
    name: "How?",
    href: "/#create-account",
  },
];

export const menuPublic = (
  <Menu
    items={[
      {
        key: "buy",
        label: (
          <Link to={ROUTES.buy}>
            Buy <TbPlus />
          </Link>
        ),
        href: ROUTES.buy,
      },
      {
        key: "news",
        label: (
          <Link to={ROUTES.news}>
            News <BsNewspaper />
          </Link>
        ),
        href: ROUTES.news,
      },
      {
        key: "events",
        label: (
          <Link to={ROUTES.events}>
            Events <AiOutlineCalendar />
          </Link>
        ),
        href: ROUTES.events,
      },
      {
        key: "stake",
        label: (
          <Link to={ROUTES.publicStake}>
            Staking <CgDollar />
          </Link>
        ),
        href: ROUTES.stake,
      },
      {
        key: "refer",
        label: (
          <Link to={ROUTES.referral}>
            Referral Program <MdPersonAddAlt />
          </Link>
        ),
        href: ROUTES.referral,
      },
      {
        key: "wallet",
        label: (
          <Link to={ROUTES.wallet}>
            Wallet <RiWalletLine />
          </Link>
        ),
        href: ROUTES.wallet,
      },
      {
        key: "swap",
        label: (
          <Link to={ROUTES.swap}>
            Swap <TbArrowsLeftRight />
          </Link>
        ),
        href: ROUTES.swap,
      },
    ]}
  />
);

export const menuPublicMobile = (goToSection) => [
  {
    key: "products",
    label: (
      <div>
        <BiCategory /> Products
      </div>
    ),
    popupClassName: "headerSubMenu",
    children: [
      {
        key: ROUTES.buy,
        label: (
          <Link to={ROUTES.buy}>
            <TbPlus /> Buy
          </Link>
        ),
      },
      {
        key: ROUTES.news,
        label: (
          <Link to={ROUTES.news}>
            <BsNewspaper /> News
          </Link>
        ),
      },
      {
        key: ROUTES.events,
        label: (
          <Link to={ROUTES.events}>
            <AiOutlineCalendar />
            Events
          </Link>
        ),
      },
      {
        key: ROUTES.publicStake,
        label: (
          <Link to={ROUTES.publicStake}>
            <CgDollar /> Staking
          </Link>
        ),
      },
      {
        key: ROUTES.referral,
        label: (
          <Link to={ROUTES.referral}>
            <MdPersonAddAlt /> Referral Program
          </Link>
        ),
      },
      {
        key: "wallet",
        label: (
          <Link to={ROUTES.wallet}>
            <RiWalletLine /> Wallet
          </Link>
        ),
        href: ROUTES.wallet,
      },
      {
        key: "swap",
        label: (
          <Link to={ROUTES.swap}>
            <TbArrowsLeftRight /> Swap
          </Link>
        ),
        href: ROUTES.swap,
      },
    ],
  },
  // {
  //   key: "launchpad",
  //   label: (
  //     <Link to={ROUTES.launchpad}>
  //       <TiThLargeOutline /> Launchpad
  //     </Link>
  //   ),
  // },
  {
    key: "/#about",
    label: (
      <a href={"/#about"} onClick={() => goToSection("/#about")}>
        <TiInfoLargeOutline /> About
      </a>
    ),
  },
  {
    key: "/#benefits",
    label: (
      <a href={"/#benefits"} onClick={() => goToSection("/#benefits")}>
        <FaHandHoldingUsd /> Benefits
      </a>
    ),
  },
  {
    key: "/#create-account",
    label: (
      <a
        href={"/#create-account"}
        onClick={() => goToSection("/#create-account")}
      >
        <MdOutlineHowToVote /> How?
      </a>
    ),
  },
];
