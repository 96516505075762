import React, { useState } from "react";
import "./styles.less";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../_helpers/config";
import { pushGtmEvent } from "../../_helpers/gtm";
import { GtmEvents } from "../../_helpers/types";
import DepositModal from "../WalletControls/DepositModal";
import WithdrawModal from "../WalletControls/WithdrawModal";
import OtpModalMultiple from "../OtpModalMultiple";
import { scrollToTop } from "../../_helpers/scroll";
import { notification } from "antd";
import { useSelector } from "react-redux";
import { getCurrentUserSelector } from "../User/selectors";
import { getMaintenanceSelector } from "../Maintenance/selectors";

import BuyIcon from "../../assets/images/wallet/buy";
import StakeIcon from "../../assets/images/wallet/stake";
import SwapIcon from "../../assets/images/wallet/swap";
import WalletIcon from "../../assets/images/wallet/wallet";

export const WalletMobileNavigation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const currentStep = params.step;

  const user = useSelector(getCurrentUserSelector);

  const [depositModalVisible, setDepositModalVisible] = useState(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [additionalPayload, setAdditionalPayload] = useState(false);
  const [otpModalVisible, setOtpModalVisible] = useState(false);

  const stakeButtonClicked = () => {
    pushGtmEvent(GtmEvents.STAKE_BUTTON_CLICKED);
    navigate(ROUTES.stake);
  };

  const buyButtonClicked = () => {
    pushGtmEvent(GtmEvents.BUY_BUTTON_CLICKED);
    navigate(ROUTES.buy);
  };

  const withdrawSubmitted = (formData) => {
    setAdditionalPayload({
      ...formData,
      contractAddress: formData.token.contractAddress,
    });
    setWithdrawModalVisible(false);
    setOtpModalVisible(true);

    scrollToTop();
  };

  const otpModalSuccess = () => {
    notification.success({
      message: "Withdraw request created successfully.",
    });
    setOtpModalVisible(false);
    navigate(ROUTES.wallet);
  };

  return (
    <div className={`walletMobileNavigation ${currentStep}Step`}>
      <div
        className={`navButton ${
          [undefined, "deposit", "withdraw"].includes(currentStep)
            ? "buttonActive"
            : ""
        }`}
        onClick={() => {
          navigate(ROUTES.wallet);
        }}
      >
        <div className="buttonIcon">
          <WalletIcon />
        </div>
        <div className="buttonLabel">Wallet</div>
      </div>

      <div
        className={`navButton ${currentStep === "buy" ? "buttonActive" : ""}`}
        onClick={() => {
          buyButtonClicked();
        }}
      >
        <div className="buttonIcon">
          <BuyIcon />
        </div>
        <div className="buttonLabel">Buy</div>
      </div>

      <div
        className={`navButton ${currentStep === "stake" ? "buttonActive" : ""}`}
        onClick={() => stakeButtonClicked()}
      >
        <div className="buttonIcon">
          <StakeIcon />
        </div>
        <div className="buttonLabel">Stake</div>
      </div>

      <div
        className={`navButton ${currentStep === "swap" ? "buttonActive" : ""}`}
        onClick={() => navigate(ROUTES.swap)}
      >
        <div className="buttonIcon">
          <SwapIcon />
        </div>
        <div className="buttonLabel">Swap</div>
      </div>

      <DepositModal
        onCancel={() => setDepositModalVisible(false)}
        isVisible={depositModalVisible}
      />

      {withdrawModalVisible ? (
        <WithdrawModal
          isVisible={withdrawModalVisible}
          onCancel={() => setWithdrawModalVisible(false)}
          onSubmit={withdrawSubmitted}
        />
      ) : (
        ""
      )}

      {otpModalVisible ? (
        <OtpModalMultiple
          endpoint={"/withdraw"}
          isVisible={otpModalVisible}
          additionalPayload={additionalPayload}
          onSuccess={() => otpModalSuccess()}
          onCancel={() => setOtpModalVisible(false)}
        />
      ) : (
        ""
      )}
    </div>
  );
};
