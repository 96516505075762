import React, { useState } from "react";
import "./style.less";
import { IAnnouncementsModalProps } from "./interface";

// Assets
import { IoClose } from "react-icons/io5";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

// Components
import { Carousel } from "antd";
import { scroller } from "react-scroll";
import { Announcement } from "./announcement";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  markAnnouncementAsReadRequest,
  resetAnnouncementsState,
  setAnnouncementsModalVisible,
} from "./announcementSlice";
import { getAnnouncementsSelector } from "./selectors";

export const AnnouncementsModal: React.FC<IAnnouncementsModalProps> = (
  props: IAnnouncementsModalProps
) => {
  const dispatch = useDispatch();

  const announcements = useSelector(getAnnouncementsSelector);
  const [seenStories, setSeenStories] = useState([0]);

  const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <div {...props}>{children}</div>
  );

  const sliderSettings = {
    infinite: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <SlickArrow currentSlide slideCount>
        <FiChevronLeft />
      </SlickArrow>
    ),
    nextArrow: (
      <SlickArrow currentSlide slideCount>
        <FiChevronRight />
      </SlickArrow>
    ),
  };

  const closeModal = () => {
    seenStories.map((index) => {
      dispatch(
        markAnnouncementAsReadRequest({
          announcementId: announcements[index].id,
        })
      );
    });

    dispatch(setAnnouncementsModalVisible(false));
    dispatch(resetAnnouncementsState());
  };

  const markStoryAsSeen = (previous, current) => {
    let seenStoriesArr = [];

    if (!seenStories.includes(previous)) {
      seenStoriesArr.push(previous);
    }

    if (!seenStories.includes(current)) {
      seenStoriesArr.push(current);
    }

    scroller.scrollTo(0, {
      duration: 1600,
      offset: 0,
      delay: 0,
      containerId: `content-${current}`,
      smooth: "easeInOutQuart",
    });

    setSeenStories([...seenStories, ...seenStoriesArr]);
  };

  return announcements.length > 0 ? (
    <div className="announcementsModal">
      <button className="closeBtn" onClick={() => closeModal()}>
        <IoClose />
      </button>

      <div className="container">
        <Carousel
          {...sliderSettings}
          dotPosition={"top"}
          beforeChange={(from, to) => markStoryAsSeen(from, to)}
        >
          {announcements.map((item, index) => (
            <Announcement key={index} index={index} announcement={item} />
          ))}
        </Carousel>
      </div>
    </div>
  ) : (
    <></>
  );
};
