import { all, fork } from "redux-saga/effects";

import loginSaga from "components/LoginForm/sagas";
import registerSaga from "components/RegisterForm/sagas";
import configSaga from "components/Layout/sagas";
import codeVerificationSaga from "components/CodeVerification/sagas";
import forgotPasswordSaga from "components/ForgotPasswordForm/sagas";
import resetPasswordSaga from "pages/resetPassword/sagas";
import secretRecoverySaga from "components/SecretRecoveryModal/sagas";
import userSaga from "components/User/sagas";
import newsSaga from "components/News/sagas";
import depositSaga from "components/WalletControls/DepositModal/sagas";
import withdrawSaga from "components/WalletControls/WithdrawModal/sagas";
import walletSaga from "components/Wallet/sagas";
import phoneValidationSaga from "components/PhoneValidationModal/sagas";
import stakingSaga from "components/Staking/sagas";
import vestingSaga from "components/Vesting/sagas";
import maintenanceSaga from "components/Maintenance/sagas";
import eventsSaga from "components/Events/sagas";
import announcementsSaga from "components/AnnouncementsModal/sagas";
import notificationsSaga from "components/Notifications/sagas";
import stakingStatisticsSaga from "components/StakingStatistics/sagas";
import reportSaga from "components/ReportDownloadModal/sagas";
import swapSaga from "components/Swap/sagas";
import referralSaga from "components/Referral/sagas";
import tokenDetailsSaga from "../components/TokenDetails/sagas";
import campaignDetailsSaga from "../pages/campaignDetails/sagas";
import watchlistCampaignsSaga from "../components/WatchlistCampaigns/sagas";
import userPortfolioSaga from "../components/Portfolio/sagas";
import launchPadSaga from "../pages/launchpad/sagas";
import investSaga from "../pages/campaignInvest/sagas";

export function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(registerSaga),
    fork(configSaga),
    fork(codeVerificationSaga),
    fork(forgotPasswordSaga),
    fork(resetPasswordSaga),
    fork(secretRecoverySaga),
    fork(userSaga),
    fork(newsSaga),
    fork(depositSaga),
    fork(withdrawSaga),
    fork(walletSaga),
    fork(phoneValidationSaga),
    fork(stakingSaga),
    fork(vestingSaga),
    fork(maintenanceSaga),
    fork(eventsSaga),
    fork(announcementsSaga),
    fork(notificationsSaga),
    fork(stakingStatisticsSaga),
    fork(reportSaga),
    fork(swapSaga),
    fork(referralSaga),
    fork(tokenDetailsSaga),
    fork(campaignDetailsSaga),
    fork(launchPadSaga),
    fork(watchlistCampaignsSaga),
    fork(userPortfolioSaga),
    fork(investSaga),
  ]);
}
