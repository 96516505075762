import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { axios as axiosService } from "../../services/axios.service";
import {
  googleLoginFailure,
  googleLoginRequest,
  googleLoginSuccess,
  logoutRequest,
  logoutSuccess,
  postLoginFailure,
  postLoginRequest,
  postLoginSuccess,
} from "./loginSlice";

/*
  Worker Saga: Fired on postLoginRequest action
*/
function* postLoginSaga(action: ReturnType<typeof postLoginRequest>) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/sign-in`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(
      postLoginSuccess({
        loginRequest: action.payload,
      })
    );
  } catch (e: any) {
    yield put(postLoginFailure());
  }
}

function* googleLoginSaga(action: ReturnType<typeof googleLoginRequest>) {
  try {
    const response = yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/google/sign-in`,
      action.payload
    );
    yield put(
      googleLoginSuccess({
        googleLoginResponse: response.data,
      })
    );
  } catch (e: any) {
    yield put(googleLoginFailure());
  }
}

function* logoutSaga(action) {
  try {
    yield call(
      axiosService.put,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/logout`,
      {},
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(logoutSuccess());
  } catch (e: any) {}
}

/*
  Starts worker saga on latest dispatched `postLoginRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* loginSaga() {
  yield all([
    takeLatest(postLoginRequest, postLoginSaga),
    takeLatest(googleLoginRequest, googleLoginSaga),
    takeLatest(logoutRequest, logoutSaga),
  ]);
}

export default loginSaga;
