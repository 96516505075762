import React, { useEffect } from "react";
import { useWindowSize } from "../../hooks/windowSize";
import { IGlowAnimateCardWrapperProps } from "./glowAnimateCardWrapper.interface";
import "./style.less";

export const GlowAnimateCardWrapper = (props: IGlowAnimateCardWrapperProps) => {
  const { children, cardId } = props;
  const windowSize = useWindowSize();

  const cardEffect = () => {
    const card = document.getElementById(cardId);
    const glowElement = document.getElementById(`glow${cardId}`);
    let bounds;

    const rotateToMouse = (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const leftX = mouseX - bounds.x;
      const topY = mouseY - bounds.y;
      const center = {
        x: leftX - bounds.width / 2,
        y: topY - bounds.height / 2,
      };
      const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

      card.style.transform = `
                scale3d(1.10, 1.10, 1.10)
                rotate3d(
                  ${center.y / 150},
                  ${-center.x / 150},
                  0,
                  ${Math.log(distance) * 2}deg
                )
            `;

      glowElement.style.backgroundImage = `
                 radial-gradient(
                   circle at
                   ${center.x * 2 + bounds.width / 2}px
                   ${center.y * 2 + bounds.height / 2}px,
                   #ffffff1a,
                   #0000000f
                 )
            `;
    };

    card.addEventListener("mouseenter", () => {
      bounds = card.getBoundingClientRect();
      document.addEventListener("mousemove", rotateToMouse);
    });

    card.addEventListener("mouseleave", () => {
      document.removeEventListener("mousemove", rotateToMouse);
      glowElement.style.backgroundImage = ``;
      card.style.transform = "";
      card.style.background = "";
    });
  };

  useEffect(() => {
    if (windowSize.width > 577) {
      cardEffect();
    }
  }, [windowSize]);

  return (
    <div id={cardId} className="glow-animate-card-wrapper">
      <div id={`glow${cardId}`} className={"glow"} />
      {children}
    </div>
  );
};
