import {
  UpdateUserCurrencyRequest,
  UserState,
  ValidateUserPasswordRequestPayload,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UserState = {
  loading: false,
  currentUser: null,
  validateUserPasswordLoading: false,
  validateUserPasswordSuccess: false,
  validateUserPasswordError: false,
  changeCurrencyLoading: false,
  changeCurrencySuccess: false,
  qrLoading: false,
  qrResponse: null,
  qrToggleLoading: false,
  qrToggleSuccess: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    toggleMFAQrRequest(state, action) {
      return {
        ...state,
        qrToggleLoading: true,
        qrToggleSuccess: false,
        params: action.payload,
      };
    },
    toggleMFAQrSuccess(state) {
      return {
        ...state,
        qrToggleLoading: false,
        qrToggleSuccess: true,
      };
    },
    toggleMFAQrFailure(state) {
      return {
        ...state,
        qrToggleLoading: false,
        qrToggleSuccess: false,
      };
    },
    getMFAQrRequest(state, action) {
      return {
        ...state,
        qrLoading: true,
        qrResponse: null,
        params: action.payload,
      };
    },
    getMFAQrSuccess(state, action) {
      return {
        ...state,
        qrLoading: false,
        qrResponse: action.payload,
      };
    },
    resetGetMFAQr(state) {
      return {
        ...state,
        qrLoading: false,
        qrResponse: null,
      };
    },
    getMFAQrFailure(state) {
      return {
        ...state,
        qrLoading: false,
        qrResponse: null,
      };
    },
    getCurrentUserRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    getCurrentUserSuccess(state, action) {
      return {
        ...state,
        loading: false,
        currentUser: action.payload.currentUser,
      };
    },
    getCurrentUserFailure(state) {
      return {
        ...state,
        loading: false,
      };
    },
    updateUserCurrencyRequest(
      state,
      action: PayloadAction<UpdateUserCurrencyRequest>
    ) {
      return {
        ...state,
        params: action.payload,
        changeCurrencyLoading: true,
        changeCurrencySuccess: false,
      };
    },
    updateUserCurrencySuccess(state) {
      return {
        ...state,
        changeCurrencyLoading: false,
        changeCurrencySuccess: true,
      };
    },
    updateUserCurrencyFailure(state) {
      return {
        ...state,
        changeCurrencyLoading: false,
        changeCurrencySuccess: false,
      };
    },
    validateUserPasswordRequest(
      state,
      action: PayloadAction<ValidateUserPasswordRequestPayload>
    ) {
      return {
        ...state,
        validateUserPasswordLoading: true,
        validateUserPasswordSuccess: false,
        validateUserPasswordError: false,
        params: action.payload,
      };
    },
    validateUserPasswordSuccess(state) {
      return {
        ...state,
        validateUserPasswordLoading: false,
        validateUserPasswordError: false,
        validateUserPasswordSuccess: true,
      };
    },
    validateUserPasswordFailure(state) {
      return {
        ...state,
        validateUserPasswordLoading: false,
        validateUserPasswordSuccess: false,
        validateUserPasswordError: true,
      };
    },
    resetValidateUserPasswordState(state) {
      return {
        ...state,
        validateUserPasswordLoading: false,
        validateUserPasswordSuccess: false,
        validateUserPasswordError: false,
      };
    },
    resetUpdateUserCurrency(state) {
      return {
        ...state,
        changeCurrencyLoading: false,
        changeCurrencySuccess: false,
      };
    },
  },
});

export const {
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  validateUserPasswordRequest,
  validateUserPasswordSuccess,
  validateUserPasswordFailure,
  resetValidateUserPasswordState,
  updateUserCurrencyRequest,
  updateUserCurrencySuccess,
  updateUserCurrencyFailure,
  resetUpdateUserCurrency,
  getMFAQrRequest,
  getMFAQrSuccess,
  getMFAQrFailure,
  toggleMFAQrRequest,
  toggleMFAQrSuccess,
  toggleMFAQrFailure,
  resetGetMFAQr,
} = userSlice.actions;

export default userSlice.reducer;
