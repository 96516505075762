import {
  AnnouncementState,
  IGetAnnouncementsParams,
  IMarkAnnouncementAsReadParams,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AnnouncementState = {
  announcements: [],
  announcementsLoading: false,
  announcementsModalVisible: false,
};

const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    resetAnnouncementsState(state) {
      return {
        ...state,
        announcements: [],
        announcementsLoading: false,
      };
    },
    resetState(state) {
      return {
        ...initialState,
      };
    },
    setAnnouncementsModalVisible(state, action) {
      return {
        ...state,
        announcementsModalVisible: action.payload,
      };
    },
    getAnnouncementsRequest(
      state,
      action: PayloadAction<IGetAnnouncementsParams>
    ) {
      return {
        ...state,
        params: action.payload,
        announcements: [],
        announcementsLoading: true,
      };
    },
    getAnnouncementsSuccess(state, action) {
      return {
        ...state,
        announcements: action.payload.announcements,
        announcementsLoading: false,
      };
    },
    getAnnouncementsFailure(state) {
      return {
        ...state,
        announcements: [],
        announcementsLoading: false,
      };
    },
    markAnnouncementAsReadRequest(
      state,
      action: PayloadAction<IMarkAnnouncementAsReadParams>
    ) {
      return {
        ...state,
        params: action.payload,
      };
    },
  },
});

export const {
  resetState,
  getAnnouncementsRequest,
  getAnnouncementsSuccess,
  getAnnouncementsFailure,
  markAnnouncementAsReadRequest,
  setAnnouncementsModalVisible,
  resetAnnouncementsState,
} = announcementSlice.actions;

export default announcementSlice.reducer;
