import { BackTop, Layout } from "antd";
import HeaderLayout from "./Header/HeaderLayout";
import FooterLayout from "./Footer/FooterLayout";
import { News } from "../index";
import CookieDisclaimer from "../CookieDisclaimer/CookieDisclaimer";
import React, { useEffect } from "react";
import { getAuthConfigRequest } from "../Layout/layoutSlice";
import { useDispatch } from "react-redux";
import Maintenance from "../Maintenance";
import { DottedBackground } from "../DottedBackground";

const { Content } = Layout;

const AppLayout = (props: any) => {
  const { children, extraClasses = "" } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuthConfigRequest());
  });
  return (
    <Layout className={`layout teaser ${extraClasses}`}>
      <Maintenance />
      <DottedBackground />
      <HeaderLayout />
      <Content>{children}</Content>
      <News />
      <CookieDisclaimer />
      <FooterLayout />
      <BackTop />
    </Layout>
  );
};

export default AppLayout;
