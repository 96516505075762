import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWhitelistedCoinsSelector } from "../Wallet/selectors";
import {
  ISwapPreviewProps,
  IWhitelistedCoin,
  SwapDirection,
} from "./interfaces";
import "./styles.less";
import { SwapPreviewCoinSelect } from "./SwapPreviewCoinSelect";
import { Spin } from "antd";
import { FiArrowDown } from "react-icons/fi";
import Button from "../Button";
import { ButtonTypes } from "components/Button/types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getCurrentUserSelector } from "../User/selectors";
import { resetState, swapQuoteRequest } from "../Swap/swapSlice";
import { getQuoteLoadingSelector, getQuoteSelector } from "../Swap/selectors";
import { useNavigate } from "react-router-dom";
import { getWhitelistedCoinsRequest } from "../Wallet/walletSlice";
import { ROUTES } from "../../_helpers/config";

export const SwapPreview = (props: ISwapPreviewProps) => {
  const { toSwapTokenContractAddress } = props;

  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();

  const whitelistedCoins = useSelector(
    getWhitelistedCoinsSelector
  ) as Array<IWhitelistedCoin>;
  const user = useSelector(getCurrentUserSelector);
  const quote = useSelector(getQuoteSelector);
  const quoteLoading = useSelector(getQuoteLoadingSelector);

  const [fromSwapToken, setFromSwapToken] = useState<IWhitelistedCoin>();
  const [availableTokens, setAvailableTokens] =
    useState<Array<IWhitelistedCoin>>();
  const [toSwapToken, setToSwapToken] = useState<IWhitelistedCoin>();
  const [toSwapValue, setToSwapValue] = useState<number>();
  const [fromSwapValue, setFromSwapValue] = useState<number>();
  const [swapDirection, setSwapDirection] = useState<SwapDirection>();

  const onValueChange = (
    value: number,
    fromContractAddress: string,
    toContractAddress: string
  ) => {
    if (value === 0) {
      setToSwapValue(0);
      setFromSwapValue(0);
    }
    const getQuote = async () => {
      const token = await executeRecaptcha("swapQuote");
      dispatch(
        swapQuoteRequest({
          captchaToken: token,
          public: !user,
          currency: user ? user.state.properties.selectedCurrency : "usd",
          fromToken: {
            contractAddress: fromContractAddress,
            amount: value.toString(),
          },
          toToken: {
            contractAddress: toContractAddress,
          },
          slippage: 0.02,
        })
      );
    };

    getQuote().then(() => {});
  };

  useEffect(() => {
    if (whitelistedCoins.length === 0) {
      dispatch(getWhitelistedCoinsRequest());
    }

    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (whitelistedCoins?.length > 0) {
      let newToSwapToken: IWhitelistedCoin;
      whitelistedCoins.forEach((coin) => {
        if (coin.contractAddress === toSwapTokenContractAddress) {
          newToSwapToken = coin;
        }
      });

      if (!newToSwapToken) {
        newToSwapToken = whitelistedCoins[0];
      }

      const newAvailableTokens = whitelistedCoins.filter(
        (coin) => coin.contractAddress !== newToSwapToken.contractAddress
      );

      setAvailableTokens(newAvailableTokens);
      setFromSwapToken(newAvailableTokens[0]);
      setToSwapToken(newToSwapToken);
      onValueChange(
        1,
        newAvailableTokens[0].contractAddress,
        newToSwapToken.contractAddress
      );
      setSwapDirection(SwapDirection.TO_TOKEN_OF_INTEREST);
    }
  }, [whitelistedCoins]);

  useEffect(() => {
    if (quote) {
      if (swapDirection === SwapDirection.TO_TOKEN_OF_INTEREST) {
        setToSwapValue(quote.to.amount);
      } else {
        setFromSwapValue(quote.to.amount);
      }
    } else {
      setToSwapValue(0);
      setFromSwapValue(1);
    }
  }, [quote]);

  const swapNowAction = () => {
    if (!user) {
      window.location.href = process.env.REACT_APP_AUTH_URL;
    } else {
      if (!fromSwapValue && !toSwapValue) {
        navigate(ROUTES.swap);
      } else {
        navigate(
          `${ROUTES.swap}?from-contract-address=${fromSwapToken.contractAddress}&from-amount=${fromSwapValue}&to-contract-address=${toSwapToken.contractAddress}`
        );
      }
    }
  };

  return (
    <div className="swapPreviewComponent">
      <Spin wrapperClassName="swapPreviewSpinComponent" spinning={quoteLoading}>
        {toSwapToken && availableTokens ? (
          <>
            <div className="swapPreviewFields">
              <SwapPreviewCoinSelect
                currentValue={fromSwapValue}
                onValueChange={(value) => {
                  onValueChange(
                    value,
                    fromSwapToken.contractAddress,
                    toSwapToken.contractAddress
                  );
                  setFromSwapValue(value);
                  setSwapDirection(SwapDirection.TO_TOKEN_OF_INTEREST);
                }}
                availableTokens={availableTokens}
                currentSelectedToken={fromSwapToken}
                onTokenChange={(token) => {
                  setFromSwapToken(token);
                  onValueChange(
                    fromSwapValue,
                    token.contractAddress,
                    toSwapToken.contractAddress
                  );
                }}
              />
              <div className="swapIcon">
                <FiArrowDown />
              </div>
              <SwapPreviewCoinSelect
                currentValue={toSwapValue}
                onValueChange={(value) => {
                  onValueChange(
                    value,
                    toSwapToken.contractAddress,
                    fromSwapToken.contractAddress
                  );
                  setToSwapValue(value);
                  setSwapDirection(SwapDirection.FROM_TOKEN_OF_INTEREST);
                }}
                availableTokens={[toSwapToken]}
                currentSelectedToken={toSwapToken}
              />
            </div>
            <Button
              label={"Swap now"}
              type={ButtonTypes.PRIMARY}
              onClick={() => swapNowAction()}
              extraClasses={`swapNowButton ${
                fromSwapValue || toSwapValue
                  ? "swapNowButton"
                  : "swapNowButton buttonNotActive"
              }`}
            />
          </>
        ) : (
          <Spin />
        )}
      </Spin>
    </div>
  );
};
