import React from "react";
import "./style.less";
import stakingImage from "../../../assets/images/pork-hard-light.webp";
const StakingHeader = () => {
  return (
    <div
      className="stakingDetailsBox wallet stakingHeader"
      data-aos="fade-up"
      data-aos-delay="800"
    >
      <div className="phoneConfirmation">
        <h2>Staking pools with always attractive APYs</h2>
        <p>
          Seeding your tokens in our staking pool is seamless, easy-to-use and
          always commission free. Participate in our staking program for high
          returns and low investments.
        </p>
      </div>
      <img src={stakingImage} />
    </div>
  );
};

export default StakingHeader;
