import React, { useCallback } from "react";
import { ITokenInformationCardProps } from "./interface";
import { notification, Tooltip } from "antd";
import "./styles.less";
import { formatNumber } from "_helpers/numberFormatter";
import { useWindowSize } from "../../hooks/windowSize";
import { FiCopy } from "react-icons/fi";
import copy from "copy-to-clipboard";

export const TokenInformationCard = (props: ITokenInformationCardProps) => {
  const {
    tokenName,
    tokenSymbol,
    totalSupply,
    numberOfDecimals,
    contractAddress,
    networkImageUrl,
    network,
  } = props;

  const windowSize = useWindowSize();

  const formatContractAddress = useCallback(
    (address: string) => {
      return (
        address.substring(0, windowSize?.width > 320 ? 6 : 3) +
        "..." +
        address.substring(
          windowSize?.width > 320 ? address.length - 7 : address.length - 4,
          address.length
        )
      );
    },
    [windowSize]
  );

  const copyToClipboard = (address) => {
    copy(address);
    copy(address);
    notification.success({
      message: `${tokenName} contract address copied to clipboard`,
    });
  };

  return (
    <div className="tokenInformationCardComponent">
      <div className="cardTitle">Token information</div>
      <div className="tokenInformationItem">
        <div className="itemTitle">Name</div>
        <div className="itemValue">{tokenName}</div>
      </div>
      <div className="tokenInformationItem">
        <div className="itemTitle">Symbol</div>
        <div className="itemValue">{tokenSymbol}</div>
      </div>
      <div className="tokenInformationItem">
        <div className="itemTitle">Decimals</div>
        <div className="itemValue">{numberOfDecimals}</div>
      </div>
      <div className="tokenInformationItem">
        <div className="itemTitle">Address</div>
        <Tooltip title={contractAddress}>
          <div className="itemValue">
            {formatContractAddress(contractAddress)}
            <div
              className="copyAddressIcon"
              onClick={() => copyToClipboard(contractAddress)}
            >
              <FiCopy />
            </div>
          </div>
        </Tooltip>
      </div>
      <div className="tokenInformationItem">
        <div className="itemTitle">Network</div>
        <div className="itemValue">
          {networkImageUrl && (
            <img src={networkImageUrl} alt="network" className="networkImage" />
          )}
          {network}
        </div>
      </div>
      <div className="tokenInformationItem">
        <div className="itemTitle">Total supply</div>
        <div className="itemValue">
          {totalSupply ? formatNumber(totalSupply) : "N/A"}
        </div>
      </div>
    </div>
  );
};
