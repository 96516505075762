import {
  GetVestingCoinsFailurePayload,
  GetVestingCoinsSuccessPayload,
  VestingState,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: VestingState = {
  loading: false,
  error: null,
  vestingList: [],
};

const vestingSlice = createSlice({
  name: "vesting",
  initialState,
  reducers: {
    resetState() {
      return {
        ...initialState,
      };
    },
    getVestingCoinsRequest(state) {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    getVestingCoinsSuccess(
      state,
      action: PayloadAction<GetVestingCoinsSuccessPayload>
    ) {
      return {
        ...state,
        loading: false,
        vestingList: action.payload.vestingEntries,
        error: false,
      };
    },
    getVestingCoinsFailure(
      state,
      action: PayloadAction<GetVestingCoinsFailurePayload>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    },
  },
});

export const {
  resetState,
  getVestingCoinsRequest,
  getVestingCoinsFailure,
  getVestingCoinsSuccess,
} = vestingSlice.actions;

export default vestingSlice.reducer;
