import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import referralImage from "../../assets/images/referral.png";
import { Form, Input, Modal, Typography } from "antd";
import { VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";
import { useWindowSize } from "../../hooks/windowSize";
import { useDispatch, useSelector } from "react-redux";
import { getReferLoading, getReferral } from "../Referral/selectors";
import copy from "copy-to-clipboard";
import { getReferralRequest, referRequest } from "../Referral/referralSlice";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { IReferralModalProps } from "./interface";
import { getCurrentUserSelector } from "../User/selectors";
import { ButtonTypes } from "../Button/types";
import Button from "../Button";
import { FiCopy } from "react-icons/fi";

const { Paragraph } = Typography;

export const ReferralModal = (props: IReferralModalProps) => {
  const { visible, setVisible, formRef } = props;
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const referral = useSelector(getReferral);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const referLoading = useSelector(getReferLoading);
  const user = useSelector(getCurrentUserSelector);

  const copyLink = () => {
    copy(referral.urlPattern.replace("%s", referral.token));
    copy(referral.urlPattern.replace("%s", referral.token));
  };

  const onFinish = (values: any) => {
    const sendForm = async () => {
      const token = await executeRecaptcha("referral");
      dispatch(
        referRequest({
          campaignName: "SEEDON_FINANCE_REFERRAL_PROGRAM",
          service: "SEEDON_FINANCE",
          email: values.email,
          captchaToken: token,
        })
      );
    };
    sendForm().then(() => {});
  };

  useEffect(() => {
    if (executeRecaptcha && user) {
      const sendForm = async () => {
        const token = await executeRecaptcha("referral");
        dispatch(
          getReferralRequest({
            campaignName: "SEEDON_FINANCE_REFERRAL_PROGRAM",
            service: "SEEDON_FINANCE",
            captchaToken: token,
          })
        );
      };
      sendForm().then(() => {});
    }
  }, [executeRecaptcha, user]);

  return (
    <Modal
      title=""
      footer={null}
      open={visible}
      closeIcon={<IoMdClose />}
      className="referral-modal"
      onCancel={() => setVisible(false)}
      forceRender
    >
      <h3>Invite friends and earn $SEON</h3>

      <br />
      <p>
        You can earn up to a total of $75 worth of $SEON as cash-back for
        inviting 3 friends, depending on the size of the purchase.
      </p>

      <img src={referralImage} alt="" />

      <div className="form-item">
        <h4>Email invitation</h4>

        <Form
          name="basic"
          autoComplete="off"
          onFinish={onFinish}
          form={formRef}
        >
          <Form.Item
            name="email"
            className="email-input"
            rules={[
              { required: true, message: VALIDATION_ERROR_MESSAGES.required },
              { type: "email", message: VALIDATION_ERROR_MESSAGES.email },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item className="submitButton">
            <Button
              type={ButtonTypes.PRIMARY}
              disabled={referLoading}
              label="Send"
            />
          </Form.Item>
        </Form>
      </div>

      <div className="form-item">
        <h4>Invitation link</h4>

        {referral && (
          <Paragraph copyable={{ onCopy: copyLink, icon: <FiCopy /> }}>
            {`${referral.urlPattern
              .replace("%s", referral.token)
              .slice(
                0,
                windowSize?.width > 500
                  ? 50
                  : windowSize?.width > 400
                  ? 40
                  : windowSize?.width > 342
                  ? 30
                  : 25
              )}...`}
          </Paragraph>
        )}
      </div>
    </Modal>
  );
};
