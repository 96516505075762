import {
  IEventsParams,
  IEventsResponse,
  EventsState,
  IGetEventParams,
  IEventInteractionParams,
  GetEventsCategoriesParams,
  IEventsStartDateParams,
} from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: EventsState = {
  loading: false,
  error: null,
  events: {},

  categoriesLoading: false,
  categories: [],

  event: null,
  eventLoading: false,

  eventInteractionLoading: false,

  eventsStartDate: null,
  eventsStartDateLoading: false,
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    getEventsRequest(state, action: PayloadAction<IEventsParams>) {
      return {
        ...state,
        loading: true,
        params: action.payload,
        error: null,
        events: {},
      };
    },
    getEventsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        error: null,
        events: action.payload,
      };
    },
    getEventsFailure(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data,
        events: {},
      };
    },
    getEventsStartDateRequest(
      state,
      action: PayloadAction<IEventsStartDateParams>
    ) {
      return {
        ...state,
        params: action.payload,
        eventsStartDate: null,
        eventsStartDateLoading: true,
      };
    },
    getEventsStartDateSuccess(state, action) {
      return {
        ...state,
        eventsStartDate: action.payload,
        eventsStartDateLoading: false,
      };
    },
    getEventsStartDateFailure(state) {
      return {
        ...state,
        eventsStartDate: null,
        eventsStartDateLoading: false,
      };
    },
    getEventRequest(state, action: PayloadAction<IGetEventParams>) {
      return {
        ...state,
        eventLoading: true,
        params: action.payload,
        event: null,
      };
    },
    getEventSuccess(state, action) {
      return {
        ...state,
        eventLoading: false,
        event: action.payload,
      };
    },
    getEventFailure(state) {
      return {
        ...state,
        eventLoading: false,
        event: null,
      };
    },
    getEventCategoriesRequest(
      state,
      action: PayloadAction<GetEventsCategoriesParams>
    ) {
      return {
        ...state,
        categoriesLoading: true,
        categories: [],
        params: action.payload,
      };
    },
    getEventCategoriesSuccess(state, action) {
      return {
        ...state,
        categoriesLoading: false,
        categories: action.payload,
      };
    },
    getEventCategoriesFailure(state, action) {
      return {
        ...state,
        categoriesLoading: false,
        categories: [],
      };
    },
    eventInteractionRequest(
      state,
      action: PayloadAction<IEventInteractionParams>
    ) {
      return {
        ...state,
        params: action.payload,
        eventInteractionLoading: true,
      };
    },
    eventInteractionSuccess(state) {
      return {
        ...state,
        eventInteractionLoading: false,
      };
    },
    eventInteractionFailure(state) {
      return {
        ...state,
        eventInteractionLoading: false,
      };
    },
    setEvent(state, action) {
      return {
        ...state,
        event: action.payload.event,
      };
    },
    resetState(state) {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  getEventsRequest,
  getEventsSuccess,
  getEventsFailure,
  getEventCategoriesRequest,
  getEventCategoriesSuccess,
  getEventCategoriesFailure,
  getEventRequest,
  getEventSuccess,
  getEventFailure,
  setEvent,
  resetState,
  eventInteractionRequest,
  eventInteractionSuccess,
  eventInteractionFailure,
  getEventsStartDateRequest,
  getEventsStartDateSuccess,
  getEventsStartDateFailure,
} = eventsSlice.actions;

export default eventsSlice.reducer;
