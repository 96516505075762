import { Button as AntDButton } from "antd";
import { ButtonTypes } from "./types";
import "./style.less";
import { IButtonProps } from "./interface";

const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  const {
    label,
    onClick,
    disabled,
    type,
    icon,
    extraClasses,
    loading,
    danger,
    isRound,
  } = props;

  const isPrimary = type === ButtonTypes.PRIMARY;
  const isSecondary = type === ButtonTypes.SECONDARY;
  const isTertiary = type === ButtonTypes.TERTIARY;

  const getButtonClassNames = () => {
    let classNames = "";

    if (isPrimary) {
      classNames += "btn-primary ";
    }
    if (isSecondary) {
      classNames += "btn-secondary ";
    }
    if (isTertiary) {
      classNames += "btn-tertiary ";
    }
    if (disabled) {
      classNames += "btn-disabled ";
    }
    if (icon) {
      classNames += "btn-has-icon ";
    }
    if (danger) {
      classNames += "btn-danger ";
    }
    if (isRound) {
      classNames += "ant-btn-round ";
    }

    return classNames;
  };

  return (
    <AntDButton
      onClick={onClick}
      disabled={disabled}
      className={`${getButtonClassNames()} ${extraClasses ? extraClasses : ""}`}
      loading={loading}
    >
      {label}

      <div className="icon">{icon}</div>
    </AntDButton>
  );
};

export default Button;
