import React, { useEffect } from "react";
import "./categories.less";
import { IArticleCategoriesProps } from "./interface";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../_helpers/config";
import { useCookies } from "react-cookie";

const ArticleCategories: React.FC<IArticleCategoriesProps> = (
  props: IArticleCategoriesProps
) => {
  const navigate = useNavigate();
  const [token] = useCookies(["sOnFinanceToken"]);

  const filterByCategory = (category) => {
    if (props.onCategorySelected) {
      props.onCategorySelected(category);
    }
    if (token.sOnFinanceToken) {
      navigate({
        pathname: ROUTES.news,
        search: `?category=${category.value}`,
      });
    }
  };
  return (
    <div className="categories">
      {props.article.categories.length ? (
        <span onClick={() => filterByCategory(props.article.categories[0])}>
          {props.article.mainCategory.value}
        </span>
      ) : (
        ""
      )}
      {props.article.categories.length === 2 && <span>+1 other</span>}
      {props.article.categories.length > 2 && (
        <span>+{props.article.categories.length - 1} others</span>
      )}
    </div>
  );
};

export default ArticleCategories;
