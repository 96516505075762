import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getAnnouncements = (state: AppState) => state.announcement.announcements;
const getAnnouncementsLoading = (state: AppState) =>
  state.announcement.announcementsLoading;
const getAnnouncementsModalVisible = (state: AppState) =>
  state.announcement.announcementsModalVisible;

export const getAnnouncementsModalVisibleSelector = createSelector(
  getAnnouncementsModalVisible,
  (res) => res
);
export const getAnnouncementsSelector = createSelector(
  getAnnouncements,
  (res) => res
);
export const getAnnouncementsLoadingSelector = createSelector(
  getAnnouncementsLoading,
  (res) => res
);
