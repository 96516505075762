import {
  GetMaintenanceFailurePayload,
  GetMaintenanceSuccessPayload,
  MaintenanceState,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: MaintenanceState = {
  loading: false,
  maintenance: null,
};

const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    resetState() {
      return {
        ...initialState,
      };
    },
    getMaintenanceRequest(state) {
      return {
        ...state,
        loading: true,
        error: false,
        maintenance: null,
      };
    },
    getMaintenanceSuccess(
      state,
      action: PayloadAction<GetMaintenanceSuccessPayload>
    ) {
      return {
        ...state,
        loading: false,
        maintenance: action.payload.maintenance,
        error: false,
      };
    },
    getMaintenanceFailure(
      state,
      action: PayloadAction<GetMaintenanceFailurePayload>
    ) {
      return {
        ...state,
        loading: false,
        maintenance: null,
        error: action.payload.error,
      };
    },
  },
});

export const {
  resetState,
  getMaintenanceRequest,
  getMaintenanceFailure,
  getMaintenanceSuccess,
} = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
