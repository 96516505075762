import {
  ICodeVerificationParams,
  CodeVerificationState,
  ICanSendCodeRequestParams,
  IToggleOtpRecipientParams,
} from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IRequestSmsOtpParams,
  IResendEmailOtpParams,
} from "../PhoneValidationModal/types";

const initialState: CodeVerificationState = {
  loading: false,
  error: null,
  codeVerificationSuccess: false,
  codeVerificationResponse: null,

  canSendCodeLoading: false,
  canSendCode: null,
  canSendEmailCodeLoading: false,
  canSendSmsCodeLoading: false,
  canSendEmailCode: null,
  canSendSmsCode: null,

  toggleOtpLoading: false,
};

const codeVerificationSlice = createSlice({
  name: "codeVerification",
  initialState,
  reducers: {
    getCanSendCodeRequest(
      state,
      action: PayloadAction<ICanSendCodeRequestParams>
    ) {
      return {
        ...state,
        canSendCodeLoading: true,
        canSendCode: null,
        params: action.payload,
        canSendSmsCodeLoading: true,
        canSendSmsCode: null,
      };
    },
    toggleOtpRecipientRequest(
      state,
      action: PayloadAction<IToggleOtpRecipientParams>
    ) {
      return {
        ...state,
        params: action.payload,
        toggleOtpLoading: true,
      };
    },
    toggleOtpRecipientSuccess(state) {
      return {
        ...state,
        toggleOtpLoading: false,
      };
    },
    toggleOtpRecipientFailure(state) {
      return {
        ...state,
        toggleOtpLoading: false,
      };
    },
    getCanSendCodeSuccess(state, action) {
      return {
        ...state,
        canSendCodeLoading: false,
        canSendCode: true,
        canSendSmsCodeLoading: false,
        ...action.payload,
      };
    },
    getCanSendCodeFailure(state, action) {
      return {
        ...state,
        canSendCodeLoading: false,
        canSendCode: false,
        canSendSmsCodeLoading: false,
        ...action.payload,
      };
    },
    getCanSendEmailCodeRequest(
      state,
      action: PayloadAction<ICanSendCodeRequestParams>
    ) {
      return {
        ...state,
        params: action.payload,
        canSendEmailCodeLoading: true,
        canSendEmailCode: null,
      };
    },
    getCanSendEmailCodeSuccess(state) {
      return {
        ...state,
        canSendEmailCode: true,
      };
    },
    getCanSendEmailCodeFailure(state) {
      return {
        ...state,
        canSendEmailCodeLoading: false,
        canSendEmailCode: false,
      };
    },
    postCodeVerificationRequest(
      state,
      action: PayloadAction<ICodeVerificationParams>
    ) {
      return {
        ...state,
        loading: true,
        codeVerificationSuccess: false,
        params: action.payload,
        error: null,
        codeVerificationResponse: null,
      };
    },
    postCodeVerificationSuccess(state, action) {
      return {
        ...state,
        loading: false,
        error: null,
        codeVerificationSuccess: true,
        codeVerificationResponse: action.payload,
      };
    },
    postCodeVerificationFailure(state, action) {
      return {
        ...state,
        loading: false,
        codeVerificationSuccess: false,
        codeVerificationResponse: null,
        error: action.payload.error.response.data,
      };
    },
    putResendEmailOtpRequest(
      state,
      action: PayloadAction<IResendEmailOtpParams>
    ) {
      return {
        ...state,
        params: action.payload,
      };
    },
    resetState(state) {
      return {
        ...initialState,
      };
    },
    resetCanSendCodeState(state) {
      return {
        ...state,
        canSendCodeLoading: false,
        canSendCode: null,
        canSendEmailCodeLoading: false,
        canSendSmsCodeLoading: false,
        canSendEmailCode: null,
        canSendSmsCode: null,
      };
    },
  },
});

export const {
  toggleOtpRecipientRequest,
  toggleOtpRecipientSuccess,
  toggleOtpRecipientFailure,
  postCodeVerificationRequest,
  postCodeVerificationSuccess,
  postCodeVerificationFailure,
  resetState,
  resetCanSendCodeState,
  getCanSendCodeRequest,
  getCanSendCodeSuccess,
  getCanSendCodeFailure,
  putResendEmailOtpRequest,
  getCanSendEmailCodeRequest,
  getCanSendEmailCodeSuccess,
  getCanSendEmailCodeFailure,
} = codeVerificationSlice.actions;

export default codeVerificationSlice.reducer;
