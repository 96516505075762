import React, { useEffect } from "react";
import "./style.less";
import classnames from "classnames";
import { INewsProps } from "./interface";
import { Carousel, Spin, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { getLoadingSelector } from "../News/selectors";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Button } from "../index";
import { ButtonTypes } from "../Button/types";

const News: React.FC<INewsProps> = (props: INewsProps) => {
  const news = [
    {
      title: "Latest updates",
      articles: [
        {
          id: 1,
          type: "ARTICLE",
          date: "7th April 2022",
          alt: "SeedOn-techround",
          title:
            "SeedOn is number 12 in top 37 Crypto Startups To Watch In 2022",
          description:
            "TechRound has provided the top Crypto startups to watch in the coming year, with startups ranging throughout the industry from Sport to Medicine and more.",
          link: "https://techround.co.uk/cryptocurrency/top-37-crypto-startups-to-watch-in-2022/",
          image: "/seedon-team.jpg",
        },
        {
          id: 3,
          type: "ARTICLE",
          date: "29th March 2022",
          alt: "SeedOn-blockchain",
          title:
            "Can Blockchain Help Underfunded Startups Make It Past Infancy?",
          description:
            "SeedOn, a blockchain-based solution that revolutionizes the crowdfunding process, conducts project funding via its Smart Contract Escrow Model. In this system, project owners have access to limited cash flows until their objectives are met and completed. This way, in case of unforeseen events that may lead to failure to start, losses are kept to a minimum. Meanwhile, the rest of the fund is given back to the investors.",
          link: "https://www.benzinga.com/markets/cryptocurrency/22/03/26359911/can-blockchain-help-underfunded-startups-make-it-past-infancy",
          image:
            "https://cdn.benzinga.com/files/andre-francois-mckenzie-jrjhtbj-pgu-unsplash.jpg?optimize=medium&dpr=1&auto=webp&width=384",
        },
        // {
        //   id: 6,
        //   type: "article",
        //   date: "8th March 2022",
        //   alt: "SeedOn-hackernoon",
        //   title:
        //     "How SeedOn Is Bringing Smart Contracts to Equity Crowdfunding",
        //   description:
        //     "Crowdfunding has played an enormous and important role in the growth of the cryptocurrency market. Since the first-ever token sale by Mastercoin in 2013 and Ethereum's $18.3 million ICO in 2014, crowdfunding has paved the way for new protocols and technologies.",
        //   link: "https://hackernoon.com/how-seedon-is-bringing-smart-contracts-to-equity-crowdfunding",
        //   image:
        //     "https://hackernoon.imgix.net/images/GwZ4a1OS3uMhvLngHCl9tofBO1J2-3ka3ow6.png?auto=format&fit=max&w=3840",
        // },
        {
          id: 4,
          type: "ARTICLE",
          date: "24th Feb 2022",
          alt: "SeedOn-crypto",
          title:
            "Crypto staking: What is it and how much can you earn in rewards?",
          description:
            "While many speculators buy and sell cryptocurrency for profit, another group of crypto owners enjoy the income created through staking rewards. Staking rewards are a kind of income paid to crypto owners who help regulate and validate a cryptocurrency’s transactions.",
          link: "https://www.bankrate.com/investing/crypto-staking/",
          image:
            "https://www.bankrate.com/2022/02/24135200/Crypto-staking-what-is-it.jpeg?auto=webp&optimize=high&crop=16:9&width=912",
        },
        {
          id: 5,
          type: "ARTICLE",
          date: "25th January 2022",
          alt: "SeedOn-tech",
          title: "Tech for Good Predictions for 2022",
          description:
            "We’ve collected industry expert predictions for the future of Tech for Good in 2022. The continued impact of COVID-19 prompted massive changes for all aspects of life, including the Tech for Good sector. Which changes will continue into 2022?",
          link: "https://techround.co.uk/guides/tech-for-good-predictions-for-2022/",
          image: "/coins.jpeg",
        },
      ],
    },
  ];
  const newsLoading = useSelector(getLoadingSelector);

  const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <div {...props}>{children}</div>
  );

  const sliderSettings = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 300,
    arrows: true,
    prevArrow: (
      <SlickArrow currentSlide slideCount>
        <FiChevronLeft />
      </SlickArrow>
    ),
    nextArrow: (
      <SlickArrow currentSlide slideCount>
        <FiChevronRight />
      </SlickArrow>
    ),
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      // { breakpoint: 1450, settings: { slidesToShow: 4 } },
      { breakpoint: 1250, settings: { slidesToShow: 3 } },
      { breakpoint: 960, settings: { slidesToShow: 2 } },
      { breakpoint: 570, settings: { slidesToShow: 1 } },
    ],
  };

  const ArticleSlide = ({ article, articleIndex }) => {
    return (
      <div
        className={classnames("slide", article.type === "EVENT" ? "event" : "")}
        key={articleIndex}
      >
        <a
          className="thumbnail"
          href={article.link}
          title={article.title}
          target={"_blank"}
        >
          <span className="promoted">Promoted</span>

          <div className="details">
            <div className="heading">
              <h3>{article.title}</h3>
              <small>{article.date}</small>
            </div>

            <p>{article.description}</p>

            <Button
              label="Read more"
              type={ButtonTypes.SECONDARY}
              extraClasses="readMore"
            />

            <div className="moodLevel">
              <Tooltip title="Positive feeling" placement="right">
                {/*<div className="bars" /> // Neutral */}
                <div className="bars good" />
                {/*<div className="bars bad" />*/}
              </Tooltip>
            </div>
          </div>

          <img src={article.image} alt={article.alt} />
        </a>
      </div>
    );
  };

  const EventSlide = ({ article, articleIndex }) => {
    return (
      <div className="slide event" key={articleIndex}>
        <a className="thumbnail" href={article.link} title={article.title}>
          <span className="heading">
            <h3>{article.title}</h3>
            <small>{article.date}</small>
          </span>

          <img src={article.image} alt={article.title} />
        </a>
      </div>
    );
  };

  if (newsLoading) {
    return (
      <div className="loading">
        <Spin />
      </div>
    );
  }

  return (
    <div className="listArticles">
      {news.map((category, categoryIndex) => (
        <div className="wrapper" key={categoryIndex}>
          <h3 className="sectionTitle">{category.title}</h3>

          <Carousel {...sliderSettings}>
            {category.articles.map((article, articleIndex) =>
              article.type === "EVENT" ? (
                <EventSlide
                  article={article}
                  key={articleIndex}
                  articleIndex={articleIndex}
                />
              ) : (
                <ArticleSlide
                  article={article}
                  key={articleIndex}
                  articleIndex={articleIndex}
                />
              )
            )}
          </Carousel>
        </div>
      ))}
    </div>
  );
};

export default News;
