import DropdownSelect from "./dropdownSelect";
import { IFormDropdownSelectProps } from "./interface.formDropdownSelect";
import "./style.less";
import { useState } from "react";

const FormDropdownSelect: React.FC<IFormDropdownSelectProps> = (
  props: IFormDropdownSelectProps
) => {
  const {
    label,
    withFilter,
    items,
    keyToShow,
    disabled,
    keyForImage,
    value,
    onChange,
    allowClear,
    onClear,
    dropdownRender,
    defaultValue,
    extraClasses,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="form-item form-dropdown-select">
      {label && value && (
        <div className={`${isFocused || value ? "label" : "placeholder"} `}>
          {label}
        </div>
      )}

      <div className="dropdown-select-wrapper">
        <DropdownSelect
          withFilter={withFilter}
          items={items}
          keyToShow={keyToShow}
          keyForImage={keyForImage}
          onChange={onChange}
          label={label}
          disabled={disabled}
          value={value}
          allowClear={allowClear}
          onClear={onClear}
          dropdownRender={dropdownRender}
          defaultValue={defaultValue}
          extraClasses={extraClasses}
        />
      </div>
    </div>
  );
};

export default FormDropdownSelect;
