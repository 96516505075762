import React, { useEffect, useState } from "react";
import { Button, WalletLayout } from "../index";
import { Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserSelector } from "./selectors";
import PhoneValidationModal from "../PhoneValidationModal";
import "./style.less";
import { scrollTo, scrollToTop } from "../../_helpers/scroll";
import { pushGtmEvent } from "../../_helpers/gtm";
import { GtmEvents } from "../../_helpers/types";

// Components
import OtpSettings from "./OtpSettings";
import ChangePassword from "./ChangePassword";
import ChangePhoneNumber from "./ChangePhoneNumber";
import ViewRecoveryPhrase from "./ViewRecoveryPhrase";
import { useWindowSize } from "../../hooks/windowSize";
import { getAuthConfigRequest } from "../Layout/layoutSlice";
import EmailOtpSettings from "./EmailOtpSettings";

// Components

const User = () => {
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUserSelector);

  useEffect(() => {
    dispatch(getAuthConfigRequest());
  }, []);

  const validatePhone = () => {
    pushGtmEvent(GtmEvents.VALIDATE_PHONE);
    setPhoneVerifyModalVisible(true);
  };

  const phoneValidationModalClosed = () => {
    setPhoneVerifyModalVisible(false);
    scrollToTop();
  };

  const panelHead = (title) => {
    return (
      <div className="panelHead">
        <h3>{title}</h3>
      </div>
    );
  };

  const tabChanged = (tabId) => {
    if (windowSize.width < 870) {
      setTimeout(() => {
        scrollTo(tabId, -50);
      }, 300);
    }
  };

  return (
    <div className="walletHeading">
      <div className="container">
        <h1 data-aos="fade-up">Account settings</h1>
        <p data-aos="fade-up" data-aos-delay="400">
          Edit, verify, and update your information here.
        </p>
      </div>

      <div
        className="userProfileDetailsBox userProfile userSettings"
        data-aos="fade-up"
        data-aos-delay="600"
      >
        {user && !user.state.properties?.phoneNumberVerified ? (
          <div className="phoneConfirmation">
            <h2>One more thing</h2>
            <p>
              We need to validate your phone number in order to send you OTP
              codes. These codes will be required whenever you are withdrawing
              tokens or changing your password.
            </p>

            <Button label="Validate phone" onClick={() => validatePhone()} />
          </div>
        ) : (
          ""
        )}

        <Collapse
          accordion
          expandIconPosition="end"
          destroyInactivePanel
          onChange={(tabId) => tabChanged(tabId)}
        >
          {user && user.state.properties?.phoneNumberVerified === true ? (
            <>
              <Collapse.Panel
                header={panelHead("Update Password")}
                key={"UpdatePassword"}
                id={"UpdatePassword"}
              >
                <ChangePassword variant="clean" />
              </Collapse.Panel>

              <Collapse.Panel
                header={panelHead("Change Phone Number")}
                key={"ChangePhoneNumber"}
                id={"ChangePhoneNumber"}
              >
                <ChangePhoneNumber />
              </Collapse.Panel>

              <Collapse.Panel
                header={panelHead("View Recovery Phrase")}
                key={"ViewRecoveryPhrase"}
                id={"ViewRecoveryPhrase"}
              >
                <ViewRecoveryPhrase />
              </Collapse.Panel>
            </>
          ) : (
            ""
          )}

          <Collapse.Panel
            header={panelHead("Mobile OTP Settings")}
            key={"OtpSettings"}
            id={"OtpSettings"}
          >
            <OtpSettings />
          </Collapse.Panel>
          <Collapse.Panel
            header={panelHead("Email OTP Settings")}
            key={"EmailOtpSettings"}
            id={"EmailOtpSettings"}
          >
            <EmailOtpSettings />
          </Collapse.Panel>
        </Collapse>

        {phoneVerifyModalVisible ? (
          <PhoneValidationModal
            isVisible={phoneVerifyModalVisible}
            closeModal={() => phoneValidationModalClosed()}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default User;
