import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "./style.less";
import { IModalProps } from "./interface";
import { Button } from "../index";
import WordsForm from "./WordsForm";
import { useDispatch, useSelector } from "react-redux";
import { getValidateRecoveryPhraseSuccess } from "./selectors";
import { getCurrentUserRequest } from "../User/userSlice";
import { pushGtmEvent } from "../../_helpers/gtm";
import { GtmEvents } from "../../_helpers/types";
import { IoClose } from "react-icons/io5";
import { ButtonTypes } from "../Button/types";

const SecretRecoveryModal: React.FC<IModalProps> = (props: IModalProps) => {
  const dispatch = useDispatch();

  const [chosenAction, setChosenAction] = useState(null);
  const isSuccess = useSelector(getValidateRecoveryPhraseSuccess);

  useEffect(() => {
    if (props.isVisible) {
      pushGtmEvent(GtmEvents.CREATE_WALLET);
    }
  }, [props]);
  useEffect(() => {
    return () => {
      setChosenAction(null);
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      pushGtmEvent(GtmEvents.CREATE_WALLET_SUCCESS);
      dispatch(getCurrentUserRequest());
      props.closeModal(true);
    }
  }, [isSuccess]);

  return (
    <Modal
      closeIcon={<IoClose />}
      maskClosable={false}
      footer={null}
      className="authModals"
      open={props.isVisible}
      onCancel={() => props.closeModal(false)}
      forceRender
    >
      {chosenAction ? (
        <>
          <WordsForm
            action={chosenAction}
            callback={(newAction) => setChosenAction(newAction)}
          />
        </>
      ) : (
        <>
          <div className="heading">
            <h2>Now, to get started</h2>
            <p>
              You can start setting up your brand new SeedOn Finance wallet.
            </p>
          </div>

          <div className="importCreateWalletBtns">
            <Button
              type={ButtonTypes.PRIMARY}
              label={"Create a wallet"}
              onClick={() => setChosenAction("create")}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default SecretRecoveryModal;
