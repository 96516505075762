import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./styles.less";
import { ISwipeLuxModal } from "./interface";
import { IoClose } from "react-icons/io5";
import { useScript } from "../../hooks/hooks";
import { useSelector } from "react-redux";
import { getCurrentUserSelector } from "../User/selectors";

const SwipeLuxModal = (props: ISwipeLuxModal) => {
  const user = useSelector(getCurrentUserSelector);
  const [widgetLoaded, setWidgetLoaded] = useState(false);

  useEffect(() => {
    if (props.isVisible && widgetLoaded) {
      const root = document.getElementById("widget");
      // @ts-ignore
      const widget = new window.SwipeluxWidget(root, {
        apiKey: process.env.REACT_APP_SWIPELUX_API_KEY,
        orderToken: props.orderToken,
        targetAddress: {
          value: "0xE9A3E991A9D23780025324693044e706d7b82c3a",
          editable: false,
        },
        phone: {
          value: user.details.phoneNumber,
          editable: false,
        },
        email: {
          value: user.email,
          editable: false,
        },
      });
      widget.init();
    }
  }, [props.isVisible, widgetLoaded]);

  return (
    <>
      {useScript({
        url: process.env.REACT_APP_SWIPELUX_SDK,
        async: true,
        defer: true,
        callback: () => setWidgetLoaded(true),
      })}
      <Modal
        closeIcon={<IoClose />}
        open={props.isVisible}
        onCancel={() => props.onCancel()}
        className="authModals walletModals swipeLuxModal"
        maskClosable={false}
        forceRender
        footer={null}
      >
        <div id="widget" />
      </Modal>
    </>
  );
};

export default SwipeLuxModal;
