import React, { useEffect, useState } from "react";
import { Button, WalletLayout } from "../index";
import {
  AutoComplete,
  Button as AntdButton,
  Form,
  Input,
  Spin,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletSelector,
  getWhitelistedCoinsSelector,
} from "../Wallet/selectors";
import { getAvailableFundsRequest } from "../Wallet/walletSlice";
import "./style.less";
import { resetState, swapQuoteRequest, swapRequest } from "./swapSlice";
import { getCurrentUserSelector } from "../User/selectors";
import {
  getQuoteErrorSelector,
  getQuoteLoadingSelector,
  getQuoteSelector,
  getSwapLoadingSelector,
  getSwapSuccessSelector,
} from "./selectors";
import { CoinSelectInput } from "./CoinSelectInput";
import {
  API_VALIDATION_ERROR_MESSAGES,
  ROUTES,
  VALIDATION_ERROR_MESSAGES,
} from "../../_helpers/config";
import SuccessModal from "../SuccessModal";
import { isGreater } from "../../_helpers/numberFunctions";
import { formatStringNumber } from "../../_helpers/numberFormatter";
import { FiArrowDown, FiHelpCircle } from "react-icons/fi";
import SwipeButton from "../SwipeButton";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { SuccessModalTypes } from "../SuccessModal/interface";
import { useWindowSize } from "../../hooks/windowSize";
import { useSearchParams } from "react-router-dom";
import { ButtonTypes } from "../Button/types";

const Swap = ({ shouldAnimate = false }) => {
  const dispatch = useDispatch();
  const [formRef] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const windowSize = useWindowSize();

  const whitelistedCoins = useSelector(getWhitelistedCoinsSelector);
  const wallet = useSelector(getWalletSelector);
  const user = useSelector(getCurrentUserSelector);
  const quote = useSelector(getQuoteSelector);
  const quoteLoading = useSelector(getQuoteLoadingSelector);
  const quoteError = useSelector(getQuoteErrorSelector);

  const swapLoading = useSelector(getSwapLoadingSelector);
  const swapSuccess = useSelector(getSwapSuccessSelector);

  const [fromSelectedToken, setFromSelectedToken] = useState(null);
  const [toSelectedToken, setToSelectedToken] = useState(null);

  const [fromTokens, setFromTokens] = useState([]);
  const [toTokens, setToTokens] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formNameTo, setFormNameTo] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [slippage, setSlippage] = useState("2");
  const [slippageWarning, setSlippageWarning] = useState(null);
  const [hasSlippageError, setHasSlippageError] = useState(false);
  const [hasInitialQuote, setHasInitialQuote] = useState(false);
  const [swipeButtonTimer, setSwipeButtonTimer] = useState(5);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [searchParams, _] = useSearchParams();

  const amountWatch = Form.useWatch("amount_1", formRef);
  const amountToWatch = Form.useWatch("amount_2", formRef);

  const slippageOptions = [
    { label: "1%", value: "1" },
    { label: "2%", value: "2" },
  ];

  const tokenSelected = (
    val,
    callback,
    tokensFilterCallback,
    shouldRequestQuote = false
  ) => {
    callback(val);

    if (val) {
      dispatch(
        getAvailableFundsRequest({
          contractAddress: val.contractAddress,
        })
      );
    }

    tokensFilterCallback(
      whitelistedCoins.filter((item) =>
        val ? item.contractAddress !== val.contractAddress : true
      )
    );
    if (shouldRequestQuote) {
      const sendForm = async () => {
        const token = await executeRecaptcha("swapQuote");
        dispatch(
          swapQuoteRequest({
            captchaToken: token,
            currency: user.state.properties.selectedCurrency,
            fromToken: {
              contractAddress: formRef.getFieldsValue().token_1
                ? formRef.getFieldsValue().token_1.contractAddress
                : undefined,
              amount: formRef.getFieldsValue().amount_1,
            },
            toToken: {
              contractAddress: formRef.getFieldsValue().token_2
                ? formRef.getFieldsValue().token_2.contractAddress
                : undefined,
            },
            slippage: 0.02,
          })
        );
      };
      sendForm().then(() => {});
    }
  };

  const coinSelectInputChanged = (val, convertFrom, convertTo, fieldName) => {
    if (val) {
      setFormNameTo(fieldName);
      const sendForm = async () => {
        const token = await executeRecaptcha("swapQuote");
        resetSwipeButton();
        dispatch(
          swapQuoteRequest({
            captchaToken: token,
            currency: user.state.properties.selectedCurrency,
            fromToken: {
              contractAddress: convertFrom.contractAddress,
              amount: fieldName !== "amount_1" ? val : undefined,
            },
            toToken: {
              contractAddress: convertTo
                ? convertTo.contractAddress
                : undefined,
              amount: fieldName === "amount_1" ? val : undefined,
            },
            slippage: 0.02,
          })
        );
      };
      sendForm().then(() => {});
    } else {
      const newFormObj = {};
      newFormObj[fieldName] = null;
      formRef.setFieldsValue(newFormObj);
    }
  };
  const swap = () => {
    formRef.validateFields().then((values) => {
      const sendForm = async () => {
        const token = await executeRecaptcha("swap");
        dispatch(
          swapRequest({
            captchaToken: token,
            currency: user.state.properties.selectedCurrency,
            fromToken: {
              contractAddress: values["token_1"].contractAddress,
              amount: values["amount_1"],
            },
            toToken: {
              contractAddress: values["token_2"].contractAddress,
              amount: values["amount_2"],
            },
            slippage: values["slippage"] / 100,
          })
        );
      };
      sendForm().then(() => {});
    });
  };

  const invert = () => {
    if (fromSelectedToken && toSelectedToken) {
      const fromCopy = fromSelectedToken;
      const toCopy = toSelectedToken;
      const formValuesCopy = formRef.getFieldsValue();
      tokenSelected(toCopy, setFromSelectedToken, setToTokens);
      tokenSelected(fromCopy, setToSelectedToken, setFromTokens);

      formRef.setFieldsValue({
        amount_1: formValuesCopy.amount_2 ?? "0",
        amount_2: formValuesCopy.amount_1 ?? "0",
        token_1: toCopy,
        token_2: fromCopy,
      });
      const sendForm = async () => {
        const token = await executeRecaptcha("swap");
        resetSwipeButton();
        dispatch(
          swapQuoteRequest({
            captchaToken: token,
            currency: user.state.properties.selectedCurrency,
            fromToken: {
              contractAddress: toCopy.contractAddress,
              amount: formValuesCopy.amount_2 ?? "0",
            },
            toToken: {
              contractAddress: fromCopy.contractAddress,
            },
            slippage: 0.02,
          })
        );
      };
      sendForm().then(() => {});
    }
  };
  const swapQuote = () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("swapQuote");
      resetSwipeButton();
      dispatch(
        swapQuoteRequest({
          captchaToken: token,
          currency: user.state.properties.selectedCurrency,
          fromToken: {
            contractAddress: formRef.getFieldsValue().token_1
              ? formRef.getFieldsValue().token_1.contractAddress
              : undefined,
            amount: formRef.getFieldsValue().amount_1,
          },
          toToken: {
            contractAddress: formRef.getFieldsValue().token_2
              ? formRef.getFieldsValue().token_2.contractAddress
              : undefined,
          },
          slippage: 0.02,
        })
      );
    };
    sendForm().then(() => {});
  };
  const resetSwipeButton = () => {
    setShowRefreshButton(false);
    setSwipeButtonTimer(5);
    clearInterval(refreshInterval);
  };
  const startInterval = () => {
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
    let timeRemaining = 4;
    const interval = setInterval(() => {
      if (timeRemaining >= 1) {
        setSwipeButtonTimer(timeRemaining);
        timeRemaining -= 1;
      } else {
        setShowRefreshButton(true);
        clearInterval(interval);
      }
    }, 1000);
    setRefreshInterval(interval);
  };
  useEffect(() => {
    if (hasSlippageError) {
      startInterval();
    } else {
      if (quote) {
        swapQuote();
      }
    }
  }, [hasSlippageError]);
  useEffect(() => {
    if (quote && formNameTo) {
      const newFormObj = {};
      newFormObj[formNameTo] =
        formNameTo === "amount_2" ? quote.to.amount : quote.from.amount;
      formRef.setFieldsValue(newFormObj);
      formRef
        .validateFields()
        .then((res) => {
          setSubmitDisabled(false);
          startInterval();
        })
        .catch((err) => {
          if (err.errorFields.length === 0) {
            startInterval();
          }
          resetSwipeButton();
          setSubmitDisabled(err.errorFields.length !== 0);
        });
    }
  }, [quote]);

  useEffect(() => {
    if (
      executeRecaptcha &&
      toSelectedToken &&
      fromSelectedToken &&
      !hasInitialQuote &&
      searchParams
    ) {
      const fromAmount = searchParams.get("from-amount");

      setHasInitialQuote(true);

      if (fromAmount) {
        coinSelectInputChanged(
          fromAmount,
          fromSelectedToken,
          toSelectedToken,
          "amount_2"
        );
      } else {
        coinSelectInputChanged(1, fromSelectedToken, toSelectedToken, null);
      }
    }
  }, [toSelectedToken, fromSelectedToken, executeRecaptcha, searchParams]);

  useEffect(() => {
    if (swapSuccess) {
      formRef.resetFields();
      dispatch(resetState());
      setShowSuccessModal(true);
    }
  }, [swapSuccess]);

  useEffect(() => {
    if (searchParams && whitelistedCoins?.length > 0) {
      const fromContractAddress = searchParams.get("from-contract-address");
      const fromAmount = searchParams.get("from-amount");
      const toContractAddress = searchParams.get("to-contract-address");

      let fromToken, toToken;

      if (fromContractAddress && fromAmount && toContractAddress) {
        whitelistedCoins.map((item) => {
          if (item.contractAddress === fromContractAddress) {
            fromToken = item;
            tokenSelected(item, setFromSelectedToken, setToTokens);
            formRef.setFieldsValue({
              token_1: item,
            });
          }

          if (item.contractAddress === toContractAddress) {
            toToken = item;
            tokenSelected(item, setToSelectedToken, setFromTokens);
            formRef.setFieldsValue({
              token_2: item,
            });
          }
        });
        formRef.setFieldsValue({ amount_1: fromAmount });
      } else {
        whitelistedCoins.map((item) => {
          if (item.contractAddress === "BNB") {
            tokenSelected(item, setFromSelectedToken, setToTokens);
            formRef.setFieldsValue({
              token_1: item,
            });
          }

          if (
            item.contractAddress === process.env.REACT_APP_SEON_CONTRACT_ADDRESS
          ) {
            tokenSelected(item, setToSelectedToken, setFromTokens);
            formRef.setFieldsValue({
              token_2: item,
            });
          }
        });
      }
    }
  }, [searchParams, whitelistedCoins]);
  useEffect(() => {
    return () => {
      formRef.resetFields();
      dispatch(resetState());
    };
  }, []);
  useEffect(() => {
    if (amountWatch && isGreater(amountWatch, "0")) {
      formRef
        .validateFields()
        .then(() => {
          setSubmitDisabled(false);
        })
        .catch(() => {
          resetSwipeButton();
          setSubmitDisabled(true);
        });
    } else {
      resetSwipeButton();
      setSubmitDisabled(true);
    }
  }, [amountWatch]);

  useEffect(() => {
    if (slippage && parseFloat(slippage) <= 1) {
      setSlippageWarning(
        "Due to a low slippage rate, your transaction may fail due to the token's volatility."
      );
    } else if (slippage && parseFloat(slippage) > 5) {
      setSlippageWarning(
        "Due to a high slippage rate, your transaction can be frontrun, resulting in unexpected high conversion rates."
      );
    } else {
      setSlippageWarning(null);
    }
  }, [slippage]);

  return (
    <>
      <div
        className={`detailsBox wallet swapPage ${
          shouldAnimate ? "animateTabs" : ""
        }`}
      >
        <Spin spinning={quoteLoading || swapLoading}>
          <Form
            form={formRef}
            initialValues={{
              slippage: "2",
              amount_1: "0",
              amount_2: "0",
            }}
          >
            <CoinSelectInput
              index={1}
              formRef={formRef}
              onChange={(val) =>
                coinSelectInputChanged(
                  val,
                  fromSelectedToken,
                  toSelectedToken,
                  "amount_2"
                )
              }
              onTokenSelected={(token) =>
                tokenSelected(token, setFromSelectedToken, setToTokens, true)
              }
              tokens={fromTokens}
              selectedToken={fromSelectedToken}
              disclaimerLabel={"Available"}
              disclaimerValue={
                fromSelectedToken &&
                wallet[`${fromSelectedToken.contractAddress}Funds`] &&
                wallet[`${fromSelectedToken.contractAddress}Funds`]
                  .availableFunds
                  ? wallet[
                      `${fromSelectedToken.contractAddress}Funds`
                    ].availableFunds.toString()
                  : "0"
              }
              customInputRules={[
                { required: true, message: VALIDATION_ERROR_MESSAGES.required },
                ({}) => ({
                  validator(_, value) {
                    if (
                      value &&
                      value.length - (value.search(/\.|$/) + 1) > 18
                    ) {
                      return Promise.reject(
                        "Maximum number of decimals allowed is 18."
                      );
                    }
                    return Promise.resolve();
                  },
                }),
                () => ({
                  validator(_, value) {
                    if (value) {
                      return isGreater(value, "0")
                        ? Promise.resolve()
                        : Promise.reject("Amount must be greater than 0.");
                    }
                    return Promise.resolve();
                  },
                }),
                () => ({
                  validator(_, value) {
                    if (
                      value &&
                      wallet[`${fromSelectedToken.contractAddress}Funds`] &&
                      wallet[`${fromSelectedToken.contractAddress}Funds`]
                        .availableFunds
                    ) {
                      return isGreater(
                        value,
                        wallet[`${fromSelectedToken.contractAddress}Funds`]
                          .availableFunds
                      )
                        ? Promise.reject("Insufficient funds.")
                        : Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              hasMaxFunctionality
            />

            <AntdButton
              type="text"
              onClick={() => invert()}
              className="swap-button"
            >
              <FiArrowDown />
            </AntdButton>

            <CoinSelectInput
              index={2}
              formRef={formRef}
              onChange={(val) =>
                coinSelectInputChanged(
                  val,
                  fromSelectedToken,
                  toSelectedToken,
                  "amount_1"
                )
              }
              onTokenSelected={(token) =>
                tokenSelected(token, setToSelectedToken, setFromTokens, true)
              }
              tokens={toTokens}
              selectedToken={toSelectedToken}
              disclaimerLabel={"Commission"}
              disclaimerValue={
                quote && formNameTo ? quote?.commission?.amount : null
              }
              disclaimerTokenSymbol={fromSelectedToken}
              customInputRules={[
                () => ({
                  validator(_, value) {
                    if (value) {
                      return isGreater(value, "0")
                        ? Promise.resolve()
                        : Promise.reject("Amount must be greater than 0.");
                    }
                    return Promise.resolve();
                  },
                }),
                ({}) => ({
                  validator(_, value) {
                    if (
                      value &&
                      value.length - (value.search(/\.|$/) + 1) > 18
                    ) {
                      return Promise.reject(
                        "Maximum number of decimals allowed is 18."
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            />

            <Form.Item
              name={"slippage"}
              className="slippage-select-input"
              rules={[
                { required: true, message: VALIDATION_ERROR_MESSAGES.required },
                ({}) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      (parseFloat(value) >= 1 && parseFloat(value) <= 49.99)
                    ) {
                      setHasSlippageError(false);
                      return Promise.resolve();
                    }
                    setHasSlippageError(true);
                    return Promise.reject(
                      new Error(VALIDATION_ERROR_MESSAGES.invalidSlippage)
                    );
                  },
                }),
              ]}
            >
              <AutoComplete
                options={slippageOptions}
                filterOption={(inputValue, option) => true}
                onChange={setSlippage}
              >
                <Input
                  type={"number"}
                  prefix={<span>Slippage</span>}
                  suffix={<span>%</span>}
                  min={0.1}
                  max={49.99}
                  step={0.01}
                  // @ts-ignore
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => setSlippage(e.target.value)}
                />
              </AutoComplete>
            </Form.Item>

            {slippageWarning && (
              <p className="slippage-warning">{slippageWarning}</p>
            )}
          </Form>
          <div className={"price-info"}>
            <span className="convert-ratio">Price</span>
            {fromSelectedToken && toSelectedToken && quote ? (
              <Tooltip
                placement={"top"}
                title={
                  <div className={"tooltip-info"}>
                    1{fromSelectedToken.tokenSymbol} <br />=<br />{" "}
                    {formatStringNumber(quote.conversionRate)}{" "}
                    {toSelectedToken.tokenSymbol}
                  </div>
                }
              >
                <span className="convert-ratio">
                  1{fromSelectedToken.tokenSymbol} ={" "}
                  {windowSize.width <= 420
                    ? formatStringNumber(quote.conversionRate).slice(0, -10)
                    : formatStringNumber(quote.conversionRate)}
                  {toSelectedToken.tokenSymbol}
                </span>
              </Tooltip>
            ) : (
              <span className="convert-ratio">-</span>
            )}
          </div>
          <div className={"price-info"}>
            <span className="convert-ratio">
              <Tooltip title="Large swap orders may have an impact on the price. This is a trade size index marking a potential change of price during your transaction.">
                Price impact
                <FiHelpCircle className="infoIcon" />
              </Tooltip>
            </span>
            {quote &&
            amountWatch &&
            amountToWatch &&
            isGreater(amountWatch, "0") &&
            isGreater(amountToWatch, "0") ? (
              <span className="convert-ratio">{quote.priceImpact}% </span>
            ) : (
              <span className="convert-ratio">-</span>
            )}
          </div>
        </Spin>
        {quoteError && (
          <span className="generalError">
            {API_VALIDATION_ERROR_MESSAGES[quoteError]}
          </span>
        )}
        {showRefreshButton && (
          <p className="quote-warning">
            Current swap quote has expired. Please request a new quote by
            clicking 'Refresh'.
          </p>
        )}
      </div>
      <div data-aos="fade-up" className="containerSwap">
        <Button
          type={ButtonTypes.PRIMARY}
          label={"Refresh quote"}
          extraClasses={
            showRefreshButton ? "submitButton" : "submitButton displayNone"
          }
          onClick={() => swapQuote()}
        />
        <div className={showRefreshButton ? "displayNoneSwipe" : ""}>
          <SwipeButton
            onSwipe={swap}
            label={"Swipe to confirm"}
            timer={swipeButtonTimer}
            disabled={
              submitDisabled ||
              quoteError !== null ||
              !slippage ||
              hasSlippageError
            }
          />
        </div>
      </div>

      <SuccessModal
        isVisible={showSuccessModal}
        ctaLabel="Continue"
        title="Swap request submitted"
        description="Swap request has been registered. Once it's status is going to be updated you will receive a notification."
        ctaRedirect={ROUTES.wallet}
        modalType={SuccessModalTypes.SWAP}
        fromTokenName={fromSelectedToken?.tokenSymbol}
        toTokenName={toSelectedToken?.tokenSymbol}
      />
    </>
  );
};

export default Swap;
