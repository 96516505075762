import {
  GetStakingStatisticsParams,
  GetStakingStatisticsPayload,
  StakingStatisticsState,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: StakingStatisticsState = {
  stakingStatistics: null,
  stakingStatisticsLoading: false,
};

const stakingStatisticsSlice = createSlice({
  name: "stakingStatistics",
  initialState,
  reducers: {
    resetStakingStatisticsState() {
      return {
        ...initialState,
      };
    },
    getStakingStatisticsRequest(
      state,
      action: PayloadAction<GetStakingStatisticsParams>
    ) {
      return {
        ...state,
        stakingStatistics: null,
        stakingStatisticsLoading: true,
        params: action.payload,
      };
    },
    getStakingStatisticsSuccess(
      state,
      action: PayloadAction<GetStakingStatisticsPayload>
    ) {
      return {
        ...state,
        stakingStatistics: action.payload.statistics,
        stakingStatisticsLoading: false,
      };
    },
    getStakingStatisticsFailure(state) {
      return {
        ...state,
        stakingStatistics: null,
        stakingStatisticsLoading: false,
      };
    },
  },
});

export const {
  resetStakingStatisticsState,
  getStakingStatisticsRequest,
  getStakingStatisticsFailure,
  getStakingStatisticsSuccess,
} = stakingStatisticsSlice.actions;

export default stakingStatisticsSlice.reducer;
