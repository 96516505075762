import React from "react";
import DOMPurify from "dompurify";
import "./styles.less";

export const AboutTab = ({ data }) => {
  return (
    <div className="aboutTabComponent">
      {data.map((item, index) => (
        <div key={index}>
          <div className="aboutTabHeader">
            <div className="field">{item.category}</div>
            <div className="cardTitle">{item.title}</div>
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(item.content),
            }}
          />
        </div>
      ))}
    </div>
  );
};
