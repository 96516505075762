import { IValidateSecretRecoveryParams, SecretRecoveryState } from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: SecretRecoveryState = {
  secretRecoveryGenerationLoading: false,
  generatedRecoveryPhrase: {},
  validateRecoveryPhraseError: null,
  validateRecoveryPhraseSuccess: false,
};

const secretRecoverySlice = createSlice({
  name: "secretRecovery",
  initialState,
  reducers: {
    generateRecoveryOtpRequest(state) {
      return {
        ...state,
      };
    },
    postGenerateSecretRecoveryRequest(state) {
      return {
        ...state,
        secretRecoveryGenerationLoading: true,
        generatedRecoveryPhrase: [],
      };
    },
    postGenerateSecretRecoverySuccess(state, action) {
      return {
        ...state,
        secretRecoveryGenerationLoading: action.payload.status === "NEW",
        generatedRecoveryPhrase: action.payload.phrase,
      };
    },
    postGenerateSecretRecoveryFailure(state, action) {
      return {
        ...state,
        secretRecoveryGenerationLoading: false,
        generatedRecoveryPhrase: [],
      };
    },
    validateSecretRecoveryRequest(
      state,
      action: PayloadAction<IValidateSecretRecoveryParams>
    ) {
      return {
        ...state,
        secretRecoveryGenerationLoading: true,
        validateRecoveryPhraseSuccess: false,
        validateRecoveryPhraseError: null,
        params: action.payload,
        generatedRecoveryPhrase: [],
      };
    },
    validateSecretRecoverySuccess(state, action) {
      return {
        ...state,
        validateRecoveryPhraseError: null,
        validateRecoveryPhraseSuccess: true,
        secretRecoveryGenerationLoading: false,
        generatedRecoveryPhrase: action.payload,
      };
    },
    validateSecretRecoveryFailure(state, action) {
      return {
        ...state,
        validateRecoveryPhraseError: action.payload.error.response.data,
        secretRecoveryGenerationLoading: false,
        validateRecoveryPhraseSuccess: false,
        generatedRecoveryPhrase: [],
      };
    },
    resetState(state) {
      return {
        ...initialState,
      };
    },
    setRecoveryPhraseGenerationLoading(state, action) {
      return {
        ...state,
        secretRecoveryGenerationLoading: action.payload,
      };
    },
  },
});

export const {
  postGenerateSecretRecoveryRequest,
  postGenerateSecretRecoverySuccess,
  postGenerateSecretRecoveryFailure,

  validateSecretRecoveryRequest,
  validateSecretRecoverySuccess,
  validateSecretRecoveryFailure,
  setRecoveryPhraseGenerationLoading,
  generateRecoveryOtpRequest,
  resetState,
} = secretRecoverySlice.actions;

export default secretRecoverySlice.reducer;
