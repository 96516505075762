import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const selectGetCountriesLoading = (state: AppState) =>
  state.phoneValidation.getCountriesLoading;
const selectGetCountries = (state: AppState) => state.phoneValidation.countries;

const selectRequestSmsOtpLoading = (state: AppState) =>
  state.phoneValidation.requestSmsOtpLoading;
const selectRequestSmsOtpSuccess = (state: AppState) =>
  state.phoneValidation.requestSmsOtpSuccess;
const selectRequestSmsOtpError = (state: AppState) =>
  state.phoneValidation.requestSmsOtpError;

export const getCountriesLoading = createSelector(
  selectGetCountriesLoading,
  (res) => res
);
export const getCountries = createSelector(selectGetCountries, (res) => res);
export const getRequestSmsOtpLoading = createSelector(
  selectRequestSmsOtpLoading,
  (res) => res
);
export const getRequestSmsOtpSuccess = createSelector(
  selectRequestSmsOtpSuccess,
  (res) => res
);
export const getRequestSmsOtpError = createSelector(
  selectRequestSmsOtpError,
  (res) => res
);
