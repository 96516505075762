import moment from "moment";

export const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const years = Array(15)
  .fill(parseInt(moment().format("YYYY")))
  .map((e, i) => e + i);
