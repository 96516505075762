import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  getMaintenanceFailure,
  getMaintenanceRequest,
  getMaintenanceSuccess,
} from "./maintenanceSlice";
import { IMaintenanceResponse } from "./types";
import { axios } from "services/axios.service";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getMaintenanceSaga(action: ReturnType<typeof getMaintenanceRequest>) {
  try {
    const response: AxiosResponse<IMaintenanceResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/maintenance`
    );
    yield put(
      getMaintenanceSuccess({
        maintenance: response.data,
      })
    );
  } catch (e: any) {
    yield put(
      getMaintenanceFailure({
        error: e.response.data,
      })
    );
  }
}

function* walletSaga() {
  yield all([takeEvery(getMaintenanceRequest, getMaintenanceSaga)]);
}

export default walletSaga;
