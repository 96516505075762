import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/homePage";
import { AppLayout, TeaserLayout } from "./components";
import { ROUTES } from "./_helpers/config";
import ResetPassword from "./pages/resetPassword";
import { useCookies } from "react-cookie";
import NotFound from "./pages/errors/404";
import InternalServerError from "./pages/errors/500";
import WalletPage from "./pages/wallet";
import AOS from "aos";
import "aos/dist/aos.css";
import StakingPage from "./pages/staking";
import VestingPage from "./pages/vesting";
import UserProfile from "./pages/userProfile";
import HomePageProtected from "./pages/homePageProtected";
import { useEffect } from "react";
import { scrollToTop } from "./_helpers/scroll";
import { initGTM } from "./_helpers/gtm";
import ReleaseNotes from "./pages/releaseNotes";
import NewsPage from "./pages/news";
import Notifications from "./pages/notifications";
import EventsPage from "./pages/events";
import ArticlePage from "./pages/article";
import FavoriteNews from "./pages/favoriteNews";
import SwapPage from "./pages/swap";
import ReferralPage from "./pages/referral";
import PublicStaking from "./pages/publicStaking";
import Launchpad from "./pages/launchpad";
import CampaignDetailsPage from "./pages/campaignDetails";
import Portfolio from "./pages/portfolio";
import TokenDetailsPage from "./pages/tokenDetails";
import CampaignInvestPage from "./pages/campaignInvest";
import TransactionsPage from "./pages/transactions";
import CommunityTestLaunchpad from "./pages/communityTestLaunchpad";
import WatchlistCampaigns from "./pages/watchlistCampaigns";
import BuyPage from "./pages/buy";
import FiatDepositsCampaignPage from "./pages/fiatDepositsCampaign";
import BuyTestPage from "./pages/buyTest";

AOS.init({
  offset: 50,
  duration: 800,
  easing: "ease-in-out-sine",
  delay: 200,
  once: true,
});

export const ProtectedRoute = ({
  children,
  extraClasses,
}: {
  children: React.ReactNode;
  extraClasses?: string;
}) => {
  const [token] = useCookies(["sOnFinanceToken"]);
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  if (
    !token.sOnFinanceToken &&
    ![ROUTES.homePage, ROUTES["404"], ROUTES["500"]].includes(
      window.location.pathname
    )
  ) {
    localStorage.setItem("intended", window.location.pathname);
    window.location.href = process.env.REACT_APP_AUTH_URL;
  }

  return token.sOnFinanceToken ? (
    <AppLayout extraClasses={extraClasses}>{children}</AppLayout>
  ) : (
    <Navigate to="/" />
  );
};

export const UnProtectedRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [token] = useCookies(["sOnFinanceToken"]);
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return token?.sOnFinanceToken ? (
    <Navigate to={ROUTES.homePage} />
  ) : (
    <TeaserLayout>{children}</TeaserLayout>
  );
};

export const HomePageLayout = () => {
  const [token] = useCookies(["sOnFinanceToken"]);
  return token?.sOnFinanceToken ? (
    <AppLayout>
      <HomePageProtected />
    </AppLayout>
  ) : (
    <TeaserLayout>
      <HomePage />
    </TeaserLayout>
  );
};

export const StakingLayout = () => {
  const [token] = useCookies(["sOnFinanceToken"]);
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return token?.sOnFinanceToken ? (
    <Navigate to={ROUTES.stake} />
  ) : (
    <TeaserLayout>
      <PublicStaking />
    </TeaserLayout>
  );
};

export const PublicLayout = ({
  children,
  isNotFound,
  extraClasses,
}: {
  children: React.ReactNode;
  isNotFound?: boolean;
  extraClasses?: string;
}) => {
  const [token] = useCookies(["sOnFinanceToken"]);
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return token?.sOnFinanceToken ? (
    <AppLayout isNotFound={isNotFound} extraClasses={extraClasses}>
      {children}
    </AppLayout>
  ) : (
    <TeaserLayout extraClasses={extraClasses}>{children}</TeaserLayout>
  );
};

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_INIT_GTM) {
      initGTM();
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.homePage} element={<HomePageLayout />} />
        <Route
          path={ROUTES.resetPassword}
          element={
            <UnProtectedRoute>
              <ResetPassword />
            </UnProtectedRoute>
          }
        />
        <Route
          path={ROUTES["404"]}
          element={
            <PublicLayout isNotFound>
              <NotFound />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES["500"]}
          element={
            <PublicLayout isNotFound>
              <InternalServerError />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.tokenDetails}
          element={
            <PublicLayout>
              <TokenDetailsPage />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.wallet}
          element={
            <ProtectedRoute>
              <WalletPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.buy}
          element={
            <PublicLayout>
              <BuyPage />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.fiatDepositsCampaign}
          element={
            <PublicLayout extraClasses="fiat-background-image">
              <FiatDepositsCampaignPage />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.fiatDepositsCampaignTC}
          element={
            <PublicLayout extraClasses="fiat-background-image">
              <FiatDepositsCampaignPage />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.transactions}
          element={
            <ProtectedRoute>
              <TransactionsPage />
            </ProtectedRoute>
          }
        />
        <Route path={ROUTES.publicStake} element={<StakingLayout />} />
        <Route
          path={ROUTES.vesting}
          element={
            <ProtectedRoute>
              <VestingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.referral}
          element={
            <PublicLayout>
              <ReferralPage />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.userProfile}
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.releaseNotes}
          element={
            <PublicLayout isNotFound={true}>
              <ReleaseNotes />
            </PublicLayout>
          }
        />

        {/*<Route*/}
        {/*  path={ROUTES.launchpad}*/}
        {/*  element={*/}
        {/*    <PublicLayout>*/}
        {/*      <Launchpad />*/}
        {/*    </PublicLayout>*/}
        {/*  }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path={ROUTES.campaignDetails}*/}
        {/*  element={*/}
        {/*    <PublicLayout>*/}
        {/*      <CampaignDetailsPage />*/}
        {/*    </PublicLayout>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path={ROUTES.campaignInvestment}
          element={
            <ProtectedRoute>
              <CampaignInvestPage />
            </ProtectedRoute>
          }
        />
        {/*<Route*/}
        {/*  path={ROUTES.portfolio}*/}
        {/*  element={*/}
        {/*    <ProtectedRoute>*/}
        {/*      <Portfolio />*/}
        {/*    </ProtectedRoute>*/}
        {/*  }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path={ROUTES.watchlist}*/}
        {/*  element={*/}
        {/*    <ProtectedRoute>*/}
        {/*      <WatchlistCampaigns />*/}
        {/*    </ProtectedRoute>*/}
        {/*  }*/}
        {/*/>*/}

        {/*<Route*/}
        {/*  path={ROUTES.launchpad}*/}
        {/*  element={*/}
        {/*    <PublicLayout>*/}
        {/*      <CommunityTestLaunchpad />*/}
        {/*    </PublicLayout>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path={ROUTES.news}
          element={
            <PublicLayout>
              <NewsPage />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.favoriteNews}
          element={
            <PublicLayout>
              <FavoriteNews />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.notifications}
          element={
            <PublicLayout>
              <Notifications />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.events}
          element={
            <PublicLayout>
              <EventsPage />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.event}
          element={
            <PublicLayout>
              <EventsPage />
            </PublicLayout>
          }
        />
        <Route
          path={ROUTES.article}
          element={
            <PublicLayout>
              <ArticlePage />
            </PublicLayout>
          }
        />
        <Route
          path={`${ROUTES.wallet}`}
          element={
            <ProtectedRoute>
              <WalletPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${ROUTES.wallet}/:step`}
          element={
            <ProtectedRoute>
              <WalletPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <PublicLayout isNotFound>
              <NotFound />
            </PublicLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
