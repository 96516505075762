export interface IFile {
  checksum: string;
  contentType: string;
  fileName: string;
  filePath: string;
  id: string;
  imageResolution: {
    height: number;
    width: number;
  };
  owner: string;
  private: boolean;
  size: number;
  url: string;
}

export interface ITeamMemberCardProps {
  memberId: number;
  name: string;
  position: string;
  description: string;
  socialLinks: Array<ISocialLinkProps>;
  avatar: IFile;
  hidden?: boolean;
  onDelete: (cardCorrelationId: string) => void;
  onEdit: (cardCorrelationId: string) => void;
  disabled?: boolean;
  correlationId?: string;
}

interface ISocialLinkProps {
  type: string;
  url: string;
}

export enum SOCIAL_MEDIA_PLATFORM {
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  TELEGRAM = "TELEGRAM",
  OTHER = "OTHER",
  DISCORD = "DISCORD",
  MEDIUM = "MEDIUM",
  REDDIT = "REDDIT",
}
