import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IGetReferralSuccess, ReferralState } from "./types";

const initialState: ReferralState = {
  referral: null,
  referLoading: false,
  referSuccess: false,
};

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
    getReferralRequest(state, action) {
      return {
        ...state,
        params: action.payload,
        referral: null,
      };
    },
    getReferralSuccess(state, action: PayloadAction<IGetReferralSuccess>) {
      return {
        ...state,
        referral: action.payload.referral,
      };
    },
    getReferralFailure(state) {
      return {
        ...state,
        referral: null,
      };
    },
    referRequest(state, action) {
      return {
        ...state,
        params: action.payload,
        referLoading: true,
        referSuccess: false,
      };
    },
    referSuccess(state) {
      return {
        ...state,
        referLoading: false,
        referSuccess: true,
      };
    },
    referFailure(state) {
      return {
        ...state,
        referLoading: false,
        referSuccess: false,
      };
    },
    resetReferState(state) {
      return {
        ...state,
        referLoading: false,
        referSuccess: false,
      };
    },
  },
});

export const {
  getReferralRequest,
  getReferralSuccess,
  getReferralFailure,
  referRequest,
  referSuccess,
  referFailure,
  resetReferState,
} = referralSlice.actions;

export default referralSlice.reducer;
