import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const postLoading = (state: AppState) => state.login.loading;
const postLoginRequest = (state: AppState) => state.login.loginRequest;

const postError = (state: AppState) => state.login.error;
const postLoginSuccess = (state: AppState) => state.login.loginSuccess;

const googleLoginLoading = (state: AppState) => state.login.googleLoginLoading;
const googleLoginResponse = (state: AppState) =>
  state.login.googleLoginResponse;
const googleLoginError = (state: AppState) => state.login.googleLoginError;
const logoutSuccess = (state: AppState) => state.login.logoutSuccess;

export const logoutSuccessSelector = createSelector(
  logoutSuccess,
  (login) => login
);
export const googleLoginLoadingSelector = createSelector(
  googleLoginLoading,
  (login) => login
);
export const googleLoginResponseSelector = createSelector(
  googleLoginResponse,
  (login) => login
);
export const googleLoginErrorSelector = createSelector(
  googleLoginError,
  (login) => login
);

export const postLoginRequestSelector = createSelector(
  postLoginRequest,
  (login) => login
);
export const postLoginSuccessSelector = createSelector(
  postLoginSuccess,
  (login) => login
);

export const postLoadingSelector = createSelector(
  postLoading,
  (pending) => pending
);

export const postLoginErrorSelector = createSelector(
  postError,
  (error) => error
);
