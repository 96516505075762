import React, { useEffect, useState } from "react";
import { DatePicker, Modal } from "antd";
import { IoClose } from "react-icons/io5";
import { IReportDownloadModalProps } from "./interface";
import { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import "./reportDownload.less";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadReportRequest,
  getReportRequest,
  resetReportState,
} from "./reportSlice";
import {
  getReportDownloadSelector,
  getReportErrorSelector,
  getReportSelector,
} from "./selectors";
import { openFileFromUrl } from "../../_helpers/fileDownloader";
import Button from "../Button";
import { ButtonTypes } from "../Button/types";
const WithdrawModal = (props: IReportDownloadModalProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(null);
  const getReportError = useSelector(getReportErrorSelector);
  const reportDownload = useSelector(getReportDownloadSelector);
  const report = useSelector(getReportSelector);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && (current > moment() || current <= moment("2022-01-01"));
  };
  const downloadFile = () => {
    if (getReportError) {
      const sendForm = async () => {
        const token = await executeRecaptcha("test");
        dispatch(
          downloadReportRequest({
            year: selectedDate.format("Y"),
            month: selectedDate.format("M"),
            captchaToken: token,
          })
        );
      };
      sendForm().then(() => {});
    }
    if (report) {
      openFileFromUrl(report.url, report.name, "csv");
    }
  };

  useEffect(() => {
    if (selectedDate) {
      const sendForm = async () => {
        const token = await executeRecaptcha("test");
        dispatch(
          getReportRequest({
            year: selectedDate.format("Y"),
            month: selectedDate.format("M"),
            captchaToken: token,
          })
        );
      };
      sendForm().then(() => {});
    }
  }, [selectedDate]);
  useEffect(() => {
    if (!props.isVisible) {
      setSelectedDate(null);
      dispatch(resetReportState());
    }
  }, [props]);
  useEffect(() => {
    if (reportDownload) {
      openFileFromUrl(reportDownload.url, reportDownload.name, "csv");
    }
  }, [reportDownload]);
  return (
    <>
      <Modal
        closeIcon={<IoClose />}
        open={props.isVisible}
        onCancel={() => props.onCancel()}
        className="authModals walletModals withdrawModal reportDownload"
        maskClosable={false}
        footer={null}
        forceRender
      >
        <div className="heading">
          <h2>Download report</h2>
          <p>
            Export your monthly transaction records for tax filing purposes.
          </p>
          <a
            href="https://finance-s3.seedon.io/seedon-finance/public/files/SeedOn-Finance/sample-report.csv"
            target="_blank"
            className="sample"
          >
            Download Sample
          </a>
        </div>

        <div className="form-item">
          <p>Select month</p>
          <DatePicker
            picker="month"
            value={selectedDate}
            disabledDate={disabledDate}
            onChange={(val) => setSelectedDate(val)}
          />

          {getReportError || report ? (
            <>
              <Button
                type={ButtonTypes.PRIMARY}
                onClick={() => downloadFile()}
                label={"Download"}
                extraClasses={"downloadBtn"}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </>
  );
};

export default WithdrawModal;
