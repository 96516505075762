import { GetReportParams, GetReportPayload, ReportState } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ReportState = {
  report: null,
  reportLoading: false,
  reportError: false,
  reportDownload: null,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    resetReportState() {
      return {
        ...initialState,
      };
    },
    getReportRequest(state, action: PayloadAction<GetReportParams>) {
      return {
        ...state,
        report: null,
        reportLoading: true,
        reportError: false,
        params: action.payload,
      };
    },
    getReportSuccess(state, action: PayloadAction<GetReportPayload>) {
      return {
        ...state,
        report: action.payload.report,
        reportLoading: false,
        reportError: false,
      };
    },
    getReportFailure(state) {
      return {
        ...state,
        report: null,
        reportLoading: false,
        reportError: true,
      };
    },
    downloadReportRequest(state, action: PayloadAction<GetReportParams>) {
      return {
        ...state,
        reportDownload: null,
        params: action.payload,
      };
    },
    downloadReportSuccess(state, action: PayloadAction<GetReportPayload>) {
      return {
        ...state,
        report: action.payload.report,
        reportDownload: action.payload.report,
      };
    },
    downloadReportFailure(state) {
      return {
        ...state,
        reportDownload: null,
      };
    },
  },
});

export const {
  resetReportState,
  getReportRequest,
  getReportFailure,
  getReportSuccess,
  downloadReportRequest,
  downloadReportSuccess,
  downloadReportFailure,
} = reportSlice.actions;

export default reportSlice.reducer;
