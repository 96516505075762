import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axios } from "../../services/axios.service";
import {
  campaignInteractionFailure,
  campaignInteractionRequest,
  campaignInteractionSuccess,
  getWatchlistCampaignsFailure,
  getWatchlistCampaignsRequest,
  getWatchlistCampaignsSuccess,
} from "./watchlistSlice";
import { ICampaignsResponse } from "./types";

function* getWatchlistCampaignsSaga(
  action: ReturnType<typeof getWatchlistCampaignsRequest>
) {
  try {
    const response: AxiosResponse<ICampaignsResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/launchpad/watchlist`,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getWatchlistCampaignsSuccess(response.data));
  } catch (e: any) {
    yield put(getWatchlistCampaignsFailure());
  }
}

function* campaignInteractionSaga(
  action: ReturnType<typeof campaignInteractionRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/launchpad/${action.payload.campaignId}/interaction`,
      action.payload,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(campaignInteractionSuccess());
  } catch (e: any) {
    yield put(campaignInteractionFailure());
  }
}

function* watchlistCampaignsSaga() {
  yield all([
    takeLatest(getWatchlistCampaignsRequest, getWatchlistCampaignsSaga),
    takeLatest(campaignInteractionRequest, campaignInteractionSaga),
  ]);
}

export default watchlistCampaignsSaga;
