import React, { useEffect, useState } from "react";
import "./style.less";
import { FormInput } from "../index";
import { useDispatch, useSelector } from "react-redux";
import {
  getFundsTotalLoadingSelector,
  getFundsTotalSelector,
  getWalletDataLoadingSelector,
  getWalletDataSelector,
} from "../Wallet/selectors";
import { notification, Spin } from "antd";
import {
  getFundsTotalRequest,
  getWalletDataRequest,
} from "../Wallet/walletSlice";
import { formatNumber } from "../../_helpers/numberFormatter";
import copy from "copy-to-clipboard";
import { getCurrentUserSelector } from "../User/selectors";
import { pushGtmEvent } from "../../_helpers/gtm";
import { GtmEvents } from "../../_helpers/types";
import DepositModal from "../WalletControls/DepositModal";
import { getMaintenanceSelector } from "../Maintenance/selectors";
import { CURRENCIES } from "../../_helpers/config";
import { displayWalletAddress } from "../../_helpers/walletAddressTruncate";
import { FiCopy, FiHome } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import PhoneValidationModal from "../PhoneValidationModal";
import { scrollToTop } from "../../_helpers/scroll";
import { BiRightArrowAlt } from "react-icons/bi";

const WalletSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);

  const fundsTotal = useSelector(getFundsTotalSelector);
  const walletData = useSelector(getWalletDataSelector);
  const fundsTotalLoading = useSelector(getFundsTotalLoadingSelector);
  const walletDataLoading = useSelector(getWalletDataLoadingSelector);
  const user = useSelector(getCurrentUserSelector);
  const [depositModalVisible, setDepositModalVisible] = useState(false);
  const maintenance = useSelector(getMaintenanceSelector);

  const copyToClipboard = () => {
    if (maintenance && maintenance.status === "ACTIVE") {
      return;
    }
    //TODO FIX bug on copy to clipboard when there is only one call
    copy(walletData.blockchainAddress);
    copy(walletData.blockchainAddress);
    pushGtmEvent(GtmEvents.COPY_WALLET_TO_CLIPBOARD);
    notification.success({
      message: "Wallet address successfully copied to clipboard.",
    });
    setDepositModalVisible(true);
  };

  useEffect(() => {
    dispatch(getWalletDataRequest());
    dispatch(getFundsTotalRequest());
  }, []);

  const validatePhone = () => {
    pushGtmEvent(GtmEvents.VALIDATE_PHONE);
    setPhoneVerifyModalVisible(true);
  };

  const phoneValidationModalClosed = () => {
    setPhoneVerifyModalVisible(false);
    scrollToTop();
  };

  return (
    <div className="detailsBox wallet" data-aos="fade-up" data-aos-delay="800">
      <div className="network">
        <span className="active" />
        Binance Smart Chain
      </div>

      {fundsTotalLoading ? (
        <div className="loading inline">
          <Spin />
        </div>
      ) : (
        <>
          {fundsTotal ? (
            <span className="total">
              {user ? CURRENCIES[user.state.properties.selectedCurrency] : ""}
              {formatNumber(fundsTotal.total)}
            </span>
          ) : (
            ""
          )}
        </>
      )}

      {walletDataLoading ? (
        <div className="loading inline">
          <Spin />
        </div>
      ) : (
        <div className="walletAddressContainer">
          <FiHome className="homeIcon" onClick={() => navigate("/wallet")} />
          {maintenance && maintenance.status === "ACTIVE" ? (
            <div onClick={() => copyToClipboard()} className="walletInput">
              {walletData ? (
                <div className="walletAddressCopyContainer">
                  <FormInput
                    disabled
                    value={displayWalletAddress(
                      walletData.blockchainAddress,
                      10
                    )}
                  />
                  <FiCopy className="copyIcon" />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div onClick={() => copyToClipboard()} className="walletInput">
              {walletData ? (
                <div className="walletAddressCopyContainer">
                  <FormInput
                    disabled
                    value={displayWalletAddress(
                      walletData.blockchainAddress,
                      10
                    )}
                  />
                  <FiCopy className="copyIcon" />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      )}

      {user && !user.state.properties.phoneNumberVerified && (
        <div className="phoneConfirmationBox">
          <div>
            <AiOutlineInfoCircle size={20} color="#fff" />
          </div>
          <div>
            <p>
              In order to withdraw or buy SEON using a credit card, it is
              necessary to validate your phone number first.
            </p>
            <div
              className="validatePhoneAction"
              onClick={() => validatePhone()}
            >
              Validate now <BiRightArrowAlt size={18} />
            </div>
          </div>
        </div>
      )}

      <DepositModal
        onCancel={() => setDepositModalVisible(false)}
        isVisible={depositModalVisible}
        cta={"I understand"}
      />
      {phoneVerifyModalVisible && (
        <PhoneValidationModal
          isVisible={phoneVerifyModalVisible}
          closeModal={() => phoneValidationModalClosed()}
        />
      )}
    </div>
  );
};

export default WalletSummary;
