import React, { useEffect } from "react";
import { WalletLayout } from "../index";
import { Spin, Tabs } from "antd";
import CoinsList from "./CoinsList";
import { useDispatch, useSelector } from "react-redux";
import { getLoadingSelector } from "../Wallet/selectors";
import StakingHeader from "./StakingHeader";
import { getWhitelistedCoinsRequest } from "../Wallet/walletSlice";
import { AnimatedCard } from "../AnimatedCard/animatedCard";
import "./style.less";

const Staking = () => {
  const dispatch = useDispatch();

  const whitelistedCoinsLoading = useSelector(getLoadingSelector);

  useEffect(() => {
    dispatch(getWhitelistedCoinsRequest());
  }, []);

  return (
    <WalletLayout showControls={false} title="DeFi Staking">
      <StakingHeader />
      <Tabs
        centered
        defaultActiveKey="1"
        destroyInactiveTabPane
        className="walletTabs stakingTabs"
        data-aos="fade-up"
        data-aos-delay="1000"
        items={[
          {
            label: "Pools",
            key: "1",
            children: whitelistedCoinsLoading ? (
              <div className="loading inline">
                <Spin />
              </div>
            ) : (
              <CoinsList />
            ),
          },
        ]}
      />
      <div className={"stakingCards"}>
        <div className={"cards"}>
          <AnimatedCard
            cardId={"1"}
            title={"Why choose our staking solution?"}
            description={
              "Taking part in our staking program is always commission free, be it either in $SEON, $USDT or any other available token. Thus, you can benefit from a fully staking investment, as we'll not take any fees out of it."
            }
          />
          <AnimatedCard
            cardId={"2"}
            title={"Convenient <br/> requirements"}
            description={
              "Be it either a full time crypto investor or just learning the whereabouts of blockchain investments, you can stake your amount in a user-friendly way to enhance your savings."
            }
          />
          <AnimatedCard
            cardId={"3"}
            title={"Easy-to-use<br/> staking process"}
            description={
              "Our staking process is as easy as just depositing and staking your tokens with one click. No previous experience with crypto or mining is required."
            }
          />
          <AnimatedCard
            cardId={"4"}
            title={"Why choose our staking solution?"}
            description={
              "On our platform you can choose to enhance your earnings either via a flexible staking type, meaning that you'll get your rewards daily and can be cancelled at any time. For higher APYs you can choose the locked staking."
            }
          />
        </div>
      </div>
    </WalletLayout>
  );
};

export default Staking;
