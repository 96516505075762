import "./style.less";
import moment from "moment";
import { useEffect, useState } from "react";

const Counter = (props: {
  dueDate: string;
  callback: () => void;
  title: string;
  description: string;
}) => {
  const [seconds, setSeconds] = useState<number>();

  useEffect(() => {
    if (seconds <= 0) {
      props.callback();
    }
  }, [seconds]);
  useEffect(() => {
    const interval = setInterval(
      () => setSeconds(moment.utc(props.dueDate).diff(moment(), "seconds")),
      1000
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  const displayTime = (data, label) => (
    <div className="date">
      <div className="date-item">
        <span>{data > 0 ? data : 0}</span>
      </div>
      <div className="label">{label}</div>
    </div>
  );

  return (
    <div className="community-test">
      <div className="pageTitle">
        <div className="content" data-aos="fade-up">
          <h1>{props.title}</h1>
          <p>{props.description}</p>
        </div>

        <div className="date-container-placeholder">
          {seconds !== undefined && (
            <div data-aos="zoom-in">
              <div className="date-container">
                {displayTime(
                  Math.floor(seconds / (60 * 60 * 24)),
                  Math.floor(seconds / (60 * 60 * 24)) === 1 ? "day" : "days"
                )}
                {displayTime(
                  Math.floor((seconds / (60 * 60)) % 24),
                  Math.floor((seconds / (60 * 60)) % 24) === 1
                    ? "hour"
                    : "hours"
                )}
                {displayTime(
                  Math.floor((seconds / 60) % 60),
                  Math.floor((seconds / 60) % 60) === 1 ? "minute" : "minutes"
                )}
                {displayTime(
                  Math.floor(seconds % 60),
                  Math.floor(seconds % 60) === 1 ? "second" : "seconds"
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Counter;
