import { Input, InputNumber } from "antd";

import { IFormInputNumberProps } from "./interface";
import { useState } from "react";
import "./style.less";
import { formatStringNumber } from "../../_helpers/numberFormatter";

const FormInputNumber = (props: IFormInputNumberProps) => {
  const { label, value, hidelabel, defaultValue } = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="form-number-item">
      {hidelabel !== "true" && (
        <div className={`${isFocused || value ? "label" : "placeholder"} `}>
          {label}
        </div>
      )}

      <div className="input-wrapper">
        <InputNumber
          stringMode
          controls={false}
          autoComplete={"off"}
          style={{
            borderRightWidth: "2px !important",
          }}
          formatter={(value) => {
            return formatStringNumber(value);
          }}
          defaultValue={defaultValue as any}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          className="input-with-placeholder"
          disabled={props.disabled ? props.disabled : false}
          prefix={props.prefix}
          suffix={props.suffix}
          onChange={props.onChange}
          onPaste={(e) => {
            if (props.disablepaste === "true") {
              e.preventDefault();
            }
            return !(props.disablepaste === "true");
          }}
          min={props.min}
          max={props.max}
          {...props}
        />
      </div>
    </div>
  );
};
export default FormInputNumber;
