import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getNotificationsRequest,
  getNotificationsSuccess,
  getNotificationsFailure,
  markNotificationAsReadRequest,
  getUnreadNotificationsCountRequest,
  getUnreadNotificationsCountSuccess,
  getUnreadNotificationsCountFailure,
  markAllNotificationsAsReadSuccess,
  markAllNotificationsAsReadFailure,
  markAllNotificationsAsReadRequest,
  markNotificationAsReadSuccess,
  markNotificationAsReadFailure,
} from "./notificationSlice";
import { axios } from "services/axios.service";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getNotificationsSaga(
  action: ReturnType<typeof getNotificationsRequest>
) {
  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_NOTIFICATION_URL}/notifications`,
      {
        params: {
          ...action.payload,
          service: "SEEDON_FINANCE",
        },
      }
    );
    yield put(
      getNotificationsSuccess({
        notifications: response.data,
      })
    );
  } catch (e: any) {
    yield put(getNotificationsFailure());
  }
}

function* markNotificationAsReadSaga(
  action: ReturnType<typeof markNotificationAsReadRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_NOTIFICATION_URL}/notifications/${action.payload.notificationId}/read`
    );
    yield put(markNotificationAsReadSuccess());
  } catch (e: any) {
    yield put(markNotificationAsReadFailure());
  }
}

function* markAllNotificationAsReadSaga() {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_NOTIFICATION_URL}/notifications/read/all`
    );
    yield put(markAllNotificationsAsReadSuccess());
  } catch (e: any) {
    yield put(markAllNotificationsAsReadFailure());
  }
}

function* unreadNotificationsSaga() {
  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_NOTIFICATION_URL}/notifications/unread/count`
    );
    yield put(
      getUnreadNotificationsCountSuccess({
        unreadNotifications: response.data.unread,
      })
    );
  } catch (e: any) {
    yield put(getUnreadNotificationsCountFailure());
  }
}

function* walletSaga() {
  yield all([
    takeLatest(getNotificationsRequest, getNotificationsSaga),
    takeLatest(markNotificationAsReadRequest, markNotificationAsReadSaga),
    takeLatest(getUnreadNotificationsCountRequest, unreadNotificationsSaga),
    takeLatest(
      markAllNotificationsAsReadRequest,
      markAllNotificationAsReadSaga
    ),
  ]);
}

export default walletSaga;
