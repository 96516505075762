import React, { useState } from "react";
import "./style.less";

// Helpers
import { ROUTES } from "../../_helpers/config";
import { useNavigate, useParams } from "react-router-dom";
import { scrollToTop } from "../../_helpers/scroll";

// Components
import { Button } from "../index";
import { notification } from "antd";
import DepositModal from "./DepositModal";
import WithdrawModal from "./WithdrawModal";
import OtpModalMultiple from "../OtpModalMultiple";

// Selectors
import { getCurrentUserSelector } from "../User/selectors";
import { getMaintenanceSelector } from "../Maintenance/selectors";

// Google Analytics
import { GtmEvents } from "../../_helpers/types";
import { pushGtmEvent } from "../../_helpers/gtm";

import BuyIcon from "../../assets/images/wallet/buy";
import StakeIcon from "../../assets/images/wallet/stake";
import SwapIcon from "../../assets/images/wallet/swap";

// Redux
import { useSelector } from "react-redux";
import { FiDownload, FiUpload } from "react-icons/fi";

const WalletControls = ({ defaultButtons = false }) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentStep = params.step;

  const [depositModalVisible, setDepositModalVisible] = useState(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [otpModalVisible, setOtpModalVisible] = useState(false);
  const [additionalPayload, setAdditionalPayload] = useState(false);
  const maintenance = useSelector(getMaintenanceSelector);

  const user = useSelector(getCurrentUserSelector);

  const withdrawSubmitted = (formData) => {
    setAdditionalPayload({
      ...formData,
      contractAddress: formData.token.contractAddress,
    });
    setWithdrawModalVisible(false);
    setOtpModalVisible(true);

    scrollToTop();
  };

  const otpModalSuccess = () => {
    notification.success({
      message: "Withdraw request created successfully.",
    });
    setOtpModalVisible(false);
    navigate(ROUTES.wallet);
  };

  const depositButtonClicked = () => {
    pushGtmEvent(GtmEvents.DEPOSIT_BUTTON_CLICKED);
    setDepositModalVisible(true);
  };

  const withdrawButtonClicked = () => {
    pushGtmEvent(GtmEvents.WITHDRAW_BUTTON_CLICKED);
    setWithdrawModalVisible(true);
  };

  const stakeButtonClicked = () => {
    pushGtmEvent(GtmEvents.STAKE_BUTTON_CLICKED);
    navigate(ROUTES.stake);
  };

  return (
    <div
      className={`walletButtons ${defaultButtons ? "defaultButtons" : ""}`}
      data-aos="fade-up"
      data-aos-delay="600"
    >
      {!defaultButtons ? (
        <>
          <div
            className={`walletControlButtonContainer ${
              currentStep === "deposit" ? "active" : ""
            }`}
          >
            <div
              className="walletControlButton"
              onClick={() => {
                depositButtonClicked();
                navigate(ROUTES.deposit);
              }}
            >
              <FiDownload />
            </div>
            <div className="buttonLabel">Deposit</div>
          </div>

          <div
            className={`walletControlButtonContainer ${
              currentStep === "stake" ? "active" : ""
            }`}
          >
            <div
              className="walletControlButton"
              onClick={() => stakeButtonClicked()}
            >
              <StakeIcon />
            </div>
            <div className="buttonLabel">Stake</div>
          </div>

          <div
            className={`walletControlButtonContainer ${
              currentStep === "buy" ? "active" : ""
            }`}
          >
            <div
              className={`walletControlButton`}
              onClick={() => {
                navigate(ROUTES.buy);
              }}
            >
              <BuyIcon />
            </div>
            <div className="buttonLabel">Buy</div>
          </div>

          <div
            className={`walletControlButtonContainer ${
              currentStep === "swap" ? "active" : ""
            }`}
          >
            <div
              className="walletControlButton"
              onClick={() => navigate(ROUTES.swap)}
            >
              <SwapIcon />
            </div>
            <div className="buttonLabel">Swap</div>
          </div>

          <div
            className={`walletControlButtonContainer ${
              currentStep === "withdraw" ? "active" : ""
            } ${
              !user ||
              !user.state.properties?.phoneNumberVerified ||
              (maintenance && maintenance.status === "ACTIVE")
                ? "disabled"
                : ""
            }`}
          >
            <div
              className={`walletControlButton ${
                !user ||
                !user.state.properties?.phoneNumberVerified ||
                (maintenance && maintenance.status === "ACTIVE")
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                if (
                  user &&
                  user.state.properties?.phoneNumberVerified &&
                  !(maintenance && maintenance.status === "ACTIVE")
                ) {
                  withdrawButtonClicked();
                  navigate(ROUTES.withdraw);
                }
              }}
            >
              <FiUpload />
            </div>
            <div className="buttonLabel">Withdraw</div>
          </div>

          <div className="walletControlButtonMobile">
            <div
              className="walletControlButtonMobileItem"
              onClick={() => {
                depositButtonClicked();
                navigate(ROUTES.deposit);
              }}
            >
              <FiDownload />
              Deposit
            </div>
            <div
              className={`walletControlButtonMobileItem ${
                !user ||
                !user.state.properties?.phoneNumberVerified ||
                (maintenance && maintenance.status === "ACTIVE")
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                if (
                  user &&
                  user.state.properties?.phoneNumberVerified &&
                  !(maintenance && maintenance.status === "ACTIVE")
                ) {
                  withdrawButtonClicked();
                  navigate(ROUTES.withdraw);
                }
              }}
            >
              <FiUpload /> Withdraw
            </div>
          </div>
        </>
      ) : (
        <>
          <Button label="View balance" onClick={() => navigate("/wallet")} />
          <Button label="Stake" onClick={() => navigate(ROUTES.stake)} />
        </>
      )}

      <DepositModal
        onCancel={() => setDepositModalVisible(false)}
        isVisible={depositModalVisible}
      />

      {withdrawModalVisible ? (
        <WithdrawModal
          isVisible={withdrawModalVisible}
          onCancel={() => setWithdrawModalVisible(false)}
          onSubmit={withdrawSubmitted}
        />
      ) : (
        ""
      )}

      {otpModalVisible ? (
        <OtpModalMultiple
          endpoint={"/withdraw"}
          isVisible={otpModalVisible}
          additionalPayload={additionalPayload}
          onSuccess={() => otpModalSuccess()}
          onCancel={() => setOtpModalVisible(false)}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default WalletControls;
