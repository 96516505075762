import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getCampaignsFailure,
  getCampaignsFilteredFailure,
  getCampaignsFilteredRequest,
  getCampaignsFilteredSuccess,
  getCampaignsRequest,
  getCampaignsSuccess,
  getCategoriesFailure,
  getCategoriesRequest,
  getCategoriesSuccess,
  getTagsFailure,
  getTagsRequest,
  getTagsSuccess,
} from "./launchpadSlice";
import { AxiosResponse } from "axios";
import {
  ICampaignResponse,
  ICategory,
  IFilteredCampaignResponse,
  ITag,
} from "./type";
import { axios } from "../../services/axios.service";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getCampaignsSaga(action: ReturnType<typeof getCampaignsRequest>) {
  try {
    const response: AxiosResponse<ICampaignResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/launchpad`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
        params: action.payload,
      }
    );
    yield put(getCampaignsSuccess(response.data));
  } catch (e: any) {
    yield put(getCampaignsFailure());
  }
}

function* getCampaignsFilteredSaga(
  action: ReturnType<typeof getCampaignsFilteredRequest>
) {
  try {
    const response: AxiosResponse<IFilteredCampaignResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/launchpad/filtered`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
        params: action.payload,
      }
    );
    yield put(getCampaignsFilteredSuccess(response.data.content));
  } catch (e: any) {
    yield put(getCampaignsFilteredFailure());
  }
}

function* getCategoriesSaga(action: ReturnType<typeof getCategoriesRequest>) {
  try {
    const response: AxiosResponse<ICategory[]> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/launchpad/categories`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
        params: action.payload,
      }
    );
    yield put(getCategoriesSuccess(response.data));
  } catch (e: any) {
    yield put(getCategoriesFailure());
  }
}

function* getTagsSaga(action: ReturnType<typeof getTagsRequest>) {
  try {
    const response: AxiosResponse<ITag[]> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/launchpad/tags`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
        params: action.payload,
      }
    );
    yield put(getTagsSuccess(response.data));
  } catch (e: any) {
    yield put(getTagsFailure());
  }
}

function* tokenDetailsSaga() {
  yield all([
    takeLatest(getCampaignsRequest, getCampaignsSaga),
    takeLatest(getCategoriesRequest, getCategoriesSaga),
    takeLatest(getTagsRequest, getTagsSaga),
    takeLatest(getCampaignsFilteredRequest, getCampaignsFilteredSaga),
  ]);
}
export default tokenDetailsSaga;
