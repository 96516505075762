import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getPoolDataFailure,
  getPoolDataRequest,
  getPoolDataSuccess,
} from "./investSlice";
import { AxiosResponse } from "axios";
import { axios } from "../../services/axios.service";
import { IPoolData } from "./type";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getPoolDataSaga(action: ReturnType<typeof getPoolDataRequest>) {
  try {
    const response: AxiosResponse<IPoolData> = yield call(
      axios.get,
      `${process.env.REACT_APP_FUNDRAISE_API_URL}/launchpad/${action.payload.projectId}/pool-data`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
        params: action.payload,
      }
    );
    yield put(getPoolDataSuccess(response.data));
  } catch (e: any) {
    yield put(getPoolDataFailure());
  }
}

function* tokenDetailsSaga() {
  yield all([takeLatest(getPoolDataRequest, getPoolDataSaga)]);
}
export default tokenDetailsSaga;
