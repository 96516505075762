const BuyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.944"
    height="16.944"
    viewBox="0 0 16.944 16.944"
  >
    <g
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      data-name="Icon feather-plus"
      transform="translate(-6.5 -6.5)"
    >
      <path d="M14.972 7.5v14.944" data-name="Path 10926"></path>
      <path d="M7.5 14.972h14.944" data-name="Path 10927"></path>
    </g>
  </svg>
);

export default BuyIcon;
