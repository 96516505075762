export const displayWalletAddress = (address, decimalsCount = 4) => {
  let walletAddress = "";
  for (let index in address) {
    if (Number.parseInt(index) <= decimalsCount) {
      walletAddress += address[index];
      continue;
    }
    if (Number.parseInt(index) === decimalsCount + 1) {
      walletAddress += "...";
      continue;
    }
    if (Number.parseInt(index) >= address.length - decimalsCount) {
      walletAddress += address[index];
      continue;
    }
  }
  return walletAddress;
};
