import React from "react";
import { Form, Tooltip } from "antd";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";

import FormInputNumber from "../FormInputNumber";
import FormDropdownSelect from "../DropdownSelect";

import { getWalletSelector } from "../Wallet/selectors";
import { formatStringNumber } from "../../_helpers/numberFormatter";

export const CoinSelectInput = (props: any) => {
  const wallet = useSelector(getWalletSelector);

  const insertMaxAmount = () => {
    const newFormRefValue = {};
    newFormRefValue[`amount_${props.index}`] =
      wallet[`${props.selectedToken?.contractAddress}Funds`].availableFunds;
    props.formRef.setFieldsValue(newFormRefValue);
    props.onChange(
      wallet[`${props.selectedToken?.contractAddress}Funds`].availableFunds
    );
  };
  return (
    <div className="coin-select-input">
      {props.selectedToken ? (
        <>
          <img
            src={props.selectedToken.iconUrl}
            className="token-icon"
            alt="token-icon"
          />

          <span className="token-name">{props.selectedToken.tokenName}</span>
        </>
      ) : (
        ""
      )}

      <Form.Item name={`token_${props.index}`}>
        <FormDropdownSelect
          onChange={props.onTokenSelected}
          label="Token"
          items={props.tokens}
          keyToShow={"tokenSymbol"}
          onClear={() => props.onTokenSelected(null)}
        />
      </Form.Item>

      <Form.Item
        name={`amount_${props.index}`}
        rules={props.customInputRules ? props.customInputRules : []}
        className="coin-form-item"
      >
        <FormInputNumber
          min={"0"}
          label="Amount"
          hidelabel={"true"}
          step={"0.000000000000000001"}
          onChange={debounce(props.onChange, 500)}
          disabled={!props.selectedToken}
        />
      </Form.Item>

      {props.selectedToken &&
        wallet[`${props.selectedToken?.contractAddress}Funds`] &&
        props.disclaimerValue && (
          <span className="token-disclaimer">
            <span>{props.disclaimerLabel}:</span>{" "}
            <Tooltip
              title={`${formatStringNumber(props.disclaimerValue)} ${
                props.disclaimerTokenSymbol
                  ? props.disclaimerTokenSymbol?.tokenSymbol
                  : ""
              }`}
            >
              <span className="token-commission">
                {formatStringNumber(props.disclaimerValue)}
              </span>
            </Tooltip>{" "}
            {props.disclaimerTokenSymbol
              ? props.disclaimerTokenSymbol?.tokenSymbol
              : null}
            {props.hasMaxFunctionality && (
              <span className="maxValue" onClick={() => insertMaxAmount()}>
                MAX
              </span>
            )}
          </span>
        )}
    </div>
  );
};
