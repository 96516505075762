import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store";
import { IUseScriptParams } from "./hookInterfaces";
import { useEffect } from "react";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useScript = (props: IUseScriptParams) => {
  const { url, async, defer, callback, id, lang } = props;

  if (!url) {
    throw new Error("useScript used without url");
  }
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.type = "text/javascript";
    script.id = id;
    script.lang = lang;

    if (defer) {
      script.defer = true;
    }

    if (async) {
      script.async = true;
    }

    if (callback) {
      script.onload = () => callback();
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
