import { IGoogleLoginParams, ILoginParams, LoginState } from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: LoginState = {
  loading: false,
  error: false,
  loginRequest: {
    email: null,
    password: null,
  },
  loginSuccess: false,
  googleLoginLoading: false,
  googleLoginResponse: null,
  googleLoginError: false,
  logoutSuccess: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    postLoginRequest(state, action: PayloadAction<ILoginParams>) {
      return {
        ...state,
        loading: true,
        params: action.payload,
        error: false,
        loginSuccess: false,
      };
    },
    logoutRequest(state, action) {
      return {
        ...state,
        params: action.payload,
      };
    },
    logoutSuccess(state) {
      return {
        ...state,
        logoutSuccess: true,
      };
    },
    postLoginSuccess(state, action) {
      return {
        ...state,
        loading: false,
        loginRequest: action.payload.loginRequest,
        error: false,
        loginSuccess: true,
      };
    },
    postLoginFailure(state) {
      return {
        ...state,
        loading: false,
        loginSuccess: false,
        loginRequest: initialState.loginRequest,
        error: true,
      };
    },
    googleLoginRequest(state, action: PayloadAction<IGoogleLoginParams>) {
      return {
        ...state,
        params: action.payload,
        googleLoginLoading: true,
        googleLoginResponse: null,
        googleLoginError: false,
      };
    },
    googleLoginSuccess(state, action) {
      return {
        ...state,
        params: action.payload,
        googleLoginLoading: false,
        googleLoginResponse: action.payload.googleLoginResponse,
        googleLoginError: false,
      };
    },
    googleLoginFailure(state) {
      return {
        ...state,
        googleLoginLoading: false,
        googleLoginResponse: null,
        googleLoginError: true,
      };
    },
    resetState(state) {
      return {
        ...initialState,
        loginRequest: state.loginRequest,
        googleLoginLoading: false,
        googleLoginResponse: null,
        googleLoginError: false,
        logoutSuccess: false,
      };
    },
  },
});

export const {
  logoutRequest,
  logoutSuccess,
  postLoginRequest,
  postLoginSuccess,
  postLoginFailure,
  resetState,
  googleLoginRequest,
  googleLoginSuccess,
  googleLoginFailure,
} = loginSlice.actions;

export default loginSlice.reducer;
