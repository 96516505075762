import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, Row, Tooltip, Typography } from "antd";
import { BiInfoCircle, BiRefresh } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoadingSelector,
  getWalletSelector,
  getWhitelistedCoinsSelector,
} from "../../../components/Wallet/selectors";
import {
  getQuoteErrorSelector,
  getQuoteLoadingSelector,
} from "../../../components/Swap/selectors";
import { isGreater, isLower } from "../../../_helpers/numberFunctions";
import { errorMapper } from "../../../_helpers/errorMapper";
import Button from "../../../components/Button";
import { CoinSelectInput } from "../../../components/Swap/CoinSelectInput";
import { scrollTo } from "../../../_helpers/scroll";
import {
  getAvailableFundsRequest,
  getWhitelistedCoinsRequest,
} from "../../../components/Wallet/walletSlice";
import { ROUTES } from "../../../_helpers/config";
import SuccessModal from "../../../components/SuccessModal";
import { SuccessModalTypes } from "../../../components/SuccessModal/interface";
import { ButtonTypes } from "../../../components/Button/types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { axios } from "../../../services/axios.service";

const { Title, Paragraph } = Typography;

const CryptoTab = ({ setInvestAmount, minInvest, campaignId, projectId }) => {
  const [formCrypto] = Form.useForm();
  const dispatch = useDispatch();

  const [tokenWalletBalance, setTokenWalletBalance] = useState(null);
  const [investCryptoSuccess, setInvestCryptoSuccess] = useState(false);
  const [campaignPoolData, setCampaignPoolData] = useState({
    investmentCurrencies: ["0x337610d27c682e347c9cd60bd4b3b107c9d34ddd"],
  });
  const [selectedCurrency, setSelectedCurrency] = useState<any>();

  const wallet = useSelector(getWalletSelector);
  const quoteLoading = useSelector(getQuoteLoadingSelector);
  const quoteError = useSelector(getQuoteErrorSelector);
  const whitelistedCoins = useSelector(getWhitelistedCoinsSelector);
  const whitelistedCoinsLoading = useSelector(getLoadingSelector);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const tokenSymbolUSDT = "USDT";
  const slippage = 2;

  const USDTToken = whitelistedCoins.find(
    (item) => item.tokenSymbol === tokenSymbolUSDT
  );
  const amountWatch = Form.useWatch("amount_1", formCrypto);

  const onSubmit = () => {
    formCrypto.validateFields().then(() => {
      const sendForm = async () => {
        const token = await executeRecaptcha("invest");
        axios
          .put(
            `${process.env.REACT_APP_FUNDRAISE_API_URL}/fundraising/launchpad/investments/${projectId}/invest`,
            {
              amount: amountWatch,
              currencyContract: "0x337610d27c682e347c9cd60bd4b3b107c9d34ddd",
            },
            {
              headers: {
                "SeedOn-Recaptcha": token,
              },
            }
          )
          .then((res) => {
            setInvestCryptoSuccess(true);
          });
      };
      sendForm();
    });
  };

  useEffect(() => {
    if (
      wallet &&
      wallet["0x337610d27c682e347c9cd60bd4b3b107c9d34dddFunds"] &&
      wallet["0x337610d27c682e347c9cd60bd4b3b107c9d34dddFunds"].availableFunds
    ) {
      setTokenWalletBalance(
        wallet["0x337610d27c682e347c9cd60bd4b3b107c9d34dddFunds"]
      );
    }
  }, [wallet]);
  const coinSelectInputChanged = (val, convertFrom, convertTo) => {};
  useEffect(() => {
    dispatch(getWhitelistedCoinsRequest());
    dispatch(
      getAvailableFundsRequest({
        contractAddress: "0x337610d27c682e347c9cd60bd4b3b107c9d34ddd",
      })
    );
    return () => {
      formCrypto.resetFields();
    };
  }, []);

  useEffect(() => {
    if (investCryptoSuccess) {
      scrollTo("invest-heading");
    }
  }, [investCryptoSuccess]);

  useEffect(() => {
    if (campaignPoolData && whitelistedCoins) {
      setSelectedCurrency(
        whitelistedCoins.filter(
          (item) =>
            item.contractAddress === campaignPoolData.investmentCurrencies[0]
        )[0]
      );
    }
  }, [campaignPoolData, whitelistedCoins]);

  useEffect(() => {
    if (!formCrypto.getFieldValue("token_1")) {
      formCrypto.setFieldsValue({ token_1: selectedCurrency });
    }
  }, [selectedCurrency]);
  return (
    <>
      <Row className="swapPage cryptoTabForm">
        <Form
          form={formCrypto}
          name="nest-messages"
          autoComplete={"off"}
          initialValues={{
            slippage: slippage,
            amount_1: "0",
            token_1: selectedCurrency,
          }}
        >
          {!whitelistedCoinsLoading && (
            <CoinSelectInput
              onTokenSelected={(value) => setSelectedCurrency(value)}
              index={1}
              formRef={formCrypto}
              onChange={(val) => {
                formCrypto.validateFields();
                coinSelectInputChanged(val, selectedCurrency, USDTToken);
              }}
              tokens={[USDTToken]}
              selectedToken={selectedCurrency}
              disclaimerLabel="Available"
              disclaimerValue={
                tokenWalletBalance
                  ? tokenWalletBalance.availableFunds.toString()
                  : "0"
              }
              customInputRules={[
                { required: true, message: "This field is required." },
                () => ({
                  validator(_, value) {
                    if (value && isGreater(value, "5")) {
                      return Promise.reject("Maximum investment is 5 USDT.");
                    }
                    return Promise.resolve();
                  },
                }),
                () => ({
                  validator(_, value) {
                    if (
                      value &&
                      value.length - (value.search(/\.|$/) + 1) > 18
                    ) {
                      return Promise.reject(
                        "Maximum number of decimals allowed is 18."
                      );
                    }
                    return Promise.resolve();
                  },
                }),
                () => ({
                  validator(_, value) {
                    if (value) {
                      return isLower(value, "1")
                        ? Promise.reject("Amount must be greater than 1.")
                        : Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                }),
                () => ({
                  validator(_, value) {
                    if (value && tokenWalletBalance?.availableFunds) {
                      return isGreater(value, tokenWalletBalance.availableFunds)
                        ? Promise.reject("Insufficient funds.")
                        : Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              hasMaxFunctionality
              wallet={tokenWalletBalance}
            />
          )}

          <Row>
            <Col xs={24} sm={24}>
              <div className="priceInfo seon">
                <span>Price</span>
                <span>
                  {/*{quote?.conversionRate*/}
                  {/*  ? `1 ${selectedCurrency?.tokenSymbol} = $${SEONPrice}`*/}
                  {/*  : "-"}*/}1USDT = $1
                  <div
                    onClick={() =>
                      coinSelectInputChanged(
                        amountWatch,
                        selectedCurrency,
                        USDTToken
                      )
                    }
                    className="refresh"
                  >
                    <BiRefresh size={20} />
                  </div>
                </span>
              </div>
            </Col>

            <Col xs={24} sm={24}>
              <div className="priceInfo slippage">
                <span>Slippage Tolerance:</span>
                <span>
                  {`${slippage}%`}
                  <Tooltip
                    className="slippageTooltip"
                    title="Slippage tolerance is a setting for the amount of price slippage you are willing to accept for a trade."
                  >
                    <BiInfoCircle size={16} />
                  </Tooltip>
                </span>
              </div>
            </Col>

            {/*{amountWatch > 0 && (*/}
            {/*  <Col xs={24} sm={24}>*/}
            {/*    <div className="priceInfo">*/}
            {/*      <span>Commission</span>*/}
            {/*      <span>*/}
            {/*        {`${quote?.commission || "-"} ${*/}
            {/*          selectedCurrency?.tokenSymbol*/}
            {/*        }`}*/}
            {/*        <Tooltip className="slippageTooltip" title="Commission">*/}
            {/*          <BiInfoCircle size={16} />*/}
            {/*        </Tooltip>*/}
            {/*      </span>*/}
            {/*    </div>*/}
            {/*  </Col>*/}
            {/*)}*/}
          </Row>

          <Row className="investAmount">
            <Col xs={24} sm={24}>
              <div className="priceInfo">
                <span>Invest amount</span>
                <span>
                  {amountWatch > 0 ? `${amountWatch || "-"} $` : `0 $`}
                </span>
              </div>
            </Col>
          </Row>

          {quoteError && (
            <span className="generalError">{errorMapper[quoteError]}</span>
          )}

          <Row className="separatorLine" />

          <Row className="formDisclaimer">
            <Title level={3} className="formDisclaimerTitle">
              Terms and conditions
            </Title>

            <Paragraph className="formDisclaimerDescription">
              SeedOn Venture is not intended as, and does not provide, any
              investment or financial advice whatsoever. As with any financial
              or investment decisions, you should conduct your own research and
              due diligence investigation, to properly evaluate the benefits and
              risks of any investment or financial transaction performed on or
              in connection to SeedOn Venture. You should also seek the advice
              and guidance of qualified accountants, financial advisors, tax
              advisors, legal counsels and investment advisors, in connection
              with any investment or financial transaction performed on or in
              connection to SeedOn Venture. Read more here{" "}
              <a
                href="https://seedon.io/terms-and-conditions/"
                target={"_blank"}
              >
                Terms and conditions.
              </a>
            </Paragraph>
          </Row>
          <Form.Item
            name="remember"
            valuePropName="checked"
            className="disclaimerCheckbox"
            rules={[
              {
                required: true,
                message: "Please check terms and conditions.",
              },
            ]}
          >
            <Checkbox>
              I acknowledge and agree with the{" "}
              <a
                href="https://seedon.io/terms-and-conditions/"
                target={"_blank"}
              >
                Terms and Conditions
              </a>{" "}
              provided by SeedOn Venture.
            </Checkbox>
          </Form.Item>
          <Button
            disabled={!amountWatch || isGreater(amountWatch, "5")}
            type={ButtonTypes.PRIMARY}
            extraClasses="submitButton"
            label="Invest"
            loading={quoteLoading || whitelistedCoinsLoading}
            onClick={() => onSubmit()}
          />
        </Form>
      </Row>

      <SuccessModal
        isVisible={investCryptoSuccess}
        ctaRedirect={ROUTES.launchpad}
        modalType={SuccessModalTypes.STAKE}
        ctaLabel="Continue"
        title="Investment request submitted"
        description="Investment request has been registered.
        Once it's status is going to be updated you will receive a notification."
      />
    </>
  );
};

export default CryptoTab;
