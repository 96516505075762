import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getTokenData = (state: AppState) => state.tokenDetails.tokenData;
const getTokenDataLoading = (state: AppState) =>
  state.tokenDetails.tokenDataLoading;
const getTokenDetails = (state: AppState) => state.tokenDetails.tokenDetails;
const getTokenDetailsLoading = (state: AppState) =>
  state.tokenDetails.tokenDetailsLoading;

export const getTokenDataSelector = createSelector(getTokenData, (res) => res);
export const getTokenDataLoadingSelector = createSelector(
  getTokenDataLoading,
  (res) => res
);
export const getTokenDetailsSelector = createSelector(
  getTokenDetails,
  (res) => res
);
export const getTokenDetailsLoadingSelector = createSelector(
  getTokenDetailsLoading,
  (res) => res
);
