import React, { useEffect, useState } from "react";
import { Collapse, Spin } from "antd";
import "chart.js/auto";
import { Button } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import {
  getStakingPoolsLoadingSelector,
  getStakingPoolsSelector,
} from "../../Staking/selectors";
import CoinPanelHead from "./CoinPanelHead";
import {
  getStakingPoolsRequest,
  resetStakingPoolDeposit,
  resetState,
} from "../../Staking/stakingSlice";
import {
  getLoadingSelector,
  getWhitelistedCoinsSelector,
} from "components/Wallet/selectors";
import StakingModal from "../StakeModal";
import { scrollToTop } from "../../../_helpers/scroll";
import { pushGtmEvent } from "../../../_helpers/gtm";
import { GtmEvents } from "../../../_helpers/types";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { ButtonTypes } from "../../Button/types";

const CoinsList = () => {
  const dispatch = useDispatch();

  const whitelistedCoinsLoading = useSelector(getLoadingSelector);
  const whitelistedCoins = useSelector(getWhitelistedCoinsSelector);

  const stakingPools = useSelector(getStakingPoolsSelector);
  const stakingPoolsLoading = useSelector(getStakingPoolsLoadingSelector);

  const [showStakeModal, setShowStakeModal] = useState(false);

  const [selectedCoinForStaking, setSelectedCoinForStaking] = useState(null);
  const [selectedStakingOption, setSelectedStakingOption] = useState(null);
  const [formattedStakingPools, setFormattedStakingPools] = useState([]);
  const [searchParams, _] = useSearchParams();

  const displayStakeModal = (coin, stakingOption) => {
    pushGtmEvent(GtmEvents.STAKE_BUTTON_CLICKED, {
      coin: coin.tokenSymbol,
      apy: stakingOption.apy,
    });
    setSelectedCoinForStaking(coin);
    setSelectedStakingOption(stakingOption);
    setShowStakeModal(true);
  };

  const closeStakingModal = () => {
    setSelectedCoinForStaking(null);
    setSelectedStakingOption(null);
    setShowStakeModal(false);
    dispatch(resetStakingPoolDeposit());
    scrollToTop();
  };

  const getCoinDetailsByAddress = (address) => {
    let token;
    whitelistedCoins.map((item) => {
      if (item.contractAddress.toLowerCase() === address.toLowerCase()) {
        token = item;
      }
    });
    return token;
  };

  useEffect(() => {
    dispatch(getStakingPoolsRequest());
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    const formattedPools = [];
    for (let contractAddress in stakingPools) {
      formattedPools.push({
        pools: [...stakingPools[contractAddress]],
        ...getCoinDetailsByAddress(contractAddress),
      });
    }
    setFormattedStakingPools(formattedPools);
  }, [stakingPools]);

  useEffect(() => {
    if (
      searchParams.get("id") &&
      searchParams.get("contract-address") &&
      formattedStakingPools?.length > 0 &&
      !isEmpty(stakingPools)
    ) {
      let coin, poolOption;
      formattedStakingPools.forEach((whitelistedCoin) => {
        if (
          whitelistedCoin.contractAddress ===
          searchParams.get("contract-address")
        ) {
          coin = whitelistedCoin;
        }
      });

      stakingPools[searchParams.get("contract-address")].forEach(
        (availablePoolOption) => {
          if (availablePoolOption.id === searchParams.get("id")) {
            poolOption = availablePoolOption;
          }
        }
      );

      displayStakeModal(coin, poolOption);
    }
  }, [searchParams, formattedStakingPools]);

  if (whitelistedCoinsLoading || stakingPoolsLoading) {
    return (
      <div className="loading inline">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Collapse accordion expandIconPosition="end">
        {formattedStakingPools.map((coin, index) => (
          <Collapse.Panel key={index} header={<CoinPanelHead coin={coin} />}>
            {coin.pools.map((pool, stakingKey) => (
              <div className="stakeItem" key={stakingKey}>
                <div className="stakeDetails">
                  <span>{pool.apy}%</span>
                  For {pool.durationInDays} Days
                </div>
                <Button
                  label={"Details"}
                  type={ButtonTypes.PRIMARY}
                  extraClasses="stakeButton"
                  onClick={() => displayStakeModal(coin, pool)}
                />
              </div>
            ))}
          </Collapse.Panel>
        ))}
        {formattedStakingPools.length === 0 ? (
          <span className="staking-disclaimer">
            No staking pools available.
          </span>
        ) : (
          ""
        )}
      </Collapse>
      {selectedCoinForStaking ? (
        <StakingModal
          isVisible={showStakeModal}
          onCancel={() => closeStakingModal()}
          selectedCoin={selectedCoinForStaking}
          stakingOption={selectedStakingOption}
        />
      ) : null}
    </>
  );
};

export default CoinsList;
