export const getEncodedString = (string) => {
  let returnString = "";
  const length = string.length;
  for (let index in string) {
    if (
      Number.parseInt(index) <= length * 0.2 ||
      Number.parseInt(index) >= length * 0.8
    ) {
      returnString = returnString + string[index];
      continue;
    }
    returnString = returnString + "*";
  }
  return returnString;
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
