import React from "react";
import AssetsTab from "./AssetsTab";
import { Spin, Tabs } from "antd";
import CoinsList from "../Staking/CoinsList";
import StakingStatistics from "../StakingStatistics";

interface ITabProps {
  shouldAnimate?: boolean;
  whitelistedCoinsLoading?: boolean;
  defaultActiveKey?: string;
}

export const WalletTabs = (props: ITabProps) => {
  const { shouldAnimate } = props;

  return (
    <Tabs
      centered
      defaultActiveKey="1"
      destroyInactiveTabPane
      className={`walletTabs ${shouldAnimate ? "animateTabs" : ""}`}
      items={[
        {
          label: "",
          key: "1",
          children: <AssetsTab />,
        },
      ]}
    />
  );
};

export const StakingTabs = (props: ITabProps) => {
  const { shouldAnimate, whitelistedCoinsLoading } = props;

  const items = [
    {
      label: "Pools",
      key: "1",
      children: whitelistedCoinsLoading ? (
        <div className="loading inline">
          <Spin />
        </div>
      ) : (
        <CoinsList />
      ),
    },
    {
      label: "Statistics",
      key: "2",
      children: <StakingStatistics />,
    },
  ];

  return (
    <Tabs
      centered
      defaultActiveKey="1"
      destroyInactiveTabPane
      className={`walletTabs stakingTabs ${shouldAnimate ? "animateTabs" : ""}`}
      items={items}
    />
  );
};
