import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  getTokenDataRequest,
  getTokenDataSuccess,
  getTokenDataFailure,
} from "./tokenDetails";
import { axios } from "services/axios.service";
import { ITokenData } from "./interface";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getTokenDataSaga(action: ReturnType<typeof getTokenDataRequest>) {
  try {
    const response: AxiosResponse<ITokenData> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/public/whitelist/${action.payload.chainId}/${action.payload.tokenSymbol}`,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(
      getTokenDataSuccess({
        tokenData: response.data,
      })
    );
  } catch (e: any) {
    yield put(getTokenDataFailure());
  }
}

function* tokenDetailsSaga() {
  yield all([takeLatest(getTokenDataRequest, getTokenDataSaga)]);
}
export default tokenDetailsSaga;
