import {
  CancelStakingStateEnum,
  GetAvailableFundsFailurePayload,
  GetAvailableFundsRequestPayload,
  GetAvailableFundsSuccessPayload,
  GetCoinDataFailurePayload,
  GetCoinDataRequestPayload,
  GetCoinDataSuccessPayload,
  GetFundsHistoryRequest,
  GetFundsHistorySuccessPayload,
  GetFundsTotalSuccessPayload,
  GetTransactionDetailsRequestPayload,
  GetTransactionsLogRequestPayload,
  GetTransactionsLogSuccessPayload,
  getWalletDataSuccessPayload,
  GetWhitelistedCoinsFailurePayload,
  GetWhitelistedCoinsSuccessPayload,
  PutCancelStakingSuccessPayload,
  ResetTransactionsLogsPayload,
  UpdateHistoryPagerParams,
  UpdateTransactionPagerParams,
  WalletState,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: WalletState = {
  loading: false,
  error: null,
  whitelistedCoins: [],
  transactionTypes: [],
  history: {},
  historyLoading: false,
  fundsTotal: null,
  fundsTotalLoading: false,
  walletData: null,
  walletDataLoading: false,
  cancelStaking: null,
  cancelStakingLoading: false,
  cancelStakingState: null,
  transactionsPager: {
    page: 0,
    size: 25,
  },
  historyPager: {
    page: 0,
    size: 25,
  },
  openSearchTransactions: false,
  purchaseOrder: null,
  purchaseOrderLoading: false,
  estimateRates: null,
  estimateRatesLoading: false,
  estimateRatesError: false,
  transactionDetails: null,
  transactionDetailsLoading: false,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    resetState(state) {
      return {
        ...initialState,
      };
    },
    resetPurChaseOrderState(state) {
      return {
        ...state,
        purchaseOrder: null,
        purchaseOrderLoading: false,
      };
    },
    resetEstimateRatesState(state) {
      return {
        ...state,
        estimateRates: null,
        estimateRatesLoading: false,
        estimateRatesError: false,
      };
    },
    resetCancelStakingState(state) {
      return {
        ...state,
        cancelStaking: null,
        cancelStakingLoading: false,
        cancelStakingState: null,
      };
    },
    updateCancelStakingState(state, action) {
      return {
        ...state,
        cancelStakingState: action.payload,
      };
    },
    purchaseOrderRequest(state, action) {
      return {
        ...state,
        purchaseOrder: null,
        purchaseOrderLoading: true,
        params: action.payload,
      };
    },
    purchaseOrderSuccess(state, action) {
      return {
        ...state,
        purchaseOrder: action.payload,
        purchaseOrderLoading: false,
      };
    },
    purchaseOrderFailure(state) {
      return {
        ...state,
        purchaseOrder: null,
        purchaseOrderLoading: false,
      };
    },
    estimateRatesRequest(state, action) {
      return {
        ...state,
        estimateRates: null,
        estimateRatesLoading: true,
        estimateRatesError: false,
        params: action.payload,
      };
    },
    estimateRatesSuccess(state, action) {
      return {
        ...state,
        estimateRates: action.payload,
        estimateRatesLoading: false,
        estimateRatesError: false,
      };
    },
    estimateRatesFailure(state) {
      return {
        ...state,
        estimateRates: null,
        estimateRatesLoading: false,
        estimateRatesError: true,
      };
    },
    getWhitelistedCoinsRequest(state) {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    getWhitelistedCoinsSuccess(
      state,
      action: PayloadAction<GetWhitelistedCoinsSuccessPayload>
    ) {
      return {
        ...state,
        loading: false,
        whitelistedCoins: action.payload.coins,
        error: false,
      };
    },
    getWhitelistedCoinsFailure(
      state,
      action: PayloadAction<GetWhitelistedCoinsFailurePayload>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    },
    getCoinDataRequest(
      state,
      action: PayloadAction<GetCoinDataRequestPayload>
    ) {
      return {
        ...state,
        error: false,
        [`${action.payload.contractAddress}Data`]: {},
        [`${action.payload.contractAddress}Loading`]: true,
        params: action.payload,
      };
    },
    getCoinDataSuccess(
      state,
      action: PayloadAction<GetCoinDataSuccessPayload>
    ) {
      return {
        ...state,
        [`${action.payload.contractAddress}Data`]: action.payload.coin,
        [`${action.payload.contractAddress}Loading`]: false,
        error: false,
      };
    },
    getCoinDataFailure(
      state,
      action: PayloadAction<GetCoinDataFailurePayload>
    ) {
      return {
        ...state,
        [`${action.payload.contractAddress}Loading`]: false,
        [`${action.payload.contractAddress}Data`]: {},
        error: action.payload.error,
      };
    },
    getTransactionTypesRequest(state) {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    getTransactionTypesSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        loading: false,
        transactionTypes: action.payload,
        error: false,
      };
    },
    getTransactionTypesFailure(state, action: PayloadAction<any>) {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    },
    getTransactionsLogRequest(
      state,
      action: PayloadAction<GetTransactionsLogRequestPayload>
    ) {
      return {
        ...state,
        error: false,
        [`${action.payload.contractAddress}TransactionsLoading`]: true,
        params: action.payload,
      };
    },
    getTransactionsLogSuccess(
      state,
      action: PayloadAction<GetTransactionsLogSuccessPayload>
    ) {
      const transactions = [`${action.payload.contractAddress}Transactions`];
      return {
        ...state,
        [`${action.payload.contractAddress}Transactions`]: {
          last: action.payload.transactions.last,
          content:
            action.payload.page !== 0
              ? // @ts-ignore
                state[transactions].content.concat(
                  action.payload.transactions.content
                )
              : action.payload.transactions.content,
        },
        [`${action.payload.contractAddress}TransactionsLoading`]: false,
        error: false,
      };
    },
    resetTransactionLogs(
      state,
      action: PayloadAction<ResetTransactionsLogsPayload>
    ) {
      return {
        ...state,
        [`${action.payload.contractAddress}TransactionsLoading`]: undefined,
        [`${action.payload.contractAddress}Transactions`]: undefined,
      };
    },
    getTransactionsLogFailure(
      state,
      action: PayloadAction<GetCoinDataFailurePayload>
    ) {
      return {
        ...state,
        [`${action.payload.contractAddress}Transactions`]: {},
        error: action.payload.error,
      };
    },
    getAvailableFundsRequest(
      state,
      action: PayloadAction<GetAvailableFundsRequestPayload>
    ) {
      let newStateObj = {
        ...state,
        params: action.payload,
      };
      if (!action.payload.isFromWs) {
        newStateObj[`${action.payload.contractAddress}Funds`] = {};
        newStateObj[`${action.payload.contractAddress}FundsLoading`] = true;
      }
      return newStateObj;
    },
    getAvailableFundsSuccess(
      state,
      action: PayloadAction<GetAvailableFundsSuccessPayload>
    ) {
      return {
        ...state,
        [`${action.payload.contractAddress}Funds`]: action.payload.coin,
        [`${action.payload.contractAddress}FundsLoading`]: false,
      };
    },
    wsFundsUpdate(
      state,
      action: PayloadAction<GetAvailableFundsSuccessPayload>
    ) {
      return {
        ...state,
        [`${action.payload.contractAddress}Funds`]: {
          ...state[`${action.payload.contractAddress}Funds`],
          ...action.payload.coin,
          tokenData: {
            ...state[`${action.payload.contractAddress}Funds`].tokenData,
            ...action.payload.coin.tokenData,
          },
        },
      };
    },
    getAvailableFundsFailure(
      state,
      action: PayloadAction<GetAvailableFundsFailurePayload>
    ) {
      return {
        ...state,
        [`${action.payload.contractAddress}Funds`]: {},
        [`${action.payload.contractAddress}FundsLoading`]: false,
      };
    },
    getFundsHistoryRequest(
      state,
      action: PayloadAction<GetFundsHistoryRequest>
    ) {
      return {
        ...state,
        historyLoading: true,
        params: action.payload,
      };
    },
    getFundsHistorySuccess(
      state,
      action: PayloadAction<GetFundsHistorySuccessPayload>
    ) {
      return {
        ...state,
        history: {
          ...action.payload.history,
          content:
            action.payload.page !== 0
              ? state.history.content.concat(action.payload.history.content)
              : action.payload.history.content,
        },
        historyLoading: false,
      };
    },
    getFundsHistoryFailure(state) {
      return {
        ...state,
        history: {},
        historyLoading: false,
      };
    },
    getFundsTotalRequest(state) {
      return {
        ...state,
        fundsTotalLoading: true,
        fundsTotal: null,
      };
    },
    getFundsTotalSuccess(
      state,
      action: PayloadAction<GetFundsTotalSuccessPayload>
    ) {
      return {
        ...state,
        fundsTotal: action.payload.fundsTotal,
        fundsTotalLoading: false,
      };
    },
    getFundsTotalFailure(state) {
      return {
        ...state,
        fundsTotal: null,
        fundsTotalLoading: false,
      };
    },
    getWalletDataRequest(state) {
      return {
        ...state,
        walletDataLoading: true,
        walletData: null,
      };
    },
    getWalletDataSuccess(
      state,
      action: PayloadAction<getWalletDataSuccessPayload>
    ) {
      return {
        ...state,
        walletData: action.payload.walletData,
        walletDataLoading: false,
      };
    },
    getWalletDataFailure(state) {
      return {
        ...state,
        walletData: null,
        walletDataLoading: false,
      };
    },
    putCancelStakingRequest(state, action: any) {
      return {
        ...state,
        cancelStakingLoading: true,
        cancelStaking: null,
        cancelStakingState: CancelStakingStateEnum.LOADING,
        params: action.payload,
      };
    },
    putCancelStakingSuccess(
      state,
      action: PayloadAction<PutCancelStakingSuccessPayload>
    ) {
      return {
        ...state,
        cancelStaking: action.payload.cancelStaking,
        cancelStakingLoading: false,
      };
    },
    putCancelStakingFailure(state) {
      return {
        ...state,
        cancelStaking: null,
        cancelStakingLoading: false,
        cancelStakingState: CancelStakingStateEnum.ERROR,
      };
    },
    resetHistoryState(state) {
      return {
        ...state,
        history: {},
        historyLoading: false,
      };
    },
    updateTransactionsPager(
      state,
      action: PayloadAction<UpdateTransactionPagerParams>
    ) {
      return {
        ...state,
        transactionsPager: { ...action.payload },
      };
    },
    updateHistoryPager(state, action: PayloadAction<UpdateHistoryPagerParams>) {
      return {
        ...state,
        historyPager: { ...action.payload },
      };
    },
    setOpenSearchTransactions: (state, action) => {
      state.openSearchTransactions = action.payload;
    },
    getTransactionDetailsRequest(
      state,
      action: PayloadAction<GetTransactionDetailsRequestPayload>
    ) {
      return {
        ...state,
        transactionDetailsLoading: true,
        transactionDetails: null,
        params: action.payload,
      };
    },
    getTransactionDetailsSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        transactionDetailsLoading: false,
        transactionDetails: action.payload.details,
      };
    },
    getTransactionDetailsFailure(state) {
      return {
        ...state,
        transactionDetailsLoading: false,
        transactionDetails: null,
      };
    },
  },
});

export const {
  resetState,
  getWhitelistedCoinsRequest,
  getWhitelistedCoinsFailure,
  getWhitelistedCoinsSuccess,
  getCoinDataRequest,
  getCoinDataSuccess,
  getCoinDataFailure,
  getAvailableFundsRequest,
  getAvailableFundsSuccess,
  getAvailableFundsFailure,
  getFundsHistoryRequest,
  getFundsHistorySuccess,
  getFundsHistoryFailure,
  resetHistoryState,
  getTransactionTypesRequest,
  getTransactionTypesSuccess,
  getTransactionTypesFailure,
  getTransactionsLogRequest,
  getTransactionsLogSuccess,
  getTransactionsLogFailure,
  resetTransactionLogs,
  getFundsTotalRequest,
  getFundsTotalSuccess,
  getFundsTotalFailure,
  getWalletDataRequest,
  getWalletDataSuccess,
  getWalletDataFailure,
  putCancelStakingRequest,
  putCancelStakingSuccess,
  putCancelStakingFailure,
  updateTransactionsPager,
  updateHistoryPager,
  wsFundsUpdate,
  updateCancelStakingState,
  resetCancelStakingState,
  setOpenSearchTransactions,
  resetPurChaseOrderState,
  purchaseOrderRequest,
  purchaseOrderSuccess,
  purchaseOrderFailure,
  estimateRatesRequest,
  estimateRatesSuccess,
  estimateRatesFailure,
  resetEstimateRatesState,
  getTransactionDetailsFailure,
  getTransactionDetailsRequest,
  getTransactionDetailsSuccess,
} = walletSlice.actions;

export default walletSlice.reducer;
