import { combineReducers } from "redux";
import loginSlice from "../components/LoginForm/loginSlice";
import registerSlice from "../components/RegisterForm/registerSlice";
import layoutSlice from "../components/Layout/layoutSlice";
import codeVerificationSlice from "../components/CodeVerification/codeVerificationSlice";
import forgotPasswordSlice from "../components/ForgotPasswordForm/forgotPasswordSlice";
import resetPasswordSlice from "../pages/resetPassword/resetPasswordSlice";
import secretRecoverySlice from "../components/SecretRecoveryModal/secretRecoverySlice";
import userSlice from "../components/User/userSlice";
import newsSlice from "../components/News/newsSlice";
import depositSlice from "../components/WalletControls/DepositModal/depositSlice";
import withdrawSlice from "../components/WalletControls/WithdrawModal/withdrawSlice";
import walletSlice from "../components/Wallet/walletSlice";
import phoneValidationSlice from "../components/PhoneValidationModal/phoneValidationSlice";
import stakingSlice from "../components/Staking/stakingSlice";
import vestingSlice from "../components/Vesting/vestingSlice";
import maintenanceSlice from "../components/Maintenance/maintenanceSlice";
import eventsSlice from "../components/Events/eventsSlice";
import announcementSlice from "../components/AnnouncementsModal/announcementSlice";
import notificationSlice from "../components/Notifications/notificationSlice";
import stakingStatisticsSlice from "../components/StakingStatistics/stakingStatisticsSlice";
import reportSlice from "../components/ReportDownloadModal/reportSlice";
import swapSlice from "../components/Swap/swapSlice";
import referralSlice from "../components/Referral/referralSlice";
import watchlistCampaignsSlice from "../components/WatchlistCampaigns/watchlistSlice";
import userPortfolioSlice from "../components/Portfolio/slice";
import launchpadSlice from "../pages/launchpad/launchpadSlice";
import tokenDetailsSlice from "../components/TokenDetails/tokenDetails";
import campaignDetailsSlice from "../pages/campaignDetails/campaignDetails";
import investSlice from "../pages/campaignInvest/investSlice";

const rootReducer = combineReducers({
  login: loginSlice,
  register: registerSlice,
  layout: layoutSlice,
  codeVerification: codeVerificationSlice,
  forgotPassword: forgotPasswordSlice,
  resetPassword: resetPasswordSlice,
  secretRecovery: secretRecoverySlice,
  user: userSlice,
  news: newsSlice,
  deposit: depositSlice,
  withdraw: withdrawSlice,
  wallet: walletSlice,
  phoneValidation: phoneValidationSlice,
  staking: stakingSlice,
  vesting: vestingSlice,
  maintenance: maintenanceSlice,
  events: eventsSlice,
  announcement: announcementSlice,
  notification: notificationSlice,
  stakingStatistics: stakingStatisticsSlice,
  report: reportSlice,
  swap: swapSlice,
  referral: referralSlice,
  launchpad: launchpadSlice,
  tokenDetails: tokenDetailsSlice,
  campaignDetails: campaignDetailsSlice,
  watchlistCampaigns: watchlistCampaignsSlice,
  userPortfolio: userPortfolioSlice,
  invest: investSlice,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
