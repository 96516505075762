import React, { useEffect, useState } from "react";
import "./style.less";
import { Form, Modal, notification } from "antd";
import { IoClose } from "react-icons/io5";
import { IOtpModal } from "./interface";
import { Button, FormInput } from "../index";
import {
  API_VALIDATION_ERROR_MESSAGES,
  VALIDATION_ERROR_MESSAGES,
} from "../../_helpers/config";
import { FiRotateCw } from "react-icons/fi";
import {
  getCanSendCodeRequest,
  getCanSendEmailCodeRequest,
  resetCanSendCodeState,
} from "../CodeVerification/codeVerificationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getCanSendEmailCode,
  getCanSendSmsCode,
} from "../CodeVerification/selectors";
import { getCurrentUserSelector } from "../User/selectors";
import { getEncodedString } from "../../_helpers/stringEncoder";
import {
  postWithdrawRequest,
  resendOTPCodeRequest,
  resetWithdraw,
} from "../WalletControls/WithdrawModal/withdrawSlice";
import {
  getWithdrawErrorSelector,
  getWithdrawLoadingSelector,
  getWithdrawSuccessSelector,
} from "../WalletControls/WithdrawModal/selectors";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { pushGtmEvent } from "../../_helpers/gtm";
import { GtmEvents } from "../../_helpers/types";
import { ButtonTypes } from "../Button/types";

const RetryButton = ({ type }) => {
  const dispatch = useDispatch();

  let timeRemaining = 30;
  const [retryButtonLabel, setRetryButtonLabel] = useState("Retry.");
  const [retryButtonDisabled, setRetryButtonDisabled] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const canSendCode = useSelector(
    type === "EMAIL" ? getCanSendEmailCode : getCanSendSmsCode
  );
  useEffect(() => {
    if (canSendCode) {
      notification.success({
        icon: <FiRotateCw />,
        message: "We have sent you the code again. Please check your device.",
      });
      const generateOtp = async () => {
        const token = await executeRecaptcha("withdrawOtp");
        dispatch(resendOTPCodeRequest({ type: type, captchaToken: token }));
      };
      generateOtp().then(() => {});
    }
    if (canSendCode === false) {
      notification.error({
        icon: <FiRotateCw />,
        message: VALIDATION_ERROR_MESSAGES.tooManyOtpAttempts,
      });
    }
  }, [canSendCode]);

  const resendOtp = () => {
    if (retryButtonDisabled === false) {
      setRetryButtonDisabled(true);
      timeRemaining = 30;
      const refreshInterval = setInterval(() => {
        if (timeRemaining > 1) {
          setRetryButtonLabel(`${timeRemaining - 1} s`);
          timeRemaining = timeRemaining - 1;
        } else {
          setRetryButtonLabel(`Retry`);
          setRetryButtonDisabled(false);
          clearInterval(refreshInterval);
        }
      }, 1000);
      pushGtmEvent(GtmEvents.WITHDRAW_STEP_2_RETRY, { type });
      if (type === "EMAIL") {
        dispatch(
          getCanSendEmailCodeRequest({ type, userIsNotAuthenticated: false })
        );
      } else {
        dispatch(
          getCanSendCodeRequest({ type, userIsNotAuthenticated: false })
        );
      }
    }
  };

  return (
    <span className="maxValue" onClick={() => resendOtp()}>
      {retryButtonLabel}
    </span>
  );
};

const OtpModalMultiple = (props: IOtpModal) => {
  const [formRef] = Form.useForm();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUserSelector);
  const [encodedPhone, setEncodedPhone] = useState(null);
  const [encodedEmail, setEncodedEmail] = useState(null);
  const withdrawLoading = useSelector(getWithdrawLoadingSelector);
  const withdrawSuccess = useSelector(getWithdrawSuccessSelector);
  const withdrawError = useSelector(getWithdrawErrorSelector);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (currentUser) {
      setEncodedPhone(getEncodedString(currentUser.details.phoneNumber));
      setEncodedEmail(getEncodedString(currentUser.email));
    }
  }, [currentUser]);

  const submitForm = () => {
    formRef.validateFields().then(async (formValues) => {
      const token = await executeRecaptcha("otpMultiple");
      dispatch(
        postWithdrawRequest({
          ...formValues,
          ...props.additionalPayload,
          captchaToken: token,
        })
      );
    });
  };
  useEffect(() => {
    if (withdrawError) {
      if (withdrawError === "SMS_OTP_VERIFICATION_FAILED") {
        pushGtmEvent(GtmEvents.WITHDRAW_STEP_2_INVALID_SMS_CODE);
      }
      if (withdrawError === "EMAIL_OTP_VERIFICATION_FAILED") {
        pushGtmEvent(GtmEvents.WITHDRAW_STEP_2_INVALID_EMAIL_CODE);
      }
    }
  }, [withdrawError]);
  useEffect(() => {
    pushGtmEvent(GtmEvents.WITHDRAW_STEP_2);
    return () => {
      dispatch(resetCanSendCodeState());
      dispatch(resetWithdraw());
    };
  }, []);

  useEffect(() => {
    if (withdrawSuccess) {
      pushGtmEvent(GtmEvents.WITHDRAW_SUCCESS);
      props.onSuccess();
    }
  }, [withdrawSuccess]);

  return (
    <>
      <Modal
        footer={null}
        maskClosable={false}
        closeIcon={<IoClose />}
        open={props.isVisible}
        onCancel={() => props.onCancel()}
        className="authModals walletModals"
        forceRender
      >
        <div className="heading">
          <h2>One more check</h2>
          <p>To make sure its really you.</p>
        </div>

        <Form form={formRef}>
          <Form.Item
            hasFeedback
            name="emailOtpCode"
            rules={[
              { required: true, message: VALIDATION_ERROR_MESSAGES.required },
              ({}) => ({
                validator(_, value) {
                  if (value && value.length > 6) {
                    return Promise.reject("Invalid code length.");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <FormInput
              label={
                currentUser?.state?.properties?.isGoogleTfaSetup
                  ? "MFA Code"
                  : "Email Code"
              }
              type="number"
              suffix={
                currentUser?.state?.properties?.isGoogleTfaSetup ? (
                  <></>
                ) : (
                  <RetryButton type={"EMAIL"} />
                )
              }
            />
          </Form.Item>

          {currentUser?.state?.properties?.isGoogleTfaSetup ? (
            <p className="disclaimer">Please enter the code from the MFA app</p>
          ) : (
            <p className="disclaimer">
              We've sent you the code at {encodedEmail}
            </p>
          )}

          <Form.Item
            hasFeedback
            name="smsOtpCode"
            rules={[
              { required: true, message: VALIDATION_ERROR_MESSAGES.required },
              ({}) => ({
                validator(_, value) {
                  if (value && value.length > 6) {
                    return Promise.reject("Invalid code length.");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <FormInput
              label="Phone Code"
              type="number"
              suffix={<RetryButton type={"SMS"} />}
            />
          </Form.Item>

          <p className="disclaimer">
            We've sent you the code at {encodedPhone}
          </p>

          <Button
            type={ButtonTypes.PRIMARY}
            label="Continue"
            extraClasses="submitButton"
            onClick={() => submitForm()}
            loading={withdrawLoading}
          />

          {withdrawError ? (
            <span className="generalError">
              {withdrawError === "EMAIL_OTP_VERIFICATION_FAILED" &&
              currentUser?.state?.properties?.isGoogleTfaSetup
                ? "Invalid MFA code."
                : API_VALIDATION_ERROR_MESSAGES[withdrawError]}
            </span>
          ) : null}
        </Form>
      </Modal>
    </>
  );
};

export default OtpModalMultiple;
