import { DepositState } from "./types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: DepositState = {
  loading: false,
  error: null,
  showDepositModal: false,
};

const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers: {
    resetState(state) {
      return {
        ...initialState,
      };
    },
    setShowDepositModal(state, action) {
      return {
        ...state,
        showDepositModal: action.payload,
      };
    },
  },
});

export const { resetState, setShowDepositModal } = depositSlice.actions;

export default depositSlice.reducer;
