import { IRegisterParams, RegisterState } from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: RegisterState = {
  loading: false,
  error: null,
  registerSuccess: false,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    postRegisterRequest(state, action: PayloadAction<IRegisterParams>) {
      return {
        ...state,
        loading: true,
        params: action.payload,
        error: null,
        registerSuccess: false,
      };
    },
    postRegisterSuccess(state, action) {
      return {
        ...state,
        loading: false,
        register: action.payload.register,
        error: null,
        registerSuccess: true,
      };
    },
    postRegisterFailure(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data,
        registerSuccess: false,
      };
    },
    resetState(state) {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  postRegisterRequest,
  postRegisterSuccess,
  postRegisterFailure,
  resetState,
} = registerSlice.actions;

export default registerSlice.reducer;
