import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  postForgotPasswordFailure,
  postForgotPasswordRequest,
  postForgotPasswordSuccess,
} from "./forgotPasswordSlice";

import { axios } from "../../services/axios.service";

/*
  Worker Saga: Fired on postForgotPasswordRequest action
*/
function* postForgotPasswordSaga(
  action: ReturnType<typeof postForgotPasswordRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/forgot-password`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(postForgotPasswordSuccess());
  } catch (e: any) {
    yield put(
      postForgotPasswordFailure({
        error: e,
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `postForgotPasswordRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* loginSaga() {
  yield all([takeLatest(postForgotPasswordRequest, postForgotPasswordSaga)]);
}

export default loginSaga;
