import React, { useEffect, useState } from "react";
import "./style.less";
import { Modal, notification, Spin } from "antd";
import { IoClose } from "react-icons/io5";
import { IDepositModal } from "./interface";
import { Button, FormInput } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletDataLoadingSelector,
  getWalletDataSelector,
} from "../../Wallet/selectors";
import { getWalletDataRequest } from "../../Wallet/walletSlice";
import copy from "copy-to-clipboard";
import { getConfigSelector } from "../../Layout/selectors";
import { pushGtmEvent } from "../../../_helpers/gtm";
import { GtmEvents } from "../../../_helpers/types";
import Qr from "./qr";
import { CURRENCIES } from "../../../_helpers/config";
import { getCurrentUserSelector } from "../../User/selectors";
import { displayWalletAddress } from "../../../_helpers/walletAddressTruncate";
import { ButtonTypes } from "../../Button/types";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FiCopy } from "react-icons/fi";

const DepositModal = (props: IDepositModal) => {
  const dispatch = useDispatch();

  const config = useSelector(getConfigSelector);
  const walletData = useSelector(getWalletDataSelector);
  const walletDataLoading = useSelector(getWalletDataLoadingSelector);
  const user = useSelector(getCurrentUserSelector);

  const [qrUrl, setQrUrl] = useState(null);

  const copyToClipboard = () => {
    //TODO FIX bug on copy to clipboard when there is only one call
    copy(walletData.blockchainAddress);
    copy(walletData.blockchainAddress);
    pushGtmEvent(GtmEvents.COPY_WALLET_TO_CLIPBOARD);
    notification.success({
      message: "Wallet address successfully copied to clipboard.",
    });
  };

  useEffect(() => {
    if (!walletData && props.isVisible) {
      dispatch(getWalletDataRequest());
    }
    if (walletData && !qrUrl) {
      setQrUrl(
        `https://metamask.app.link/send/${walletData.blockchainAddress}@56`
      );
    }
  }, [props]);

  useEffect(() => {
    if (walletData && walletData.blockchainAddress) {
      setQrUrl(
        `https://metamask.app.link/send/${walletData.blockchainAddress}@56`
      );
    }
  }, [walletData]);

  const cancelModal = () => {
    setQrUrl(null);
    props.onCancel();
  };

  return (
    <>
      <Modal
        closeIcon={<IoClose />}
        open={props.isVisible}
        onCancel={() => cancelModal()}
        className="authModals walletModals withdrawModal"
        maskClosable={false}
        destroyOnClose
        forceRender
        footer={null}
      >
        <Scrollbars
          universal
          style={{ height: "75vh" }}
          renderView={(props) => <div {...props} className="scroll-view" />}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
        >
          <div className="heading">
            <h2 className="depositModalTitle">Deposit</h2>

            <p>
              Scan the QR if you use Metamask or copy the address in order to
              make your deposit.
            </p>

            {qrUrl ? <Qr url={qrUrl} imageUrl={"/metamask.png"} /> : ""}

            <div className="walletAddress">
              {walletDataLoading ? (
                <div className="loading inline">
                  <Spin />
                </div>
              ) : (
                <div onClick={() => copyToClipboard()} className="walletInput">
                  {walletData ? (
                    <FormInput
                      disabled
                      value={displayWalletAddress(
                        walletData.blockchainAddress,
                        10
                      )}
                      suffix={<FiCopy />}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}

              {config && config.depositConfiguration ? (
                <p className="disclaimer">
                  Minimum deposit amount is{" "}
                  {user
                    ? CURRENCIES[user.state.properties.selectedCurrency]
                    : ""}
                  {config.depositConfiguration.minFiatAmount} worth of your
                  token. In case the minimum amount is not met, your deposit
                  will not be processed.
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <Button
            label={props.cta ? props.cta : "I understand"}
            extraClasses="submitBtn"
            onClick={() => cancelModal()}
            type={ButtonTypes.PRIMARY}
          />
        </Scrollbars>
      </Modal>
    </>
  );
};

export default DepositModal;
