import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserPortfolioParams } from "./types";

const initialState: any = {
  loading: false,
  error: null,
  success: false,
  portfolio: [],

  investmentDetails: null,
  investmentDetailsError: null,
  investmentDetailsSuccess: false,
  investmentDetailsLoading: false,
};

const userPortfolioSlice = createSlice({
  name: "userPortfolio",
  initialState,
  reducers: {
    resetState() {
      return {
        ...initialState,
      };
    },
    getUserPortfolioRequest(
      state,
      action: PayloadAction<IUserPortfolioParams>
    ) {
      return {
        ...state,
        params: {
          ...action.payload,
        },
        portfolio: null,
        loading: true,
        success: false,
      };
    },
    getUserPortfolioSuccess(state, action) {
      return {
        ...state,
        portfolio: action.payload,
        loading: false,
        success: true,
      };
    },
    getUserPortfolioFailure(state, action) {
      return {
        ...state,
        portfolio: null,
        loading: false,
        success: false,
        error: action.payload,
      };
    },
    getUserInvestmentDetailsRequest(state, action) {
      return {
        ...state,
        params: {
          ...action.payload,
        },
        investmentDetails: null,
        investmentDetailsError: null,
        investmentDetailsSuccess: false,
        investmentDetailsLoading: true,
      };
    },
    getUserInvestmentDetailsSuccess(state, action) {
      return {
        ...state,
        investmentDetails: action.payload,
        investmentDetailsSuccess: true,
        investmentDetailsLoading: false,
      };
    },
    getUserInvestmentDetailsFailure(state) {
      return {
        ...state,
        investmentDetails: null,
        investmentDetailsSuccess: false,
        investmentDetailsLoading: false,
      };
    },
  },
});

export const {
  getUserPortfolioRequest,
  getUserPortfolioSuccess,
  getUserPortfolioFailure,
  getUserInvestmentDetailsRequest,
  getUserInvestmentDetailsSuccess,
  getUserInvestmentDetailsFailure,
  resetState,
} = userPortfolioSlice.actions;

export default userPortfolioSlice.reducer;
