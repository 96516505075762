import React, { useEffect } from "react";
import { IWordsFormProps } from "./interface";
import { Form, Input } from "antd";
import { API_VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";
import { Button, FormInput } from "../index";
import { useDispatch, useSelector } from "react-redux";
import {
  getGeneratedRecoveryPhrase,
  getSecretRecoveryGenerationLoading,
  getValidateRecoveryPhraseError,
} from "./selectors";
import {
  postGenerateSecretRecoveryRequest,
  resetState,
  validateSecretRecoveryRequest,
} from "./secretRecoverySlice";
import { pushGtmEvent } from "../../_helpers/gtm";
import { GtmEvents } from "../../_helpers/types";
import { ButtonTypes } from "../Button/types";

const WordsForm: React.FC<IWordsFormProps> = (props: IWordsFormProps) => {
  const dispatch = useDispatch();
  const [formRef] = Form.useForm();

  const error = useSelector(getValidateRecoveryPhraseError);
  const recoveryPhraseArray = useSelector(getGeneratedRecoveryPhrase);
  const recoveryPhraseLoading = useSelector(getSecretRecoveryGenerationLoading);

  const formatRecoveryPhraseToFormData = (data) => {
    const returnObject = {};

    for (let key in data) {
      returnObject[`secret_recovery_${Number.parseInt(key) - 1}`] =
        data[Number.parseInt(key)];
    }

    return returnObject;
  };

  const formatFormDataToRecoveryPhrase = (data) => {
    const returnObject = {};

    Array(12)
      .fill(0)
      .map((item, key) => {
        returnObject[key + 1] = data[`secret_recovery_${key}`];
      });

    return returnObject;
  };

  const submitForm = () => {
    formRef.validateFields().then((formValues) => {
      if (props.action === "validate") {
        dispatch(
          validateSecretRecoveryRequest(
            formatFormDataToRecoveryPhrase(formValues)
          )
        );
      } else {
        formRef.resetFields();
        props.callback("validate");
      }
    });
  };
  useEffect(() => {
    if (error) {
      pushGtmEvent(GtmEvents.CREATE_WALLET_ERROR);
    }
  }, [error]);
  useEffect(() => {
    if (props.action === "create") {
      pushGtmEvent(GtmEvents.CREATE_WALLET_STEP_1);
      dispatch(postGenerateSecretRecoveryRequest());
    }
    if (props.action === "validate") {
      pushGtmEvent(GtmEvents.CREATE_WALLET_STEP_2);
    }
    if (props.action === "readOnly") {
      formRef.setFieldsValue(formatRecoveryPhraseToFormData(props.formData));
    }
    return () => {
      formRef.resetFields();
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (recoveryPhraseArray) {
      formRef.setFieldsValue(
        formatRecoveryPhraseToFormData(recoveryPhraseArray)
      );
    }
  }, [recoveryPhraseArray]);

  return (
    <Form
      form={formRef}
      name="complex-form"
      className="wordsForm"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
    >
      <div className="heading">
        <h2>Secret recovery phrase</h2>
        {props.action === "readOnly" ? (
          ""
        ) : (
          <p>
            Write it down on a paper and keep it in a safe place. You’ll be
            asked to re-enter this phrase (in order) on the next step.
          </p>
        )}
      </div>

      {Array(6)
        .fill(0)
        .map((item, key) => (
          <Input.Group key={key}>
            <Form.Item name={`secret_recovery_${key}`}>
              <FormInput
                disablepaste={"true"}
                hidelabel={"true"}
                disabled={props.action !== "validate"}
                prefix={<span>{key + 1}.</span>}
              />
            </Form.Item>

            <Form.Item name={`secret_recovery_${key + 6}`}>
              <FormInput
                disablepaste={"true"}
                hidelabel={"true"}
                disabled={props.action !== "validate"}
                prefix={<span>{key + 7}.</span>}
              />
            </Form.Item>
          </Input.Group>
        ))}
      {props.action === "readOnly" ? (
        <Button
          label="Close"
          extraClasses="submitButton"
          loading={recoveryPhraseLoading}
          onClick={() => props.closeWordsForm()}
        />
      ) : (
        <Button
          type={ButtonTypes.PRIMARY}
          label="Continue"
          extraClasses="submitButton"
          loading={recoveryPhraseLoading}
          onClick={() => submitForm()}
        />
      )}

      {error ? (
        <span className="generalError">
          {API_VALIDATION_ERROR_MESSAGES[error.message]}
        </span>
      ) : (
        ""
      )}
    </Form>
  );
};
export default WordsForm;
