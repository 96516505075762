import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getNewsFailure,
  getNewsRequest,
  getNewsSuccess,
  getNewsCategoriesRequest,
  getNewsCategoriesSuccess,
  getNewsCategoriesFailure,
  getArticleRequest,
  getArticleSuccess,
  getArticleFailure,
  getTrendingNewsRequest,
  getTrendingNewsSuccess,
  getTrendingNewsFailure,
  getRelatedNewsRequest,
  getRelatedNewsSuccess,
  getRelatedNewsFailure,
  articleInteractionRequest,
  articleInteractionSuccess,
  articleInteractionFailure,
  getFavoriteNewsRequest,
  getFavoriteNewsSuccess,
  getFavoriteNewsFailure,
} from "./newsSlice";

import { axios } from "../../services/axios.service";
import { IArticleResponse, INewsResponse } from "./types";
import { AxiosResponse } from "axios";

/*
  Worker Saga: Fired on getNewsRequest action
*/
function* getNewsSaga(action: ReturnType<typeof getNewsRequest>) {
  try {
    const respopnse: AxiosResponse<INewsResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_NEWS_API_URL}/public/news`,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getNewsSuccess(respopnse.data));
  } catch (e: any) {
    yield put(
      getNewsFailure({
        error: e,
      })
    );
  }
}

function* getNewsCategoriesSaga(
  action: ReturnType<typeof getNewsCategoriesRequest>
) {
  try {
    const response: AxiosResponse<INewsResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_NEWS_API_URL}/public/news/categories`,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getNewsCategoriesSuccess(response.data));
  } catch (e: any) {
    yield put(getNewsCategoriesFailure());
  }
}

function* getArticleSaga(action: ReturnType<typeof getArticleRequest>) {
  try {
    const response: AxiosResponse<IArticleResponse> = yield call(
      axios.put,
      `${process.env.REACT_APP_NEWS_API_URL}/public/news/${action.payload.slag}`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(
      getArticleSuccess({
        article: response.data,
      })
    );
  } catch (e: any) {
    yield put(getArticleFailure());
  }
}

function* getTrendingNewsSaga(
  action: ReturnType<typeof getTrendingNewsRequest>
) {
  try {
    const response: AxiosResponse<IArticleResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_NEWS_API_URL}/public/news/trending`,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getTrendingNewsSuccess(response.data));
  } catch (e: any) {
    yield put(getTrendingNewsFailure());
  }
}

function* getRelatedNewsSaga(action: ReturnType<typeof getRelatedNewsRequest>) {
  try {
    const response: AxiosResponse<IArticleResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_NEWS_API_URL}/public/news/related`,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getRelatedNewsSuccess(response.data));
  } catch (e: any) {
    yield put(getRelatedNewsFailure());
  }
}

function* getFavoriteNewsSaga(
  action: ReturnType<typeof getFavoriteNewsRequest>
) {
  try {
    const response: AxiosResponse<IArticleResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_NEWS_API_URL}/news/favourite`,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(getFavoriteNewsSuccess(response.data));
  } catch (e: any) {
    yield put(getFavoriteNewsFailure());
  }
}

function* articleInteractionSaga(
  action: ReturnType<typeof articleInteractionRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_NEWS_API_URL}/news/${action.payload.articleId}/interaction`,
      action.payload,
      {
        params: action.payload,
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(articleInteractionSuccess());
  } catch (e: any) {
    yield put(articleInteractionFailure());
  }
}

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* loginSaga() {
  yield all([
    takeLatest(getNewsRequest, getNewsSaga),
    takeLatest(getNewsCategoriesRequest, getNewsCategoriesSaga),
    takeLatest(getArticleRequest, getArticleSaga),
    takeLatest(getTrendingNewsRequest, getTrendingNewsSaga),
    takeLatest(getRelatedNewsRequest, getRelatedNewsSaga),
    takeLatest(articleInteractionRequest, articleInteractionSaga),
    takeLatest(getFavoriteNewsRequest, getFavoriteNewsSaga),
  ]);
}

export default loginSaga;
