import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const postLoading = (state: AppState) => state.resetPassword.loading;

const postError = (state: AppState) => state.resetPassword.error;

const selectSuccess = (state: AppState) => state.resetPassword.success;

export const getSuccess = createSelector(selectSuccess, (res) => res);

export const postLoadingSelector = createSelector(
  postLoading,
  (pending) => pending
);

export const getErrorSelector = createSelector(postError, (error) => error);
