export interface WalletState {
  loading: boolean;
  error: any;
  whitelistedCoins: Array<any>;
  history: any;
  historyLoading: boolean;
  fundsTotal: any;
  fundsTotalLoading: boolean;
  walletData: any;
  walletDataLoading: boolean;
  transactionsPager: any;
  historyPager: any;
  cancelStaking: any;
  cancelStakingLoading: boolean;
  openSearchTransactions: boolean;
  transactionTypes: any;
  cancelStakingState: CancelStakingStateEnum;
  purchaseOrder: IPurchaseOrder;
  purchaseOrderLoading: boolean;
  estimateRates: any;
  estimateRatesLoading: boolean;
  estimateRatesError: boolean;
  transactionDetails: ITransactionDetails;
  transactionDetailsLoading: boolean;
}

export interface ITransactionDetailsModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  transactionDetails: ITransactionDetails;
}

export interface ITransactionDetails {
  contractAddress: string;
  value: string;
  type: string;
  status: string;
  metadata: {
    type: string;
    bscTxHash: string;
    nonce: number;
    gasPrice: number;
    gasLimit: number;
    transferError: {
      code: number;
      message: string;
      data: string;
      internal: boolean;
    };
  };
  timestamp: string;
  correlationId: string;
}

export interface IPurchaseOrder {
  orderId: string;
  accessToken: string;
}
export enum CancelStakingStateEnum {
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}
export interface GetWhitelistedCoinsSuccessPayload {
  coins: any;
}

export interface GetWhitelistedCoinsFailurePayload {
  error: string;
}

export interface IWalletResponse {
  data: any;
}

export interface ITransactionsLogResponse {}

export interface GetCoinDataSuccessPayload {
  coin: any;
  contractAddress: string;
}
export interface GetTransactionsLogSuccessPayload {
  transactions: any;
  contractAddress: string;
  page: number;
}
export interface ResetTransactionsLogsPayload {
  contractAddress: string;
}
export interface GetCoinDataFailurePayload {
  error: string;
  contractAddress: string;
}

export interface GetCoinDataRequestPayload {
  contractAddress: string;
  public?: boolean;
}

export interface GetTransactionsLogRequestPayload {
  contractAddress: string;
  page: number;
  size: number;
}

export interface GetTransactionDetailsRequestPayload {
  id: string;
}

export interface GetAvailableFundsRequestPayload {
  contractAddress: string;
  isFromWs?: boolean;
}

export interface GetAvailableFundsSuccessPayload {
  coin: any;
  contractAddress: string;
}

export interface GetAvailableFundsFailurePayload {
  contractAddress: string;
}

export interface GetFundsHistoryRequest {
  page: number;
  size: number;
  type?: string;
  status?: string;
  startDate?: any;
  endDate?: any;
}

export interface GetFundsHistorySuccessPayload {
  history: any;
  page: number;
}

export interface FundsHistoryResponse {
  data: any;
}

export interface GetFundsTotalSuccessPayload {
  fundsTotal: any;
}
export interface getWalletDataSuccessPayload {
  walletData: any;
}
export interface UpdateTransactionPagerParams {
  page: number;
  size: number;
}
export interface UpdateHistoryPagerParams {
  page: number;
  size: number;
}

export interface PutCancelStakingSuccessPayload {
  cancelStaking: any;
}
