import React, { useEffect, useState } from "react";
import { ICoinPanelProps } from "./interface";
import { Tooltip } from "antd";

const CoinPanelHead = (props: ICoinPanelProps) => {
  const [minAPY, setMinAPY] = useState(null);
  const [maxAPY, setMaxAPY] = useState(null);

  useEffect(() => {
    if (props.coin) {
      let minCoinApy = 1000000;
      let maxCoinApy = 0;
      props.coin.pools.map((stakingItem) => {
        if (stakingItem.apy > maxCoinApy) {
          maxCoinApy = stakingItem.apy;
        }
        if (stakingItem.apy < minCoinApy) {
          minCoinApy = stakingItem.apy;
        }
        setMaxAPY(maxCoinApy);
        setMinAPY(minCoinApy);
      });
    }
  }, [props.coin]);

  return (
    <div className="panelHead stakingPanelHead">
      <img src={props.coin.iconUrl} alt="" />

      <div className="details">
        <div className="stakingStatus">
          <Tooltip title={props.coin.tokenName} placement="right">
            <h3>
              {props.coin.tokenSymbol} <span>- {props.coin.tokenName}</span>
            </h3>
          </Tooltip>
        </div>

        <div className="stats apy">
          <h3>
            APY
            <span className="green">
              {minAPY === maxAPY ? `${minAPY}%` : `${minAPY}% - ${maxAPY}%`}
            </span>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default CoinPanelHead;
