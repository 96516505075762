import React, { useEffect, useState } from "react";
import CardForm from "./cardForm";
import { scrollTo } from "../../../_helpers/scroll";
import { ROUTES } from "../../../_helpers/config";
import { SuccessModalTypes } from "../../../components/SuccessModal/interface";
import SuccessModal from "../../../components/SuccessModal";

const CardTab = ({ investAmount, setInvestAmount, minInvest, maxInvest }) => {
  const [investCardSuccess, setInvestCardSuccess] = useState(false);

  useEffect(() => {
    if (investCardSuccess) {
      scrollTo("invest-heading");
    }
  }, [investCardSuccess]);

  return (
    <>
      <CardForm
        withNewInput={{
          initialValue: { investAmount: investAmount },
          setValue: setInvestAmount,
          min: minInvest,
          max: maxInvest,
        }}
        onSuccess={setInvestCardSuccess}
      />
      <SuccessModal
        isVisible={investCardSuccess}
        ctaRedirect={ROUTES.launchpad}
        modalType={SuccessModalTypes.STAKE}
        ctaLabel="Continue"
        title="Investment request submitted"
        description="Investment request has been registered. Once it's status is going to be updated you will receive a notification."
      />
    </>
  );
};

export default CardTab;
