import React, { useEffect, useState } from "react";
import "./style.less";
import {
  FiLock,
  FiBell,
  FiUnlock,
  FiCheckCircle,
  FiArrowUpRight,
  FiArrowDownRight,
  FiArrowRight,
  FiAlertTriangle,
  FiArrowDownLeft,
  FiRotateCcw,
  FiRotateCw,
} from "react-icons/fi";
import { Button, Empty, Pagination, Tooltip } from "antd";
import { useWindowSize } from "../../hooks/windowSize";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationsRequest,
  getUnreadNotificationsCountRequest,
  markAllNotificationsAsReadRequest,
  markNotificationAsReadRequest,
  resetNotificationsState,
} from "./notificationSlice";
import {
  getMarkAllNotificationsAsReadSuccessSelector,
  getMarkNotificationAsReadSuccessSelector,
  getNotificationsLoadingSelector,
  getNotificationsSelector,
} from "./selectors";
import moment from "moment";
import { AiOutlineSwap } from "react-icons/ai";
import { ROUTES } from "../../_helpers/config";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notifications = useSelector(getNotificationsSelector);
  const notificationsLoading = useSelector(getNotificationsLoadingSelector);
  const markNotificationAsReadSuccess = useSelector(
    getMarkNotificationAsReadSuccessSelector
  );
  const markAllNotificationsAsReadSuccess = useSelector(
    getMarkAllNotificationsAsReadSuccessSelector
  );

  const [readNotifications, setReadNotifications] = useState([]);
  const isMobile = windowSize.width < 720;

  const [pager, setPager] = useState({
    page: 1,
    size: 25,
  });

  const getNotifications = (page) => {
    setPager({
      ...pager,
      page: page,
    });
    dispatch(
      getNotificationsRequest({
        ...pager,
        page: page - 1,
      })
    );
  };

  const getIconBasedOnType = (type) => {
    switch (type) {
      case "STAKING_DEPOSIT_LOCKED":
        return <FiLock />;
      case "STAKING_DEPOSIT_REJECTED":
        return <FiAlertTriangle />;
      case "STAKING_REWARD_UNLOCKED":
        return <FiUnlock />;
      case "VESTING_UNLOCKED":
        return <FiUnlock />;
      case "FUNDS_DEPOSIT":
        return <FiArrowDownLeft />;
      case "FUNDS_WITHDRAW":
        return <FiArrowUpRight />;
      case "EVENT_NOTIFICATION":
        return <FiBell />;
      case "TOKEN_SWAP_COMPLETED":
        return <AiOutlineSwap />;
      default:
        return <FiBell />;
    }
  };

  const getRedirectBasedOnType = (type) => {
    switch (type) {
      case "STAKING_DEPOSIT_LOCKED":
        return ROUTES.transactions;
      case "STAKING_DEPOSIT_REJECTED":
        return ROUTES.transactions;
      case "STAKING_REWARD_UNLOCKED":
        return ROUTES.transactions;
      case "VESTING_UNLOCKED":
        return ROUTES.transactions;
      case "FUNDS_DEPOSIT":
        return ROUTES.transactions;
      case "FUNDS_WITHDRAW":
        return ROUTES.transactions;
      case "EVENT_NOTIFICATION":
        return ROUTES.events;
      case "TOKEN_SWAP_COMPLETED":
        return ROUTES.wallet;
      default:
        return ROUTES.wallet;
    }
  };

  const markNotificationAsRead = (notification) => {
    setReadNotifications([...readNotifications, notification.id]);
    dispatch(
      markNotificationAsReadRequest({ notificationId: notification.id })
    );
  };

  const markNotificationAsReadAndRedirect = (notification) => {
    dispatch(
      markNotificationAsReadRequest({ notificationId: notification.id })
    );
    navigate(getRedirectBasedOnType(notification.type));
  };

  const markAllNotificationsAsRead = () => {
    dispatch(markAllNotificationsAsReadRequest());
  };

  const NoNotifications = () => {
    return (
      <Empty
        description={<span>No notifications yet, we'll keep you posted..</span>}
      />
    );
  };

  useEffect(() => {
    dispatch(
      getNotificationsRequest({
        ...pager,
        page: pager.page - 1,
      })
    );
    return () => {
      dispatch(resetNotificationsState());
    };
  }, []);

  useEffect(() => {
    if (markAllNotificationsAsReadSuccess) {
      dispatch(getUnreadNotificationsCountRequest());
      setReadNotifications([]);
      setPager({
        page: 1,
        size: 25,
      });
      dispatch(
        getNotificationsRequest({
          ...pager,
          page: 0,
        })
      );
    }
  }, [markAllNotificationsAsReadSuccess]);

  useEffect(() => {
    if (markNotificationAsReadSuccess) {
      dispatch(getUnreadNotificationsCountRequest());
    }
  }, [markNotificationAsReadSuccess]);

  return (
    <div className="notificationsPage">
      <div className="container">
        <div className="pageTitle" data-aos="fade-up">
          <h1>Notifications</h1>
          <p>
            All account actions completed such as staking, vesting, funds
            deposits or withdrawals will be confirmed through a notification
            below.
          </p>
          <button
            className="markAllAsRead"
            onClick={() => markAllNotificationsAsRead()}
          >
            Mark all as read
          </button>
        </div>

        <div
          className="notifications"
          data-aos="fade-down"
          data-aos-delay="1000"
        >
          <div className="container">
            {/* Use this notification item */}

            {notifications?.content.length > 0 ? "" : <NoNotifications />}

            {notifications &&
              notifications.content.map((notification, index) => (
                <div
                  className={`item ${
                    notification.read ||
                    readNotifications.includes(notification.id)
                      ? ""
                      : "unread"
                  }`}
                  key={index}
                >
                  <div className="thumbnail">
                    {getIconBasedOnType(notification.type)}
                  </div>

                  <div className="content">
                    <div className="heading">
                      <h3>{notification.title}</h3>

                      <div className="date">
                        {moment
                          .utc(notification.dateTime)
                          .local()
                          .format("dddd, Do MMMM YYYY h:mm a")}
                      </div>
                    </div>

                    <Tooltip title="Mark as read" placement="top">
                      <button
                        className="markAsRead"
                        onClick={() => markNotificationAsRead(notification)}
                      >
                        <FiCheckCircle />
                      </button>
                    </Tooltip>

                    <div className="description">
                      {notification.content}
                      <Button
                        onClick={() =>
                          markNotificationAsReadAndRedirect(notification)
                        }
                      >
                        More details
                        <FiArrowRight />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <Pagination
          simple={isMobile}
          current={pager.page}
          onChange={(page) => {
            getNotifications(page);
          }}
          showSizeChanger={false}
          total={notifications ? notifications.totalElements : 0}
          pageSize={pager.size}
          hideOnSinglePage
        />
      </div>
    </div>
  );
};

export default Notifications;
