import React, { useEffect, useState } from "react";
import "./style.less";
import "../../SearchFilters/style.less";

// Components
import { BackTop, Collapse, Spin } from "antd";
import TransactionPanelHead from "./TransactionPanelHead";

// Assets
import { useDispatch, useSelector } from "react-redux";
import {
  getHistoryLoadingSelector,
  getHistoryPagerSelector,
  getHistorySelector,
  getTransactionDetailsLoadingSelector,
  getTransactionDetailsSelector,
} from "../selectors";
import {
  getFundsHistoryRequest,
  getTransactionDetailsRequest,
  resetHistoryState,
  setOpenSearchTransactions,
  updateHistoryPager,
} from "../walletSlice";
import TransactionPanelBody from "./TransactionPanelBody";
import Button from "../../Button";
import FiltersTransactions from "./filters";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import FiltersValues from "./filtersValues";
import { FiDownloadCloud } from "react-icons/fi";
import ReportDownloadModal from "../../ReportDownloadModal/ReportDownloadModal";
import { ButtonTypes } from "../../Button/types";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hooks";
import { TransactionDetailsModal } from "./TransactionDetailsModal";

const TransactionsTab = () => {
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();

  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [search, setSearch] = useState(false);
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(30, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [transactionDetailsModalOpen, setTransactionDetailsModalOpen] =
    useState(false);

  const history = useSelector(getHistorySelector);
  const historyLoading = useSelector(getHistoryLoadingSelector);
  const pager = useSelector(getHistoryPagerSelector);
  const transactionDetails = useAppSelector(getTransactionDetailsSelector);

  useEffect(() => {
    return () => {
      setSearch(false);
      dispatch(resetHistoryState());
      dispatch(
        updateHistoryPager({
          size: 10,
          page: 0,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (searchParams.get("id")) {
      dispatch(
        getTransactionDetailsRequest({
          id: searchParams.get("id"),
        })
      );
      setTransactionDetailsModalOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      getFundsHistoryRequest({
        ...pager,
        type: type?.id === "all" ? null : type?.id,
        status: status === "All" ? null : status?.toUpperCase(),
        startDate: startDate
          ? moment(startDate).format("YYYY-MM-DD")
          : undefined,
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
      })
    );
  }, [pager]);

  const loadMoreData = () => {
    dispatch(
      updateHistoryPager({
        ...pager,
        page: pager.page + 1,
      })
    );
  };

  useEffect(() => {
    if (search) {
      dispatch(
        getFundsHistoryRequest({
          ...pager,
          type: type?.id === "all" ? null : type?.id,
          status: status === "All" ? null : status?.toUpperCase(),
          startDate: startDate
            ? moment(startDate).format("YYYY-MM-DD")
            : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
        })
      );
      setSearch(false);
    }
  }, [search]);

  return (
    <div className="walletHeading">
      <h1 data-aos="fade-up">Transactions</h1>
      <div data-aos="fade-up">
        <span className="downloadReportBtn">
          <a onClick={() => setReportModalVisible(true)}>
            <FiDownloadCloud width={24} height={16} />
            Download report
          </a>
        </span>
      </div>
      <div data-aos="fade-down" data-aos-delay="400">
        <FiltersTransactions
          setSearch={setSearch}
          type={type}
          setType={setType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          status={status}
          setStatus={setStatus}
        />
      </div>
      <div className="walletTabs transactionsTab" data-aos="fade-up">
        {historyLoading && history.content === undefined ? (
          <div className="loading inline">
            <Spin />
          </div>
        ) : (
          <>
            <FiltersValues
              setSearch={setSearch}
              type={type}
              setType={setType}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              status={status}
              setStatus={setStatus}
            />
            <div className="transactionsWrapper">
              <Collapse accordion expandIconPosition="end">
                {history.content &&
                  history.content.map((item) => (
                    <Collapse.Panel
                      key={item.id}
                      header={<TransactionPanelHead transaction={item} />}
                      className={
                        item.correlationId === "NO_FEE_CAMPAIGN_PRIZE"
                          ? "prize"
                          : ""
                      }
                    >
                      <TransactionPanelBody transaction={item} />
                    </Collapse.Panel>
                  ))}

                {history.content && history.content.length === 0 ? (
                  <span className="disclaimer">No records were found.</span>
                ) : (
                  ""
                )}
              </Collapse>

              {history.last ? (
                ""
              ) : (
                <Button
                  label="Load more"
                  loading={historyLoading}
                  type={ButtonTypes.SECONDARY}
                  extraClasses="loadMoreButton"
                  onClick={() => loadMoreData()}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div
        className="btnOpenDrawerSearch"
        onClick={() => dispatch(setOpenSearchTransactions(true))}
      >
        <FaSearch fontSize={18} color="#fff" />
      </div>
      <ReportDownloadModal
        onCancel={() => setReportModalVisible(false)}
        onSuccess={() => setReportModalVisible(false)}
        isVisible={reportModalVisible}
      />
      <TransactionDetailsModal
        open={transactionDetailsModalOpen}
        transactionDetails={transactionDetails}
        setOpen={setTransactionDetailsModalOpen}
      />
    </div>
  );
};

export default TransactionsTab;
