import React, { useCallback, useEffect, useState } from "react";
import "./styles.less";
import { IReferralTokenDetailsTab } from "./interface";
import { requirements } from "./data";
import { FiUser } from "react-icons/fi";
import { IoAddCircleOutline } from "react-icons/io5";
import { useCookies } from "react-cookie";
import { ReferralModal } from "../ReferralModal";
import { Carousel, Empty, Form } from "antd";
import { sliderSettings } from "./config";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import { getReferral } from "../Referral/selectors";
import moment from "moment";
import { getTokenDataSelector } from "../TokenDetails/selectors";

export const ReferralTokenDetailsTab = (props: IReferralTokenDetailsTab) => {
  const { tokenSymbol, contractAddress } = props;
  const [formRef] = Form.useForm();
  const [token] = useCookies(["sOnFinanceToken"]);
  const [inviteFriendModalVisible, setInviteFriendModalVisible] =
    useState(false);
  const referral = useSelector(getReferral);
  const tokenData = useSelector(getTokenDataSelector);

  const onButtonPress = useCallback(() => {
    if (!token?.sOnFinanceToken) {
      window.location.href = process.env.REACT_APP_AUTH_URL;
    } else {
      setInviteFriendModalVisible(true);
    }
  }, [token]);

  useEffect(() => {
    formRef.resetFields();
  }, [inviteFriendModalVisible]);

  const referrals = [1, 2, 3, 4, 5, 6];

  return (
    <div className="referralTokenDetailsTab">
      <div className="tabHeader">
        <div className="headerTitle">Invite friends and earn money</div>
        <div className="headerSubtitle">
          {tokenData?.details?.brief?.content}
        </div>
      </div>
      {contractAddress === process.env.REACT_APP_SEON_CONTRACT_ADDRESS ? (
        <>
          <div className="distributionContainer">
            <div className="distributionContainerItem">
              <div className="containerContent">$25 worth of SEON</div>
              <div className="containerTitle">For each friend</div>
            </div>

            <div className="distributionContainerItem">
              <div className="containerContent">
                {moment(referral?.expirationDate).diff(moment(), "days")} days
              </div>
              <div className="containerTitle">remaining</div>
            </div>
          </div>

          <div className="requirementsContainer">
            <div className="requirementTitle">
              What my invited friends need to do?
            </div>
            {requirements.map((requirement, index) => {
              return (
                <div key={index} className="requirementItem">
                  <div className="itemIndex">{index + 1}</div>
                  <div
                    className="itemContent"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(requirement),
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div className="inviteFriendContainer">
            <div className="containerHeader">
              <div className="rewardContent">
                Invites - 0 {tokenSymbol} earned
              </div>
              <div className="pendingInvites">0 pending</div>
            </div>

            <div className="containerContent">
              <div className="inviteFriendButtonContainer">
                <div
                  className="inviteFriendButton"
                  onClick={() => onButtonPress()}
                >
                  <IoAddCircleOutline />
                </div>
                <div className="inviteFriendButtonLabel">Invite a friend</div>
              </div>
              <div className="friendsPlaceholdersContainer">
                <Carousel {...sliderSettings(referrals?.length)}>
                  {referrals?.map((_, index) => (
                    <div className="iconContainer" key={index}>
                      <FiUser />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>

            <div className="containerFooter">
              <span className="footerContent">
                By clicking send a link, you confirm that you have read and
                agree to the above disclaimers and our{" "}
                <a
                  href="https://seedon.io/terms-and-conditions/"
                  target={"_blank"}
                >
                  Terms & Conditions
                </a>
                .
              </span>
            </div>

            <ReferralModal
              visible={inviteFriendModalVisible}
              setVisible={setInviteFriendModalVisible}
              formRef={formRef}
            />
          </div>
        </>
      ) : (
        <div className="referralNoDataContainer">
          <Empty description="Currently there are not available referral programs" />
        </div>
      )}
    </div>
  );
};
