import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getVestingCoinsLoading = (state: AppState) => state.vesting.loading;
const getVestingCoinsError = (state: AppState) => state.vesting.error;
const getVestingCoins = (state: AppState) => state.vesting.vestingList;

export const getVestingCoinsLoadingSelector = createSelector(
  getVestingCoinsLoading,
  (res) => res
);
export const getVestingCoinsErrorSelector = createSelector(
  getVestingCoinsError,
  (res) => res
);
export const getVestingCoinsSelector = createSelector(
  getVestingCoins,
  (res) => res
);
