import React, { useEffect } from "react";
import { Timeline as AntTimeline } from "antd";
import "./style.less";
import { notes } from "./data";
import { scrollToTop } from "../../_helpers/scroll";

const Timeline = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <div className="walletHeading">
        <div className="container">
          <h1 data-aos="fade-up">Release notes</h1>
          <p data-aos="fade-up" data-aos-delay="400">
            We are continuously working to improve your experience and offer you
            new and exciting features. Below you can see our releases and the
            changes that we have brought to the platform.
          </p>
        </div>
      </div>

      <div className="notesTimeline">
        <div className="container">
          <AntTimeline>
            {notes.map((note, key) => (
              <AntTimeline.Item key={key}>
                <h2>
                  <b>{note.no}</b>

                  <code className="releaseDate">
                    <b>{note.date}</b>
                  </code>
                </h2>

                <ul className="mainList">
                  {note.features.length > 0 ? (
                    <li>
                      <h3>Features:</h3>

                      <ul>
                        {note.features.map((feature, fKey) => (
                          <li key={fKey}>{feature}</li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    ""
                  )}
                  {note.fixes.length > 0 ? (
                    <li>
                      <h3>Fixes & improvements:</h3>

                      <ul>
                        {note.fixes.map((fix, fKey) => (
                          <li key={fKey}>{fix}</li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </AntTimeline.Item>
            ))}
          </AntTimeline>
        </div>
      </div>
    </>
  );
};

export default Timeline;
