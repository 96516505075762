export const openFileFromUrl = (
  url: string,
  fileName: string,
  fileExtension: string
) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${fileExtension}`);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
