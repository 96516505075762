import "./style.less";
import { useEffect, useState } from "react";

// Components
import { Form, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetReferState } from "./referralSlice";
import { getReferSuccess } from "./selectors";
import referralImage from "../../assets/images/referral.png";
import { getCurrentUserSelector } from "../User/selectors";
import { ReferralModal } from "../ReferralModal";
import { ButtonTypes } from "../Button/types";
import Button from "../Button";

const Referral = () => {
  const [formRef] = Form.useForm();
  const dispatch = useDispatch();

  const [isModalVisible, toggleModal] = useState(false);

  const referSuccess = useSelector(getReferSuccess);
  const user = useSelector(getCurrentUserSelector);

  useEffect(() => {
    formRef.resetFields();
  }, [isModalVisible]);

  useEffect(() => {
    if (referSuccess) {
      dispatch(resetReferState());
      toggleModal(false);
      notification.success({
        message: "Referral request has been submitted.",
      });
    }
  }, [referSuccess]);

  const loginOrToggleModal = () => {
    if (user) {
      toggleModal(true);
    } else {
      localStorage.setItem("intended", window.location.pathname);
      window.location.href = process.env.REACT_APP_AUTH_URL;
    }
  };
  return (
    <section className="page-wrapper">
      <section className="container" data-aos="fade-right">
        <section className="intro">
          <h1>
            The crowdfunding revolution <br />
            <b>starts today!</b> Be part of it.
          </h1>
          <p>
            Interested? It is very simple. All you have to do is to convince
            your friends to join SeedOn <br />
            and you will get a cash-back on their first purchase.
          </p>

          <img src={referralImage} alt="" />
        </section>

        <section className="content">
          <h2>
            Greetings{" "}
            {user && user?.details?.firstName
              ? user?.details?.firstName
              : "Seeder"}{" "}
            🌱,
          </h2>

          <p>
            Are you ready to easily earn up to $75 worth of <b>$SEON</b>? Invite
            your friends to join SeedOn and we will offer you up to $25 worth of
            SEON for each eligible account opened by your friends. However, keep
            in mind that you can invite up to 3 friends.**
          </p>
        </section>
      </section>

      <section className="stripe-section" data-aos="fade-left">
        <section className="container">
          <h3>
            How do I invite <b>my friends?</b>
          </h3>

          <ol>
            <li>
              Carefully read the terms and conditions of our referral campaign
              in this page, then go ahead an click on the 'Invite friends'
              button below.
            </li>
            <li>
              Send an invitation email to your friends by filling in the 'Email
              address' field from the invitation pop-up, or copy the invitation
              link and share on your preferred communication channel.
            </li>
            <li>
              After your friends completed all required steps, you will receive
              your 💰 of <b>$SEON</b>
            </li>
            <li>
              You can invite up to 3 friends, that must be eligible for a SeedOn
              Finance account and must not have an already existing account on
              our platform.
            </li>
          </ol>

          <Button
            type={ButtonTypes.PRIMARY}
            onClick={() => loginOrToggleModal()}
            label="Invite friends"
          />
        </section>
      </section>

      <section className="container" data-aos="fade-right">
        <section className="content">
          <p>
            The referral campaign will be available until 30-April-2023 or when
            the budget allocated has been exhausted, whichever comes first.
          </p>
        </section>

        <section className="boxed-section">
          <section className="container">
            <h3>
              <b>What are my friends</b> required to do?
            </h3>

            <ol>
              <li>
                Your friends will be required to setup a SeedOn Finance account
                with your unique invitation link generated for your account.
              </li>
              <li>
                Confirm and validate their recovery phrase, and fully setup
                their account.
              </li>
              <li>
                A deposit of <b>$BNB/$USDT</b> worth of at least $100 must be
                added to their SeedOn Finance account.
              </li>
              <li>
                The deposited <b>$BNB</b> or <b>$USDT</b> must be used on a swap
                transaction for <b>$SEON</b>.
              </li>
              <li>
                <b>$SEON</b> tokens received after swapping must then be staked
                in one of our staking pools, be it either Flexible or Locked.
              </li>
            </ol>

            <Button
              type={ButtonTypes.PRIMARY}
              onClick={() => loginOrToggleModal()}
              label="Invite friends"
            />
          </section>
        </section>

        <section className="content">
          <p>
            ** For each of your friends staking deposit according to the
            required steps described above, you will get a 10% cash-back of
            their first purchase, up to $25 worth of $SEON.
          </p>
        </section>

        <section className="boxed-section">
          <section className="container">
            <h3>
              <b>What is in it for me</b>, then?
            </h3>

            <ol>
              <li>
                We will debit your account with up to $25 worth of $SEON for
                each of your friend that has completed the above steps, summing
                a total of $75 worth of $SEON for up to 3 friends.
              </li>
              <li>
                Invite your friends as soon as possible, so that they will have
                plenty of time to complete their account setup and the above
                steps until 30-April-2023, end of day.
              </li>
            </ol>
          </section>
        </section>
      </section>
      <ReferralModal
        visible={isModalVisible}
        setVisible={toggleModal}
        formRef={formRef}
      />
    </section>
  );
};

export default Referral;
