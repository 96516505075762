import React, { useEffect, useState } from "react";
import { Form, Select, Input } from "antd";
import { IPhoneNumberInputProps } from "./phoneNumberInput.interface";
import { FiChevronDown } from "react-icons/fi";
import { VALIDATION_ERROR_MESSAGES } from "../../_helpers/config";
import "./style.less";

const PhoneNumberInput: React.FC<IPhoneNumberInputProps> = (
  props: IPhoneNumberInputProps
) => {
  const {
    formInputName,
    formSelectName,
    placeholder,
    onCodeChange,
    loading,
    dropdownCountries,
    label,
    defaultPhonePrefix,
  } = props;
  const [selectedCountry, setSelectedCountry] = useState(null);

  const formatPhonePrefix = (phonePrefix: string) => {
    if (phonePrefix.includes("-")) {
      phonePrefix = phonePrefix.substring(0, phonePrefix.indexOf("-"));
    }

    return phonePrefix.includes("+") ? `${phonePrefix} ` : `+${phonePrefix} `;
  };

  const onSelectChange = (value) => {
    setSelectedCountry(JSON.parse(value));
  };

  const selectCountryComponent = (
    <div className="phone-number-country-select">
      <Form.Item name={formSelectName}>
        <Select
          bordered={false}
          className="dropdown-button"
          suffixIcon={<FiChevronDown className="dropdown-button-icon" />}
          onChange={onSelectChange}
          dropdownClassName={"phone-number-input-dropdown"}
          loading={loading}
          showSearch
          optionFilterProp="children"
          // @ts-ignore
          filterOption={(input, { value }) => {
            // @ts-ignore
            const countryObj = JSON.parse(value);
            return (
              `${countryObj.name.toLowerCase()} +${countryObj.phoneCode.toLowerCase()}`.indexOf(
                input.toLowerCase()
              ) >= 0
            );
          }}
          filterSort={(optionA, optionB) => {
            // @ts-ignore
            const countryObjA = JSON.parse(optionA.value);
            // @ts-ignore
            const countryObjB = JSON.parse(optionB.value);
            return countryObjA.name
              .toLowerCase()
              .localeCompare(countryObjB.name.toLowerCase());
          }}
        >
          {dropdownCountries.map((countryObj, index) => (
            <Select.Option
              key={index}
              className="dropdown-item"
              value={JSON.stringify(countryObj)}
            >
              <div className="phone-number-input-content">
                <div className="country-flag">
                  <img
                    width={22}
                    height={22}
                    src={countryObj.flagImageUrl}
                    alt={countryObj.name}
                  />
                </div>
                <div className="country-prefix">{`(${formatPhonePrefix(
                  countryObj.phoneCode
                )})`}</div>
                <span className="select-option-content">{countryObj.name}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );

  useEffect(() => {
    onCodeChange(
      formatPhonePrefix(
        selectedCountry ? selectedCountry.phoneCode : defaultPhonePrefix
      )
    );
  }, [selectedCountry]);

  return (
    <div className="phone-number-input-component">
      <span className="phone-number-input-title">{label}</span>

      <Form.Item
        name={formInputName}
        rules={[
          {
            required: true,
            message: VALIDATION_ERROR_MESSAGES.required,
          },
          {
            max: 255,
            message: VALIDATION_ERROR_MESSAGES.maxLength,
          },
          {
            pattern: new RegExp(/^\d+$/),
            message: VALIDATION_ERROR_MESSAGES.invalidPhoneNumber,
          },
        ]}
      >
        <Input
          addonBefore={selectCountryComponent}
          className="phone-number-input-field"
          type="tel"
          placeholder={placeholder}
          maxLength={255}
          disabled={loading}
        />
      </Form.Item>
    </div>
  );
};

export default PhoneNumberInput;
