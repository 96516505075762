import React, { useState } from "react";
import "./style.less";
import { WalletControls } from "../index";
import { IWalletLayoutProps } from "./interface";
import { FiDownloadCloud } from "react-icons/fi";
import ReportDownloadModal from "../ReportDownloadModal/ReportDownloadModal";
import WalletSummary from "components/WalletSummary";
import { useCookies } from "react-cookie";
import { WalletMobileNavigation } from "../WalletMobileNavigation";

const WalletLayout = (props: IWalletLayoutProps) => {
  const [token] = useCookies(["sOnFinanceToken"]);
  const [reportModalVisible, setReportModalVisible] = useState(false);

  return (
    <div className="walletHeading">
      {token?.sOnFinanceToken &&
      window.location.pathname.indexOf("/vesting") === -1 ? (
        <WalletSummary />
      ) : (
        <div className="container">
          <h1 data-aos="fade-up">{props.title}</h1>
          <p data-aos="fade-up" data-aos-delay="400">
            {props.description}
          </p>
          {window.location.pathname.indexOf("/wallet") !== -1 ? (
            <span className="reportBtn" data-aos="fade-up" data-aos-delay="400">
              <a onClick={() => setReportModalVisible(true)}>
                <FiDownloadCloud width={24} height={16} />
                Download report
              </a>
            </span>
          ) : (
            ""
          )}
        </div>
      )}

      {props.showControls ? (
        <WalletControls defaultButtons={props.withDefaultButtons} />
      ) : (
        ""
      )}
      {props.children}
      {token?.sOnFinanceToken && (
        <div className="walletMobileNavigationContainer">
          <WalletMobileNavigation />
        </div>
      )}

      <ReportDownloadModal
        onCancel={() => setReportModalVisible(false)}
        onSuccess={() => setReportModalVisible(false)}
        isVisible={reportModalVisible}
      />
    </div>
  );
};

export default WalletLayout;
