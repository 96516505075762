import { Image } from "antd";

import VisaCard from "../../../assets/images/cards/visa.svg";
import MasterCard from "../../../assets/images/cards/mastercard.svg";
import AmexCard from "../../../assets/images/cards/amex.svg";
import DiscoverCard from "../../../assets/images/cards/discover.svg";
import DinersCard from "../../../assets/images/cards/diners.svg";
import JCBCard from "../../../assets/images/cards/jcb.svg";

import { FaCreditCard } from "react-icons/fa";

export const validateCardNumber = (number) => {
  const cardNumberRegex = new RegExp(
    "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\\d{3})\\d{11})$"
  );
  if (!cardNumberRegex.test(number?.replace(/\s/g, ""))) {
    return false;
  }

  return luhnCheck(number);
};

const luhnCheck = (val) => {
  // Remove all whitespaces from card number.
  const cardNumber = val.replace(/\s/g, "");

  // 1. Remove last digit;
  const lastDigit = Number(cardNumber[cardNumber.length - 1]);

  // 2. Reverse card number
  const reverseCardNumber = cardNumber
    .slice(0, cardNumber.length - 1)
    .split("")
    .reverse()
    .map((x) => Number(x));

  let sum;

  // 3. + 4. Multiply by 2 every digit on odd position.
  // Subtract 9 if digit > 9
  for (let i = 0; i <= reverseCardNumber.length - 1; i += 2) {
    reverseCardNumber[i] = reverseCardNumber[i] * 2;
    if (reverseCardNumber[i] > 9) {
      reverseCardNumber[i] = reverseCardNumber[i] - 9;
    }
  }

  // 5. Make the sum of obtained values from step 4.
  sum = reverseCardNumber.reduce((acc, currValue) => acc + currValue, 0);

  // 6. Calculate modulo 10 of the sum from step 5 and the last digit.
  // If it's 0, you have a valid card number :)
  return (sum + lastDigit) % 10 === 0;
};

export const getCardTypeFromNumber = (number) => {
  if (!number) {
    return null;
  }

  // Diners - Carte Blanche
  let re = new RegExp("^30[0-5]");
  if (number.match(re) != null) {
    return "Diners - Carte Blanche";
  }

  // Diners
  re = new RegExp("^(30[6-9]|36|38)");
  if (number.match(re) != null) {
    return "Diners";
  }

  // JCB
  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null) {
    return "JCB";
  }

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) {
    return "AMEX";
  }

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) {
    return "Visa Electron";
  }

  // Visa
  re = new RegExp("^4");
  if (number.match(re) != null) {
    return "Visa";
  }

  // Mastercard
  re = new RegExp("^5[1-5]");
  if (number.match(re) != null) {
    return "Mastercard";
  }

  // Discover
  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null) {
    return "Discover";
  }

  return "";
};

export const getCardTypeIcon = (number: string) => {
  switch (getCardTypeFromNumber(number)) {
    case "Visa Electron":
    case "Visa":
      return <Image src={VisaCard} width={25} preview={false} />;
    case "Mastercard":
      return <Image src={MasterCard} width={25} preview={false} />;
    case "AMEX":
      return <Image src={AmexCard} width={25} preview={false} />;
    case "Discover":
      return <Image src={DiscoverCard} width={25} preview={false} />;
    case "Diners - Carte Blanche":
    case "Diners":
      return <Image src={DinersCard} width={25} preview={false} />;
    case "JCB":
      return <Image src={JCBCard} width={25} preview={false} />;
    default:
      return <FaCreditCard color="#f4f4f4" />;
  }
};

export const formatMask = (string, mask = CREDIT_CARD_NUMBER_DEFAULT_MASK) => {
  let formattedString = "";
  let numberPos = 0;
  for (let j = 0; j < mask.length; j++) {
    const currentMaskChar = mask[j];
    if (currentMaskChar == "X") {
      const digit = string.charAt(numberPos);
      if (!digit) {
        break;
      }
      formattedString += string.charAt(numberPos);
      numberPos++;
    } else {
      formattedString += currentMaskChar;
    }
  }
  return formattedString;
};

const CREDIT_CARD_NUMBER_DEFAULT_MASK = "XXXX XXXX XXXX XXXX";
