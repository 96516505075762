import React, { useState } from "react";
import "./style.less";
import { ITelegramOtpsGuideModal } from "./interface";
import { Button } from "../index";
import { Avatar, List, Modal } from "antd";
import { Scrollbars } from "react-custom-scrollbars-2";
import AnimatedScrollDown from "../AnimatedScrollDown";
import { ButtonTypes } from "../Button/types";

const TelegramOtpsGuideModal: React.FC<ITelegramOtpsGuideModal> = (
  props: ITelegramOtpsGuideModal
) => {
  const [showVideo, toggleVideo] = useState(false);

  const steps = [
    {
      title: 1,
      description: `Start a chat with <a href="https://t.me/seedon_otp_bot" target="_blank">https://t.me/seedon_otp_bot</a>.`,
      avatar: <Avatar />,
    },
    {
      title: 2,
      description:
        "The bot will then send you a message requesting your phone number.",
      avatar: <Avatar />,
    },
    {
      title: 3,
      description:
        "In order to be able to establish the connection, share your contact with the bot.",
      avatar: <Avatar />,
    },
    {
      title: 4,
      description: "Return to SeedOn Finance.",
      avatar: <Avatar />,
    },
    {
      title: 5,
      description:
        "After successfully completing all these steps, you can click on CONFIRM and return to typing your phone number.",
      avatar: <Avatar />,
    },
    {
      title: 6,
      description:
        "Your OTP code for the phone number will then be sent to the chat with the Telegram Bot instead of and SMS for countries with restrictions.",
      avatar: <Avatar />,
    },
  ];

  return (
    <Modal
      closeIcon={<></>}
      open={props.isVisible}
      className="authModals walletModals tutorialModal"
      maskClosable={false}
      footer={null}
      forceRender
    >
      <Scrollbars
        universal
        style={{ height: "75vh" }}
        renderView={(props) => <div {...props} className="scroll-view" />}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
      >
        <div className="heading">
          <h2>Almost there</h2>
          <p>
            Some countries might encounter some issues in receiving OTP codes
            through SMS. To be sure, you can receive your code from our SeedOn
            Bot on Telegram! Follow the instructions below.
          </p>
        </div>

        {showVideo ? (
          <iframe
            allowFullScreen
            className="videoTutorialIframe"
            title="YouTube video player"
            frameBorder="0"
            width="541"
            height="961"
            src="https://www.youtube.com/embed/EzSLKbNiHxA"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        ) : (
          <>
            <List
              dataSource={steps}
              itemLayout="horizontal"
              className="listTutorial"
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={item.title}
                    description={
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    }
                  />
                </List.Item>
              )}
            />

            <p className="videoDisclaimer">
              Having trouble when following the steps from above? We got your
              back, click the button below for a video tutorial.
            </p>
          </>
        )}

        <Button
          type={ButtonTypes.SECONDARY}
          label={showVideo ? "Back" : "Video Tutorial"}
          extraClasses="tutorialButton"
          onClick={() => toggleVideo(!showVideo)}
        />

        <Button
          type={ButtonTypes.PRIMARY}
          label="CONFIRM"
          extraClasses="confirmationButton"
          onClick={() => props.closeModal()}
        />
      </Scrollbars>

      <AnimatedScrollDown />
    </Modal>
  );
};

export default TelegramOtpsGuideModal;
