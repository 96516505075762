import React, { useEffect, useState } from "react";
import { WalletLayout } from "../index";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLoadingSelector } from "./selectors";
import { StakingTabs, WalletTabs } from "./WalletTabs";
import Swap from "../Swap";

const Wallet = () => {
  const params = useParams();

  const whitelistedCoinsLoading = useSelector(getLoadingSelector);

  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    if (!shouldAnimate) {
      setShouldAnimate(true);
    }
    let element = document.getElementsByClassName("walletTabs")[0];

    if (!element) {
      element = document.getElementsByClassName("swapPage")[0];
    }

    if (element) {
      element.addEventListener("animationend", () => {
        setShouldAnimate(false);
      });
    }
  }, [params]);

  const getPageContent = () => {
    const currentStep = params.step;

    if ([undefined, null, "deposit", "withdraw"].includes(currentStep)) {
      return <WalletTabs shouldAnimate={shouldAnimate} />;
    }

    if (currentStep === "stake") {
      return (
        <StakingTabs
          shouldAnimate={shouldAnimate}
          whitelistedCoinsLoading={whitelistedCoinsLoading}
        />
      );
    }

    if (currentStep === "swap") {
      return <Swap shouldAnimate={shouldAnimate} />;
    }
  };

  return (
    <WalletLayout
      title="Wallet"
      showControls
      description="Here are all the details about your wallet. You can deposit, withdraw, stake and see all your past transactions."
    >
      {getPageContent()}
    </WalletLayout>
  );
};

export default Wallet;
