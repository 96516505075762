import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <div {...props}>{children}</div>
);

export const sliderSettings = (length) => ({
  dots: false,
  autoplay: false,
  centerMode: true,
  speed: 1000,
  autoplaySpeed: 3000,
  arrows: true,
  prevArrow: (
    <SlickArrow currentSlide slideCount>
      <FiChevronLeft />
    </SlickArrow>
  ),
  nextArrow: (
    <SlickArrow currentSlide slideCount>
      <FiChevronRight />
    </SlickArrow>
  ),
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    { breakpoint: 1380, settings: { slidesToShow: length < 4 ? length : 4 } },
    { breakpoint: 991, settings: { slidesToShow: length < 4 ? length : 4 } },
    { breakpoint: 768, settings: { slidesToShow: length < 4 ? length : 4 } },
    { breakpoint: 500, settings: { slidesToShow: length < 3 ? length : 3 } },
    { breakpoint: 420, settings: { slidesToShow: 1 } },
  ],
});
