import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAnnouncementsRequest,
  getAnnouncementsSuccess,
  getAnnouncementsFailure,
  markAnnouncementAsReadRequest,
} from "./announcementSlice";
import { axios } from "services/axios.service";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getAnnouncementsSaga(
  action: ReturnType<typeof getAnnouncementsRequest>
) {
  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/announcements`,
      { params: action.payload }
    );
    yield put(
      getAnnouncementsSuccess({
        announcements: response.data,
      })
    );
  } catch (e: any) {
    yield put(getAnnouncementsFailure());
  }
}

function* markAnnouncementAsReadSaga(
  action: ReturnType<typeof markAnnouncementAsReadRequest>
) {
  try {
    yield call(
      axios.post,
      `${process.env.REACT_APP_API_URL}/announcements/${action.payload.announcementId}`
    );
  } catch (e: any) {}
}

function* walletSaga() {
  yield all([
    takeLatest(getAnnouncementsRequest, getAnnouncementsSaga),
    takeLatest(markAnnouncementAsReadRequest, markAnnouncementAsReadSaga),
  ]);
}

export default walletSaga;
