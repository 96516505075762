import React, { useEffect, useState } from "react";
import "./style.less";
import ArticleCategories from "../News/ArticleCategories";
import { capitalizeFirstLetter } from "../../_helpers/stringEncoder";
import moment from "moment";
import { Button } from "../index";
import { Empty, Pagination, Spin, Tooltip } from "antd";
import { scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import {
  articleInteractionSuccessSelector,
  favoriteNewsLoadingSelector,
  favoriteNewsSelector,
} from "../News/selectors";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/windowSize";
import {
  articleInteractionRequest,
  getFavoriteNewsRequest,
  resetArticleInteractionState,
} from "../News/newsSlice";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FiTrash } from "react-icons/fi";
import { ROUTES } from "_helpers/config";
import { ButtonTypes } from "../Button/types";

const FavoriteNewsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const isMobile = windowSize.width < 720;

  const { executeRecaptcha } = useGoogleReCaptcha();
  const articleInteractionSuccess = useSelector(
    articleInteractionSuccessSelector
  );

  const [pager, setPager] = useState({
    page: 1,
    size: 25,
  });

  const news = useSelector(favoriteNewsSelector);
  const newsLoading = useSelector(favoriteNewsLoadingSelector);

  const getSentiment = (item) => {
    return item < -0.15 ? "Negative" : item > 0.15 ? "Positive" : "Neutral";
  };

  const getNews = () => {
    const sendForm = async () => {
      const token = await executeRecaptcha("news");

      dispatch(
        getFavoriteNewsRequest({
          ...pager,
          page: 0,
          captchaToken: token,
        })
      );
    };

    sendForm().then(() => {});
  };

  const loadMoreNews = (page) => {
    setPager({
      ...pager,
      page: page,
    });

    const sendForm = async () => {
      const token = await executeRecaptcha("news");

      dispatch(
        getFavoriteNewsRequest({
          ...pager,
          page: page - 1,
          captchaToken: token,
        })
      );
    };

    sendForm().then(() => {});

    scroller.scrollTo("articles", {
      duration: 1500,
      offset: 0,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const removeFromFavourites = (article) => {
    const sendForm = async () => {
      const token = await executeRecaptcha("articleInteraction");
      dispatch(
        articleInteractionRequest({
          articleId: article._id,
          type: "UNDO_LIKE",
          captchaToken: token,
        })
      );
    };
    sendForm().then(() => {});
  };

  const NoFavoriteNews = () => {
    return (
      <Empty
        description={
          <span>
            No favorite news yet, check <a href={ROUTES.news}>latest news</a>.
          </span>
        }
      />
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetArticleInteractionState());
    };
  }, []);

  useEffect(() => {
    if (executeRecaptcha) {
      getNews();
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    if (articleInteractionSuccess) {
      loadMoreNews(1);
    }
  }, [articleInteractionSuccess]);

  return (
    <div className="favoriteNews">
      <div className="container">
        <div className="pageTitle" data-aos="fade-up">
          <h1>Favorite news</h1>
          <p>
            Below, we have listed your favorite news articles. You can read them
            again or share an article with your friends either via social media
            channels or via links.
          </p>
        </div>

        <div
          className="recommendations"
          id="articles"
          data-aos="fade-down"
          data-aos-delay="1000"
        >
          <div className="container">
            {newsLoading ? (
              <div className="loading">
                <Spin />
              </div>
            ) : news && news.content.length > 0 ? (
              news.content.map((article, index) => (
                <div className="item" key={index}>
                  <a
                    href="#"
                    className="thumbnail"
                    onClick={() => navigate(`/article/${article.slag}`)}
                  >
                    <img src={article.image} alt={article.title} />
                  </a>

                  <div className="details">
                    <ArticleCategories article={article} />

                    <button
                      className="likedButton"
                      onClick={() => removeFromFavourites(article)}
                    >
                      <Tooltip title="Remove from favorites">
                        <FiTrash />
                      </Tooltip>
                    </button>

                    <h3>
                      <a
                        href="#"
                        onClick={() => navigate(`/article/${article.slag}`)}
                      >
                        {article.title}
                      </a>
                    </h3>

                    <p className="date">
                      {capitalizeFirstLetter(
                        moment(article.dateTime).fromNow()
                      )}
                    </p>

                    <p className="excerpt">{article.body}</p>

                    <Button
                      label="Read more"
                      type={ButtonTypes.SECONDARY}
                      extraClasses="readMore"
                      onClick={() => navigate(`/article/${article.slag}`)}
                    />

                    <div className="moodLevel">
                      <Tooltip
                        placement="right"
                        title={`${getSentiment(article.sentiment)} feeling`}
                      >
                        {getSentiment(article.sentiment) === "Positive" ? (
                          <div className="bars good" />
                        ) : getSentiment(article.sentiment) === "Negative" ? (
                          <div className="bars bad" />
                        ) : (
                          <div className="bars" />
                        )}
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NoFavoriteNews />
            )}

            {news ? (
              <Pagination
                simple={isMobile}
                current={pager.page}
                onChange={loadMoreNews}
                showSizeChanger={false}
                total={news.totalElements}
                pageSize={pager.size}
                hideOnSinglePage
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteNewsPage;
