import {
  GetStakingPoolsFailurePayload,
  GetStakingPoolsSuccessPayload,
  IDepositStakingFailurePayload,
  IGetStakingPoolDetailsParams,
  IGetStakingPoolDetailsResponse,
  IGetStakingPoolSummaryParams,
  IGetStakingPoolSummaryResponse,
  StakingState,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: StakingState = {
  loading: false,
  error: null,
  stakingPools: {},
  stakingPoolSummaryLoading: false,
  stakingPoolSummary: null,

  stakingPoolDetailsLoading: false,
  stakingPoolDetails: null,

  depositStakingLoading: false,
  depositStakingSuccess: false,
  depositStakingErrors: [],
};

const stakingSlice = createSlice({
  name: "staking",
  initialState,
  reducers: {
    resetState() {
      return {
        ...initialState,
      };
    },
    getStakingPoolsRequest(state) {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    getStakingPoolsSuccess(
      state,
      action: PayloadAction<GetStakingPoolsSuccessPayload>
    ) {
      return {
        ...state,
        loading: false,
        stakingPools: action.payload.stakingPools,
        error: false,
      };
    },
    getStakingPoolsFailure(
      state,
      action: PayloadAction<GetStakingPoolsFailurePayload>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    },
    getStakingPoolSummaryRequest(
      state,
      action: PayloadAction<IGetStakingPoolSummaryParams>
    ) {
      return {
        ...state,
        params: action.payload,
        stakingPoolSummaryLoading: false,
      };
    },
    getStakingPoolSummarySuccess(
      state,
      action: PayloadAction<IGetStakingPoolSummaryResponse>
    ) {
      return {
        ...state,
        stakingPoolSummary: action.payload.summary,
        stakingPoolSummaryLoading: false,
      };
    },
    getStakingPoolSummaryFailure(state) {
      return {
        ...state,
        stakingPoolSummaryLoading: false,
      };
    },
    getStakingPoolDetailsRequest(
      state,
      action: PayloadAction<IGetStakingPoolDetailsParams>
    ) {
      return {
        ...state,
        params: action.payload,
        stakingPoolDetailsLoading: false,
      };
    },
    getStakingPoolDetailsSuccess(
      state,
      action: PayloadAction<IGetStakingPoolDetailsResponse>
    ) {
      return {
        ...state,
        stakingPoolDetails: action.payload.details,
        stakingPoolDetailsLoading: false,
      };
    },
    getStakingPoolDetailsFailure(state) {
      return {
        ...state,
        stakingPoolDetailsLoading: false,
      };
    },
    resetStakingPoolSummary(state) {
      return {
        ...state,
        stakingPoolSummaryLoading: false,
        stakingPoolSummary: null,
      };
    },
    resetStakingPoolDetails(state) {
      return {
        ...state,
        stakingPoolDetailsLoading: false,
        stakingPoolDetails: null,
      };
    },
    resetStakingPoolDeposit(state) {
      return {
        ...state,
        depositStakingLoading: false,
        depositStakingSuccess: false,
        depositStakingErrors: [],
      };
    },
    depositStakingRequest(
      state,
      action: PayloadAction<IGetStakingPoolSummaryParams>
    ) {
      return {
        ...state,
        params: action.payload,
        depositStakingLoading: true,
        depositStakingSuccess: false,
        depositStakingErrors: [],
      };
    },
    depositStakingSuccess(state) {
      return {
        ...state,
        depositStakingLoading: false,
        depositStakingSuccess: true,
        depositStakingErrors: [],
      };
    },
    depositStakingFailure(
      state,
      action: PayloadAction<IDepositStakingFailurePayload>
    ) {
      return {
        ...state,
        depositStakingLoading: false,
        depositStakingSuccess: false,
        depositStakingErrors: action.payload.errors,
      };
    },
  },
});

export const {
  resetState,
  getStakingPoolsRequest,
  getStakingPoolsFailure,
  getStakingPoolsSuccess,
  getStakingPoolSummaryRequest,
  getStakingPoolSummarySuccess,
  getStakingPoolSummaryFailure,
  getStakingPoolDetailsRequest,
  getStakingPoolDetailsSuccess,
  getStakingPoolDetailsFailure,
  resetStakingPoolSummary,
  depositStakingRequest,
  depositStakingSuccess,
  depositStakingFailure,
  resetStakingPoolDetails,
  resetStakingPoolDeposit,
} = stakingSlice.actions;

export default stakingSlice.reducer;
