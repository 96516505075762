import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { FiHelpCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserSelector,
  qrToggleLoadingSelector,
  qrToggleSuccessSelector,
} from "./selectors";
import { getCurrentUserRequest, toggleMFAQrRequest } from "./userSlice";
import MFAtOtpsGuideModal from "./MFAOtpsGuideModal";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const EmailOtpSettings = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showMFAOtpsModal, setShowMFAOtpsModal] = useState(false);
  const [shouldReceiveTroughMFA, setShouldReceiveTroughMFA] = useState(null);

  const user = useSelector(getCurrentUserSelector);
  const qrToggleLoading = useSelector(qrToggleLoadingSelector);
  const qrToggleSuccess = useSelector(qrToggleSuccessSelector);

  useEffect(() => {
    dispatch(getCurrentUserRequest());
  }, []);

  useEffect(() => {
    if (user) {
      setShouldReceiveTroughMFA(user.state.properties?.isGoogleTfaSetup);
    }
  }, [user]);
  useEffect(() => {
    if (qrToggleSuccess) {
      dispatch(getCurrentUserRequest());
    }
  }, [qrToggleSuccess]);
  const optionChanged = (val) => {
    if (val) {
      setShowMFAOtpsModal(true);
    } else {
      const sendForm = async () => {
        const token = await executeRecaptcha("mfa");
        dispatch(
          toggleMFAQrRequest({
            captchaToken: token,
          })
        );
      };
      sendForm();
      setShouldReceiveTroughMFA(val);
    }
  };
  const userHasScannedQr = () => {
    setShowMFAOtpsModal(false);
  };
  const mFACodeValidated = () => {
    setShouldReceiveTroughMFA(true);
    setShowMFAOtpsModal(false);
  };
  return (
    <>
      <div className="otpSettings">
        <p>
          If you want to change the way in which you'll receive your OTP, you
          can easily switch between receiving the code via Email or via MFA
          (Multi-Factor Authentication).
        </p>

        <a onClick={() => setShowMFAOtpsModal(true)}>
          <FiHelpCircle />
          How do I setup MFA?
        </a>

        {shouldReceiveTroughMFA !== null ? (
          <div className="radioValues">
            <div className="row">
              <span className={shouldReceiveTroughMFA ? "" : "active"}>
                Email
              </span>
              <Switch
                disabled={qrToggleLoading}
                checked={!shouldReceiveTroughMFA}
                onChange={(val) => optionChanged(!val)}
              />
            </div>

            <div className="row">
              <span className={shouldReceiveTroughMFA ? "active" : ""}>
                MFA
              </span>
              <Switch
                disabled={qrToggleLoading}
                checked={shouldReceiveTroughMFA}
                onChange={(val) => optionChanged(val)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {showMFAOtpsModal && (
        <MFAtOtpsGuideModal
          isVisible={showMFAOtpsModal}
          closeModal={() => setShowMFAOtpsModal(false)}
          callback={() => userHasScannedQr()}
          additionalPayload={{}}
          recipient={null}
          onSuccess={() => mFACodeValidated()}
          endpoint={`${process.env.REACT_APP_AUTH_API_URL}/auth/mfa/validate`}
          type={"MFA"}
          closable
        />
      )}
    </>
  );
};

export default EmailOtpSettings;
