import React, { useEffect, useState } from "react";
import "./styles.less";
import { IStakingTokenDetailsTabProps } from "./interface";
import {
  getStakingPoolDetailsRequest,
  getStakingPoolsRequest,
} from "../Staking/stakingSlice";
import { resetStakingStatisticsState } from "../StakingStatistics/stakingStatisticsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getStakingPoolDetailsLoadingSelector,
  getStakingPoolDetailsSelector,
  getStakingPoolsLoadingSelector,
  getStakingPoolsSelector,
} from "../Staking/selectors";
import { Spin } from "antd";
import { formatNumber } from "_helpers/numberFormatter";
import { isEmpty } from "lodash";
import moment from "moment";
import { getWalletSelector } from "../Wallet/selectors";
import { getCoinDataRequest } from "../Wallet/walletSlice";
import Button from "../Button";
import { ButtonTypes } from "../Button/types";
import PiggyBank from "./../../assets/images/pork-hard-light.webp";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../_helpers/config";

export const StakingTokenDetailsTab = (props: IStakingTokenDetailsTabProps) => {
  const { tokenIconUrl, tokenName, tokenSymbol, contractAddress, title } =
    props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const wallet = useSelector(getWalletSelector);

  const stakingPools = useSelector(getStakingPoolsSelector);
  const stakingPoolsLoading = useSelector(getStakingPoolsLoadingSelector);

  const stakingPoolDetails = useSelector(getStakingPoolDetailsSelector);
  const stakingPoolDetailsLoading = useSelector(
    getStakingPoolDetailsLoadingSelector
  );

  const [currentStakingPool, setCurrentStakingPool] = useState<Array<any>>();
  const [currentSelectedPool, setCurrentSelectedPool] = useState<any>();

  useEffect(() => {
    if (contractAddress === process.env.REACT_APP_SEON_CONTRACT_ADDRESS) {
      dispatch(getStakingPoolsRequest());
    }
    return () => {
      dispatch(resetStakingStatisticsState());
    };
  }, [contractAddress]);

  useEffect(() => {
    if (!isEmpty(stakingPools)) {
      setCurrentStakingPool(stakingPools[contractAddress]);

      if (stakingPools[contractAddress]) {
        setCurrentSelectedPool(stakingPools[contractAddress][0]);
      }
    }
  }, [stakingPools]);

  useEffect(() => {
    if (currentSelectedPool?.id) {
      dispatch(
        getStakingPoolDetailsRequest({
          stakingPoolId: currentSelectedPool.id,
          public: true,
        })
      );

      dispatch(
        getCoinDataRequest({
          contractAddress: contractAddress,
          public: true,
        })
      );
    }
  }, [currentSelectedPool]);

  return (
    <div className="stakingTokenDetailsTab">
      <div className="tabHeader">
        <div className="headerTitle">Staking details</div>
        <div className="headerSubtitle">{title}</div>
      </div>

      {!(stakingPoolsLoading || stakingPoolDetailsLoading) &&
      currentStakingPool &&
      stakingPoolDetails &&
      contractAddress === process.env.REACT_APP_SEON_CONTRACT_ADDRESS ? (
        <div
          className={
            currentStakingPool.length <= 2
              ? "tabContent tabContentColumn"
              : "tabContent"
          }
        >
          <div className="stakingOptionsContainer">
            <div className="stakingOptionsHeader">
              <div className="tokenDetails">
                <img src={tokenIconUrl} alt={tokenSymbol} />
                <div className="tokenSymbol">{tokenSymbol}</div>
                <div className="tokenName"> - {tokenName}</div>
              </div>
              <div className="tokenAPYDetails">
                <div className="title">APY</div>
                <div className="amount">{`${formatNumber(
                  currentStakingPool[0].apy
                )}% ${
                  currentStakingPool.length > 1
                    ? ` - ${formatNumber(
                        currentStakingPool[currentStakingPool.length - 1].apy
                      )}%`
                    : ""
                }`}</div>
              </div>
            </div>
            {currentStakingPool.map((pool) => {
              return (
                <div
                  className="stakeItem"
                  onClick={() => setCurrentSelectedPool(pool)}
                  key={pool.id}
                >
                  <div className="stakeDetails">
                    <span>{pool.apy}%</span>
                    For {pool.durationInDays}{" "}
                    {pool.durationInDays === 1 ? "Day" : "Days"}
                  </div>

                  {currentSelectedPool.id === pool.id && (
                    <div className="selectedPoolFlag">Selected</div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="selectedStakingPoolDetails">
            <div className="stakingPoolDetailsHeader">
              <div className="headerTitle">Pool information</div>
              <div className="headerContent">
                <div className="currentAPY">
                  {formatNumber(currentSelectedPool.apy)}%
                </div>
                <div className="timeAmount">
                  for {currentSelectedPool.durationInDays}{" "}
                  {currentSelectedPool.durationInDays === 1 ? "day" : "days"}
                </div>
              </div>
            </div>
            <div>
              <div className="stakingPoolDataItem">
                <div className="itemTitle">Type</div>
                <div className="itemContent itemCapitalize">
                  {currentSelectedPool.type.toLowerCase()}
                </div>
              </div>
              <div className="stakingPoolDataItem">
                <div className="itemTitle">Min. deposit</div>
                <div className="itemContent">
                  {currentSelectedPool.minStakingAmount} {tokenSymbol}
                </div>
              </div>
              <div className="stakingPoolDataItem">
                <div className="itemTitle">Pool size</div>
                <div className="itemContent">
                  {formatNumber(currentSelectedPool.poolSize)} {tokenSymbol}
                </div>
              </div>
              <div className="stakingPoolDataItem">
                <div className="itemTitle">Rewards left</div>
                <div className="itemContent">
                  {formatNumber(stakingPoolDetails.stakingPoolAvailableFunds)}{" "}
                  {tokenSymbol}
                </div>
              </div>
              <div className="stakingPoolDataItem">
                <div className="itemTitle">Total value</div>
                <div className="itemContent">
                  {wallet[`${contractAddress}Data`] &&
                    wallet[`${contractAddress}Data`].currentPrice && (
                      <>
                        $
                        {formatNumber(
                          currentSelectedPool.poolSize *
                            wallet[`${contractAddress}Data`].currentPrice
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
            <div className="stakeNowButtonContainer">
              <Button
                label="Stake"
                type={ButtonTypes.PRIMARY}
                extraClasses="stakeNowButton"
                onClick={() =>
                  navigate(
                    `${ROUTES.stake}?id=${currentSelectedPool.id}&contract-address=${currentSelectedPool.contractAddress}`
                  )
                }
              />
            </div>
          </div>
        </div>
      ) : (
        isEmpty(stakingPools) &&
        contractAddress === process.env.REACT_APP_SEON_CONTRACT_ADDRESS && (
          <Spin />
        )
      )}
      {(!stakingPools[contractAddress] && !isEmpty(stakingPools)) ||
        (contractAddress !== process.env.REACT_APP_SEON_CONTRACT_ADDRESS && (
          <div className="noStakingPoolAvailable">
            <div className="noStakingPoolAvailableMessage">
              No staking pool available
            </div>
            <img src={PiggyBank} />
          </div>
        ))}
    </div>
  );
};
