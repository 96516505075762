import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  postCodeVerificationFailure,
  postCodeVerificationRequest,
  postCodeVerificationSuccess,
  getCanSendCodeRequest,
  getCanSendCodeSuccess,
  getCanSendCodeFailure,
  putResendEmailOtpRequest,
  getCanSendEmailCodeRequest,
  getCanSendEmailCodeSuccess,
  getCanSendEmailCodeFailure,
  toggleOtpRecipientRequest,
  toggleOtpRecipientSuccess,
  toggleOtpRecipientFailure,
} from "./codeVerificationSlice";

import { axios } from "../../services/axios.service";
import { AxiosResponse } from "axios";
import { ICodeVerificationResponse } from "./types";

/*
  Worker Saga: Fired on postCodeVerificationRequest action
*/
function* postCodeVerificationSaga(
  action: ReturnType<typeof postCodeVerificationRequest>
) {
  try {
    const response: AxiosResponse<ICodeVerificationResponse> = yield call(
      axios.put,
      `${action.payload.endpoint}?otp=${action.payload.otp}`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
    yield put(postCodeVerificationSuccess(response.data));
  } catch (e: any) {
    yield put(
      postCodeVerificationFailure({
        error: e,
      })
    );
  }
}

function* getCanSendCodeSaga(action: ReturnType<typeof getCanSendCodeRequest>) {
  try {
    if (action.payload.userIsNotAuthenticated) {
      yield call(
        axios.put,
        `${process.env.REACT_APP_AUTH_API_URL}/otps?type=${action.payload.type}`,
        action.payload
      );
    } else {
      yield call(
        axios.head,
        `${process.env.REACT_APP_AUTH_API_URL}/otps?type=${action.payload.type}`
      );
    }
    yield put(
      getCanSendCodeSuccess(
        action.payload.type === "EMAIL"
          ? { canSendEmailCode: true }
          : { canSendSmsCode: true }
      )
    );
  } catch (e: any) {
    yield put(
      getCanSendCodeFailure(
        action.payload.type === "EMAIL"
          ? { canSendEmailCode: false }
          : { canSendSmsCode: false }
      )
    );
  }
}

function* getCanSendEmailCodeSaga(
  action: ReturnType<typeof getCanSendEmailCodeRequest>
) {
  try {
    if (action.payload.userIsNotAuthenticated) {
      yield call(
        axios.put,
        `${process.env.REACT_APP_AUTH_API_URL}/otps?type=${action.payload.type}`,
        action.payload
      );
    } else {
      yield call(
        axios.head,
        `${process.env.REACT_APP_AUTH_API_URL}/otps?type=${action.payload.type}`
      );
    }
    yield put(getCanSendEmailCodeSuccess());
  } catch (e: any) {
    if (e.response.status === 401) {
      yield put(getCanSendEmailCodeSuccess());
    } else {
      yield put(getCanSendEmailCodeFailure());
    }
  }
}

function* putResendEmailOtpSaga(
  action: ReturnType<typeof putResendEmailOtpRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/resend-otp?${
        action.payload.type
      }=${action.payload[action.payload.type.toLowerCase()]}`,
      action.payload,
      {
        headers: {
          "SeedOn-Recaptcha": action.payload.captchaToken,
        },
      }
    );
  } catch (e: any) {}
}

function* toggleOtpRecipientSaga(
  action: ReturnType<typeof toggleOtpRecipientRequest>
) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_AUTH_API_URL}/otps/channel?telegram=${action.payload.telegramEnabled}`,
      action.payload
    );
    yield put(toggleOtpRecipientSuccess());
  } catch (e: any) {
    yield put(toggleOtpRecipientFailure());
  }
}

/*
  Starts worker saga on latest dispatched `postCodeVerificationRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* loginSaga() {
  yield all([
    takeLatest(postCodeVerificationRequest, postCodeVerificationSaga),
    takeEvery(getCanSendCodeRequest, getCanSendCodeSaga),
    takeLatest(putResendEmailOtpRequest, putResendEmailOtpSaga),
    takeLatest(getCanSendEmailCodeRequest, getCanSendEmailCodeSaga),
    takeLatest(toggleOtpRecipientRequest, toggleOtpRecipientSaga),
  ]);
}

export default loginSaga;
