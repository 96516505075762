import { AppState } from "../../store/rootReducer";
import { createSelector } from "reselect";

const getConfig = (state: AppState) => state.layout.config;
const getAuthConfig = (state: AppState) => state.layout.authConfig;
const getShouldShowAuthModal = (state: AppState) =>
  state.layout.shouldShowAuthModal;

const getCurrencies = (state: AppState) => state.layout.currencies;
const getCurrenciesLoading = (state: AppState) =>
  state.layout.currenciesLoading;

export const getConfigSelector = createSelector(getConfig, (config) => config);
export const getAuthConfigSelector = createSelector(
  getAuthConfig,
  (config) => config
);
export const getShouldShowAuthModalSelector = createSelector(
  getShouldShowAuthModal,
  (res) => res
);

export const getCurrenciesSelector = createSelector(
  getCurrencies,
  (res) => res
);
export const getCurrenciesLoadingSelector = createSelector(
  getCurrenciesLoading,
  (res) => res
);
