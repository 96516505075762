import { createSelector } from "reselect";
import { AppState } from "../../store/rootReducer";

const getNotifications = (state: AppState) => state.notification.notifications;
const getNotificationsLoading = (state: AppState) =>
  state.notification.notificationsLoading;
const getUnreadNotificationsCount = (state: AppState) =>
  state.notification.unreadNotificationsCount;
const getMarkAllNotificationsAsReadSuccess = (state: AppState) =>
  state.notification.markAllNotificationsAsReadSuccess;
const getMarkNotificationAsReadSuccess = (state: AppState) =>
  state.notification.markNotificationAsReadSuccess;

export const getNotificationsSelector = createSelector(
  getNotifications,
  (res) => res
);
export const getNotificationsLoadingSelector = createSelector(
  getNotificationsLoading,
  (res) => res
);
export const getUnreadNotificationsCountSelector = createSelector(
  getUnreadNotificationsCount,
  (res) => res
);
export const getMarkAllNotificationsAsReadSuccessSelector = createSelector(
  getMarkAllNotificationsAsReadSuccess,
  (res) => res
);
export const getMarkNotificationAsReadSuccessSelector = createSelector(
  getMarkNotificationAsReadSuccess,
  (res) => res
);
