import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  depositStakingFailure,
  depositStakingRequest,
  depositStakingSuccess,
  getStakingPoolDetailsFailure,
  getStakingPoolDetailsRequest,
  getStakingPoolDetailsSuccess,
  getStakingPoolsFailure,
  getStakingPoolsRequest,
  getStakingPoolsSuccess,
  getStakingPoolSummaryFailure,
  getStakingPoolSummaryRequest,
  getStakingPoolSummarySuccess,
} from "./stakingSlice";
import {
  IGetStakingPoolDetailsResponse,
  IGetStakingPoolSummaryResponse,
  IStakingResponse,
} from "./types";
import { axios } from "services/axios.service";

/*
  Starts worker saga on latest dispatched `getNewsRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* getStakingPoolsSaga(
  action: ReturnType<typeof getStakingPoolsRequest>
) {
  try {
    const response: AxiosResponse<IStakingResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/staking-pools`
    );
    yield put(
      getStakingPoolsSuccess({
        stakingPools: response.data,
      })
    );
  } catch (e: any) {
    yield put(
      getStakingPoolsFailure({
        error: e.response.data,
      })
    );
  }
}

function* getStakingPoolSummarySaga(
  action: ReturnType<typeof getStakingPoolSummaryRequest>
) {
  try {
    const response: AxiosResponse<IGetStakingPoolSummaryResponse> = yield call(
      axios.put,
      `${process.env.REACT_APP_API_URL}/${
        action.payload.public ? "public/" : ""
      }staking/${action.payload.stakingPoolId}/summary`,
      action.payload
    );
    yield put(
      getStakingPoolSummarySuccess({
        summary: response.data,
      })
    );
  } catch (e: any) {
    yield put(getStakingPoolSummaryFailure());
  }
}

function* getStakingPoolDetailsSaga(
  action: ReturnType<typeof getStakingPoolDetailsRequest>
) {
  try {
    const response: AxiosResponse<IGetStakingPoolDetailsResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/${
        action.payload.public ? "public/" : ""
      }staking/${action.payload.stakingPoolId}/details`
    );
    yield put(
      getStakingPoolDetailsSuccess({
        details: response.data,
      })
    );
  } catch (e: any) {
    yield put(getStakingPoolDetailsFailure());
  }
}

function* depositStakingSaga(action: ReturnType<typeof depositStakingRequest>) {
  try {
    yield call(
      axios.put,
      `${process.env.REACT_APP_API_URL}/staking/${action.payload.stakingPoolId}/deposit`,
      action.payload
    );
    yield put(depositStakingSuccess());
  } catch (e: any) {
    yield put(
      depositStakingFailure({
        errors: e.response.data,
        // errors: [
        //     {error: 'AVAILABLE_AMOUNT_TO_STAKE', hint: {data: [1000]}},
        //     {error: 'STAKING_AMOUNT_EXCEEDS_POOLS_REMAINING_BALANCE', hint: {data: [1000]}},
        //     {error: 'STAKING_POOL_MAX_ALLOCATION_PER_USER_EXCEEDED', hint: {data: [1000]}},
        //     ]
      })
    );
  }
}

function* walletSaga() {
  yield all([
    takeLatest(getStakingPoolsRequest, getStakingPoolsSaga),
    takeLatest(getStakingPoolSummaryRequest, getStakingPoolSummarySaga),
    takeLatest(getStakingPoolDetailsRequest, getStakingPoolDetailsSaga),
    takeLatest(depositStakingRequest, depositStakingSaga),
  ]);
}

export default walletSaga;
