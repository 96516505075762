import React, { useCallback, useEffect, useState } from "react";
import { globalVariables } from "../../_helpers/config";
import { useWindowSize } from "../../hooks/windowSize";
import { debounce } from "lodash";
import "./styles.less";

const dotsCount = {
  desktop: 20,
  tablet: 12,
  mobile: 8,
};

export const DottedBackground = () => {
  const windowSize = useWindowSize();

  const [dotsRendered, setDotsRendered] = useState(false);
  const [lastRowNumber, setLastRowNumber] = useState<number>();
  const [lastColNumber, setLastColNumber] = useState<number>();

  const colNum =
    windowSize.width > globalVariables.desktopSize
      ? dotsCount.desktop
      : windowSize.width > globalVariables.mobile
      ? dotsCount.tablet
      : dotsCount.mobile;
  const rowSize = Math.round(windowSize.width / colNum);
  const rowNum = windowSize.height / rowSize;

  const removeDots = (
    rowStart: number,
    rowEnd: number,
    colStart: number,
    colEnd: number
  ) => {
    for (let rowIndex = rowStart; rowIndex < rowEnd; rowIndex++) {
      for (let colIndex = colStart; colIndex < colEnd; colIndex++) {
        const newDot = document.getElementById(`${rowIndex}-${colIndex}`);
        if (newDot) {
          newDot.remove();
        }
      }
    }
  };

  const addDots = (
    rowStart: number,
    rowEnd: number,
    colStart: number,
    colEnd: number,
    rowSize: number
  ) => {
    const dotsContainer = document.getElementById("dots-container");

    for (let rowIndex = rowStart; rowIndex < rowEnd; rowIndex++) {
      for (let colIndex = colStart; colIndex < colEnd; colIndex++) {
        const checkDot = document.getElementById(`${rowIndex}-${colIndex}`);
        if (!checkDot) {
          const newDot = document.createElement("div");
          newDot.className = "bg-dot";
          newDot.id = `${rowIndex}-${colIndex}`;
          newDot.style.left = `${rowSize * colIndex + rowSize / 2}px`;
          newDot.style.top = `${rowSize * rowIndex + rowSize}px`;

          dotsContainer.appendChild(newDot);
        }
      }
    }
  };

  const repositionDots = useCallback(
    debounce((rowNum: number, rowSize: number, colNum: number) => {
      let stopRow, stopCol;

      for (let rowIndex = 0; rowIndex < rowNum; rowIndex++) {
        for (let colIndex = 0; colIndex < colNum; colIndex++) {
          const newDot = document.getElementById(`${rowIndex}-${colIndex}`);
          if (newDot) {
            newDot.style.left = `${rowSize * colIndex + rowSize / 2}px`;
            newDot.style.top = `${rowSize * rowIndex + rowSize}px`;
          } else {
            stopRow = stopRow ?? rowIndex;
            stopCol = stopCol ?? colIndex;
          }
        }
      }

      if (lastRowNumber > rowNum) {
        removeDots(
          Math.round(rowNum),
          lastRowNumber,
          0,
          lastColNumber > colNum ? lastColNumber : colNum
        );
      }

      if (lastColNumber >= colNum) {
        removeDots(
          0,
          lastRowNumber > rowNum ? lastRowNumber : rowNum,
          colNum,
          lastColNumber
        );
      }

      setLastRowNumber(rowNum);
      if (stopRow !== undefined) {
        addDots(stopRow, rowNum, 0, colNum, rowSize);
      }

      if (stopCol !== undefined) {
        addDots(0, rowNum, stopCol, colNum, rowSize);
      }
    }, 50),
    [lastRowNumber, lastColNumber]
  );

  useEffect(() => {
    if (windowSize.width && windowSize.height) {
      const appComponent = document.getElementsByClassName("app-container")[0];

      if (!dotsRendered) {
        const dotsContainer = document.createElement("div");
        dotsContainer.id = "dots-container";
        appComponent.appendChild(dotsContainer);

        for (let rowIndex = 0; rowIndex < rowNum; rowIndex++) {
          for (let colIndex = 0; colIndex < colNum; colIndex++) {
            const newDot = document.createElement("div");
            newDot.className = "bg-dot";
            newDot.id = `${rowIndex}-${colIndex}`;
            newDot.style.left = `${rowSize * colIndex + rowSize / 2}px`;
            newDot.style.top = `${rowSize * rowIndex + rowSize}px`;

            dotsContainer.appendChild(newDot);
          }
        }

        setLastRowNumber(rowNum);
        setLastColNumber(colNum);
        setDotsRendered(true);
      } else {
        repositionDots(rowNum, rowSize, colNum);
        setLastColNumber(colNum);
      }
    }
    const pulseDots = setInterval(() => {
      for (let animationIndex = 0; animationIndex < 3; animationIndex++) {
        const randomRow = Math.floor(Math.random() * Math.round(rowNum));
        const randomCol = Math.floor(Math.random() * colNum);

        const randomDot = document.getElementById(`${randomRow}-${randomCol}`);

        if (randomDot) {
          randomDot.style.animationDelay = `${
            Math.floor(Math.random() * 700) + 300
          }ms`;
          randomDot.classList.toggle(`pulse-effect`);
        }
      }
    }, 1500);

    return () => {
      clearInterval(pulseDots);
    };
  }, [windowSize]);

  useEffect(() => {
    return () => {
      const dotsContainer = document.getElementById("dots-container");
      if (dotsContainer) {
        dotsContainer.remove();
      }
    };
  }, []);

  return <></>;
};
