import { IQuoteParams, SwapState } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SwapState = {
  swapQuoteLoading: false,
  swapQuote: null,
  swapQuoteError: null,

  swapLoading: false,
  swapError: false,
  swapSuccess: false,
};

const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {
    resetState(state) {
      return {
        ...initialState,
      };
    },
    swapQuoteRequest(state, action: PayloadAction<IQuoteParams>) {
      return {
        ...state,
        params: action.payload,
        swapQuoteLoading: true,
        swapQuoteError: null,
      };
    },
    swapQuoteSuccess(state, action) {
      return {
        ...state,
        swapQuoteLoading: false,
        swapQuote: action.payload,
        swapQuoteError: null,
      };
    },
    swapQuoteFailure(state, action) {
      return {
        ...state,
        swapQuoteLoading: false,
        swapQuote: null,
        swapQuoteError: action.payload,
      };
    },
    swapRequest(state, action: PayloadAction<IQuoteParams>) {
      return {
        ...state,
        params: action.payload,
        swapLoading: true,
        swapError: false,
        swapSuccess: false,
      };
    },
    swapSuccess(state) {
      return {
        ...state,
        swapLoading: false,
        swapError: false,
        swapSuccess: true,
      };
    },
    swapFailure(state) {
      return {
        ...state,
        swapLoading: false,
        swapError: true,
        swapSuccess: false,
      };
    },
  },
});

export const {
  resetState,
  swapQuoteRequest,
  swapQuoteSuccess,
  swapQuoteFailure,
  swapRequest,
  swapSuccess,
  swapFailure,
} = swapSlice.actions;

export default swapSlice.reducer;
