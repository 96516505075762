import { Avatar, List, Modal } from "antd";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Button } from "../../components";
import { ButtonTypes } from "../../components/Button/types";

const ModalInfo = (props) => {
  const steps = [
    {
      title: 1,
      description: `Entry: To enter the giveaway, participants must complete the required actions specified in the official rules. Only one entry per person is allowed. Multiple entries from the same person will count as one participation entry.`,
      avatar: <Avatar />,
    },
    {
      title: 2,
      description:
        "Start and End Dates: The giveaway will begin on the 15th of March, 2023. All entries must be received by the end date in order to be eligible. (48 Hours).",
      avatar: <Avatar />,
    },
    {
      title: 3,
      description:
        "Selection of Winner: The winner will be selected in a random drawing from all eligible entries received. The sponsor or its designated representative will conduct the drawing. The winner will be notified via email on their SeedOn Finance account within 48 hours of being selected to win the prize. The email will contain a confirmation link tha must be clicked within 72 hours so that the prize can be redeemed. If the winner does not respond within 72 hours, the prize will be forfeited and an alternate random winner may be selected.",
      avatar: <Avatar />,
    },
    {
      title: 4,
      description: `Prize: The total prize is of $250 worth of $USDT tokens, which will be shared according to the Giveaway Prizes. The prize is non-transferable and cannot be redeemed for cash. The sponsor reserves the right to substitute the prize with a prize of equal or greater value if the advertised prize is unavailable for any reason.`,
      avatar: <Avatar />,
    },
    {
      title: 5,
      description:
        "Publicity: By accepting the prize, the winner agrees to allow the sponsor to use his or her name, likeness, and city of residence for promotional purposes without additional compensation, unless prohibited by law.",
      avatar: <Avatar />,
    },
    {
      title: 6,
      description:
        "Limitation of Liability: The sponsors are not responsible for lost, late, misdirected, damaged, or unintelligible entries. The sponsor is not responsible for technical, hardware, or software failures of any kind, lost or unavailable network connections, or failed, incomplete, garbled, or delayed computer transmissions. The sponsors are not responsible for any other errors or malfunctions of any kind, whether human, typographical, printing, mechanical, or electronic, in the administration of the giveaway or in the announcement of the prize.",
      avatar: <Avatar />,
    },
    {
      title: 7,
      description:
        "Disputes: All issues and questions concerning the construction, validity, interpretation, and enforceability of these official rules, or the rights and obligations of participants or the sponsor, shall be governed by and construed in accordance with the laws of the European Union, without giving effect to any choice of law or conflict of law rules.",
      avatar: <Avatar />,
    },
    {
      title: 8,
      description:
        "Release and Indemnification: By participating in the giveaway, participants release and hold harmless the sponsor, its affiliates, and their respective officers, directors, employees, agents, and assigns from any and all liability for any injuries, loss, or damage of any kind to person, including death, and property, arising in whole or in part, directly or indirectly, from acceptance, possession, use, or misuse of the prize, or participation in the giveaway.",
      avatar: <Avatar />,
    },
  ];
  const eligibilitySteps = [
    {
      title: 1,
      description: `Residency: Participants must be legal residents of the European Union.`,
      avatar: <Avatar />,
    },
    {
      title: 2,
      description: `Age: Participants must be at least 18 years old as of the start date of the giveaway.`,
      avatar: <Avatar />,
    },
    {
      title: 3,
      description: `Employee Restriction: Employees of the sponsor, as well as their immediate family members (spouse, parents, siblings, and children), are not eligible to participate.`,
      avatar: <Avatar />,
    },
    {
      title: 4,
      description: `Local Laws and Regulations: The giveaway is subject to all local laws and regulations and is void where prohibited by law.`,
      avatar: <Avatar />,
    },
    {
      title: 5,
      description: `Taxes: All federal, state, and local taxes are the sole responsibility of the winner and will be reported as required by law.`,
      avatar: <Avatar />,
    },
  ];
  return (
    <Modal
      closeIcon={<></>}
      open={props.isVisible}
      className="authModals walletModals tutorialModalInfo"
      footer={null}
      forceRender
    >
      <Scrollbars
        universal
        style={{ height: "75vh" }}
        renderView={(props) => <div {...props} className="scroll-view" />}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
      >
        <div className="heading">
          <h2>Terms & Conditions Buy Giveaways</h2>
          <p>The following terms and conditions apply to this giveaway:</p>
        </div>

        <List
          dataSource={steps}
          itemLayout="horizontal"
          className="listTutorial"
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={item.title}
                description={
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                }
              />
            </List.Item>
          )}
        />
        <div className="section">
          <p>
            By participating in this giveaway, participants agree to be bound by
            these official rules and the decisions of the sponsor, which are
            final and binding in all respects.
          </p>
          <h3>Eligibility</h3>
          <p>
            To be eligible for this giveaway, participants must meet the
            following requirements:
          </p>
        </div>
        <List
          dataSource={eligibilitySteps}
          itemLayout="horizontal"
          className="listTutorial"
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={item.title}
                description={
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                }
              />
            </List.Item>
          )}
        />
        <div className="section">
          <p>
            By entering this giveaway, participants agree to abide by all terms
            and conditions stated herein. The sponsor reserves the right to
            verify the eligibility of participants and to disqualify any
            participant who fails to meet the eligibility requirements or who
            violates any of the terms and conditions.
          </p>
          <h3>Disclaimer</h3>
          <p>
            This giveaway is open only to residents of the European Union.
            Employees of the sponsors and their immediate families are not
            eligible. The giveaway is subject to all local laws and regulations
            and is void where prohibited by law. All federal, state, and local
            taxes are the sole responsibility of the winners.
          </p>
        </div>

        <div className="section">
          <h3>Selection Process</h3>
          <p>
            Within 7 days of each Giveaway's end, winners will be chosen and
            contacted via private message on Twitter. In a maximum of 72 Hours
            after contacting the winners, they will be announced on SeedOn’s
            Campaign page (
            <a
              href={"https://finance.seedon.io/fiat-deposits-campaign"}
              target={"_blank"}
            >
              https://finance.seedon.io/fiat-deposits-campaign
            </a>
            ) & via email for confirming the prize acceptance. For an entry to
            be valid, participants must follow all the giveaway rules &
            conditions. We will randomly pick a number of 5 winners for the
            first week, and 5 winners for the second week of our campaign to
            arrive at the winners of each Weekly Giveaway. Only one entry per
            person is valid. The odds of winning are random.
          </p>
        </div>
        <div className="section">
          <h3>Prizes Distribution</h3>
          <p>
            Winners of the weekly Giveaway will be randomly selected and
            notified via email within a maximum of 48 hours after being selected
            as a potential winner of the prize. The prizes will be distributed
            to each recipient within a maximum of 72 hours after confirming
            their participation via the link received on the selection email.
          </p>
        </div>
        <Button
          type={ButtonTypes.PRIMARY}
          label="CLOSE"
          extraClasses="confirmationButton"
          onClick={() => props.closeModal()}
        />
      </Scrollbars>
    </Modal>
  );
};

export default ModalInfo;
