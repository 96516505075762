import {
  GetCampaignsRequestPayload,
  GetCampaignsResponsePayload,
  GetFilteredCampaignsRequestPayload,
  ICampaign,
  ICampaignResponse,
  ICategory,
  ICategoryResponse,
  IFilteredCampaign,
  IGetCategoriesRequest,
  IGetTagsRequest,
  ITag,
  ITagResponse,
  LaunchpadState,
} from "./type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetTokenDataRequestPayload,
  GetTokenDataResponsePayload,
} from "../../components/TokenDetails/types";
import { a } from "react-spring";

const initialState: LaunchpadState = {
  openSearchDrawer: false,
  campaignsLoading: false,
  campaigns: null,
  categories: [],
  categoriesLoading: false,
  tags: [],
  tagsLoading: false,
  campaignsFilteredLoading: false,
  campaignsFiltered: null,
};

const launchpadSlice = createSlice({
  name: "launchpad",
  initialState,
  reducers: {
    resetState: (state) => {
      return initialState;
    },
    setOpenSearchDrawer: (state, action) => {
      state.openSearchDrawer = action.payload;
    },
    getCampaignsRequest(
      state,
      action: PayloadAction<GetCampaignsRequestPayload>
    ) {
      return {
        ...state,
        campaignsLoading: true,
        campaigns: null,
        params: action.payload,
      };
    },
    getCampaignsSuccess(state, action: PayloadAction<ICampaignResponse>) {
      return {
        ...state,
        campaignsLoading: false,
        campaigns: action.payload,
      };
    },
    getCampaignsFailure(state) {
      return {
        ...state,
        campaignsLoading: false,
        campaigns: null,
      };
    },
    getCategoriesRequest(state, action: PayloadAction<IGetCategoriesRequest>) {
      return {
        ...state,
        categories: [],
        categoriesLoading: true,
        params: action.payload,
      };
    },
    getCategoriesSuccess(state, action: PayloadAction<ICategory[]>) {
      return {
        ...state,
        categories: action.payload,
        categoriesLoading: false,
      };
    },
    getCategoriesFailure(state) {
      return {
        ...state,
        categories: [],
        categoriesLoading: false,
      };
    },
    getTagsRequest(state, action: PayloadAction<IGetTagsRequest>) {
      return {
        ...state,
        tags: [],
        tagsLoading: true,
        params: action.payload,
      };
    },
    getTagsSuccess(state, action: PayloadAction<ITag[]>) {
      return {
        ...state,
        tags: action.payload,
        tagsLoading: false,
      };
    },
    getTagsFailure(state) {
      return {
        ...state,
        tags: [],
        tagsLoading: false,
      };
    },
    getCampaignsFilteredRequest(
      state,
      action: PayloadAction<GetFilteredCampaignsRequestPayload>
    ) {
      return {
        ...state,
        campaignsFilteredLoading: true,
        campaignsFiltered: null,
        params: action.payload,
      };
    },
    getCampaignsFilteredSuccess(
      state,
      action: PayloadAction<IFilteredCampaign[]>
    ) {
      return {
        ...state,
        campaignsFilteredLoading: false,
        campaignsFiltered: action.payload,
      };
    },
    getCampaignsFilteredFailure(state) {
      return {
        ...state,
        campaignsFilteredLoading: false,
        campaignsFiltered: [],
      };
    },
  },
});

export const {
  setOpenSearchDrawer,
  getCampaignsRequest,
  getCampaignsSuccess,
  getCampaignsFailure,
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFailure,
  getTagsRequest,
  getTagsSuccess,
  getTagsFailure,
  getCampaignsFilteredRequest,
  getCampaignsFilteredSuccess,
  getCampaignsFilteredFailure,
  resetState,
} = launchpadSlice.actions;

export default launchpadSlice.reducer;
