import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  getAuthConfigFailure,
  getAuthConfigRequest,
  getAuthConfigSuccess,
  getConfigFailure,
  getConfigRequest,
  getConfigSuccess,
  getCurrenciesFailure,
  getCurrenciesRequest,
  getCurrenciesSuccess,
} from "./layoutSlice";

import { IConfigResponse } from "./types";
import { axios } from "../../services/axios.service";

/*
  Worker Saga: Fired on getConfigRequest action
*/
function* getConfigSaga(action: ReturnType<typeof getConfigRequest>) {
  try {
    const response: AxiosResponse<IConfigResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/configuration`
    );
    yield put(
      getConfigSuccess({
        config: response.data,
      })
    );
  } catch (e: any) {
    yield put(getConfigFailure());
  }
}

/*
  Worker Saga: Fired on getAuthConfigRequest action
*/
function* getAuthConfigSaga(action: ReturnType<typeof getAuthConfigRequest>) {
  try {
    const response: AxiosResponse<IConfigResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_AUTH_API_URL}/auth/configuration`
    );
    yield put(
      getAuthConfigSuccess({
        config: response.data,
      })
    );
  } catch (e: any) {
    yield put(getAuthConfigFailure());
  }
}

/*
  Worker Saga: Fired on getConfigRequest action
*/
function* getCurrenciesSaga() {
  try {
    const response: AxiosResponse<IConfigResponse> = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/currencies`
    );
    yield put(
      getCurrenciesSuccess({
        currencies: response.data,
      })
    );
  } catch (e: any) {
    yield put(getCurrenciesFailure());
  }
}

/*
  Starts worker saga on latest dispatched `getConfigRequest` or `forgotPasswordRequest` action.
  Allows concurrent increments.
*/
function* configSaga() {
  yield all([
    takeLatest(getConfigRequest, getConfigSaga),
    takeLatest(getCurrenciesRequest, getCurrenciesSaga),
    takeLatest(getAuthConfigRequest, getAuthConfigSaga),
  ]);
}

export default configSaga;
