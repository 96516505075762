import React, { useEffect, useState } from "react";
import "./style.less";
import { ICodeVerificationProps } from "./interface";
import ReactCodeInput from "react-verification-code-input";
import { Button } from "../index";
import { Button as AntDButton, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getCanSendCodeRequest,
  postCodeVerificationRequest,
  putResendEmailOtpRequest,
  resetCanSendCodeState,
  resetState,
} from "./codeVerificationSlice";
import {
  getCanSendCode,
  getCodeVerificationResponse,
  getCodeVerificationSuccess,
  getErrorSelector,
  postLoadingSelector,
} from "./selectors";
import { useCookies } from "react-cookie";
import {
  API_VALIDATION_ERROR_MESSAGES,
  VALIDATION_ERROR_MESSAGES,
} from "../../_helpers/config";
import { getAuthConfigSelector } from "../Layout/selectors";
import { putResendSmsOtpRequest } from "../PhoneValidationModal/phoneValidationSlice";

import { FiRotateCw } from "react-icons/fi";
import { getEncodedString } from "../../_helpers/stringEncoder";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { pushGtmEvent } from "../../_helpers/gtm";
import { GtmEvents } from "../../_helpers/types";
import { ButtonTypes } from "../Button/types";

const CodeVerification: React.FC<ICodeVerificationProps> = (
  props: ICodeVerificationProps
) => {
  const dispatch = useDispatch();

  const canSendCode = useSelector(getCanSendCode);
  const [codeValue, setCodeValue] = useState(null);
  const [canSubmitCode, setCanSubmitCode] = useState(false);

  let timeRemaining = 30;
  const [retryButtonLabel, setRetryButtonLabel] = useState(
    "Code not received? Send again."
  );
  const [retryButtonDisabled, setRetryButtonDisabled] = useState(false);

  const [_, setCookie] = useCookies(["sOnFinanceToken"]);

  const codeVerificationResponse = useSelector(getCodeVerificationResponse);
  const error = useSelector(getErrorSelector);
  const config = useSelector(getAuthConfigSelector);
  const loading = useSelector(postLoadingSelector);
  const [cookie] = useCookies(["sOnFinanceToken"]);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const codeVerificationSuccess = useSelector(getCodeVerificationSuccess);

  const codeInputCompleted = (value) => {
    setCanSubmitCode(true);
    setCodeValue(value);
  };

  const submitCode = async () => {
    const token = await executeRecaptcha("otp");
    dispatch(
      postCodeVerificationRequest({
        otp: codeValue,
        endpoint: props.endpoint,
        ...props.additionalPayload,
        captchaToken: token,
      })
    );
  };

  const resendOtp = () => {
    setRetryButtonDisabled(true);
    timeRemaining = 30;

    const refreshInterval = setInterval(() => {
      if (timeRemaining > 1) {
        setRetryButtonLabel(`Retry in ${timeRemaining - 1} seconds`);
        timeRemaining = timeRemaining - 1;
      } else {
        setRetryButtonLabel(`Retry`);
        setRetryButtonDisabled(false);
        clearInterval(refreshInterval);
      }
    }, 1000);

    if (props.type.toLowerCase() === "email") {
      pushGtmEvent(GtmEvents.LOGIN_STEP_2_RETRY);
    }

    dispatch(
      getCanSendCodeRequest({
        type: props.type.toUpperCase(),
        ...props.additionalPayload,
        userIsNotAuthenticated: cookie.sOnFinanceToken === undefined,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetState());
      dispatch(resetCanSendCodeState());
    };
  }, []);
  useEffect(() => {
    if (props.type.toLowerCase() === "email" && error) {
      pushGtmEvent(GtmEvents.LOGIN_STEP_2_INVALID_CODE);
    }
  }, [error]);
  useEffect(() => {
    if (canSendCode === false) {
      if (props.type.toLowerCase() === "email") {
        pushGtmEvent(GtmEvents.LOGIN_STEP_2_TOO_MANY_CODES);
      }
    }
  }, [canSendCode]);
  useEffect(() => {
    if (canSendCode) {
      notification.success({
        icon: <FiRotateCw />,
        message: "We have sent you the code again. Please check your device.",
      });

      if (props.type === "email") {
        const resendCode = async () => {
          const token = await executeRecaptcha("resendOtp");
          dispatch(
            putResendEmailOtpRequest({
              email: props.recipient,
              type: "email",
              captchaToken: token,
            })
          );
        };
        resendCode().then(() => {});
      } else {
        const resetCode = async () => {
          const token = await executeRecaptcha("resendOtp");
          dispatch(putResendSmsOtpRequest({ captchaToken: token }));
        };
        resetCode().then(() => {});
      }
    }
  }, [canSendCode]);

  useEffect(() => {
    if (codeVerificationSuccess) {
      if (codeVerificationResponse.token) {
        pushGtmEvent(GtmEvents.LOGIN_SUCCESS);
        setCookie(
          "sOnFinanceToken",
          JSON.stringify(codeVerificationResponse.token),
          {
            path: "/",
            maxAge: config?.securityConfiguration?.tokenValidiy,
            sameSite: true,
          }
        );
      }
      props.onSuccess(codeVerificationResponse);
    }
  }, [codeVerificationSuccess]);

  return (
    <div className={`wallet`}>
      {!props.hideTitle && (
        <>
          {props.type !== "MFA" ? (
            <div className="heading">
              {props.type === "email" ? (
                <>
                  <h2>We sent you a code</h2>
                  <h4>Please check your email</h4>
                </>
              ) : (
                <>
                  <h2>We sent you a code</h2>
                  <h4>Please check your phone</h4>
                </>
              )}

              {props.recipient && (
                <p>
                  Please enter the code you received on{" "}
                  {getEncodedString(props.recipient)}.
                </p>
              )}

              <AntDButton
                onClick={() => resendOtp()}
                disabled={retryButtonDisabled}
                className="goBackButton"
              >
                <FiRotateCw className="sendAgainIcon" />
                {retryButtonLabel}
              </AntDButton>
            </div>
          ) : (
            <div className="heading">
              <h2>Multi factor authentication</h2>
              <h4>
                Please input the authentication code displayed in your Google
                Authenticator MFA App from your device.
              </h4>
            </div>
          )}
        </>
      )}

      {/*@ts-ignore*/}
      <ReactCodeInput
        disabled={loading}
        className="codeInput"
        onComplete={(value) => codeInputCompleted(value)}
      />

      <Button
        type={ButtonTypes.PRIMARY}
        loading={loading}
        label={props.submitLabel}
        disabled={!canSubmitCode}
        extraClasses="submitButton"
        onClick={() => submitCode()}
      />

      {error ? (
        <span className="generalError">
          {API_VALIDATION_ERROR_MESSAGES[error.message]}
        </span>
      ) : null}

      {canSendCode === false ? (
        <span className="generalError">
          {VALIDATION_ERROR_MESSAGES.tooManyOtpAttempts}
        </span>
      ) : null}
    </div>
  );
};

export default CodeVerification;
