import {
  IGenerateOTPRequestPayload,
  IGetWithDrawEstimateRequestPayload,
  IGetWithDrawEstimateSuccessPayload,
  IPostWithdrawError,
  IPostWithdrawRequestPayload,
  IValidateWalletRequestPayload,
  WithdrawState,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: WithdrawState = {
  loading: false,
  error: null,
  showWithdrawModal: false,
  withdrawEstimateLoading: false,
  withdrawEstimate: null,
  withdrawEstimateError: null,
  withdrawLoading: false,
  withdrawSuccess: false,
  withdrawError: null,
  walletValidationLoading: false,
  walletValidationSuccess: false,
  walletValidationError: false,
};

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState,
  reducers: {
    resetState(state) {
      return {
        ...initialState,
      };
    },
    setShowWithdrawModal(state, action) {
      return {
        ...state,
        showWithdrawModal: action.payload,
      };
    },
    getWithdrawEstimateRequest(
      state,
      action: PayloadAction<IGetWithDrawEstimateRequestPayload>
    ) {
      return {
        ...state,
        withdrawEstimateLoading: true,
        withdrawEstimate: null,
        withdrawEstimateError: null,
        params: action.payload,
      };
    },
    getWithdrawEstimateSuccess(
      state,
      action: PayloadAction<IGetWithDrawEstimateSuccessPayload>
    ) {
      return {
        ...state,
        withdrawEstimateLoading: false,
        withdrawEstimateError: null,
        withdrawEstimate: action.payload.estimate,
      };
    },
    getWithdrawEstimateFailure(state, action) {
      return {
        ...state,
        withdrawEstimateLoading: false,
        withdrawEstimateError: action.payload.error,
        withdrawEstimate: null,
      };
    },
    resetWithdrawEstimate(state) {
      return {
        ...state,
        withdrawEstimateLoading: false,
        withdrawEstimateError: null,
        withdrawEstimate: null,
      };
    },
    postWithdrawRequest(
      state,
      action: PayloadAction<IPostWithdrawRequestPayload>
    ) {
      return {
        ...state,
        params: action.payload,
        withdrawLoading: true,
        withdrawSuccess: false,
        withdrawError: null,
      };
    },
    postWithdrawSuccess(state) {
      return {
        ...state,
        withdrawLoading: false,
        withdrawSuccess: true,
        withdrawError: null,
      };
    },
    postWithdrawFailure(state, action: PayloadAction<IPostWithdrawError>) {
      return {
        ...state,
        withdrawLoading: false,
        withdrawSuccess: false,
        withdrawError: action.payload.error,
      };
    },
    validateWalletRequest(
      state,
      action: PayloadAction<IValidateWalletRequestPayload>
    ) {
      return {
        ...state,
        params: action.payload,
        walletValidationLoading: true,
        walletValidationSuccess: false,
        walletValidationError: false,
      };
    },
    validateWalletSuccess(state) {
      return {
        ...state,
        walletValidationLoading: false,
        walletValidationSuccess: true,
        walletValidationError: false,
      };
    },
    validateWalletFailure(state) {
      return {
        ...state,
        walletValidationLoading: false,
        walletValidationSuccess: false,
        walletValidationError: true,
      };
    },
    generateOTPCodeRequest(state, action) {
      return {
        ...state,
        params: action.payload,
      };
    },
    resendOTPCodeRequest(
      state,
      action: PayloadAction<IGenerateOTPRequestPayload>
    ) {
      return {
        ...state,
        params: action.payload.type,
      };
    },
    resetWithdraw(state) {
      return {
        ...state,
        withdrawLoading: false,
        withdrawSuccess: false,
        withdrawError: null,
      };
    },
    resetWalletValidation(state) {
      return {
        ...state,
        walletValidationLoading: false,
        walletValidationSuccess: false,
        walletValidationError: false,
      };
    },
  },
});

export const {
  resetState,
  setShowWithdrawModal,
  getWithdrawEstimateRequest,
  getWithdrawEstimateSuccess,
  getWithdrawEstimateFailure,
  resetWithdrawEstimate,
  postWithdrawRequest,
  postWithdrawSuccess,
  postWithdrawFailure,
  resetWithdraw,
  validateWalletRequest,
  validateWalletSuccess,
  validateWalletFailure,
  generateOTPCodeRequest,
  resetWalletValidation,
  resendOTPCodeRequest,
} = withdrawSlice.actions;

export default withdrawSlice.reducer;
